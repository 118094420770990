import {
    Box,
    CircularProgress,
    InputAdornment,
    TextField,
    useTheme,
} from '@mui/material';
import { TextFieldProps } from '@mui/material';
import React, { ReactElement, ReactNode } from 'react';
import { MdSearch } from 'react-icons/md';

export type SearchInputProps = Omit<TextFieldProps, 'value' | 'onChange'> & {
    value: string | null;
    onChange: (value: string | null) => void;
    loading?: boolean;
    adornment?: ReactNode | null;
};

const threshold = 200;

const SearchInput = (props: SearchInputProps): ReactElement => {
    const { value, onChange, loading = false, adornment, ...rest } = props;

    const [tValue, setTValue] = React.useState(value);
    const [timer, setTimer] = React.useState<null | number>(null);

    const { palette } = useTheme();

    React.useEffect(() => {
        if (timer !== null) {
            if (timer == 0) {
                onChange(tValue);
            } else {
                const timeout = setTimeout(() => {
                    setTimer(timer - threshold / 4);
                }, threshold);

                return () => clearTimeout(timeout);
            }
        }
    }, [timer]);

    React.useEffect(() => {
        if (value !== tValue) setTValue(value);
    }, [value]);

    return (
        <TextField
            value={tValue}
            onChange={(e) => {
                setTValue(e.target.value || '');
                setTimer(threshold);
            }}
            {...rest}
            InputProps={{
                ...rest.InputProps,
                endAdornment: loading ? (
                    <InputAdornment position="end">
                        <Box sx={{ display: 'flex' }}>
                            <CircularProgress size={20} />
                        </Box>
                    </InputAdornment>
                ) : undefined,
                startAdornment:
                    adornment === null ? undefined : adornment ? (
                        adornment
                    ) : (
                        <InputAdornment position="start">
                            <MdSearch
                                style={
                                    tValue && tValue.length > 0
                                        ? { color: palette.primary.main }
                                        : undefined
                                }
                            />
                        </InputAdornment>
                    ),
            }}
        />
    );
};

export default SearchInput;
