import React, { ReactElement, ReactNode } from 'react';
import {
    getUiPermissions,
    UiPermission,
    UiPermissions,
} from '../../UiPermission';
import useMyProfile from '../../useMyProfile';
import { UserRole } from '../../UserRole';

export interface AuthGuyProps {
    children: ReactElement | null;
    challenge: { permission: UiPermission } | { role: UserRole };
}

const AuthGuy = (props: AuthGuyProps): ReactElement | null => {
    const { challenge, children } = props;

    const { permissions, roles } = useMyProfile();

    if ('permission' in challenge) {
        // check permission
        const uiPermissions = getUiPermissions(permissions);
        if (
            roles.includes(UserRole.Admin) ||
            roles.includes(UserRole.Manager) ||
            uiPermissions.some((p) => p.name == challenge.permission)
        )
            return children;
        else return null;
    } else {
        // check role
        if (roles.includes(challenge.role)) return children;
        else return null;
    }
};

export default AuthGuy;
