import { Box, TextField, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { MdCheck, MdDelete, MdRefresh } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import FormResult from '../../../../../../components/feedback/FormResult';
import CarefullButton from '../../../../../../components/inputs/Buttons/CarefulButton';
import SuccessButton from '../../../../../../components/inputs/Buttons/SuccessButton';
import AppNav from '../../../../../../components/Layout/AppNav/components';
import NavContent from '../../../../../../components/Layout/AppNav/components/NavContent';
import FormRow from '../../../../../../components/Layout/FormRow';
import NavHeader from '../../../../../../components/Layout/NavHeader';
import { useCompany } from '../../../../../../graphql/Company/operations/useCompany';
import {
    CreateCompanyArgs,
    CreateCompanyRes,
    useCompanyCreation,
} from '../../../../../../graphql/Company/operations/useCompanyCreation';
import {
    UpdateCompanyArgs,
    UpdateCompanyRes,
    useCompanyUpdate,
} from '../../../../../../graphql/Company/operations/useCompanyUpdate';
import { OperationResult } from '../../../../../../utils/types/OperationResult';

const CompanyForm = (): ReactElement => {
    const { id } = useParams();
    const nav = useNavigate();

    const [state, setState] = React.useState<
        UpdateCompanyArgs | CreateCompanyArgs
    >({
        data: {
            name: '',
        },
    });

    const { data } = useCompany({
        variables: { id: id || '' },
        skip: !id,
        fetchPolicy: 'network-only',
        onCompleted: ({ company }) => {
            setState({
                id: company._id,
                data: {
                    name: company.name,
                },
            });
        },
    });

    const [result, setResult] = React.useState<null | OperationResult<
        CreateCompanyRes | UpdateCompanyRes
    >>(null);

    const [handleCreate, { loading: createLoading }] = useCompanyCreation({
        variables: 'id' in state ? undefined : state,
        onCompleted: (data) => setResult({ success: true, data }),
        onError: (error) => setResult({ success: false, error }),
    });

    const [handleUpdate, { loading: updateLoading }] = useCompanyUpdate({
        variables:
            'id' in state ? { ...state, id: id || state.id || '' } : undefined,
        onCompleted: (data) => setResult({ success: true, data }),
        onError: (error) => setResult({ success: false, error }),
    });

    const getHoldup = (): string | null => {
        if (!state.data.name) return 'Please enter a company name.';
        return null;
    };

    return (
        <AppNav loading={updateLoading || createLoading}>
            {result ? (
                <FormResult
                    entity="Company"
                    clear={() => setResult(null)}
                    onComplete={() => nav('/library/companies')}
                >
                    {result}
                </FormResult>
            ) : (
                <NavContent>
                    {{
                        header: (
                            <NavHeader
                                back={
                                    data
                                        ? [
                                              data.company.name,
                                              `/library/companies/${data.company._id}`,
                                          ]
                                        : ['Companies', '/library/companies']
                                }
                            >
                                <Typography variant="crisp">
                                    {id && !data
                                        ? ''
                                        : data
                                        ? `Update ${data.company.name}`
                                        : 'New Company'}
                                </Typography>
                                <Box />
                            </NavHeader>
                        ),
                        content: (
                            <Box sx={{ maxWidth: 400 }}>
                                <FormRow>
                                    <TextField
                                        autoFocus
                                        fullWidth
                                        label="Name"
                                        value={state.data.name}
                                        onChange={(e) =>
                                            setState({
                                                ...state,
                                                data: {
                                                    ...state.data,
                                                    name: e.target.value,
                                                },
                                            })
                                        }
                                    />
                                </FormRow>
                                <FormRow>
                                    <SuccessButton
                                        holdup={getHoldup()}
                                        success={null}
                                        onSuccess={() => null}
                                        endIcon={<MdCheck />}
                                        onClick={() => {
                                            if ('id' in state) {
                                                handleUpdate();
                                            } else {
                                                handleCreate();
                                            }
                                        }}
                                    >
                                        Save
                                    </SuccessButton>
                                    {data && (
                                        <CarefullButton
                                            endIcon={
                                                data.company.deletion ? (
                                                    <MdRefresh />
                                                ) : (
                                                    <MdDelete />
                                                )
                                            }
                                            onClick={() => {
                                                handleUpdate({
                                                    variables: {
                                                        id: data.company._id,
                                                        data: {
                                                            name: data.company
                                                                .name,
                                                            deleted: true,
                                                        },
                                                    },
                                                    onCompleted: (data) =>
                                                        setResult({
                                                            success: true,
                                                            data,
                                                        }),
                                                    onError: (error) =>
                                                        setResult({
                                                            success: false,
                                                            error,
                                                        }),
                                                });
                                            }}
                                        >
                                            {data.company.deletion
                                                ? 'Restore Company'
                                                : 'Delete Company'}
                                        </CarefullButton>
                                    )}
                                </FormRow>
                            </Box>
                        ),
                    }}
                </NavContent>
            )}
        </AppNav>
    );
};

export default CompanyForm;
