import { gql } from '@apollo/client';
import { TinyCompany } from '../Company/Company';
import { TinyLocation } from '../Location/Location';

export class Appointment {
    date!: Date | null;
    time!: number | null;
    location!: TinyLocation | null;
    company!: TinyCompany | null;
}

export const AppointmentFragment = gql`
    fragment AppointmentFragment on Appointment {
        date
        time
        location {
            ...TinyLocationFragment
        }
        company {
            ...TinyCompanyFragment
        }
    }
`;
