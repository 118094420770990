import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import SmartTable from '../../../../../../../../components/display/SmartTable';
import SearchInput from '../../../../../../../../components/inputs/FieldInputs/SearchInput';
import { ItemType } from '../../../../../../../../graphql/Item/Item';
import { useUnits } from '../../../../../../../../graphql/Unit/operations/useUnits';
import { UnitFilter } from '../../../../../../../../graphql/Unit/UnitFilter';
import { baseUnits } from '../../../../../../../Recipes/components/RecipeDetailForm/components/ConversionForm';

export interface UnitTableProps {
    item_type: ItemType;
}

const UnitTable = (props: UnitTableProps): ReactElement => {
    const { item_type } = props;
    const nav = useNavigate();

    const [filter, setFilter] = React.useState<UnitFilter>({
        skip: 0,
        take: 50,
    });

    const { data, error, loading } = useUnits({
        variables: { filter: { ...filter, item_types: [item_type] } },
        fetchPolicy: 'network-only',
    });

    const count = data ? data.units.count : 0;
    const units = data ? data.units.items : [];

    return (
        <SmartTable
            data={units}
            getProps={(d) => ({
                id: d._id,
                onClick: () => nav(`/library/units/unit/${d._id}`),
            })}
            pagination={{
                count,
                filter,
                setFilter: (f) => setFilter({ ...filter, ...f }),
            }}
            controls={{
                Name: (
                    <SearchInput
                        placeholder="Name"
                        variant="standard"
                        InputProps={{ disableUnderline: true }}
                        value={filter.name || ''}
                        onChange={(name) =>
                            setFilter({
                                ...filter,
                                name: name || undefined,
                                skip: 0,
                            })
                        }
                    />
                ),
            }}
        >
            {{
                Name: (c) => c.name,
                ['Item type']: (c) => c.item_type,
                Class: (c) => c.class,
                ['Base equivelant']: (c) =>
                    `${c.base_per_unit} ${
                        c.item_type === ItemType.Product
                            ? `case${
                                  c.base_per_unit <= 1 && c.base_per_unit >= 0
                                      ? ''
                                      : 's'
                              }`
                            : baseUnits[c.class][
                                  c.base_per_unit <= 1 && c.base_per_unit >= 0
                                      ? 0
                                      : 1
                              ]
                    } per ${c.name}`,
            }}
        </SmartTable>
    );
};

export default UnitTable;
