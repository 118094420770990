import { gql } from '@apollo/client';
import { FormFieldResponse } from './FormFieldResponse';

export interface FormResponse {
    responses: FormFieldResponse[];
}

export const FormResponseFragment = gql`
    fragment FormResponseFragment on FormResponse {
        responses {
            response
            passed
            category
            question
            options
            required
            passable_options
        }
    }
`;
