import { gql } from '@apollo/client';

export interface Address {
    line_1: string;
    line_2?: string | null;
    line_3?: string | null;
    city: string;
    state: string;
    postal: string;
}

export const AddressFragment = gql`
    fragment AddressFragment on Address {
        line_1
        line_2
        line_3
        city
        state
        postal
    }
`;
