import { gql } from '@apollo/client';
import { DateRange } from '../../utils/types/DateRange';
import { getQueryHook } from '../types';
import { Dashboard, DashboardFragment } from './Dashboard';
import { DashboardBatchFragment } from './entities/DashboardBatch';
import { DashboardOrderFragment } from './entities/DashboardOrder';
import { DashboardReceiptFragment } from './entities/DashboardReceipt';
import { DashboardRecipeFragment } from './entities/DashboardRecipe';
import { DashboardShipmentFragment } from './entities/DashboardShipment';

export const DashboardQuery = gql`
    ${DashboardFragment}
    ${DashboardOrderFragment}
    ${DashboardReceiptFragment}
    ${DashboardRecipeFragment}
    ${DashboardBatchFragment}
    ${DashboardShipmentFragment}
    query DashboardQuery($range: DateRangeInput!) {
        dashboard(range: $range) {
            ...DashboardFragment
        }
    }
`;

export enum DashboardInterval {
    Day = 'Day',
    Week = 'Week',
    Month = 'Month',
}

export const useDashboard = getQueryHook<
    { dashboard: Dashboard },
    { range: DateRange }
>(DashboardQuery);
