import {
    Box,
    Button,
    Drawer,
    LinearProgress,
    List,
    ListItem,
    ListItemText,
    Tab,
    Tabs,
    useTheme,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { MdAdd } from 'react-icons/md';
import SearchInput from '../../../../../../components/inputs/FieldInputs/SearchInput';
import { ItemType, TinyItem } from '../../../../../../graphql/Item/Item';
import { useTinyItems } from '../../../../../../graphql/Item/operations/useTinyItems';

export interface ItemDrawerProps {
    onClick: (item: TinyItem) => void;
}

const ItemDrawer = (props: ItemDrawerProps): ReactElement => {
    const { onClick } = props;

    const { palette } = useTheme();

    const [search, setSearch] = React.useState('');

    const [itemType, setItemType] = React.useState<ItemType>(
        ItemType.Ingredient
    );

    const { data, error, loading } = useTinyItems({
        variables: {
            filter: { skip: 0, take: 500, types: [itemType], name: search },
        },
    });

    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
        setSearch('');
    };

    const items = data ? data.items.items : [];

    return (
        <React.Fragment>
            <Button startIcon={<MdAdd />} onClick={() => setOpen(true)}>
                Item
            </Button>
            <Drawer anchor="right" onClose={handleClose} open={open}>
                <Box
                    sx={{
                        height: '100%',
                        width: 450,
                        display: 'flex',
                        flexFlow: 'column',
                    }}
                >
                    <Box
                        sx={{ background: palette.tonal, position: 'relative' }}
                    >
                        <Box sx={{ p: 2, paddingBottom: 0 }}>
                            <SearchInput
                                fullWidth
                                InputProps={{ disableUnderline: true }}
                                variant="standard"
                                value={search}
                                onChange={(v) => setSearch(v || '')}
                                placeholder="Search"
                            />
                        </Box>
                        {loading && (
                            <Box
                                sx={{
                                    position: 'absolute',
                                    left: 0,
                                    bottom: 0,
                                    right: 0,
                                }}
                            >
                                <LinearProgress />
                            </Box>
                        )}
                        <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
                            <Tabs
                                value={
                                    itemType === ItemType.Ingredient
                                        ? 0
                                        : itemType === ItemType.Package
                                        ? 1
                                        : 2
                                }
                            >
                                <Tab
                                    onClick={() =>
                                        setItemType(ItemType.Ingredient)
                                    }
                                    label="Ingredients"
                                />
                                <Tab
                                    onClick={() =>
                                        setItemType(ItemType.Package)
                                    }
                                    label="Packaging"
                                />
                                <Tab
                                    onClick={() =>
                                        setItemType(ItemType.Product)
                                    }
                                    label="Products"
                                />
                                <Tab
                                    onClick={() =>
                                        setItemType(ItemType.MiscItem)
                                    }
                                    label="Misc"
                                />
                            </Tabs>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            flex: 1,
                            overflow: 'auto',
                            background: palette.background.default,
                        }}
                    >
                        <List>
                            {items.map((item) => (
                                <ListItem
                                    button
                                    divider
                                    key={'item_' + item._id}
                                    onClick={() => onClick(item)}
                                >
                                    <ListItemText primary={item.name} />
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Box>
            </Drawer>
        </React.Fragment>
    );
};

export default ItemDrawer;
