import { gql } from '@apollo/client';
import { Pagination } from '../../../../../utils/types/Pagination';
import { BaseFragment } from '../../../../Base/Base';
import { TinyItemFragment } from '../../../../Item/Item';
import { getQueryHook } from '../../../../types';
import {
    QualityCheckForm,
    QualityCheckFormFragment,
} from '../QualityCheckForm';
import { QualityCheckFormFilter } from '../QualityCheckFormFilter';

export const QualityCheckFormsQuery = gql`
    ${BaseFragment}
    ${QualityCheckFormFragment}
    ${TinyItemFragment}
    query QualityCheckFormsQuery($filter: QualityCheckFormFilter!) {
        qualityCheckForms(filter: $filter) {
            count
            items {
                ...QualityCheckFormFragment
            }
        }
    }
`;

export const useQualityCheckForms = getQueryHook<
    { qualityCheckForms: Pagination<QualityCheckForm> },
    { filter: QualityCheckFormFilter }
>(QualityCheckFormsQuery);
