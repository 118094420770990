import { gql } from '@apollo/client';
import { getQueryHook } from '../types';
import { OrderStat, OrderStatFragment } from './OrderStat';

export const OrderStats = gql`
    ${OrderStatFragment}
    query OrderStats($locations: [ObjectId!]!, $year: Int!, $itemName: String) {
        orderStats(locations: $locations, year: $year, item_name: $itemName) {
            ...OrderStatFragment
        }
    }
`;

export interface OrderStatsRes {
    orderStats: OrderStat[];
}

export interface OrderStatsArgs {
    year: number;
    itemName?: string;
    locations: string[];
}

export const useOrderStats = getQueryHook<OrderStatsRes, OrderStatsArgs>(
    OrderStats
);
