import { gql } from '@apollo/client';
import { BaseFragment } from '../../../../Base/Base';
import { getAtomicQueryHook } from '../../../../types';
import { Product, ProductFragment } from '../Product';

export const ProductQuery = gql`
    ${BaseFragment}
    ${ProductFragment}
    query ProductQuery($id: ObjectId!) {
        product(id: $id) {
            ...ProductFragment
        }
    }
`;

export const useProduct = getAtomicQueryHook<{ product: Product }>(
    ProductQuery
);
