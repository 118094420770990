import { ApolloError, gql, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { Permission } from './Permission';
import { UserRole } from './UserRole';

const MyProfileQuery = gql`
    query MyProfileQuery {
        me {
            profile {
                user_id
                name
                given_name
                family_name
                email
                photo
            }
            permissions
            roles
        }
    }
`;

export interface MyProfile {
    profile: {
        user_id: string;
        name: string;
        given_name?: string | null;
        family_name?: string | null;
        email?: string | null;
        photo?: string | null;
    };
    roles: UserRole[];
    permissions: Permission[];
}

const useMyProfile = (): {
    permissions: Permission[];
    roles: UserRole[];
    profile: MyProfile['profile'] | null;
    loading: boolean;
    error: ApolloError | undefined;
} => {
    const { data, error, loading } = useQuery<{ me: MyProfile }>(
        MyProfileQuery
    );

    const permissions = data ? data.me.permissions : [];
    const roles = data ? data.me.roles : [];
    const profile = data ? data.me.profile : null;

    return {
        loading,
        permissions,
        roles,
        profile,
        error,
    };
};

export default useMyProfile;
