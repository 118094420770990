import React, { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import IngredientDetail from './components/IngredientDetail';
import IngredientForm from './components/IngredientForm';
import IngredientList from './components/IngredientList';

const Ingredients = (): ReactElement => {
    return (
        <Routes>
            <Route index element={<IngredientList />} />
            <Route path={':id'} element={<IngredientDetail />} />
            <Route path={':id/edit'} element={<IngredientForm />} />
            <Route path={'create'} element={<IngredientForm />} />
        </Routes>
    );
};

export default Ingredients;
