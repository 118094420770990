import { gql } from '@apollo/client';
import { DashboardBatch } from './entities/DashboardBatch';
import { DashboardOrder } from './entities/DashboardOrder';
import { DashboardReceipt } from './entities/DashboardReceipt';
import { DashboardRecipe } from './entities/DashboardRecipe';
import { DashboardShipment } from './entities/DashboardShipment';

export interface Dashboard {
    orders: DashboardOrder[];
    receipts: DashboardReceipt[];
    recipes: DashboardRecipe[];
    batches: DashboardBatch[];
    shipments: DashboardShipment[];
}

export const DashboardFragment = gql`
    fragment DashboardFragment on Dashboard {
        orders {
            ...DashboardOrderFragment
        }
        receipts {
            ...DashboardReceiptFragment
        }
        recipes {
            ...DashboardRecipeFragment
        }
        batches {
            ...DashboardBatchFragment
        }
        shipments {
            ...DashboardShipmentFragment
        }
    }
`;
