import { gql } from '@apollo/client';
import { Pagination } from '../../../utils/types/Pagination';
import { RecipeFragment } from '../../Recipe/Recipe';
import { getQueryHook } from '../../types';
import { Folder, FolderFragment } from '../Folder';
import { FolderFilter } from '../FolderFilter';

export const FoldersQuery = gql`
    ${FolderFragment}
    ${RecipeFragment}
    query FoldersQuery($filter: FolderFilter!) {
        folderList(filter: $filter) {
            count
            items {
                ...FolderFragment
            }
        }
    }
`;

export interface FoldersRes {
    folderList: Pagination<Folder>;
}

export interface FoldersArgs {
    filter: FolderFilter;
}

export const useFolders = getQueryHook<FoldersRes, FoldersArgs>(FoldersQuery);
