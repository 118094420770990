import { gql } from '@apollo/client';
import { getMutationHook } from '../../types';
import { OrderQueueLineInput } from '../inputs/OrderQueueLineInput';
import { OrderQueueLine } from '../OrderQueueLine';

export const UpdateOrderQueue = gql`
    mutation UpdateOrderQueue($data: [OrderQueueLineInput!]!) {
        updateOrderQueue(data: $data) {
            po
            contents {
                unit
                item
                quantity
            }
            customer
            customer_location
            vendor
            vendor_location
            date
            time
        }
    }
`;

export const useOrderQueueUpdate = getMutationHook<
    {
        updateOrderQueue: OrderQueueLine[];
    },
    {
        data: OrderQueueLineInput[];
    }
>(UpdateOrderQueue);
