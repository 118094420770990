export enum FieldCategory {
    ShortAnswer = 'ShortAnswer',
    Paragraph = 'Paragraph',
    Number = 'Number',
    CheckBoxes = 'CheckBoxes',
    Dropdown = 'Dropdown',
    LinearScale = 'LinearScale',
    Date = 'Date',
    Time = 'Time',
}
