import { gql } from '@apollo/client';
import { Recipe } from '../Recipe/Recipe';

export interface Folder {
    _id: string;
    name: string;
    parent: {
        _id: string;
        name: string;
    };
    folders: {
        _id: string;
        name: string;
    }[];
    ancestry: {
        _id: string;
        name: string;
    }[];
    recipes: Recipe[];
}

export const FolderFragment = gql`
    fragment FolderFragment on Folder {
        _id
        name
        parent {
            _id
            name
        }
        folders {
            _id
            name
        }
        ancestry {
            _id
            name
        }
        recipes {
            ...RecipeFragment
        }
    }
`;
