import { DesktopDatePicker } from '@mui/lab';
import {
    Stack,
    FormControlLabel,
    Checkbox,
    Box,
    TextField,
    MenuItem,
    Slider,
    Typography,
    useTheme,
} from '@mui/material';
import React, { useCallback, ReactElement } from 'react';
import { FieldCategory } from '../../../../../../graphql/Form/FieldCategory';
import { FormFieldResponseInput } from '../../../../../../graphql/FormResponse/inputs/FormFieldResponseInput';
import TimePicker from '../../../../FieldInputs/TimePicker';

export interface QualityCheckFormFieldProps {
    value: FormFieldResponseInput;
    onChange: (value: FormFieldResponseInput) => void;
}

const QualityCheckFormField = (
    props: QualityCheckFormFieldProps
): ReactElement => {
    const { value, onChange } = props;

    const { palette, shape } = useTheme();

    const { required } = value;

    const getComponent = (field: FormFieldResponseInput) => {
        const ComponentMap: Record<FieldCategory, ReactElement> = {
            [FieldCategory.CheckBoxes]: (
                <Box
                    sx={{
                        minWidth: 350,
                        background: palette.background.paper,
                        ...shape,
                    }}
                >
                    <Box sx={{ padding: 1.5 }}>
                        <Typography color="textSecondary">
                            {field.question}
                        </Typography>
                    </Box>
                    <Box sx={{ padding: 2, paddingTop: 0 }}>
                        <Stack>
                            {field.options.map((option, opIndex) => (
                                <FormControlLabel
                                    key={field.question + '_op_' + opIndex}
                                    label={option}
                                    control={
                                        <Checkbox
                                            checked={
                                                value.response !== null &&
                                                value.response
                                                    .split(', ')
                                                    .includes(option)
                                            }
                                            onChange={(e, checked) => {
                                                if (checked)
                                                    onChange({
                                                        ...value,
                                                        response: value.response
                                                            ? value.response +
                                                              ', ' +
                                                              option
                                                            : option,
                                                    });
                                                else {
                                                    const values =
                                                        value.response
                                                            ? value.response.split(
                                                                  ', '
                                                              )
                                                            : [];

                                                    const newVal = values
                                                        .filter(
                                                            (val) =>
                                                                val !== option
                                                        )
                                                        .join(', ');

                                                    onChange({
                                                        ...value,
                                                        response: newVal,
                                                    });
                                                }
                                            }}
                                        />
                                    }
                                />
                            ))}
                        </Stack>
                    </Box>
                </Box>
            ),
            [FieldCategory.Date]: (
                <DesktopDatePicker
                    label={field.question}
                    value={value.response ? new Date(value.response) : null}
                    onChange={(date) => {
                        onChange({
                            ...value,
                            response: date ? date.toUTCString() : null,
                        });
                    }}
                    inputFormat="MM/dd/yyyy"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required={required}
                            fullWidth
                            InputProps={{
                                ...params.InputProps,
                                fullWidth: true,
                            }}
                        />
                    )}
                />
            ),
            [FieldCategory.Dropdown]: (
                <TextField
                    required={required}
                    fullWidth
                    label={field.question}
                    select
                    value={value.response || ''}
                    onChange={(e) => {
                        onChange({
                            ...value,
                            response: e.target.value || null,
                        });
                    }}
                >
                    {field.options.map((option, opIndex) => (
                        <MenuItem
                            value={option}
                            key={field.question + '_op_' + opIndex}
                        >
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
            ),
            [FieldCategory.LinearScale]: (
                <Box
                    sx={{
                        minWidth: 350,
                        background: palette.background.paper,
                        ...shape,
                    }}
                >
                    <Box sx={{ padding: 1.5 }}>
                        <Typography color="textSecondary">
                            {field.question}
                        </Typography>
                    </Box>
                    <Box sx={{ padding: 2, paddingTop: 0 }}>
                        <Slider
                            defaultValue={parseInt(field.options[0])}
                            step={1}
                            marks
                            min={parseInt(field.options[0])}
                            max={parseInt(field.options[1])}
                            value={
                                value.response !== null
                                    ? parseFloat(value.response)
                                    : value.options[0]
                                    ? parseInt(value.options[0])
                                    : 0
                            }
                            onChange={(event, number) => {
                                if (!(number instanceof Array)) {
                                    onChange({
                                        ...value,
                                        response: number.toString(),
                                    });
                                }
                            }}
                        />
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography color="textSecondary" variant="body2">
                                {field.passable_options[0]}
                            </Typography>
                            <Typography color="textSecondary" variant="body2">
                                {field.passable_options[1]}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            ),
            [FieldCategory.Number]: (
                <TextField
                    required={required}
                    fullWidth
                    type="number"
                    label={field.question + ' (number)'}
                    value={value.response ? parseFloat(value.response) : ''}
                    onChange={(e) => {
                        const newVal = e.target.value || null;
                        const parsed =
                            newVal == null ? null : parseFloat(newVal);
                        onChange({
                            ...value,
                            response:
                                parsed == null ? parsed : parsed.toString(),
                        });
                    }}
                />
            ),
            [FieldCategory.Paragraph]: (
                <TextField
                    required={required}
                    fullWidth
                    multiline
                    minRows={3}
                    maxRows={6}
                    label={field.question}
                    value={value.response || ''}
                    onChange={({ target }) =>
                        onChange({ ...value, response: target.value || null })
                    }
                />
            ),
            [FieldCategory.ShortAnswer]: (
                <TextField
                    required={required}
                    value={value.response || ''}
                    onChange={({ target }) =>
                        onChange({ ...value, response: target.value || null })
                    }
                    fullWidth
                    label={field.question}
                />
            ),
            [FieldCategory.Time]: (
                <TimePicker
                    required={required}
                    label={field.question}
                    fullWidth
                    value={
                        value.response !== null
                            ? isNaN(parseInt(value.response))
                                ? null
                                : parseInt(value.response)
                            : null
                    }
                    onChange={(val) => {
                        onChange({
                            ...value,
                            response: val == null ? null : val.toString(),
                        });
                    }}
                />
            ),
        };

        return ComponentMap[field.category];
    };

    return getComponent(value);
};

export default QualityCheckFormField;
