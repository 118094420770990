import { gql } from '@apollo/client';
import { Pagination } from '../../../../../utils/types/Pagination';
import { BaseFragment } from '../../../../Base/Base';
import { getQueryHook } from '../../../../types';
import {
    Ingredient,
    IngredientFragment,
    TinyIngredient,
    TinyIngredientFragment,
} from '../Ingredient';
import { IngredientFilter } from '../IngredientFilter';

export const IngredientsQuery = gql`
    ${BaseFragment}
    ${IngredientFragment}
    query IngredientsQuery($filter: IngredientFilter!) {
        ingredients(filter: $filter) {
            count
            items {
                ...IngredientFragment
            }
        }
    }
`;

export const TinyIngredientsQuery = gql`
    ${TinyIngredientFragment}
    query TinyIngredientsQuery($filter: IngredientFilter!) {
        ingredients(filter: $filter) {
            count
            items {
                ...TinyIngredientFragment
            }
        }
    }
`;

export const useIngredients = getQueryHook<
    { ingredients: Pagination<Ingredient> },
    { filter: IngredientFilter }
>(IngredientsQuery);

export const useTinyIngredients = getQueryHook<
    { ingredients: Pagination<TinyIngredient> },
    { filter: IngredientFilter }
>(TinyIngredientsQuery);
