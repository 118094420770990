import { gql } from '@apollo/client';
import { BaseFragment } from '../../Base/Base';
import { getAtomicQueryHook } from '../../types';
import { Contact, ContactFragment } from '../Contact';

export const ContactQuery = gql`
    ${ContactFragment}
    ${BaseFragment}
    query ContactQuery($id: ObjectId!) {
        contact(id: $id) {
            ...ContactFragment
        }
    }
`;

export interface ContactRes {
    contact: Contact;
}

export const useContact = getAtomicQueryHook<ContactRes>(ContactQuery);
