import { DesktopDatePicker } from '@mui/lab';
import {
    Box,
    Button,
    Divider,
    IconButton,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import React, { ReactElement } from 'react';
import {
    MdAdd,
    MdArrowDownward,
    MdArrowUpward,
    MdCheck,
    MdClear,
} from 'react-icons/md';
import UnitField from '../../../../../../../../components/inputs/FieldInputs/UnitField';
import QualityCheckForm from '../../../../../../../../components/inputs/LightForms/QualityCheckForm';
import FormRow from '../../../../../../../../components/Layout/FormRow';
import { Bol } from '../../../../../../../../graphql/Bol/Bol';
import { QualityCheckCategory } from '../../../../../../../../graphql/Form/extensions/QualityCheckForm/QualityCheckCategory';
import { useTinyItems } from '../../../../../../../../graphql/Item/operations/useTinyItems';
import { CreateReceiptArgs } from '../../../../../../../../graphql/Receipt/operations/useReceiptCreation';
import { UpdateReceiptArgs } from '../../../../../../../../graphql/Receipt/operations/useReceiptUpdate';
import { useTinyUnits } from '../../../../../../../../graphql/Unit/operations/useUnits';
import ReceiptLine from './components/ReceiptLineInput';

export interface ReceiptStepperProps {
    bol: Bol;
    state: UpdateReceiptArgs['data'] | CreateReceiptArgs['data'];
    setState: (
        data: UpdateReceiptArgs['data'] | CreateReceiptArgs['data']
    ) => void;
    handleSave: () => void;
}

const ReceiptStepper = (props: ReceiptStepperProps): ReactElement => {
    const { bol, state, setState, handleSave } = props;

    const { palette, shape } = useTheme();

    const [index, setIndex] = React.useState(0);

    const { data } = useTinyItems({
        variables: { filter: { skip: 0, take: 500 } },
    });

    const items = data ? data.items.items : [];

    const { data: unitData } = useTinyUnits({
        variables: { filter: { skip: 0, take: 500 } },
    });

    const units = unitData ? unitData.units.items : [];

    return (
        <Stepper activeStep={index} orientation="vertical">
            <Step>
                <StepLabel>Receipt Details</StepLabel>
                <StepContent>
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 2,
                            padding: 2,
                            flexFlow: 'column',
                            maxWidth: 260,
                        }}
                    >
                        <Box>
                            <DesktopDatePicker
                                label="Date received"
                                value={state.date_created}
                                onChange={(date) => {
                                    if (date)
                                        setState({
                                            ...state,
                                            date_created: date,
                                        });
                                }}
                                inputFormat="MM/dd/yyyy"
                                renderInput={(params) => (
                                    <TextField fullWidth {...params} />
                                )}
                            />
                        </Box>
                        <Box>
                            <TextField
                                fullWidth
                                label={'BOL Number'}
                                value={state.bol}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        bol: e.target.value,
                                    });
                                }}
                            />
                        </Box>
                        <Box>
                            <TextField
                                fullWidth
                                label={'Seal Number'}
                                value={state.seal}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        seal: e.target.value,
                                    });
                                }}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Button
                                startIcon={<MdArrowDownward />}
                                onClick={() => setIndex(index + 1)}
                                disabled={!state.seal}
                            >
                                Next
                            </Button>
                        </Box>
                    </Box>
                </StepContent>
            </Step>
            {state.lines.map((line, lineIndex) => {
                const item = items.find((it) => it._id === line.item);
                const content = bol.contents.find(
                    (content) => content.item._id === line.item
                );
                if (!item) return <Box />;
                return (
                    <ReceiptLine
                        index={lineIndex + 1}
                        moveIndex={(d) => setIndex(index + d)}
                        content={content}
                        item={item}
                        key={'line_' + lineIndex}
                        value={line}
                        onChange={(l) => {
                            const copy = { ...state };
                            copy.lines[lineIndex] = l;
                            setState(copy);
                        }}
                    />
                );
            })}
            <Step>
                <StepLabel>Review</StepLabel>
                <StepContent>
                    <Box sx={{ p: 2 }}>
                        <Box
                            sx={{
                                ...shape,
                                background: palette.tonal,
                                p: 2,
                                display: 'inline-block',
                                minWidth: 400,
                                marginBottom: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexFlow: 'column',
                                    gap: 1,
                                }}
                            >
                                <Typography
                                    variant="crisp"
                                    sx={{ fontSize: '1rem' }}
                                >
                                    Receipt
                                </Typography>
                                <Divider />
                                <Box>
                                    <Typography>{`Seal: ${state.seal}`}</Typography>
                                </Box>
                                <Divider />
                                {state.lines.map((line, lineIndex) => {
                                    const item = items.find(
                                        (it) => it._id === line.item
                                    );
                                    if (item)
                                        return (
                                            <Box key={'rv_' + lineIndex}>
                                                <Typography>
                                                    {item.name}
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        paddingLeft: 2,
                                                        padding: 1,
                                                    }}
                                                >
                                                    <Typography
                                                        color="textSecondary"
                                                        variant="caption"
                                                    >
                                                        Lots
                                                    </Typography>
                                                    <Box>
                                                        {line.lots.map(
                                                            (lot, lotIndex) => {
                                                                const unit =
                                                                    units.find(
                                                                        (u) =>
                                                                            u._id ===
                                                                            lot.unit
                                                                    );
                                                                return (
                                                                    <Box
                                                                        key={
                                                                            lineIndex +
                                                                            '_' +
                                                                            lotIndex
                                                                        }
                                                                    >
                                                                        <Typography variant="body2">
                                                                            {`${
                                                                                lot.code
                                                                            } ${
                                                                                unit
                                                                                    ? `- ${
                                                                                          lot.quantity
                                                                                      } ${
                                                                                          unit[
                                                                                              lot.quantity ==
                                                                                              1
                                                                                                  ? 'name'
                                                                                                  : 'plural'
                                                                                          ]
                                                                                      }`
                                                                                    : ''
                                                                            }`}
                                                                        </Typography>
                                                                    </Box>
                                                                );
                                                            }
                                                        )}
                                                    </Box>
                                                </Box>
                                                <Divider />
                                            </Box>
                                        );
                                })}
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Button
                                variant="outlined"
                                color="inherit"
                                startIcon={<MdArrowUpward />}
                                onClick={() => setIndex(index - 1)}
                            >
                                Previous
                            </Button>
                            <Button
                                color="success"
                                endIcon={<MdCheck />}
                                onClick={() => handleSave()}
                            >
                                Complete
                            </Button>
                        </Box>
                    </Box>
                </StepContent>
            </Step>
        </Stepper>
    );
};

export default ReceiptStepper;
