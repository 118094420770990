import { LoadingButton } from '@mui/lab';
import { Box, Button, Tooltip, Typography, useTheme } from '@mui/material/';
import TextField from '@mui/material/TextField';
import React, { ReactElement } from 'react';
import { MdCheck } from 'react-icons/md';
import Message from '../../../../../../components/feedback/Message';
import CarefullButton from '../../../../../../components/inputs/Buttons/CarefulButton';
import FormRow from '../../../../../../components/Layout/FormRow';
import ResponsiveDialog from '../../../../../../components/Layout/ResponsiveDialog';
import { FolderQuery } from '../../../../../../graphql/Folder/operations/useFolder';
import {
    CreateFolderArgs,
    CreateFolderRes,
    useFolderCreation,
} from '../../../../../../graphql/Folder/operations/useFolderCreation';
import { FoldersQuery } from '../../../../../../graphql/Folder/operations/useFolders';
import {
    UpdateFolderArgs,
    UpdateFolderRes,
    useFolderUpdate,
} from '../../../../../../graphql/Folder/operations/useFolderUpdate';
import { OperationResult } from '../../../../../../utils/types/OperationResult';

export interface FolderFormProps {
    parent: string | null;
    focus: { _id: string; name: string } | null | true;
    onClose: () => void;
}

const FolderForm = (props: FolderFormProps): ReactElement => {
    const { focus, parent, onClose } = props;

    const { palette } = useTheme();

    const [state, setState] = React.useState<
        null | CreateFolderArgs | UpdateFolderArgs
    >(null);

    const [result, setResult] = React.useState<null | OperationResult<
        CreateFolderRes | UpdateFolderRes
    >>(null);

    const [handleCreate, { loading: createLoading }] = useFolderCreation({
        variables: state && !('id' in state) ? state : undefined,
        onCompleted: (data) => setResult({ success: true, data }),
        onError: (error) => setResult({ success: false, error }),
        refetchQueries: [FolderQuery, FoldersQuery],
    });

    const [handleUpdate, { loading: updateLoading }] = useFolderUpdate({
        variables: state && 'id' in state ? state : undefined,
        onCompleted: (data) => setResult({ success: true, data }),
        onError: (error) => setResult({ success: false, error }),
        refetchQueries: [FolderQuery, FoldersQuery],
    });

    React.useEffect(() => {
        if (focus == null) setState(null);
        if (focus == true) setState({ data: { name: '', parent } });
        else if (focus)
            setState({ id: focus._id, data: { name: focus.name, parent } });
    }, [focus, parent]);

    const handleClose = () => {
        setState(null);
        setResult(null);
        onClose();
    };

    const submit = () => {
        if (state) {
            if ('id' in state) handleUpdate();
            else handleCreate();
        }
    };

    const getHoldup = (): string | null => {
        if (!state) return null;
        if (!state.data.name) return 'Please enter a folder name';
        if (state.data.name == 'Home' || state.data.name == 'home')
            return '*Home* is a restricted folder name';
        return null;
    };

    const holdup = getHoldup();

    return (
        <ResponsiveDialog
            PaperProps={{ sx: { background: palette.tonal } }}
            open={Boolean(state)}
            onClose={() => handleClose()}
        >
            <Box sx={{ width: 300, position: 'relative' }}>
                {result && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            background: palette.tonal,
                            zIndex: 100,
                        }}
                    >
                        {result.success ? (
                            <Message
                                type="Success"
                                onComplete={() => handleClose()}
                            >
                                {'createFolder' in result.data
                                    ? 'Folder created!'
                                    : 'Folder Updated!'}
                            </Message>
                        ) : (
                            <Message
                                type="Error"
                                action={
                                    <Button onClick={() => setResult(null)}>
                                        Reset
                                    </Button>
                                }
                            />
                        )}
                    </Box>
                )}
                <Typography variant="h6">
                    {state
                        ? 'id' in state
                            ? 'Update Folder'
                            : 'Create Folder'
                        : ''}
                </Typography>
                <Box p={0.5} />
                <FormRow>
                    <TextField
                        autoFocus
                        value={state ? state.data.name : ''}
                        onChange={(e) => {
                            if (state) {
                                setState({
                                    ...state,
                                    data: {
                                        ...state.data,
                                        name: e.target.value,
                                    },
                                });
                            }
                        }}
                        label="Folder Name"
                        error={
                            state !== null &&
                            (state.data.name == 'Home' ||
                                state.data.name == 'home')
                        }
                        fullWidth
                    />
                </FormRow>
                <Box
                    sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}
                >
                    {state && 'id' in state && (
                        <CarefullButton
                            loading={createLoading || updateLoading}
                            onClick={() => {
                                handleUpdate({
                                    variables:
                                        state && 'id' in state
                                            ? {
                                                  ...state,
                                                  data: {
                                                      ...state.data,
                                                      deleted: true,
                                                  },
                                              }
                                            : undefined,
                                    onCompleted: (data) =>
                                        setResult({ success: true, data }),
                                    onError: (error) =>
                                        setResult({ success: false, error }),
                                    refetchQueries: [FolderQuery, FoldersQuery],
                                });
                            }}
                        >
                            Delete
                        </CarefullButton>
                    )}
                    <Tooltip arrow title={holdup || ''}>
                        <Box sx={{ display: 'inline-block' }}>
                            <LoadingButton
                                variant="contained"
                                loading={createLoading || updateLoading}
                                onClick={submit}
                                endIcon={<MdCheck />}
                                disabled={Boolean(holdup)}
                            >
                                Save
                            </LoadingButton>
                        </Box>
                    </Tooltip>
                </Box>
            </Box>
        </ResponsiveDialog>
    );
};

export default FolderForm;
