import { gql } from '@apollo/client';
import { Pagination } from '../../../utils/types/Pagination';
import { getQueryHook } from '../../types';
import { RecipeVersionFilter } from '../RecipeVersionFilter';

export const RecipeVersionsQuery = gql`
    query RecipeVersionsQuery($filter: RecipeVersionFilter!) {
        recipeVersions(filter: $filter) {
            count
            items {
                _id
                date_created
                created_by {
                    user_id
                    name
                    picture
                }
                note
            }
        }
    }
`;

export interface RecipeVersionsRes {
    recipeVersions: Pagination<{
        _id: string;
        note: string | null;
        date_created: Date;
        created_by: {
            user_id: string;
            name: string;
            picture: string | null | undefined;
        };
    }>;
}

export interface RecipeVersionsArgs {
    filter: RecipeVersionFilter;
}

export const useRecipeVersions = getQueryHook<
    RecipeVersionsRes,
    RecipeVersionsArgs
>(RecipeVersionsQuery);
