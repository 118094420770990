import { gql } from '@apollo/client';
import { Pagination } from '../../utils/types/Pagination';
import { AddressFragment } from '../Address/Address';
import { TinyCompanyFragment } from '../Company/Company';
import { TinyContactFragment } from '../Contact/Contact';
import { TinyItemFragment } from '../Item/Item';
import {
    CompanyLocationFragment,
    TinyLocationFragment,
} from '../Location/Location';
import { getQueryHook } from '../types';
import { TableOrder, TableOrderFragment } from './TableOrder';
import { TableOrderFilter } from './TableOrderFilter';

export const TableOrders = gql`
    ${TableOrderFragment}
    ${TinyLocationFragment}
    ${TinyItemFragment}
    ${TinyCompanyFragment}
    ${AddressFragment}
    ${TinyContactFragment}
    ${CompanyLocationFragment}
    query TableOrders($filter: TableOrderFilter!) {
        tableOrders(filter: $filter) {
            count
            items {
                ...TableOrderFragment
            }
        }
    }
`;

export interface TableOrderRes {
    tableOrders: Pagination<TableOrder>;
}

export interface TableOrderArgs {
    filter: TableOrderFilter;
}

export const useTableOrders = getQueryHook<TableOrderRes, TableOrderArgs>(
    TableOrders
);
