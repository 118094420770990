import {
    Box,
    Button,
    LinearProgress,
    Typography,
    useTheme,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ResponsiveDrawer from '../../../../../../components/Layout/ResponsiveDrawer';
import TabFade from '../../../../../../components/Layout/TabFade';
import {
    HeavyOrdersQuery,
    useHeavyOrders,
} from '../../../../../../graphql/Order/operations/useOrders';
import { JSONTree } from 'react-json-tree';
import LotBreakdown from './components/LotBreakdown';
import { MdChevronRight, MdDelete, MdEdit } from 'react-icons/md';
import CarefullButton from '../../../../../../components/inputs/Buttons/CarefulButton';
import { useReceiptUpdate } from '../../../../../../graphql/Receipt/operations/useReceiptUpdate';
import { FulfillmentEvents } from '../../../../../../graphql/FulfillmentEvent/useFulfillmentEvents';
import { niceList } from '../../../../../../utils/niceList';
import { format } from 'date-fns';
import { dateFormats } from '../../../../../../utils/dateFormats';
import Message from '../../../../../../components/feedback/Message';
import VerificationForm from '../../../../../../components/inputs/LightForms/VerificationForm';
import VerificationBadge from '../../../../../../components/display/VerificationBadge';
import ReceiptLabels from './components/ReceiptLabels';
import { FulfillmentRowQuery } from '../../../../../../graphql/FulfillmentRow/useFulfillmentRows';

export interface ReceiptPanelProps {
    receipt: string | null;
}

const ReceiptPanel = (props: ReceiptPanelProps): ReactElement => {
    const { receipt: id } = props;
    const { tab } = useParams();

    const { palette, shape } = useTheme();

    const nav = useNavigate();

    const { data, error, loading } = useHeavyOrders({
        variables: {
            filter: { skip: 0, take: 10, receipt: id },
        },
        skip: !id,
        fetchPolicy: 'network-only',
    });

    const [handleUpdate, { loading: updateLoading, error: updateError }] =
        useReceiptUpdate();

    const order = data && data.orders.items[0] ? data.orders.items[0] : null;
    const bols = order ? order.itineraries.map((itin) => itin.bols).flat() : [];
    const receipts = order
        ? order.itineraries
              .map((itin) => itin.bols.map((bol) => bol.receipts))
              .flat()
              .flat()
        : [];

    const receipt = receipts.find((r) => r._id == id) || null;
    const bol =
        bols.find((b) => b.receipts.map((r) => r._id).includes(id || '')) ||
        null;

    return (
        <ResponsiveDrawer
            open={Boolean(id)}
            onClose={() => nav('/warehouse/receiving')}
            anchor="right"
            padding={0}
            PaperProps={{
                sx: {
                    background: palette.background.default,
                    minWidth: 600,
                    maxWidth: 600,
                },
            }}
        >
            {(loading || updateLoading) && (
                <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
                    <LinearProgress />
                </Box>
            )}
            {error && (
                <Message type="Error" error={error}>
                    {error.message}
                </Message>
            )}
            {bol && (
                <Box
                    sx={{
                        background: palette.tonal,
                        p: 3,
                        paddingBottom: 1,
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        {receipt && (
                            <VerificationBadge>{receipt}</VerificationBadge>
                        )}
                        <Typography variant="crisp">Receipt</Typography>
                    </Box>
                    <Typography sx={{ paddingTop: 1 }} variant="h6">
                        {niceList(
                            bol.contents.map((content) => content.item.name)
                        )}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            paddingTop: 1.5,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexFlow: 'column',
                                gap: 0.25,
                            }}
                        >
                            <Typography>
                                {bol.from.company ? bol.from.company.name : ''}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {bol.from.location
                                    ? bol.from.location.label
                                    : ''}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                paddingLeft: 2,
                                paddingRight: 2,
                            }}
                        >
                            <MdChevronRight style={{ fontSize: '2rem' }} />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexFlow: 'column',
                                gap: 0.25,
                            }}
                        >
                            <Typography>
                                {bol.to.company ? bol.to.company.name : ''}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {bol.to.location ? bol.to.location.label : ''}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            )}
            {receipt && bol && (
                <TabFade
                    tonal
                    tabPadding={3}
                    start_index={tab == 'labels' ? 2 : 0}
                >
                    {{
                        Details: (
                            <Box p={3}>
                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                >
                                    PO Number
                                </Typography>
                                <Typography>{order?.code}</Typography>
                                <Box p={1} />
                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                >
                                    BOL Number
                                </Typography>
                                <Typography>{bol.code}</Typography>
                                <Box p={1} />
                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                >
                                    Received By
                                </Typography>
                                <Typography>
                                    {receipt.created_by.name}
                                </Typography>
                                <Box p={1} />
                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                >
                                    Date Received
                                </Typography>
                                <Typography>
                                    {format(
                                        new Date(receipt.date_created),
                                        dateFormats.condensedDate
                                    )}
                                </Typography>
                                <Box p={1} />
                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                >{`Seal #`}</Typography>
                                <Typography>{receipt.seal}</Typography>
                                <Box p={1} />
                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                >
                                    Expected to receive
                                </Typography>
                                {bol && (
                                    <Box>
                                        {bol.contents.map((content, cIndex) => (
                                            <Box
                                                sx={{
                                                    paddingBottom: 0.5,
                                                }}
                                                key={'c_' + cIndex}
                                            >
                                                {`${content.item.name} - ${
                                                    content.client_qty
                                                } ${
                                                    content.client_unit[
                                                        content.client_qty == 1
                                                            ? 'name'
                                                            : 'plural'
                                                    ]
                                                }`}
                                            </Box>
                                        ))}
                                    </Box>
                                )}
                                <Box p={1} />
                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                >
                                    Actually received
                                </Typography>
                                {receipt.lines.map((line, lineIndex) => {
                                    return (
                                        <LotBreakdown
                                            bol={bol}
                                            key={'line_' + lineIndex}
                                        >
                                            {line}
                                        </LotBreakdown>
                                    );
                                })}

                                <Box
                                    sx={{
                                        paddingTop: 4,
                                        display: 'flex',
                                        gap: 2,
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <CarefullButton
                                        loading={updateLoading}
                                        endIcon={<MdDelete />}
                                        onClick={() => {
                                            handleUpdate({
                                                variables: {
                                                    id: receipt._id,
                                                    data: {
                                                        date_created:
                                                            receipt.date_created,
                                                        bol: bol
                                                            ? bol.code || ''
                                                            : '',
                                                        deleted: true,
                                                        seal: receipt.seal,
                                                        lines: receipt.lines.map(
                                                            (line) => ({
                                                                pallet_count:
                                                                    line.pallet_count,
                                                                item: line.item
                                                                    ._id,
                                                                form_response: {
                                                                    responses:
                                                                        line.form_response.responses.map(
                                                                            (
                                                                                res
                                                                            ) => ({
                                                                                ...res,
                                                                                __typename:
                                                                                    undefined,
                                                                                passed: undefined,
                                                                            })
                                                                        ),
                                                                },
                                                                lots: line.lots.map(
                                                                    (lot) => ({
                                                                        unit: lot
                                                                            .client_unit
                                                                            ._id,
                                                                        code: lot
                                                                            .lot
                                                                            .code,
                                                                        quantity:
                                                                            lot.client_qty,
                                                                    })
                                                                ),
                                                            })
                                                        ),
                                                    },
                                                },
                                                onCompleted: () =>
                                                    nav('/warehouse/receiving'),
                                                refetchQueries: [
                                                    FulfillmentEvents,
                                                    HeavyOrdersQuery,
                                                    FulfillmentRowQuery,
                                                ],
                                            });
                                        }}
                                    >
                                        Delete
                                    </CarefullButton>
                                    {/* "receiving/:order/:bol/:id" */}
                                    <Button
                                        onClick={() => {
                                            if (bol && order)
                                                nav(
                                                    `/warehouse/receiving/form/${order._id}/${bol._id}/${receipt._id}`
                                                );
                                        }}
                                        endIcon={<MdEdit />}
                                    >
                                        Edit
                                    </Button>
                                </Box>
                                {updateError && <JSONTree data={updateError} />}
                            </Box>
                        ),
                        Verification: (
                            <Box>
                                <Box p={3}>
                                    <VerificationForm
                                        entity="receipt"
                                        verification={receipt.verification}
                                        loading={updateLoading}
                                        error={updateError || null}
                                        onSave={(v) => {
                                            handleUpdate({
                                                variables: {
                                                    id: receipt._id,
                                                    data: {
                                                        date_created:
                                                            receipt.date_created,
                                                        bol: bol
                                                            ? bol.code || ''
                                                            : '',
                                                        seal: receipt.seal,
                                                        lines: receipt.lines.map(
                                                            (line) => ({
                                                                pallet_count:
                                                                    line.pallet_count,
                                                                item: line.item
                                                                    ._id,
                                                                form_response: {
                                                                    responses:
                                                                        line.form_response.responses.map(
                                                                            (
                                                                                res
                                                                            ) => ({
                                                                                ...res,
                                                                                __typename:
                                                                                    undefined,
                                                                                passed: undefined,
                                                                            })
                                                                        ),
                                                                },
                                                                lots: line.lots.map(
                                                                    (lot) => ({
                                                                        unit: lot
                                                                            .client_unit
                                                                            ._id,
                                                                        code: lot
                                                                            .lot
                                                                            .code,
                                                                        quantity:
                                                                            lot.client_qty,
                                                                    })
                                                                ),
                                                            })
                                                        ),
                                                        verification: v,
                                                    },
                                                },
                                                refetchQueries: [
                                                    FulfillmentEvents,
                                                    HeavyOrdersQuery,
                                                ],
                                            });
                                        }}
                                    />
                                </Box>
                            </Box>
                        ),
                        Labels: <ReceiptLabels receipt={receipt} />,
                    }}
                </TabFade>
            )}
        </ResponsiveDrawer>
    );
};

export default ReceiptPanel;
