import { gql } from '@apollo/client';
import { ItemType } from '../Item/Item';
import { UnitClass } from '../Unit/UnitClass';

export interface Recipe {
    _id: string;
    name: string;
    item: {
        _id: string;
        name: string;
        spanish_name: string;
        type: ItemType;
        unit_class: UnitClass;
    };
    folder: { _id: string; name: string };
    active: {
        _id: string;
        date_created: Date;
        created_by: { name: string; user_id: string };
    } | null;
    deletion: null | {
        timestamp: Date;
    };
}

export const RecipeFragment = gql`
    fragment RecipeFragment on Recipe {
        _id
        name
        deletion {
            timestamp
        }
        item {
            _id
            name
            spanish_name
            type
            unit_class
        }
        folder {
            _id
            name
        }
        active {
            _id
            date_created
            created_by {
                name
                user_id
            }
        }
    }
`;
