import { gql } from '@apollo/client';
import { Base } from '../Base/Base';
import { FulfillmentLine } from '../FulfillmentLine/FulfillmentLine';
import { Verification } from '../Verification/Verification';

export interface Shipment extends Base {
    lines: FulfillmentLine[];
    seal: string;
    verification: Verification | null;
}

export const ShipmentFragment = gql`
    fragment ShipmentFragment on Shipment {
        ...BaseFragment
        lines {
            ...FulfillmentLineFragment
        }
        seal
        verification {
            ...VerificationFragment
        }
    }
`;
