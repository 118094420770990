import { gql } from '@apollo/client';

export interface LotEdge {
    id: string;
    source: string;
    target: string;
}

export const LotEdgeFragment = gql`
    fragment LotEdgeFragment on LotEdge {
        id
        source
        target
    }
`;
