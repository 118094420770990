import { Box, Button } from '@mui/material/';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Recipe } from '../../../../../../graphql/Recipe/Recipe';

export interface NoVersionYetProps {
    children: Recipe;
}

const NoVersionYet = (props: NoVersionYetProps): ReactElement => {
    const { children: recipe } = props;

    const nav = useNavigate();

    return (
        <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
            <Button onClick={() => nav('new')}>Create first version</Button>
        </Box>
    );
};

export default NoVersionYet;
