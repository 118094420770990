import React from 'react';
import {
    Skeleton,
    useTheme,
    Box,
    ButtonBase,
    Typography,
    SxProps,
    Theme,
} from '@mui/material/';
import { ReactElement } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useLotLabel } from '../../../graphql/Lot/useLotLabel';
import { format } from 'date-fns';
import { dateFormats } from '../../../utils/dateFormats';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export interface LotDocumentProps {
    id: string;
    overlay?: boolean;
    overlaySx?: SxProps<Theme>;
}

const LotDocument = (props: LotDocumentProps): ReactElement => {
    const { id, overlay = true, overlaySx = {} } = props;

    const { data, error, loading } = useLotLabel({ variables: { id } });

    const [link, setLink] = React.useState('');

    const [numPages, setNumPages] = React.useState<number | null>(null);
    const [pageNumber, setPageNumber] = React.useState(1);
    const { shape } = useTheme();

    const lot = data ? data.lot : null;

    const click = () => {
        if (lot) {
            window.open(
                (process.env.REACT_APP_API_URL || '').replace(
                    '/graphql',
                    `/labels/lot/${lot._id}`
                ),
                '_blank'
            );
        }
    };

    if (!lot) return <Skeleton sx={{ width: '400px', height: '300px' }} />;

    return (
        <Box>
            <ButtonBase
                sx={{
                    display: 'inline-block',
                    ...shape,
                    overflow: 'hidden',
                }}
                onClick={() => {
                    if (!overlay) click();
                }}
            >
                {overlay && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: 5,
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: 'rgba(255,255,255,.65)',
                            ...overlaySx,
                        }}
                        onClick={() => click()}
                    >
                        <Box>
                            <Typography
                                sx={{ color: 'rgba(0,0,0,.95)' }}
                                variant="h4"
                            >
                                {format(
                                    new Date(lot.date_created),
                                    dateFormats.time
                                )}
                            </Typography>
                        </Box>
                    </Box>
                )}
                <Document
                    file={`data:application/pdf;base64,${lot.document.data}`}
                    onLoadSuccess={({ numPages }) => {
                        setNumPages(numPages);
                    }}
                >
                    <Page pageNumber={pageNumber} />
                </Document>
            </ButtonBase>
        </Box>
    );
};

export default LotDocument;
