import { gql } from '@apollo/client';
import { ItemPluralContentFragment } from '../../Content/ItemPluralContent/ItemPluralContent';
import { TinyItemFragment } from '../../Item/Item';
import { RecipeSectionFragment } from '../../RecipeSection/RecipeSection';
import { RecipeStepFragment } from '../../RecipeStep/RecipeStep';
import { getQueryHook } from '../../types';
import { TinyUnitFragment } from '../../Unit/Unit';
import { RecipeVersion, RecipeVersionFragment } from '../RecipeVersion';

export const RecipeVersionQuery = gql`
    ${RecipeVersionFragment}
    ${RecipeSectionFragment}
    ${RecipeStepFragment}
    ${ItemPluralContentFragment}
    ${TinyItemFragment}
    ${TinyUnitFragment}
    query RecipeVersionQuery($id: ObjectId!) {
        recipeVersion(id: $id) {
            ...RecipeVersionFragment
        }
    }
`;

export interface RecipeVersionRes {
    recipeVersion: RecipeVersion;
}

export interface RecipeVersionArgs {
    id: string | null;
}

export const useRecipeVersion = getQueryHook<
    RecipeVersionRes,
    RecipeVersionArgs
>(RecipeVersionQuery);
