import { gql } from '@apollo/client';
import { Bol } from '../Bol/Bol';
import { TinyCompany } from '../Company/Company';
import { TinyLocation } from '../Location/Location';

export interface CleanAppointment {
    date: Date;
    time: number | null;
    location: TinyLocation | null;
    company: TinyCompany;
    appointment_key: string;
}

export interface CleanBol extends Bol {
    from: CleanAppointment;
    to: CleanAppointment;
}

export interface ItinerarySchedule {
    appointments: CleanAppointment[];
    bols: CleanBol[];
}

export const ItineraryScheduleFragment = gql`
    fragment ItineraryScheduleFragment on ItinerarySchedule {
        appointments {
            date
            time
            location {
                ...TinyLocationFragment
            }
            company {
                ...TinyCompanyFragment
            }
            appointment_key
        }
        bols {
            from {
                date
                time
                location {
                    ...TinyLocationFragment
                }
                company {
                    ...TinyCompanyFragment
                }
                appointment_key
            }
            to {
                date
                time
                location {
                    ...TinyLocationFragment
                }
                company {
                    ...TinyCompanyFragment
                }
                appointment_key
            }
            contents {
                ...ItemContentFragment
            }
            receipts {
                ...ReceiptFragment
            }
        }
    }
`;
