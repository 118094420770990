import React, { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import MiscItemDetail from './components/MiscItemDetail';
import MiscItemForm from './components/MiscItemForm';
import MiscItemList from './components/MiscItemList';

const MiscItems = (): ReactElement => {
    return (
        <Routes>
            <Route index element={<MiscItemList />} />
            <Route path={':id'} element={<MiscItemDetail />} />
            <Route path={':id/edit'} element={<MiscItemForm />} />
            <Route path={'create'} element={<MiscItemForm />} />
        </Routes>
    );
};

export default MiscItems;
