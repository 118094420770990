import { LoadingButton } from '@mui/lab';
import {
    ButtonBase,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import { format, setHours, setMinutes } from 'date-fns';
import React, { ReactElement } from 'react';
import { MdAdd } from 'react-icons/md';
import { Appointment } from '../../../../../../../../../../../../graphql/Appointment/Appointment';
import { Bol } from '../../../../../../../../../../../../graphql/Bol/Bol';
import { useBolCreation } from '../../../../../../../../../../../../graphql/Bol/operations/useBolCreation';
import { UpdateBolArgs } from '../../../../../../../../../../../../graphql/Bol/operations/useBolUpdate';
import { Itinerary } from '../../../../../../../../../../../../graphql/Itinerary/Itinerary';
import { UpdateItineraryArgs } from '../../../../../../../../../../../../graphql/Itinerary/operations/useItineraryUpdate';
import { dateFormats } from '../../../../../../../../../../../../utils/dateFormats';
import BolForm from './components/BolForm';
import ItineraryForm from './components/ItineraryForm';

const getArgsFromBol = (bol: Bol): UpdateBolArgs => {
    return {
        id: bol._id,
        data: {
            code: bol.code,
            on_behalf: bol.on_behalf ? bol.on_behalf._id : null,
            from: {
                company: bol.from.company ? bol.from.company._id : null,
                location: bol.from.location ? bol.from.location._id : null,
                date: bol.from.date,
                time: bol.from.time,
            },
            to: {
                company: bol.to.company ? bol.to.company._id : null,
                location: bol.to.location ? bol.to.location._id : null,
                date: bol.to.date,
                time: bol.to.time,
            },
            contents: bol.contents.map(({ client_qty, client_unit, item }) => ({
                unit: client_unit._id,
                item: item._id,
                quantity: client_qty,
            })),
        },
    };
};

export interface ItineraryRenderProps {
    children: Itinerary;
}

export const getTimeLabel = (value: number | null) => {
    const minutes = value == null ? null : value % 60;
    const hours =
        value == null || minutes == null ? null : (value - minutes) / 60;

    const parsedValue =
        hours == null || minutes == null
            ? ''
            : setMinutes(setHours(new Date(), hours), minutes);

    if (parsedValue == '') return '';
    else return ' by ' + format(parsedValue, 'HH:MM aa');
};

const AppointmentCell = (props: {
    children: Appointment;
    from?: true;
}): ReactElement => (
    <Box sx={{ display: 'flex' }}>
        <Box sx={{ display: 'flex', flexFlow: 'column' }}>
            <Typography variant="body2" color="textSecondary">
                {props.children.company
                    ? props.children.company.name
                    : 'Unspecified company'}
            </Typography>
            <Typography variant="caption" color="textSecondary">
                {props.children.location
                    ? props.children.location.label
                    : !props.children.company
                    ? ''
                    : 'Any location'}
            </Typography>
            <Typography variant="caption" color="textSecondary">
                {props.children.date
                    ? format(
                          new Date(props.children.date),
                          dateFormats.condensedDate
                      ) + getTimeLabel(props.children.time)
                    : 'Unspecified date'}
            </Typography>
        </Box>
    </Box>
);

const ItineraryRender = (props: ItineraryRenderProps): ReactElement => {
    const { children: itinerary } = props;

    const { palette, shape, shadows } = useTheme();

    const [bolState, setBolState] = React.useState<null | UpdateBolArgs>(null);
    const [itinState, setItinState] =
        React.useState<null | UpdateItineraryArgs>(null);

    const [createBol, { loading: createBolLoading }] = useBolCreation({
        variables: {
            itinerary: itinerary._id,
            data: {
                code: null,
                from: { date: null, time: null, location: null, company: null },
                to: { date: null, time: null, location: null, company: null },
                contents: [],
                on_behalf: null,
            },
        },
        onCompleted: ({ createBol: { itineraries } }) => {
            // const match = itineraries.find((it) => it._id == itinerary._id);
            // if (match) {
            //     if (match.bols.length > 0) {
            //         const bol = match.bols[match.bols.length - 1];
            //         setBolState(getArgsFromBol(bol));
            //     }
            // }
        },
    });
    return (
        <Box
            sx={{
                border: `1px solid ${palette.divider}`,
                ...shape,
                overflow: 'hidden',
                boxShadow: shadows[3],
            }}
        >
            <Box
                sx={{
                    padding: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingBottom: 0,
                }}
            >
                <ButtonBase
                    onClick={() =>
                        setItinState({
                            id: itinerary._id,
                            data: {
                                code: itinerary.code,
                                carrier: itinerary.carrier
                                    ? itinerary.carrier._id
                                    : null,
                            },
                        })
                    }
                    sx={{
                        flexFlow: 'column',
                        alignItems: 'flex-start',
                        ...shape,
                        padding: 0.5,
                        borderRadius: '8px',
                    }}
                >
                    <Typography>
                        {'PRO#: ' + (itinerary.code || 'Unassigned')}
                    </Typography>
                    <Typography
                        variant="body2"
                        color={!itinerary.carrier ? 'textSecondary' : undefined}
                    >
                        {'Carrier: ' +
                            (itinerary.carrier
                                ? itinerary.carrier.name
                                : 'Unassigned')}
                    </Typography>
                </ButtonBase>
            </Box>
            <Box sx={{ background: palette.background.default }}>
                {/* <ItineraryPreview>{itinerary}</ItineraryPreview> */}
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableCell sx={{ background: palette.tonal }}>
                            From
                        </TableCell>
                        <TableCell sx={{ background: palette.tonal }}>
                            To
                        </TableCell>
                        <TableCell sx={{ background: palette.tonal }}>
                            Contents
                        </TableCell>
                    </TableHead>
                    <TableBody>
                        {itinerary.bols.map((bol) => (
                            <TableRow
                                hover
                                key={'row_' + bol._id}
                                onClick={() => {
                                    setBolState(getArgsFromBol(bol));
                                }}
                            >
                                <TableCell>
                                    <AppointmentCell from>
                                        {bol.from}
                                    </AppointmentCell>
                                </TableCell>
                                <TableCell>
                                    <AppointmentCell>{bol.to}</AppointmentCell>
                                </TableCell>
                                <TableCell>
                                    {bol.contents.map(
                                        (content, contentIndex) => (
                                            <Typography
                                                key={'content_' + contentIndex}
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                {`${content.item.name} (${
                                                    content.client_qty
                                                } ${
                                                    content.client_unit[
                                                        content.client_qty == 1
                                                            ? 'name'
                                                            : 'plural'
                                                    ]
                                                })`}
                                            </Typography>
                                        )
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <LoadingButton
                    onClick={() => createBol()}
                    sx={{ marginLeft: 1 }}
                    startIcon={<MdAdd />}
                    size="small"
                    variant="text"
                    loading={createBolLoading}
                >
                    BOL to truck
                </LoadingButton>
            </Box>
            <BolForm state={bolState} setState={(v) => setBolState(v)} />
            <ItineraryForm
                state={itinState}
                setState={(v) => setItinState(v)}
            />
        </Box>
    );
};

export default ItineraryRender;
