import { gql } from '@apollo/client';

export interface DashboardRecipe {
    profile: { user_id: string; name: string; picture?: string | null };
    recipe: { _id: string; name: string };
}

export const DashboardRecipeFragment = gql`
    fragment DashboardRecipeFragment on DashboardRecipe {
        profile {
            user_id
            name
            picture
        }
        recipe {
            _id
            name
        }
    }
`;
