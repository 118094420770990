import { gql } from '@apollo/client';
import { AppDocument } from '../AppDocument/AppDocument';
import { Base } from '../Base/Base';
import { getLazyQueryHook, getQueryHook } from '../types';

export const ReceiptLineQuery = gql`
    query ReceiptLineQuery($identifier: String!) {
        receipt_line(identifier: $identifier) {
            order {
                _id
                code
            }
            receipt {
                _id
                date_created
                created_by {
                    email
                    name
                    username
                    user_id
                    picture
                    given_name
                    family_name
                    roles
                }
            }
            line {
                _id
                item {
                    _id
                    name
                }
                lots {
                    lot {
                        _id
                        code
                        company {
                            _id
                            name
                        }
                        item {
                            _id
                            name
                            spanish_name
                        }
                        qty
                    }
                }
                document {
                    filename
                    mimetype
                    data
                }
            }
        }
    }
`;

export interface ReceiptLine {
    order: {
        _id: string;
        code: string;
    };
    receipt: {
        _id: string;
        date_created: Date;
        created_by: Base['created_by'];
    };
    line: {
        _id: string;
        item: {
            _id: string;
            name: string;
        };
        lots: {
            lot: {
                _id: string;
                code: string;
                company: {
                    _id: string;
                    name: string;
                };
                item: {
                    _id: string;
                    name: string;
                    spanish_name: string;
                };
                qty: number;
            };
        }[];
        document: AppDocument;
    };
}

export interface ReceiptLineArgs {
    identifier: string;
}

export interface ReceiptLineRes {
    receipt_line: ReceiptLine;
}

export const useReceiptLine = getQueryHook<ReceiptLineRes, ReceiptLineArgs>(
    ReceiptLineQuery
);

export const useLazyReceiptLine = getLazyQueryHook<
    ReceiptLineRes,
    ReceiptLineArgs
>(ReceiptLineQuery);
