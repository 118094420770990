import { getMutationHook } from '../../../graphql/types';
import { gql } from '@apollo/client';

export enum LoginType {
    Username = 'Username',
    Email = 'Email',
}

export const LoginUpdate = gql`
    mutation ChangeLogin($value: String!, $type: LoginType!) {
        changeLogin(value: $value, type: $type)
    }
`;

export const useLoginUpdate = getMutationHook<
    { changeLogin: boolean },
    { value: string; type: LoginType }
>(LoginUpdate);
