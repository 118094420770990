import {
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Tooltip,
    useTheme,
} from '@mui/material/';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import React, { ReactElement } from 'react';
import { MdCheck, MdExpandMore, MdWarning } from 'react-icons/md';
import Anima from '../../../../../../../../components/Layout/Anima';
import { Bol } from '../../../../../../../../graphql/Bol/Bol';
import { FieldCategory } from '../../../../../../../../graphql/Form/FieldCategory';
import { FulfillmentLine } from '../../../../../../../../graphql/FulfillmentLine/FulfillmentLine';
import { UnitClass } from '../../../../../../../../graphql/Unit/UnitClass';
import { dateFormats } from '../../../../../../../../utils/dateFormats';
import { getTime } from '../../../../../../../../utils/getTime';
import { percentage } from '../../../../../../../../utils/percentage';

export interface LotBreakdownProps {
    bol: Bol;
    children: FulfillmentLine;
}

const LotBreakdown = (props: LotBreakdownProps): ReactElement => {
    const { bol, children: line } = props;

    const [focused, setFocused] = React.useState(false);

    const { palette } = useTheme();

    const lineUnits: Record<UnitClass, number> = {
        [UnitClass.Count]: 0,
        [UnitClass.Length]: 0,
        [UnitClass.Volume]: 0,
        [UnitClass.Weight]: 0,
    };

    for (const lot of line.lots) {
        const { qty, client_unit } = lot;
        lineUnits[client_unit.class] += qty;
    }

    const getQtys = (): [string, number | null] => {
        const unitText: Record<UnitClass, string> = {
            [UnitClass.Count]: 'Each',
            [UnitClass.Length]: 'Feet',
            [UnitClass.Volume]: 'Gallons',
            [UnitClass.Weight]: 'Pounds',
        };

        const vals: string[] = [];
        for (const key of Object.keys(lineUnits)) {
            const val = lineUnits[key as UnitClass];

            if (val) vals.push(`${val} ${unitText[key as UnitClass]}`);
        }

        const required =
            bol.contents.find((b) => b.item._id === line.item._id) || null;

        if (!required) return [vals.join(', '), null];

        const matchVal = lineUnits[required.client_unit.class];

        return [vals.join(', '), matchVal / required.qty];
    };

    const [label, percent] = getQtys();

    return (
        <React.Fragment>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexFlow: 'row',
                        gap: 2,
                    }}
                >
                    <Typography sx={{ fontWeight: 500 }}>
                        {`${line.item.name} - ${label}`}
                    </Typography>
                    {percent !== null && (
                        <Typography
                            sx={
                                percent == null
                                    ? {}
                                    : {
                                          color:
                                              percent >= 1
                                                  ? palette.success.main
                                                  : palette.error.main,
                                      }
                            }
                        >
                            ({percentage(percent)})
                        </Typography>
                    )}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <IconButton
                        size="small"
                        onClick={() => setFocused(!focused)}
                    >
                        <Anima type="rotate" in={focused}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <MdExpandMore />
                            </Box>
                        </Anima>
                    </IconButton>
                </Box>
            </Box>
            <Collapse in={focused}>
                <Box sx={{ paddingLeft: 3, paddingTop: 3, paddingBottom: 4 }}>
                    <Typography color="textSecondary">Lot Breakdown</Typography>
                    <Box p={0.5} />
                    <Table size="small">
                        <TableBody>
                            {line.lots.map((lot, lotIndex) => (
                                <TableRow key={'lot_' + lotIndex}>
                                    <TableCell>
                                        <Box sx={{ display: 'flex', gap: 1 }}>
                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                {`Lot: `}
                                            </Typography>
                                            <Typography variant="body2">
                                                {lot.lot.code}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Typography variant="body2">
                                            {`${lot.client_qty} ${
                                                lot.client_unit[
                                                    lot.client_qty == 1
                                                        ? 'name'
                                                        : 'plural'
                                                ]
                                            }`}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {line.lots.length > 1 && (
                                <TableRow>
                                    <TableCell sx={{ borderBottom: 'none' }} />
                                    <TableCell
                                        sx={{
                                            borderBottom: 'none',
                                            color: palette.text.secondary,
                                        }}
                                    >
                                        {label}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <Box p={2} />
                    {line.form_response &&
                        line.form_response.responses.length > 0 && (
                            <Box>
                                <Typography color="textSecondary">
                                    Quality Checks
                                </Typography>
                                <Box p={0.5} />
                                <Table size="small">
                                    <TableBody>
                                        {line.form_response.responses.map(
                                            (res, resIndex) => (
                                                <TableRow
                                                    key={'row_' + resIndex}
                                                >
                                                    <TableCell>
                                                        <Typography variant="body2">
                                                            {res.category ==
                                                            FieldCategory.LinearScale
                                                                ? `${res.question} (${res.options[0]}-${res.options[1]})`
                                                                : res.question}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="body2">
                                                            {res.category ===
                                                            FieldCategory.Date
                                                                ? format(
                                                                      new Date(
                                                                          res.response
                                                                      ),
                                                                      dateFormats.condensedDate
                                                                  )
                                                                : res.category ===
                                                                  FieldCategory.Time
                                                                ? res.response !==
                                                                  null
                                                                    ? getTime(
                                                                          parseInt(
                                                                              res.response
                                                                          )
                                                                      )
                                                                    : ''
                                                                : res.response}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        {res.passed !==
                                                            null && (
                                                            <Tooltip
                                                                arrow
                                                                title={
                                                                    res.passed
                                                                        ? 'Passed!'
                                                                        : 'Failed!'
                                                                }
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        display:
                                                                            'inline-block',
                                                                    }}
                                                                >
                                                                    {res.passed ? (
                                                                        <MdCheck
                                                                            style={{
                                                                                color: palette
                                                                                    .success
                                                                                    .main,
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <MdWarning
                                                                            style={{
                                                                                color: palette
                                                                                    .warning
                                                                                    .main,
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Box>
                                                            </Tooltip>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </Box>
                        )}
                </Box>
            </Collapse>
        </React.Fragment>
    );
};

export default LotBreakdown;
