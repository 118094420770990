import { gql } from '@apollo/client';
import { getQueryHook } from '../types';
import { LotEdgeFragment } from './LotEdge/LotEdge';
import { LotGraph } from './LotGraph';
import { LotNodeFragment } from './LotNode/LotNode';

export const LotGraphQuery = gql`
    ${LotNodeFragment}
    ${LotEdgeFragment}
    query LotGraphQuery($id: ObjectId!, $direction: Boolean!) {
        lot(id: $id) {
            code
            item {
                _id
                name
            }
            graph(direction: $direction) {
                nodes {
                    ...LotNodeFragment
                }
                edges {
                    ...LotEdgeFragment
                }
            }
        }
    }
`;

export interface LotGraphRes {
    lot: { graph: LotGraph; item: { _id: string; name: string }; code: string };
}

export interface LotGraphArgs {
    id: string;
    direction: boolean;
}

export const useLotGraph = getQueryHook<LotGraphRes, LotGraphArgs>(
    LotGraphQuery
);
