import { Button, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { MdAdd } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import SmartTable from '../../../../../../components/display/SmartTable';
import SearchInput from '../../../../../../components/inputs/FieldInputs/SearchInput';
import AppNav from '../../../../../../components/Layout/AppNav/components';
import NavContent from '../../../../../../components/Layout/AppNav/components/NavContent';
import NavHeader from '../../../../../../components/Layout/NavHeader';
import { ProductFilter } from '../../../../../../graphql/Item/extensions/Product/ProductFilter';
import { useProducts } from '../../../../../../graphql/Item/extensions/Product/operations/useProducts';

const ProductList = (): ReactElement => {
    const nav = useNavigate();

    const [filter, setFilter] = React.useState<ProductFilter>({
        skip: 0,
        take: 50,
    });

    const { data, error, loading } = useProducts({
        variables: { filter },
        fetchPolicy: 'network-only',
    });

    const count = data ? data.products.count : 0;
    const products = data ? data.products.items : [];

    return (
        <AppNav error={error} loading={loading}>
            <NavContent>
                {{
                    header: (
                        <NavHeader>
                            <Typography variant="crisp">Products</Typography>
                            <Button
                                onClick={() => nav('create')}
                                startIcon={<MdAdd />}
                            >
                                New Product
                            </Button>
                        </NavHeader>
                    ),
                    content: (
                        <SmartTable
                            data={products}
                            getProps={(d) => ({
                                id: d._id,
                                onClick: () => nav(d._id),
                            })}
                            pagination={{
                                count,
                                filter,
                                setFilter: (f) =>
                                    setFilter({ ...filter, ...f }),
                            }}
                            controls={{
                                Name: (
                                    <SearchInput
                                        placeholder="Name"
                                        variant="standard"
                                        InputProps={{ disableUnderline: true }}
                                        value={filter.name || ''}
                                        onChange={(name) =>
                                            setFilter({
                                                ...filter,
                                                name: name || undefined,
                                                skip: 0,
                                            })
                                        }
                                    />
                                ),
                                SKU: (
                                    <SearchInput
                                        placeholder="SKU"
                                        variant="standard"
                                        InputProps={{ disableUnderline: true }}
                                        value={filter.sku || ''}
                                        onChange={(sku) =>
                                            setFilter({
                                                ...filter,
                                                sku: sku || undefined,
                                                skip: 0,
                                            })
                                        }
                                    />
                                ),
                                UPC: (
                                    <SearchInput
                                        placeholder="UPC"
                                        variant="standard"
                                        InputProps={{ disableUnderline: true }}
                                        value={filter.upc || ''}
                                        onChange={(upc) =>
                                            setFilter({
                                                ...filter,
                                                upc: upc || undefined,
                                                skip: 0,
                                            })
                                        }
                                    />
                                ),
                            }}
                        >
                            {{
                                Name: (c) => (
                                    <Typography sx={{ fontWeight: 700 }}>
                                        {c.name}
                                    </Typography>
                                ),
                                SKU: (c) => c.sku,
                                UPC: (c) => c.upc,
                            }}
                        </SmartTable>
                    ),
                }}
            </NavContent>
        </AppNav>
    );
};

export default ProductList;
