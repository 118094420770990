import {
    Box,
    Button,
    Collapse,
    Grow,
    IconButton,
    useTheme,
} from '@mui/material/';
import Typography from '@mui/material/Typography';
import React, { ReactElement } from 'react';
import { MdClear } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import AppNav from '../../../../components/Layout/AppNav/components';
import NavContent from '../../../../components/Layout/AppNav/components/NavContent';
import { TinyLot } from '../../../../graphql/Lot/Lot';
import { LotFilter } from '../../../../graphql/Lot/LotFilter';
import { useTinyLots } from '../../../../graphql/Lot/useLots';
import NodeGraphRender from './components/NodeGraphRender';
import NodeGraphSearch from './components/NodeGraphSearch';

const NodeGraph = (): ReactElement => {
    const { palette, shape } = useTheme();

    const nav = useNavigate();

    const [filter, setFilter] = React.useState<LotFilter>({
        skip: 0,
        take: 25,
    });

    const { data, error, loading } = useTinyLots({
        variables: {
            filter,
        },
        skip: !filter.code,
    });

    return (
        <AppNav>
            <NavContent>
                {{
                    header: (
                        <Box>
                            <Typography variant="crisp">Node Graph</Typography>
                        </Box>
                    ),
                    content: (
                        <NodeGraphSearch>
                            {{
                                setLot: (l) => nav(l._id),
                                lots: data ? data.lots.items : [],
                                search: filter.code || '',
                                loading,
                                setSearch: (code) =>
                                    setFilter({ ...filter, code }),
                            }}
                        </NodeGraphSearch>
                    ),
                }}
            </NavContent>
        </AppNav>
    );
};

export default NodeGraph;
