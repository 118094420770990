import { ApolloError } from '@apollo/client';
import React, { ReactElement } from 'react';

export interface LanguageContext {
    preference: 'en' | 'sp';
    setPreference: (value: 'en' | 'sp') => void;
}

export const Context = React.createContext<LanguageContext>({
    preference: 'sp',
    setPreference: (en) => null,
});

const LanguageProvider = (props: { children: ReactElement }): ReactElement => {
    const [preference, setPreference] =
        React.useState<LanguageContext['preference']>('sp');

    return (
        <Context.Provider
            value={{
                preference,
                setPreference: (p) => setPreference(p),
            }}
        >
            {props.children}
        </Context.Provider>
    );
};

export default LanguageProvider;

export const useLanguageContext = (): LanguageContext =>
    React.useContext(Context);
