import { gql } from '@apollo/client';
import { Item, TinyItem } from '../../Item';

export type Package = Item;
export type TinyPackage = TinyItem;

export const PackageFragment = gql`
    fragment PackageFragment on Package {
        ...BaseFragment
        name
        spanish_name
        unit_class
        conversions {
            to
            multiplier
        }
    }
`;

export const TinyPackageFragment = gql`
    fragment TinyPackageFragment on Package {
        _id
        name
        spanish_name
        unit_class
        conversions {
            to
            multiplier
        }
    }
`;
