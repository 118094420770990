import { Box } from '@mui/material/';
import Typography from '@mui/material/Typography';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import SmartTable from '../../../../components/display/SmartTable';
import SearchInput from '../../../../components/inputs/FieldInputs/SearchInput';
import AppNav from '../../../../components/Layout/AppNav/components';
import NavContent from '../../../../components/Layout/AppNav/components/NavContent';
import NavHeader from '../../../../components/Layout/NavHeader';
import { LotFilter } from '../../../../graphql/Lot/LotFilter';
import { useTinyLots } from '../../../../graphql/Lot/useLots';
import LotDrawer from './components/LotDrawer';

const LotList = (): ReactElement => {
    const nav = useNavigate();
    const [filter, setFilter] = React.useState<LotFilter>({
        skip: 0,
        take: 50,
    });

    const { data, error, loading } = useTinyLots({
        variables: { filter },
    });

    const lots = data ? data.lots.items : [];

    return (
        <AppNav error={error} loading={loading}>
            <NavContent>
                {{
                    header: (
                        <NavHeader>
                            <Typography variant="crisp">Lots</Typography>
                            <Box></Box>
                        </NavHeader>
                    ),
                    content: (
                        <SmartTable
                            pagination={{
                                filter,
                                setFilter: (f) =>
                                    setFilter({ ...filter, ...f }),
                                count: data ? data.lots.count : 0,
                            }}
                            data={lots}
                            getProps={(lot) => ({
                                id: lot._id,
                                onClick: () => nav(lot._id),
                            })}
                            controls={{
                                ['Lot Number']: (
                                    <SearchInput
                                        placeholder="Lot Number"
                                        value={filter.code || ''}
                                        onChange={(val) => {
                                            setFilter({
                                                ...filter,
                                                code: val || '',
                                                skip: 0,
                                            });
                                        }}
                                        variant="standard"
                                        InputProps={{ disableUnderline: true }}
                                    />
                                ),
                                Item: (
                                    <SearchInput
                                        placeholder="Item"
                                        value={filter.item_name || ''}
                                        onChange={(val) => {
                                            setFilter({
                                                ...filter,
                                                item_name: val || '',
                                                skip: 0,
                                            });
                                        }}
                                        variant="standard"
                                        InputProps={{ disableUnderline: true }}
                                    />
                                ),
                                ['Produced by']: (
                                    <SearchInput
                                        placeholder="Produced by"
                                        value={filter.company_name || ''}
                                        onChange={(val) => {
                                            setFilter({
                                                ...filter,
                                                company_name: val || '',
                                                skip: 0,
                                            });
                                        }}
                                        variant="standard"
                                        InputProps={{ disableUnderline: true }}
                                    />
                                ),
                            }}
                        >
                            {{
                                ['Lot Number']: (lot) => lot.code,
                                Item: (lot) => lot.item.name,
                                ['Produced by']: (lot) => lot.company.name,
                            }}
                        </SmartTable>
                    ),
                }}
            </NavContent>
            <LotDrawer />
        </AppNav>
    );
};

export default LotList;
