import {
    Button,
    IconButton,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material/';
import Box from '@mui/material/Box';
import React, { ReactElement } from 'react';
import { MdAdd, MdClear } from 'react-icons/md';
import { getSectionStyles } from '../..';

export interface ParameterFormProps {
    children: { parameters: string[]; setParameters: (d: string[]) => void };
}

const ParameterForm = (props: ParameterFormProps): ReactElement => {
    const {
        children: { parameters, setParameters },
    } = props;

    const theme = useTheme();
    const { palette, shape } = theme;

    return (
        <Box sx={{ ...getSectionStyles(theme) }}>
            <Box
                sx={{
                    background: palette.background.paper,
                    p: 2,
                    paddingLeft: 2,
                    paddingRight: 2,
                }}
            >
                <Typography>Parameters</Typography>
            </Box>
            <Box sx={{ background: palette.tonal }}>
                <Box>
                    {parameters.map((param, paramIndex) => (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                paddingTop: 1,
                                paddingLeft: 2,
                                paddingRight: 2,
                            }}
                            key={'p_' + paramIndex}
                        >
                            <TextField
                                placeholder="Parameter"
                                variant="standard"
                                InputProps={{ disableUnderline: true }}
                                value={param}
                                onChange={(e) => {
                                    const newVal = e.target.value;
                                    const copy = [...parameters];
                                    copy[paramIndex] = newVal;
                                    setParameters(copy);
                                }}
                            />
                            <Box>
                                <Tooltip arrow title="Remove Step">
                                    <IconButton
                                        onClick={() => {
                                            const copy = [...parameters];
                                            copy.splice(paramIndex, 1);
                                            setParameters(copy);
                                        }}
                                        size="small"
                                    >
                                        <MdClear />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    ))}
                </Box>
                <Box sx={{ p: 1 }}>
                    <Button
                        onClick={() => setParameters([...parameters, ''])}
                        variant="text"
                        startIcon={<MdAdd />}
                    >
                        Parameter
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default ParameterForm;
