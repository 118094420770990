import { gql } from '@apollo/client';

export interface TinyProfile {
    name: string;
    given_name?: string | null;
    family_name?: string | null;
    email?: string | null;
    picture?: string | null;
    user_id: string;
}

export const TinyProfileFragment = gql`
    fragment TinyProfileFragment on Profile {
        name
        given_name
        family_name
        email
        picture
        user_id
    }
`;
