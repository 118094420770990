import { gql } from '@apollo/client';
import { AddressFragment } from '../../Address/Address';
import { AppointmentFragment } from '../../Appointment/Appointment';
import { BaseFragment } from '../../Base/Base';
import { BolFragment } from '../../Bol/Bol';
import { TinyCompanyFragment } from '../../Company/Company';
import { TinyContactFragment } from '../../Contact/Contact';
import { ItemContentFragment } from '../../Content/ItemContent/ItemContent';
import { LotContentFragment } from '../../Content/LotContent/LotContent';
import { FormResponseFragment } from '../../FormResponse/FormResponse';
import { FulfillmentLineFragment } from '../../FulfillmentLine/FulfillmentLine';
import { TinyItemFragment } from '../../Item/Item';
import { ItineraryFragment } from '../../Itinerary/Itinerary';
import { ItineraryScheduleFragment } from '../../Itinerary/ItinerarySchedule';
import {
    CompanyLocationFragment,
    TinyLocationFragment,
} from '../../Location/Location';
import { TinyLotFragment } from '../../Lot/Lot';
import { Order, OrderFragment } from '../../Order/Order';
import { ShipmentFragment } from '../Shipment';
import { getMutationHook } from '../../types';
import { TinyUnitFragment } from '../../Unit/Unit';
import { UpdateShipmentInput } from '../inputs/UpdateShipmentInput';
import { ReceiptFragment } from '../../Receipt/Receipt';
import { VerificationFragment } from '../../Verification/Verification';

export const UpdateShipment = gql`
    ${BaseFragment}
    ${OrderFragment}
    ${TinyCompanyFragment}
    ${TinyContactFragment}
    ${CompanyLocationFragment}
    ${ItineraryFragment}
    ${BolFragment}
    ${AppointmentFragment}
    ${ItemContentFragment}
    ${ShipmentFragment}
    ${ReceiptFragment}
    ${TinyLocationFragment}
    ${TinyItemFragment}
    ${TinyUnitFragment}
    ${FulfillmentLineFragment}
    ${FormResponseFragment}
    ${LotContentFragment}
    ${TinyLotFragment}
    ${AddressFragment}
    ${ItineraryScheduleFragment}
    ${VerificationFragment}
    mutation UpdateShipment($data: UpdateShipmentInput!, $id: ObjectId!) {
        updateShipment(data: $data, id: $id) {
            ...OrderFragment
        }
    }
`;

export interface UpdateShipmentArgs {
    data: UpdateShipmentInput;
    id: string;
}

export interface UpdateShipmentRes {
    updateShipment: Order;
}

export const useShipmentUpdate = getMutationHook<
    UpdateShipmentRes,
    UpdateShipmentArgs
>(UpdateShipment);
