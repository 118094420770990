import { Button } from '@mui/material';
import React, { ReactElement } from 'react';
import { OperationResult } from '../../../utils/types/OperationResult';
import Message from '../Message';

export interface FormResultProps<T> {
    entity: string;
    children: OperationResult<T> | null;
    clear: () => void;
    onComplete: () => void;
}

const FormResult = <T,>(props: FormResultProps<T>): ReactElement | null => {
    const { entity, children: result, clear, onComplete } = props;

    if (!result) return null;

    if (result.success === false)
        return (
            <Message
                type="Warning"
                action={<Button onClick={clear}>Try again</Button>}
            >
                {result.error.message}
            </Message>
        );
    const getActionPhrase = (): string => {
        let phrase = 'Updated';

        if (Object.keys(result.data).some((d) => d.includes('create'))) {
            phrase = 'Created';
        } else {
            for (const key of Object.keys(result.data)) {
                const obj = result.data as Record<string, any>;
                if ('deletion' in obj[key] && obj[key].deletion !== null)
                    phrase = 'Deleted';
            }
        }

        return phrase;
    };

    return (
        <Message
            type="Success"
            onComplete={onComplete}
        >{`${entity} ${getActionPhrase()}!`}</Message>
    );
};

export default FormResult;
