import {
    Box,
    Breadcrumbs,
    Button,
    Divider,
    Grid,
    Link,
    ListItemIcon,
    Menu,
    MenuItem,
    Typography,
    useTheme,
} from '@mui/material/';
import React, { ReactElement } from 'react';
import { MdCreateNewFolder, MdNoteAdd } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import AppNav from '../../../../components/Layout/AppNav/components';
import NavContent from '../../../../components/Layout/AppNav/components/NavContent';
import NavHeader from '../../../../components/Layout/NavHeader';
import { Folder } from '../../../../graphql/Folder/Folder';
import FolderChip from './components/FolderChip';
import FolderForm from './components/FolderForm';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useFolderUpdate } from '../../../../graphql/Folder/operations/useFolderUpdate';
import { Edit, FolderOpen } from '@mui/icons-material';
import RecipeForm from './components/RecipeForm';
import RecipeChip from './components/RecipeChip';
import { useRecipeUpdate } from '../../../../graphql/Recipe/operations/useRecipeUpdate';
import { useRecipeDuplication } from '../../../../graphql/Recipe/operations/useRecipeDuplication';
import { FoldersQuery } from '../../../../graphql/Folder/operations/useFolders';
import {
    FolderQuery,
    useFolder,
} from '../../../../graphql/Folder/operations/useFolder';

const RecipeNav = (): ReactElement => {
    const { id } = useParams();
    const nav = useNavigate();
    const { palette } = useTheme();

    const [focus, setFocus] = React.useState<
        | null
        | {
              coordinates: {
                  x: number;
                  y: number;
              };
              folder: Folder['folders'][number];
          }
        | {
              coordinates: {
                  x: number;
                  y: number;
              };
              recipe: Folder['recipes'][number];
          }
    >(null);

    const [folder, setFolder] = React.useState<null | Folder>(null);

    const [folderFocus, setFolderFocus] = React.useState<
        null | { _id: string; name: string } | true
    >(null);

    const [recipeFocus, setRecipeFocus] = React.useState<
        null | { _id: string; name: string; item: string } | true
    >(null);

    const { data, error, loading, refetch } = useFolder({
        variables: { id: id || null },
        fetchPolicy: 'network-only',
        onCompleted: (d) => setFolder(d.folder),
    });

    const [handleUpdate] = useFolderUpdate({
        refetchQueries: [
            {
                query: FolderQuery,
                variables: { id: id || null },
                fetchPolicy: 'network-only',
            },
        ],
    });
    const [handleRecipeUpdate] = useRecipeUpdate({
        refetchQueries: [
            {
                query: FolderQuery,
                variables: { id: id || null },
                fetchPolicy: 'network-only',
            },
        ],
        onCompleted: ({ updateRecipe }) => {
            if (folder && updateRecipe.deletion) {
                const copy = { ...folder };
                copy.recipes = copy.recipes?.filter(
                    (r) => r._id != updateRecipe._id
                );
                setFolder(copy);
            }
        },
    });

    const [duplicate, { loading: duplicateLoading }] = useRecipeDuplication({
        variables:
            focus && 'recipe' in focus
                ? {
                      id: focus.recipe._id,
                  }
                : undefined,
        refetchQueries: [{ query: FolderQuery, variables: { id: id || null } }],
    });

    const folders = data ? data.folder.folders : [];

    return (
        <AppNav error={error} loading={loading}>
            <DragDropContext
                onDragEnd={(event) => {
                    if (event.combine) {
                        if (folder) {
                            const [moveType, moveTo] =
                                event.combine.draggableId.split('_');
                            const [type, moving] = event.draggableId.split('_');

                            const moveToFolder = folder.folders.find(
                                (f) => f._id == moveTo
                            );

                            const folderToMove = folder.folders.find(
                                (f) => f._id == moving
                            );

                            const recipeToMove = folder.recipes.find(
                                (f) => f._id == moving
                            );

                            if (moveToFolder && folderToMove) {
                                setFolder({
                                    ...folder,
                                    folders: folder.folders.filter(
                                        (f) => f._id !== folderToMove._id
                                    ),
                                });
                                handleUpdate({
                                    variables: {
                                        id: folderToMove._id,
                                        data: {
                                            name: folderToMove.name,
                                            parent: moveToFolder._id,
                                        },
                                    },
                                });
                            } else if (moveToFolder && recipeToMove) {
                                setFolder({
                                    ...folder,
                                    recipes: folder.recipes.filter(
                                        (r) => r._id !== recipeToMove._id
                                    ),
                                });
                                handleRecipeUpdate({
                                    variables: {
                                        id: recipeToMove._id,
                                        data: {
                                            name: recipeToMove.name,
                                            item: recipeToMove.item._id,
                                            folder: moveToFolder._id,
                                        },
                                    },
                                    refetchQueries: ['FolderQuery'],
                                });
                            }
                        }
                    } else if (folder && event.destination) {
                        const moving = event.draggableId.split('_')[1];
                        const [type, destination] =
                            event.destination.droppableId.split('_');

                        if (type == 'ancestor') {
                            const folderToMove = folder.folders.find(
                                (f) => f._id == moving
                            );

                            const recipeToMove = folder.recipes.find(
                                (f) => f._id == moving
                            );

                            const moveToFolder = folder.ancestry.find(
                                (f) => f._id == destination
                            );

                            if (folderToMove && moveToFolder) {
                                setFolder({
                                    ...folder,
                                    folders: folder.folders.filter(
                                        (f) => f._id !== folderToMove._id
                                    ),
                                });
                                handleUpdate({
                                    variables: {
                                        id: folderToMove._id,
                                        data: {
                                            name: folderToMove.name,
                                            parent:
                                                moveToFolder.name == 'Home'
                                                    ? null
                                                    : moveToFolder._id,
                                        },
                                    },
                                });
                            } else if (recipeToMove && moveToFolder) {
                                setFolder({
                                    ...folder,
                                    recipes: folder.recipes.filter(
                                        (r) => r._id != recipeToMove._id
                                    ),
                                });
                                handleRecipeUpdate({
                                    variables: {
                                        id: recipeToMove._id,
                                        data: {
                                            name: recipeToMove.name,
                                            item: recipeToMove.item._id,
                                            folder:
                                                moveToFolder.name == 'Home'
                                                    ? null
                                                    : moveToFolder._id,
                                        },
                                    },
                                });
                            }
                        }
                    }
                }}
            >
                {folder && (
                    <NavContent>
                        {{
                            header: (
                                <Box>
                                    <NavHeader>
                                        <Box>
                                            <Typography variant="crisp">
                                                Recipes
                                            </Typography>
                                            <Box sx={{ paddingTop: 2 }}>
                                                <Breadcrumbs
                                                    maxItems={6}
                                                    aria-label="breadcrumb"
                                                >
                                                    {folder.ancestry.map(
                                                        (ancestor) => (
                                                            <Box
                                                                key={
                                                                    'key_' +
                                                                    ancestor._id
                                                                }
                                                            >
                                                                <Droppable
                                                                    droppableId={
                                                                        'ancestor_' +
                                                                        ancestor._id
                                                                    }
                                                                >
                                                                    {(
                                                                        provided,
                                                                        snapshot
                                                                    ) => (
                                                                        <Box
                                                                            {...provided.droppableProps}
                                                                            ref={
                                                                                provided.innerRef
                                                                            }
                                                                            sx={
                                                                                snapshot.isDraggingOver
                                                                                    ? {
                                                                                          background:
                                                                                              palette
                                                                                                  .background
                                                                                                  .paper,
                                                                                      }
                                                                                    : undefined
                                                                            }
                                                                        >
                                                                            <Link
                                                                                sx={{
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                                underline="hover"
                                                                                color="inherit"
                                                                                onClick={() =>
                                                                                    nav(
                                                                                        `/recipes/${
                                                                                            ancestor.name ==
                                                                                            'Home'
                                                                                                ? ''
                                                                                                : ancestor._id
                                                                                        }`
                                                                                    )
                                                                                }
                                                                            >
                                                                                {
                                                                                    ancestor.name
                                                                                }
                                                                            </Link>
                                                                        </Box>
                                                                    )}
                                                                </Droppable>
                                                            </Box>
                                                        )
                                                    )}
                                                    <Typography color="text.primary">
                                                        {folder.name}
                                                    </Typography>
                                                </Breadcrumbs>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: 'flex', gap: 2 }}>
                                            <Button
                                                color="inherit"
                                                onClick={() =>
                                                    setFolderFocus(true)
                                                }
                                                endIcon={<MdCreateNewFolder />}
                                            >
                                                Folder
                                            </Button>
                                            <Button
                                                onClick={() =>
                                                    setRecipeFocus(true)
                                                }
                                                endIcon={<MdNoteAdd />}
                                            >
                                                Recipe
                                            </Button>
                                        </Box>
                                    </NavHeader>
                                    <Box p={1} />
                                    <Divider />
                                </Box>
                            ),
                            content: (
                                <Box>
                                    <Droppable
                                        isCombineEnabled
                                        droppableId="folder"
                                    >
                                        {(provided, snapshot) => (
                                            <Grid
                                                container
                                                spacing={0}
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {folder.folders.map(
                                                    (f, folderIndex) => (
                                                        <FolderChip
                                                            setFocus={(d) =>
                                                                setFocus(d)
                                                            }
                                                            index={folderIndex}
                                                            key={'f_' + f._id}
                                                        >
                                                            {f}
                                                        </FolderChip>
                                                    )
                                                )}
                                                {folder.recipes.map(
                                                    (recipe, recipeIndex) => (
                                                        <RecipeChip
                                                            setFocus={(d) =>
                                                                setFocus(d)
                                                            }
                                                            index={
                                                                recipeIndex +
                                                                folder.folders
                                                                    .length
                                                            }
                                                            key={
                                                                'r_' +
                                                                recipe._id
                                                            }
                                                        >
                                                            {recipe}
                                                        </RecipeChip>
                                                    )
                                                )}
                                                {provided.placeholder}
                                            </Grid>
                                        )}
                                    </Droppable>
                                </Box>
                            ),
                        }}
                    </NavContent>
                )}
                <FolderForm
                    parent={id || null}
                    focus={folderFocus}
                    onClose={() => setFolderFocus(null)}
                />
                <RecipeForm
                    parent={id || null}
                    focus={recipeFocus}
                    onClose={() => setRecipeFocus(null)}
                    onDeleted={(dId) => {
                        if (folder) {
                            const copy = { ...folder };
                            copy.recipes = [
                                ...copy.recipes.filter((r) => r._id !== dId),
                            ];
                            setFolder(copy);
                        }
                    }}
                />
            </DragDropContext>
            <Menu
                anchorReference="anchorPosition"
                anchorPosition={
                    focus !== null
                        ? {
                              top: focus.coordinates.y,
                              left: focus.coordinates.x,
                          }
                        : undefined
                }
                onClose={() => setFocus(null)}
                open={focus !== null && 'folder' in focus}
            >
                <MenuItem
                    divider
                    onClick={() => {
                        if (focus && 'folder' in focus) {
                            setFolderFocus(focus.folder);
                            setFocus(null);
                        }
                    }}
                >
                    <ListItemIcon>
                        <Edit />
                    </ListItemIcon>
                    Edit Folder
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        if (focus && 'folder' in focus) {
                            nav(`/recipes/${focus.folder._id}`);
                            setFocus(null);
                        }
                    }}
                >
                    <ListItemIcon>
                        <FolderOpen />
                    </ListItemIcon>
                    Open
                </MenuItem>
            </Menu>
            <Menu
                anchorReference="anchorPosition"
                anchorPosition={
                    focus !== null
                        ? {
                              top: focus.coordinates.y,
                              left: focus.coordinates.x,
                          }
                        : undefined
                }
                onClose={() => setFocus(null)}
                open={focus !== null && 'recipe' in focus}
            >
                <MenuItem
                    onClick={() => {
                        if (focus && 'recipe' in focus) {
                            setRecipeFocus({
                                _id: focus.recipe._id,
                                name: focus.recipe.name,
                                item: focus.recipe.item._id,
                            });
                            setFocus(null);
                        }
                    }}
                >
                    Edit Recipe
                </MenuItem>
                <MenuItem
                    disabled={duplicateLoading}
                    onClick={() => {
                        duplicate();
                        setFocus(null);
                    }}
                >
                    Duplicate Recipe
                </MenuItem>
            </Menu>
        </AppNav>
    );
};

export default RecipeNav;
