import { LoadingButton } from '@mui/lab';
import {
    Box,
    Button,
    ButtonGroup,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material/';
import TextField from '@mui/material/TextField';
import React, { ReactElement } from 'react';
import { MdCheck } from 'react-icons/md';
import Message from '../../../../../../components/feedback/Message';
import CarefullButton from '../../../../../../components/inputs/Buttons/CarefulButton';
import ItemField from '../../../../../../components/inputs/FieldInputs/ItemField';
import ItemTypeInput from '../../../../../../components/inputs/FieldInputs/ItemTypeInput';
import FormRow from '../../../../../../components/Layout/FormRow';
import ResponsiveDialog from '../../../../../../components/Layout/ResponsiveDialog';
import { FolderQuery } from '../../../../../../graphql/Folder/operations/useFolder';
import { ItemType } from '../../../../../../graphql/Item/Item';
import { useItem } from '../../../../../../graphql/Item/operations/useItem';
import { RecipeQuery } from '../../../../../../graphql/Recipe/operations/useRecipe';
import {
    CreateRecipeArgs,
    CreateRecipeRes,
    useRecipeCreation,
} from '../../../../../../graphql/Recipe/operations/useRecipeCreation';
import { RecipesQuery } from '../../../../../../graphql/Recipe/operations/useRecipes';
import {
    UpdateRecipeArgs,
    UpdateRecipeRes,
    useRecipeUpdate,
} from '../../../../../../graphql/Recipe/operations/useRecipeUpdate';
import { OperationResult } from '../../../../../../utils/types/OperationResult';

export interface RecipeFormProps {
    parent: string | null;
    focus: { _id: string; name: string; item: string } | null | true;
    onClose: () => void;
    onDeleted: (id: string) => void;
}

const RecipeForm = (props: RecipeFormProps): ReactElement => {
    const { focus, parent, onClose, onDeleted } = props;

    const { palette } = useTheme();

    const [itemType, setItemType] = React.useState<ItemType>(ItemType.Cookie);

    const [state, setState] = React.useState<
        null | CreateRecipeArgs | UpdateRecipeArgs
    >(null);

    useItem({
        fetchPolicy: 'network-only',
        skip: focus == true || focus == null || !focus.item,
        variables:
            focus !== null && focus !== true ? { id: focus.item } : undefined,
        onCompleted: (data) => {
            if (data.item.type !== itemType) {
                setItemType(data.item.type);
                if (state) {
                    setState({
                        ...state,
                        data: { ...state.data, item: data.item._id },
                    });
                }
            }
        },
    });

    const [result, setResult] = React.useState<null | OperationResult<
        CreateRecipeRes | UpdateRecipeRes
    >>(null);

    const [handleCreate, { loading: createLoading }] = useRecipeCreation({
        variables: state && !('id' in state) ? state : undefined,
        onCompleted: (data) => setResult({ success: true, data }),
        onError: (error) => setResult({ success: false, error }),
        refetchQueries: [
            RecipeQuery,
            RecipesQuery,
            {
                query: FolderQuery,
                variables: { id: parent },
                fetchPolicy: 'network-only',
            },
        ],
    });

    const [handleUpdate, { loading: updateLoading }] = useRecipeUpdate({
        variables: state && 'id' in state ? state : undefined,
        onCompleted: (data) => setResult({ success: true, data }),
        onError: (error) => setResult({ success: false, error }),
        refetchQueries: [
            RecipeQuery,
            RecipesQuery,
            {
                query: FolderQuery,
                variables: { id: parent },
                fetchPolicy: 'network-only',
            },
        ],
    });

    React.useEffect(() => {
        if (focus == null) setState(null);
        if (focus == true)
            setState({ data: { name: '', item: '', folder: parent } });
        else if (focus)
            setState({
                id: focus._id,
                data: {
                    name: focus.name,
                    item: focus.item,
                    folder: parent,
                },
            });
    }, [focus, parent]);

    const handleClose = () => {
        if (
            result &&
            result.success &&
            Object.values(result.data)[0].deletion
        ) {
            onDeleted(Object.values(result.data)[0]._id);
            setState(null);
            setResult(null);
            onClose();
        } else {
            setState(null);
            setResult(null);
            onClose();
        }
    };

    const submit = () => {
        if (state) {
            if ('id' in state) handleUpdate();
            else handleCreate();
        }
    };

    const getHoldup = (): string | null => {
        if (!state) return null;
        if (!state.data.name) return 'Please enter a recipe name';
        if (state.data.name == 'Home' || state.data.name == 'home')
            return '*Home* is a restricted recipe name';
        return null;
    };

    const holdup = getHoldup();

    return (
        <ResponsiveDialog
            PaperProps={{ sx: { background: palette.tonal } }}
            open={Boolean(state)}
            onClose={() => handleClose()}
        >
            <Box sx={{ width: 300, position: 'relative' }}>
                {result && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            background: palette.tonal,
                            zIndex: 100,
                        }}
                    >
                        {result.success ? (
                            <Message
                                type="Success"
                                onComplete={() => handleClose()}
                            >
                                {'createRecipe' in result.data
                                    ? 'Recipe created!'
                                    : 'Recipe Updated!'}
                            </Message>
                        ) : (
                            <Message
                                type="Error"
                                action={
                                    <Button onClick={() => setResult(null)}>
                                        Reset
                                    </Button>
                                }
                            >
                                {result.error.message}
                            </Message>
                        )}
                    </Box>
                )}
                <Typography variant="h6">
                    {state
                        ? 'id' in state
                            ? 'Update Recipe'
                            : 'Create Recipe'
                        : ''}
                </Typography>
                <Box p={0.5} />
                <FormRow>
                    <TextField
                        autoFocus
                        value={state ? state.data.name : ''}
                        onChange={(e) => {
                            if (state) {
                                setState({
                                    ...state,
                                    data: {
                                        ...state.data,
                                        name: e.target.value,
                                    },
                                });
                            }
                        }}
                        label="Recipe Name"
                        error={
                            state !== null &&
                            (state.data.name == 'Home' ||
                                state.data.name == 'home')
                        }
                        fullWidth
                    />
                </FormRow>
                <FormRow>
                    <ToggleButtonGroup fullWidth value={itemType}>
                        <ToggleButton
                            onClick={() => {
                                setItemType(ItemType.Cookie);
                                if (state) {
                                    setState({
                                        ...state,
                                        data: { ...state.data, item: '' },
                                    });
                                }
                            }}
                            value={ItemType.Cookie}
                        >
                            Cookie
                        </ToggleButton>
                        <ToggleButton
                            onClick={() => {
                                setItemType(ItemType.Ingredient);
                                if (state) {
                                    setState({
                                        ...state,
                                        data: { ...state.data, item: '' },
                                    });
                                }
                            }}
                            value={ItemType.Ingredient}
                        >
                            Ingredient
                        </ToggleButton>
                    </ToggleButtonGroup>
                </FormRow>
                <FormRow>
                    <ItemField
                        types={[itemType]}
                        label="Item"
                        fullWidth
                        multiple={false}
                        value={state ? state.data.item : ''}
                        onChange={(val) => {
                            if (state)
                                setState({
                                    ...state,
                                    data: { ...state.data, item: val },
                                });
                        }}
                    />
                </FormRow>
                <Box
                    sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}
                >
                    {state && 'id' in state && (
                        <CarefullButton
                            loading={createLoading || updateLoading}
                            onClick={() => {
                                handleUpdate({
                                    variables:
                                        state && 'id' in state
                                            ? {
                                                  ...state,
                                                  data: {
                                                      ...state.data,
                                                      deleted: true,
                                                  },
                                              }
                                            : undefined,
                                    onCompleted: (data) =>
                                        setResult({ success: true, data }),
                                    onError: (error) =>
                                        setResult({ success: false, error }),
                                    refetchQueries: [RecipeQuery, RecipesQuery],
                                });
                            }}
                        >
                            Delete
                        </CarefullButton>
                    )}
                    <Tooltip arrow title={holdup || ''}>
                        <Box sx={{ display: 'inline-block' }}>
                            <LoadingButton
                                variant="contained"
                                loading={createLoading || updateLoading}
                                onClick={submit}
                                endIcon={<MdCheck />}
                                disabled={Boolean(holdup)}
                            >
                                Save
                            </LoadingButton>
                        </Box>
                    </Tooltip>
                </Box>
            </Box>
        </ResponsiveDialog>
    );
};

export default RecipeForm;
