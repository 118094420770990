import { gql } from '@apollo/client';
import { Base, TinyBase } from '../Base/Base';

export interface Contact extends Base {
    name: string;
    email: string;
    associations: { _id: string; name: string }[];
    phone?: string;
    send_cc: boolean;
    send_to: boolean;
}

export interface TinyContact extends TinyBase {
    name: string;
    email: string;
    associations: { _id: string; name: string }[];
    phone?: string;
    send_cc: boolean;
    send_to: boolean;
}

export const ContactFragment = gql`
    fragment ContactFragment on Contact {
        ...BaseFragment
        name
        email
        associations {
            _id
            name
        }
        phone
        send_cc
        send_to
    }
`;

export const TinyContactFragment = gql`
    fragment TinyContactFragment on Contact {
        _id
        name
        email
        associations {
            _id
            name
        }
        phone
        send_cc
        send_to
    }
`;
