import { gql } from '@apollo/client';
import { BaseFragment } from '../../Base/Base';
import { getAtomicQueryHook } from '../../types';
import { Unit, UnitFragment } from '../Unit';

export const UnitQuery = gql`
    ${BaseFragment}
    ${UnitFragment}
    query UnitQuery($id: ObjectId!) {
        unit(id: $id) {
            ...UnitFragment
        }
    }
`;

export const useUnit = getAtomicQueryHook<{ unit: Unit }>(UnitQuery);
