import { useTheme } from '@mui/material/';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { palette } from '@mui/system';
import React, { ReactElement } from 'react';
import { MdAssignment, MdChevronLeft } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import AppNav from '../../../../components/Layout/AppNav/components';
import NavContent from '../../../../components/Layout/AppNav/components/NavContent';
import NavHeader from '../../../../components/Layout/NavHeader';
import TabFade from '../../../../components/Layout/TabFade';
import { useRecipe } from '../../../../graphql/Recipe/operations/useRecipe';
import { RecipeVersion } from '../../../../graphql/RecipeVersion/RecipeVersion';
import RecipePreview from './components/RecipePreview';
import VersionExplorer from './components/VersionExplorer';

const RecipeDetail = (): ReactElement => {
    const { id } = useParams();
    const nav = useNavigate();
    const { palette } = useTheme();

    const { data, error, loading } = useRecipe({
        variables: {
            id: id || '',
        },
        fetchPolicy: 'network-only',
    });

    const recipe = data ? data.recipe : null;

    return (
        <AppNav error={error} loading={loading}>
            {recipe && (
                <NavContent padding={{ header: 4, content: 4, footer: 4 }}>
                    {{
                        header: (
                            <NavHeader>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexFlow: 'column',
                                        alignItems: 'flex-start',
                                    }}
                                >
                                    <Button
                                        variant="text"
                                        color="inherit"
                                        startIcon={<MdChevronLeft />}
                                        onClick={() =>
                                            nav(
                                                `/recipes/${
                                                    recipe.folder.name == 'Home'
                                                        ? ''
                                                        : recipe.folder._id
                                                }`
                                            )
                                        }
                                    >
                                        {recipe.folder.name}
                                    </Button>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: 2,
                                            alignItems: 'center',
                                        }}
                                    >
                                        <MdAssignment
                                            style={{
                                                fontSize: '4rem',
                                                color: palette.primary.main,
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexFlow: 'column',
                                            }}
                                        >
                                            <Typography variant="crisp">
                                                {recipe.name}
                                            </Typography>
                                            <Typography color="text.secondary">
                                                {recipe.item.name}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box></Box>
                            </NavHeader>
                        ),
                        content: (
                            <TabFade>
                                {{
                                    Current: (
                                        <RecipePreview>{recipe}</RecipePreview>
                                    ),
                                    ['Version History']: (
                                        <VersionExplorer recipe={recipe} />
                                    ),
                                }}
                            </TabFade>
                        ),
                    }}
                </NavContent>
            )}
        </AppNav>
    );
};

export default RecipeDetail;
