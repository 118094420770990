import { useAuth0 } from '@auth0/auth0-react';
import { format } from 'date-fns';
import mailtoLink from 'mailto-link';
import { TinyCompany } from '../../../../graphql/Company/Company';
import { useTinyCompanies } from '../../../../graphql/Company/operations/useCompanies';
import { useOurCompany } from '../../../../graphql/Company/operations/useOurCompany';
import { useTinyContacts } from '../../../../graphql/Contact/operations/useContacts';
import { useTinyItems } from '../../../../graphql/Item/operations/useTinyItems';
import { useTinyLocations } from '../../../../graphql/Location/operations/useTinyLocations';
import { OrderQueueContentInput } from '../../../../graphql/OrderQueue/inputs/OrderQueueContentInput';
import { OrderQueueLineInput } from '../../../../graphql/OrderQueue/inputs/OrderQueueLineInput';
import { OrderQueueLine } from '../../../../graphql/OrderQueue/OrderQueueLine';
import { useTinyUnits } from '../../../../graphql/Unit/operations/useUnits';
import { dateFormats } from '../../../../utils/dateFormats';
import { getTime } from '../../../../utils/getTime';

const template = (
    to: string[],
    cc: string[],
    bcc: string[],
    subject: string,
    body: string
) => {
    if (!body) throw new Error('Please provide a body!');
    if (!subject) throw new Error('Please provide a subject!');

    return mailtoLink({ to, cc, bcc, subject, body });
};

export interface VendorDraft {
    company: TinyCompany;
    link: string;
    body: string;
    holdup: string | null;
}

const filter = { skip: 0, take: 500 };

const br = '\n';

export const useEmails = (
    data: OrderQueueLine[]
): {
    loading: boolean;
    error?: Error;
    drafts: VendorDraft[];
} => {
    const {
        data: ourData,
        error: ourError,
        loading: ourLoading,
    } = useOurCompany();

    const {
        data: itemData,
        loading: itemLoading,
        error: itemError,
    } = useTinyItems({
        variables: { filter },
    });

    const {
        data: unitData,
        loading: unitLoading,
        error: unitError,
    } = useTinyUnits({
        variables: { filter },
    });

    const {
        data: companyData,
        loading: companyLoading,
        error: companyError,
    } = useTinyCompanies({
        variables: { filter },
    });

    const {
        data: locationData,
        loading: locationLoading,
        error: locationError,
    } = useTinyLocations({
        variables: { filter },
    });

    const {
        data: contactData,
        loading: contactLoading,
        error: contactError,
    } = useTinyContacts({
        variables: { filter },
    });

    const { user } = useAuth0();

    const items = itemData ? itemData.items.items : [];
    const units = unitData ? unitData.units.items : [];
    const companies = companyData ? companyData.companies.items : [];
    const locations = locationData ? locationData.locations.items : [];
    const contacts = contactData ? contactData.contacts.items : [];

    const getTextForContent = (
        content: OrderQueueContentInput
    ): { holdup: string } | { content_line: string } => {
        const item = items.find((i) => i._id == content.item);
        const unit = units.find((i) => i._id == content.unit);

        if (!item || !unit || content.quantity == null)
            return { holdup: 'Line needs contents.' };

        return {
            content_line: `${item.name} - ${content.quantity} ${
                unit[content.quantity == 1 ? 'name' : 'plural']
            }${br}`,
        };
    };

    const getTextForLine = (
        line: OrderQueueLineInput
    ): { holdup: string } | { line: string; po: string } => {
        const vendor = companies.find((c) => c._id == line.vendor);
        const customerLocation = locations.find(
            (c) => c._id == line.customer_location
        );

        const contentLines = line.contents.map((c) => getTextForContent(c));

        if (contentLines.some((d) => 'holdup' in d))
            return { holdup: 'Contents need attention.' };

        if (!line.po) return { holdup: 'Line needs a PO number' };
        if (!vendor) return { holdup: 'Line needs a Vendor' };
        if (!customerLocation)
            return { holdup: 'Line needs a Customer Location' };
        if (!line.date) return { holdup: 'Line needs a date' };

        return {
            line: `${(contentLines as { content_line: string }[])
                .map((line) => line.content_line)
                .join('')}Delivered to ${customerLocation.label} on ${format(
                new Date(line.date),
                dateFormats.condensedDate
            )}${line.time ? ` by ${getTime(line.time)}` : ''}${br}PO# ${
                line.po
            }${br}${br}`,
            po: line.po,
        };
    };

    const getDraftForVendor = (vendor_id: string): VendorDraft | null => {
        const vendor = companies.find((c) => c._id == vendor_id);

        if (!vendor) return null;

        const lines = data.filter((d) => d.vendor == vendor_id);

        const poData = lines.map((l) => getTextForLine(l));

        if (poData.some((po) => 'holdup' in po))
            return {
                company: vendor,
                link: '',
                body: '',
                holdup:
                    (poData.find((p) => 'holdup' in p) as { holdup: string })
                        .holdup || 'PO not ready',
            };

        if (poData.length == 0)
            return {
                holdup: "No PO's for this vendor.",
                company: vendor,
                link: '',
                body: '',
            };

        const poRequests = poData as { line: string; po: string }[];

        const subject =
            poRequests.length !== 1
                ? 'PO Little Dutch Boy Bakeries'
                : `Little Dutch Boy PO# ${poRequests[0].po}`;

        let body =
            'Hello,' +
            `${br}${br}${
                poRequests.length == 1
                    ? 'We need - '
                    : `Please process these ${poRequests.length} POs for Little Dutch Boy - `
            }${br}${br}`;

        for (const request of poRequests) {
            body += `${request.line}`;
        }

        body += `Please confirm.${br}Thanks,${br}${br}${
            user
                ? user.given_name && user.family_name
                    ? `${user.given_name} ${user.family_name}`
                    : user.name
                : 'Little Dutch Boy Procurement'
        }`;

        const vendorContacts = contacts.filter((c) =>
            c.associations.map((a) => a._id).includes(vendor._id)
        );

        const ccContacts = vendorContacts.filter((c) => c.send_cc && c.email);
        const toContacts = vendorContacts.filter((c) => c.send_to && c.email);

        return {
            company: vendor,
            link: mailtoLink({
                body,
                subject,
                to: toContacts.map((c) => c.email),
                cc: ccContacts.map((c) => c.email),
            }),
            body: body,
            holdup: null,
        };
    };

    const ourCompany = ourData ? ourData.ourCompany : null;

    const relevantLines = ourCompany
        ? data.filter((d) => d.customer == ourCompany._id)
        : [];
    const vendors = [
        ...new Set(
            relevantLines
                .map((r) => r.vendor)
                .filter((r) => r !== null) as string[]
        ),
    ];

    const drafts = vendors.map((c) => getDraftForVendor(c));

    return {
        loading:
            itemLoading ||
            unitLoading ||
            companyLoading ||
            locationLoading ||
            ourLoading,
        error:
            itemError || unitError || companyError || locationError || ourError,
        drafts: drafts.filter((d) => d !== null) as VendorDraft[],
    };
};
