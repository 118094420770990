import { gql } from '@apollo/client';
import { Base, TinyBase } from '../Base/Base';
import { TinyContact } from '../Contact/Contact';
import { CompanyLocation } from '../Location/Location';

export interface Company extends Base {
    name: string;
    contacts: TinyContact[];
    locations: CompanyLocation[];
}

export const CompanyFragment = gql`
    fragment CompanyFragment on Company {
        ...BaseFragment
        name
        internal
        contacts {
            ...TinyContactFragment
        }
        locations {
            ...CompanyLocationFragment
        }
    }
`;

export const TinyCompanyFragment = gql`
    fragment TinyCompanyFragment on Company {
        _id
        name
        internal
        contacts {
            ...TinyContactFragment
        }
        locations {
            ...CompanyLocationFragment
        }
    }
`;

export interface TinyCompany extends TinyBase {
    name: string;
}
