import { gql } from '@apollo/client';
import { Pagination } from '../../../utils/types/Pagination';
import { BaseFragment } from '../../Base/Base';
import { getQueryHook } from '../../types';
import { Unit, UnitFragment, TinyUnit, TinyUnitFragment } from '../Unit';
import { UnitFilter } from '../UnitFilter';

export const UnitsQuery = gql`
    ${BaseFragment}
    ${UnitFragment}
    query UnitsQuery($filter: UnitFilter!) {
        units(filter: $filter) {
            count
            items {
                ...UnitFragment
            }
        }
    }
`;

export const TinyUnitsQuery = gql`
    ${TinyUnitFragment}
    query TinyUnitsQuery($filter: UnitFilter!) {
        units(filter: $filter) {
            count
            items {
                ...TinyUnitFragment
            }
        }
    }
`;

export const useUnits = getQueryHook<
    { units: Pagination<Unit> },
    { filter: UnitFilter }
>(UnitsQuery);

export const useTinyUnits = getQueryHook<
    { units: Pagination<TinyUnit> },
    { filter: UnitFilter }
>(TinyUnitsQuery);
