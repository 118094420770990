import { MenuItem, TextField, TextFieldProps } from '@mui/material';
import React, { ReactElement } from 'react';
import { ItemType } from '../../../../graphql/Item/Item';
import { ItemLanguage } from '../ItemPopover';

export type ItemTypeInputProps = Omit<
    TextFieldProps,
    'value' | 'onChange' | 'children' | 'select'
> & {
    value: ItemType;
    onChange: (item_type: ItemType) => void;
};

const ItemTypeInput = (props: ItemTypeInputProps): ReactElement => {
    const { value, onChange, ...rest } = props;

    return (
        <TextField
            {...rest}
            value={value}
            select
            onChange={(e) => {
                onChange(e.target.value as ItemType);
            }}
        >
            {Object.keys(ItemType).map((uType) => (
                <MenuItem key={'type_' + uType} value={uType}>
                    {ItemLanguage[uType as ItemType]}
                </MenuItem>
            ))}
        </TextField>
    );
};

export default ItemTypeInput;
