import { gql } from '@apollo/client';
import { AddressFragment } from '../../Address/Address';
import { AppointmentFragment } from '../../Appointment/Appointment';
import { BaseFragment } from '../../Base/Base';
import { BolFragment } from '../../Bol/Bol';
import { TinyCompanyFragment } from '../../Company/Company';
import { TinyContactFragment } from '../../Contact/Contact';
import { ItemContentFragment } from '../../Content/ItemContent/ItemContent';
import { LotContentFragment } from '../../Content/LotContent/LotContent';
import { FormResponseFragment } from '../../FormResponse/FormResponse';
import { FulfillmentLineFragment } from '../../FulfillmentLine/FulfillmentLine';
import { TinyItemFragment } from '../../Item/Item';
import { ItineraryFragment } from '../../Itinerary/Itinerary';
import { ItineraryScheduleFragment } from '../../Itinerary/ItinerarySchedule';
import {
    CompanyLocationFragment,
    TinyLocationFragment,
} from '../../Location/Location';
import { TinyLotFragment } from '../../Lot/Lot';
import { Order, OrderFragment } from '../../Order/Order';
import { ShipmentFragment } from '../../Shipment/Shipment';
import { getMutationHook } from '../../types';
import { TinyUnitFragment } from '../../Unit/Unit';
import { VerificationFragment } from '../../Verification/Verification';
import { UpdateReceiptInput } from '../inputs/UpdateReceiptInput';
import { ReceiptFragment } from '../Receipt';

export const UpdateReceipt = gql`
    ${BaseFragment}
    ${OrderFragment}
    ${TinyCompanyFragment}
    ${TinyContactFragment}
    ${CompanyLocationFragment}
    ${ItineraryFragment}
    ${BolFragment}
    ${AppointmentFragment}
    ${ItemContentFragment}
    ${ReceiptFragment}
    ${ShipmentFragment}
    ${TinyLocationFragment}
    ${TinyItemFragment}
    ${TinyUnitFragment}
    ${FulfillmentLineFragment}
    ${FormResponseFragment}
    ${LotContentFragment}
    ${TinyLotFragment}
    ${AddressFragment}
    ${ItineraryScheduleFragment}
    ${VerificationFragment}
    mutation UpdateReceipt($data: UpdateReceiptInput!, $id: ObjectId!) {
        updateReceipt(data: $data, id: $id) {
            ...OrderFragment
        }
    }
`;

export interface UpdateReceiptArgs {
    data: UpdateReceiptInput;
    id: string;
}

export interface UpdateReceiptRes {
    updateReceipt: Order;
}

export const useReceiptUpdate = getMutationHook<
    UpdateReceiptRes,
    UpdateReceiptArgs
>(UpdateReceipt);
