import { gql } from '@apollo/client';
import { getMutationHook } from '../../types';
import { RecipeVersion, RecipeVersionFragment } from '../RecipeVersion';
import { CreateRecipeVersionInput } from '../inputs/CreateRecipeVersionInput';
import { RecipeSectionFragment } from '../../RecipeSection/RecipeSection';
import { RecipeStepFragment } from '../../RecipeStep/RecipeStep';
import { ItemPluralContentFragment } from '../../Content/ItemPluralContent/ItemPluralContent';
import { TinyItemFragment } from '../../Item/Item';
import { TinyUnitFragment } from '../../Unit/Unit';

export const CreateRecipeVersion = gql`
    ${RecipeVersionFragment}
    ${RecipeSectionFragment}
    ${RecipeStepFragment}
    ${ItemPluralContentFragment}
    ${TinyItemFragment}
    ${TinyUnitFragment}
    mutation CreateRecipeVersion($data: CreateRecipeVersionInput!) {
        createRecipeVersion(data: $data) {
            ...RecipeVersionFragment
        }
    }
`;

export interface CreateRecipeVersionRes {
    createRecipeVersion: RecipeVersion;
}

export interface CreateRecipeVersionArgs {
    data: CreateRecipeVersionInput;
}

export const useRecipeVersionCreation = getMutationHook<
    CreateRecipeVersionRes,
    CreateRecipeVersionArgs
>(CreateRecipeVersion);
