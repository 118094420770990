import { gql } from '@apollo/client';
import { BaseFragment } from '../../../../Base/Base';
import { getAtomicQueryHook } from '../../../../types';
import { Package, PackageFragment } from '../Package';

export const PackageQuery = gql`
    ${BaseFragment}
    ${PackageFragment}
    query PackageQuery($id: ObjectId!) {
        package(id: $id) {
            ...PackageFragment
        }
    }
`;

export const usePackage = getAtomicQueryHook<{ package: Package }>(
    PackageQuery
);
