import { gql } from '@apollo/client';
import { RecipeFragment } from '../../Recipe/Recipe';
import { getMutationHook } from '../../types';
import { MixingCard, MixingCardFragment } from '../MixingCard';
import { CreateMixingCardInput } from '../inputs/CreateMixingCardInput';
import { AddressFragment } from '../../Address/Address';
import { TinyCompanyFragment } from '../../Company/Company';
import { TinyContactFragment } from '../../Contact/Contact';
import {
    TinyLocationFragment,
    CompanyLocationFragment,
} from '../../Location/Location';
import { MixingCardLineFragment } from '../../MixingCardLine/MixingCardLine';

export const CreateMixingCard = gql`
    ${MixingCardFragment}
    ${RecipeFragment}
    ${MixingCardLineFragment}
    ${RecipeFragment}
    ${TinyLocationFragment}
    ${TinyCompanyFragment}
    ${AddressFragment}
    ${TinyContactFragment}
    ${CompanyLocationFragment}
    mutation CreateMixingCard($data: CreateMixingCardInput!) {
        createMixingCard(data: $data) {
            ...MixingCardFragment
        }
    }
`;

export interface CreateMixingCardRes {
    createMixingCard: MixingCard;
}

export interface CreateMixingCardArgs {
    data: CreateMixingCardInput;
}

export const useMixingCardCreation = getMutationHook<
    CreateMixingCardRes,
    CreateMixingCardArgs
>(CreateMixingCard);
