import { gql } from '@apollo/client';
import { BaseFragment } from '../../Base/Base';
import { getMutationHook } from '../../types';
import { Order, OrderFragment } from '../Order';
import { UpdateOrderInput } from '../inputs/UpdateOrderInput';
import { AddressFragment } from '../../Address/Address';
import { AppointmentFragment } from '../../Appointment/Appointment';
import { BolFragment } from '../../Bol/Bol';
import { TinyCompanyFragment } from '../../Company/Company';
import { TinyContactFragment } from '../../Contact/Contact';
import { ItemContentFragment } from '../../Content/ItemContent/ItemContent';
import { LotContentFragment } from '../../Content/LotContent/LotContent';
import { FormResponseFragment } from '../../FormResponse/FormResponse';
import { FulfillmentLineFragment } from '../../FulfillmentLine/FulfillmentLine';
import { TinyItemFragment } from '../../Item/Item';
import { ItineraryFragment } from '../../Itinerary/Itinerary';
import { ItineraryScheduleFragment } from '../../Itinerary/ItinerarySchedule';
import {
    CompanyLocationFragment,
    TinyLocationFragment,
} from '../../Location/Location';
import { TinyLotFragment } from '../../Lot/Lot';
import { ReceiptFragment } from '../../Receipt/Receipt';
import { ShipmentFragment } from '../../Shipment/Shipment';
import { TinyUnitFragment } from '../../Unit/Unit';
import { VerificationFragment } from '../../Verification/Verification';

export const UpdateOrderMutation = gql`
    ${BaseFragment}
    ${OrderFragment}
    ${TinyCompanyFragment}
    ${TinyContactFragment}
    ${CompanyLocationFragment}
    ${ItineraryFragment}
    ${BolFragment}
    ${AppointmentFragment}
    ${ItemContentFragment}
    ${ReceiptFragment}
    ${ShipmentFragment}
    ${TinyLocationFragment}
    ${TinyItemFragment}
    ${TinyUnitFragment}
    ${FulfillmentLineFragment}
    ${FormResponseFragment}
    ${LotContentFragment}
    ${TinyLotFragment}
    ${AddressFragment}
    ${ItineraryScheduleFragment}
    ${VerificationFragment}
    mutation UpdateOrderMutation($id: ObjectId!, $data: UpdateOrderInput!) {
        updateOrder(id: $id, data: $data) {
            ...OrderFragment
        }
    }
`;

export interface UpdateOrderArgs {
    id: string;
    data: UpdateOrderInput;
}

export interface UpdateOrderRes {
    updateOrder: Order;
}

export const useOrderUpdate = getMutationHook<UpdateOrderRes, UpdateOrderArgs>(
    UpdateOrderMutation
);
