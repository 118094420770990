import { gql } from '@apollo/client';
import { AddressFragment } from '../../Address/Address';
import { BaseFragment } from '../../Base/Base';
import { TinyCompanyFragment } from '../../Company/Company';
import { TinyContactFragment } from '../../Contact/Contact';
import { getAtomicQueryHook } from '../../types';
import {
    CompanyLocationFragment,
    Location,
    LocationFragment,
} from '../Location';

export const LocationQuery = gql`
    ${LocationFragment}
    ${BaseFragment}
    ${AddressFragment}
    ${TinyContactFragment}
    ${CompanyLocationFragment}
    ${TinyCompanyFragment}
    query LocationQuery($id: ObjectId!) {
        location(id: $id) {
            ...LocationFragment
        }
    }
`;

export interface LocationRes {
    location: Location;
}

export const useLocation = getAtomicQueryHook<LocationRes>(LocationQuery);
