import { gql } from '@apollo/client';
import { Pagination } from '../../../../../utils/types/Pagination';
import { BaseFragment } from '../../../../Base/Base';
import { getQueryHook } from '../../../../types';
import {
    MiscItem,
    MiscItemFragment,
    TinyMiscItem,
    TinyMiscItemFragment,
} from '../MiscItem';
import { MiscItemFilter } from '../MiscItemFilter';

export const MiscItemsQuery = gql`
    ${BaseFragment}
    ${MiscItemFragment}
    query MiscItemsQuery($filter: MiscItemFilter!) {
        miscitems(filter: $filter) {
            count
            items {
                ...MiscItemFragment
            }
        }
    }
`;

export const TinyMiscItemsQuery = gql`
    ${TinyMiscItemFragment}
    query TinyMiscItemsQuery($filter: MiscItemFilter!) {
        miscitems(filter: $filter) {
            count
            items {
                ...TinyMiscItemFragment
            }
        }
    }
`;

export const useMiscItems = getQueryHook<
    { miscitems: Pagination<MiscItem> },
    { filter: MiscItemFilter }
>(MiscItemsQuery);

export const useTinyMiscItems = getQueryHook<
    { miscitems: Pagination<TinyMiscItem> },
    { filter: MiscItemFilter }
>(TinyMiscItemsQuery);
