import { gql } from '@apollo/client';
import { RecipeFragment } from '../../Recipe/Recipe';
import { getMutationHook } from '../../types';
import { Folder, FolderFragment } from '../Folder';
import { CreateFolderInput } from '../inputs/CreateFolderInput';

export const CreateFolder = gql`
    ${FolderFragment}
    ${RecipeFragment}
    mutation CreateFolder($data: CreateFolderInput!) {
        createFolder(data: $data) {
            ...FolderFragment
        }
    }
`;

export interface CreateFolderRes {
    createFolder: Folder;
}

export interface CreateFolderArgs {
    data: CreateFolderInput;
}

export const useFolderCreation = getMutationHook<
    CreateFolderRes,
    CreateFolderArgs
>(CreateFolder);
