import { gql } from '@apollo/client';
import { Appointment } from '../Appointment/Appointment';
import { Base } from '../Base/Base';
import { ItemContent } from '../Content/ItemContent/ItemContent';
import { Order } from '../Order/Order';
import { Receipt } from '../Receipt/Receipt';
import { Shipment } from '../Shipment/Shipment';

export interface Bol extends Base {
    code: string | null;
    on_behalf: { _id: string; code: Order['code'] } | null;
    from: Appointment;
    to: Appointment;
    contents: ItemContent[];
    receipts: Receipt[];
    shipments: Shipment[];
}

export const BolFragment = gql`
    fragment BolFragment on Bol {
        ...BaseFragment
        code
        on_behalf {
            _id
            code
        }
        from {
            ...AppointmentFragment
        }
        to {
            ...AppointmentFragment
        }
        contents {
            ...ItemContentFragment
        }
        receipts {
            ...ReceiptFragment
        }
        shipments {
            ...ShipmentFragment
        }
    }
`;
