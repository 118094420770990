import { gql } from '@apollo/client';
import { Item, TinyItem } from '../../Item';

export type Cookie = Item;
export type TinyCookie = TinyItem;

export const CookieFragment = gql`
    fragment CookieFragment on Cookie {
        ...BaseFragment
        name
        spanish_name
        unit_class
        conversions {
            to
            multiplier
        }
    }
`;

export const TinyCookieFragment = gql`
    fragment TinyCookieFragment on Cookie {
        _id
        name
        spanish_name
        unit_class
        conversions {
            to
            multiplier
        }
    }
`;
