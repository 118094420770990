import { gql } from '@apollo/client';
import { Pagination } from '../../../utils/types/Pagination';
import { getQueryHook } from '../../types';
import { TinyItem, TinyItemFragment } from '../Item';
import { ItemFilter } from '../ItemFilter';

export const TinyItemsQuery = gql`
    ${TinyItemFragment}
    query TinyItemsQuery($filter: ItemFilter!) {
        items(filter: $filter) {
            count
            items {
                ...TinyItemFragment
            }
        }
    }
`;

export const useTinyItems = getQueryHook<
    { items: Pagination<TinyItem> },
    { filter: ItemFilter }
>(TinyItemsQuery);
