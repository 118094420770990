import { Box, Button, IconButton, TextField, useTheme } from '@mui/material';
import React, { ReactElement } from 'react';
import { MdClear } from 'react-icons/md';
import ItemPopover from '../../../../../../../../../../../../../../../../components/inputs/FieldInputs/ItemPopover';
import UnitField from '../../../../../../../../../../../../../../../../components/inputs/FieldInputs/UnitField';
import { ItemContentInput } from '../../../../../../../../../../../../../../../../graphql/Content/ItemContent/ItemContentInput';
import { useTinyItems } from '../../../../../../../../../../../../../../../../graphql/Item/operations/useTinyItems';
import { useClickState } from '../../../../../../../../../../../../../../../../hooks/useClickState';

const ContentForm = (props: {
    value: ItemContentInput;
    onChange: (value: ItemContentInput | null) => void;
}): ReactElement => {
    const { value, onChange } = props;

    const { palette } = useTheme();

    const { data } = useTinyItems({
        variables: { filter: { skip: 0, take: 500 } },
    });

    const items = data ? data.items.items : [];

    const [itemFocus, setItemFocus] = useClickState();

    const thisItem = items.find((it) => it._id === value.item) || null;

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottom: `1px solid ${palette.divider}`,
                p: 1,
            }}
        >
            <Box>
                <IconButton size="small" onClick={() => onChange(null)}>
                    <MdClear />
                </IconButton>
                <Button
                    onClick={(e) => setItemFocus({ element: e.currentTarget })}
                    variant="text"
                    color="inherit"
                    sx={{
                        fontWeight: 400,
                        color: thisItem ? undefined : palette.text.disabled,
                    }}
                >
                    {thisItem ? thisItem.name : 'Select an item'}
                </Button>
            </Box>
            <Box sx={{ display: 'flex', gap: 2 }}>
                <TextField
                    value={value.quantity || ''}
                    onChange={(e) => {
                        const quantity = parseFloat(e.target.value);
                        if (!isNaN(quantity)) {
                            onChange({ ...value, quantity });
                        } else {
                            onChange({ ...value, quantity: null });
                        }
                    }}
                    type="number"
                    placeholder="Qty"
                    variant="standard"
                    InputProps={{
                        disableUnderline: true,
                        sx: { maxWidth: 72 },
                    }}
                />
                <UnitField
                    classes={thisItem ? [thisItem.unit_class] : undefined}
                    item_types={thisItem ? [thisItem.type] : []}
                    multiple={false}
                    value={value.unit || ''}
                    onChange={(unit) => onChange({ ...value, unit })}
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                />
            </Box>
            <ItemPopover
                target={itemFocus ? itemFocus.element : null}
                value={value.item || ''}
                onChange={(item) => {
                    onChange({ ...value, item: item || '', unit: '' });
                }}
                onClose={() => setItemFocus(null)}
            />
        </Box>
    );
};

export default ContentForm;
