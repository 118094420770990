import { gql } from '@apollo/client';
import { BaseFragment } from '../../../../Base/Base';
import { getMutationHook } from '../../../../types';
import { Ingredient, IngredientFragment } from '../Ingredient';
import { CreateIngredientInput } from '../inputs/CreateIngredientInput';

export const CreateIngredientMutation = gql`
    ${BaseFragment}
    ${IngredientFragment}
    mutation CreateIngredientMutation($data: CreateIngredientInput!) {
        createIngredient(data: $data) {
            ...IngredientFragment
        }
    }
`;

export interface CreateIngredientArgs {
    data: CreateIngredientInput;
}

export interface CreateIngredientRes {
    createIngredient: Ingredient;
}

export const useIngredientCreation = getMutationHook<
    CreateIngredientRes,
    CreateIngredientArgs
>(CreateIngredientMutation);
