import { Box, Tooltip, useTheme } from '@mui/material/';
import React, { ReactElement, ReactNode } from 'react';
import {
    Verification,
    VerificationStatus,
} from '../../../graphql/Verification/Verification';
import { RiShieldCheckFill, RiErrorWarningFill } from 'react-icons/ri';
import { BsFlagFill } from 'react-icons/bs';
import { MdCheckCircleOutline } from 'react-icons/md';

export interface VerificationBadgeProps<
    T extends { verification: Verification | null }
> {
    children: T;
    fontSize?: string;
    override?: ReactNode;
}

const VerificationBadge = <T extends { verification: Verification | null }>(
    props: VerificationBadgeProps<T>
): ReactElement | null => {
    const {
        children: { verification },
        fontSize = '2.5rem',
    } = props;

    const { palette } = useTheme();

    if (!verification)
        return (
            <Tooltip arrow title="Unverified">
                <Box sx={{ display: 'flex' }}>
                    {props.override || (
                        <MdCheckCircleOutline
                            style={{ fontSize, color: palette.success.main }}
                        />
                    )}
                </Box>
            </Tooltip>
        );

    const previewData: Record<VerificationStatus, [ReactNode, string]> = {
        [VerificationStatus.Approved]: [
            props.override || (
                <RiShieldCheckFill
                    key={'icon_Verified'}
                    style={{ fontSize, color: palette.success.main }}
                />
            ),
            'Verified',
        ],
        [VerificationStatus.Flagged]: [
            props.override || (
                <BsFlagFill
                    key={'icon_Flagged'}
                    style={{ fontSize, color: palette.primary.main }}
                />
            ),
            'Flagged',
        ],
        [VerificationStatus.Warning]: [
            props.override || (
                <RiErrorWarningFill
                    key={'icon_Warning'}
                    style={{ fontSize, color: palette.warning.main }}
                />
            ),
            'Warning',
        ],
    };

    return (
        <Tooltip
            arrow
            title={`${previewData[verification.status][1]} by ${
                verification.verified_by.name
            }`}
        >
            <Box sx={{ display: 'flex' }}>
                {props.override || previewData[verification.status][0]}
            </Box>
        </Tooltip>
    );
};

export default VerificationBadge;
