import { Box, TextField, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { MdCheck, MdDelete, MdRefresh } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import FormResult from '../../../../../../components/feedback/FormResult';
import CarefullButton from '../../../../../../components/inputs/Buttons/CarefulButton';
import SuccessButton from '../../../../../../components/inputs/Buttons/SuccessButton';
import AppNav from '../../../../../../components/Layout/AppNav/components';
import NavContent from '../../../../../../components/Layout/AppNav/components/NavContent';
import FormRow from '../../../../../../components/Layout/FormRow';
import NavHeader from '../../../../../../components/Layout/NavHeader';
import { usePackage } from '../../../../../../graphql/Item/extensions/Package/operations/usePackage';
import {
    CreatePackageArgs,
    CreatePackageRes,
    usePackageCreation,
} from '../../../../../../graphql/Item/extensions/Package/operations/usePackageCreation';
import {
    UpdatePackageArgs,
    UpdatePackageRes,
    usePackageUpdate,
} from '../../../../../../graphql/Item/extensions/Package/operations/usePackageUpdate';
import { OperationResult } from '../../../../../../utils/types/OperationResult';

const PackageForm = (): ReactElement => {
    const { id } = useParams();
    const nav = useNavigate();

    const [state, setState] = React.useState<
        UpdatePackageArgs | CreatePackageArgs
    >({
        data: {
            name: '',
            spanish_name: '',
            conversions: [],
        },
    });

    const { data } = usePackage({
        variables: { id: id || '' },
        skip: !id,
        fetchPolicy: 'network-only',
        onCompleted: ({ package: packageItem }) => {
            setState({
                id: packageItem._id,
                data: {
                    name: packageItem.name,
                    spanish_name: packageItem.spanish_name,
                    conversions: packageItem.conversions.map(
                        ({ to, multiplier }) => ({ to, multiplier })
                    ),
                },
            });
        },
    });

    const [result, setResult] = React.useState<null | OperationResult<
        CreatePackageRes | UpdatePackageRes
    >>(null);

    const [handleCreate, { loading: createLoading }] = usePackageCreation({
        variables: 'id' in state ? undefined : state,
        onCompleted: (data) => setResult({ success: true, data }),
        onError: (error) => setResult({ success: false, error }),
    });

    const [handleUpdate, { loading: updateLoading }] = usePackageUpdate({
        variables:
            'id' in state ? { ...state, id: id || state.id || '' } : undefined,
        onCompleted: (data) => setResult({ success: true, data }),
        onError: (error) => setResult({ success: false, error }),
    });

    const getHoldup = (): string | null => {
        if (!state.data.name) return 'Please enter a package name.';
        if (!state.data.spanish_name) return 'Please enter a spanish name.';
        return null;
    };

    return (
        <AppNav loading={updateLoading || createLoading}>
            {result ? (
                <FormResult
                    entity="Packaging Item"
                    clear={() => setResult(null)}
                    onComplete={() => nav('/library/packaging')}
                >
                    {result}
                </FormResult>
            ) : (
                <NavContent>
                    {{
                        header: (
                            <NavHeader
                                back={
                                    data
                                        ? [
                                              data.package.name,
                                              `/library/packaging/${data.package._id}`,
                                          ]
                                        : ['Packaging', '/library/packaging']
                                }
                            >
                                <Box>
                                    <Typography variant="crisp">
                                        {id && !data
                                            ? ''
                                            : data
                                            ? `Update ${data.package.name}`
                                            : 'New Packaging Item'}
                                    </Typography>
                                    <Typography
                                        sx={{ paddingTop: 1 }}
                                        color="textSecondary"
                                    >
                                        Packaging
                                    </Typography>
                                </Box>
                                <Box />
                            </NavHeader>
                        ),
                        content: (
                            <Box sx={{ maxWidth: 400 }}>
                                <FormRow>
                                    <TextField
                                        autoFocus
                                        fullWidth
                                        label="Name"
                                        value={state.data.name}
                                        onChange={(e) =>
                                            setState({
                                                ...state,
                                                data: {
                                                    ...state.data,
                                                    name: e.target.value,
                                                },
                                            })
                                        }
                                    />
                                </FormRow>
                                <FormRow>
                                    <TextField
                                        fullWidth
                                        label="Spanish"
                                        value={state.data.spanish_name}
                                        onChange={(e) =>
                                            setState({
                                                ...state,
                                                data: {
                                                    ...state.data,
                                                    spanish_name:
                                                        e.target.value,
                                                },
                                            })
                                        }
                                    />
                                </FormRow>
                                <FormRow>
                                    <SuccessButton
                                        holdup={getHoldup()}
                                        success={null}
                                        onSuccess={() => null}
                                        endIcon={<MdCheck />}
                                        onClick={() => {
                                            if ('id' in state) {
                                                handleUpdate();
                                            } else {
                                                handleCreate();
                                            }
                                        }}
                                    >
                                        Save
                                    </SuccessButton>
                                    {data && (
                                        <CarefullButton
                                            endIcon={
                                                data.package.deletion ? (
                                                    <MdRefresh />
                                                ) : (
                                                    <MdDelete />
                                                )
                                            }
                                            onClick={() => {
                                                handleUpdate({
                                                    variables: {
                                                        id: data.package._id,
                                                        data: {
                                                            name: data.package
                                                                .name,
                                                            spanish_name:
                                                                data.package
                                                                    .spanish_name,
                                                            conversions:
                                                                data.package.conversions.map(
                                                                    ({
                                                                        to,
                                                                        multiplier,
                                                                    }) => ({
                                                                        to,
                                                                        multiplier,
                                                                    })
                                                                ),
                                                            deleted: data
                                                                .package
                                                                .deletion
                                                                ? false
                                                                : true,
                                                        },
                                                    },
                                                    onCompleted: (data) =>
                                                        setResult({
                                                            success: true,
                                                            data,
                                                        }),
                                                    onError: (error) =>
                                                        setResult({
                                                            success: false,
                                                            error,
                                                        }),
                                                });
                                            }}
                                        >
                                            {data.package.deletion
                                                ? 'Restore Package'
                                                : 'Delete Package'}
                                        </CarefullButton>
                                    )}
                                </FormRow>
                            </Box>
                        ),
                    }}
                </NavContent>
            )}
        </AppNav>
    );
};

export default PackageForm;
