import { LoadingButton } from '@mui/lab';
import {
    Box,
    Collapse,
    FormControlLabel,
    MenuItem,
    Switch,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { MdCheck, MdDelete, MdRefresh } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { UserRole } from '../../../../../../auth/UserRole';
import FormResult from '../../../../../../components/feedback/FormResult';
import SuccessButton from '../../../../../../components/inputs/Buttons/SuccessButton';
import RoleInput from '../../../../../../components/inputs/FieldInputs/RoleInput';
import AppNav from '../../../../../../components/Layout/AppNav/components';
import NavContent from '../../../../../../components/Layout/AppNav/components/NavContent';
import FormRow from '../../../../../../components/Layout/FormRow';
import NavHeader from '../../../../../../components/Layout/NavHeader';
import {
    CreateProfileArgs,
    CreateProfileRes,
    useProfileCreation,
} from '../../../../../../graphql/People/useProfileCreation';
import {
    UpdateProfileArgs,
    UpdateProfileRes,
    useProfileUpdate,
} from '../../../../../../graphql/People/useProfileUpdate';
import { useProfile } from '../../../../../../graphql/Profile/useProfile';
import { OperationResult } from '../../../../../../utils/types/OperationResult';
import {
    checkPassword,
    PasswordPolicy,
} from '../../../../../MyAccount/checkPassword';

const ProfileForm = (): ReactElement => {
    const { id } = useParams();
    const nav = useNavigate();

    const [confirmation, setConfirmation] = React.useState('');

    const { palette } = useTheme();

    const [state, setState] = React.useState<
        UpdateProfileArgs | CreateProfileArgs
    >({
        data: {
            given_name: '',
            family_name: '',
            email: '',
            temporary_password: '',
            role: UserRole.User,
        },
    });

    const [ready, validation] = checkPassword(
        'id' in state
            ? state.data.password || ''
            : state.data.temporary_password || '',
        confirmation
    );

    const { data } = useProfile({
        variables: { id: id || '' },
        skip: !id,
        fetchPolicy: 'network-only',
        onCompleted: ({ profile }) => {
            setState({
                id: profile.user_id,
                data: profile.username
                    ? {
                          given_name: profile.given_name || '',
                          family_name: profile.family_name || '',
                          username: profile.username,
                          role: profile.roles[0],
                      }
                    : {
                          given_name: profile.given_name || '',
                          family_name: profile.family_name || '',
                          email: profile.email,
                          role: profile.roles[0],
                      },
            });
        },
    });

    const [result, setResult] = React.useState<null | OperationResult<
        CreateProfileRes | UpdateProfileRes
    >>(null);

    const [handleCreate, { loading: createLoading }] = useProfileCreation({
        variables: 'id' in state ? undefined : state,
        onCompleted: (data) => setResult({ success: true, data }),
        onError: (error) => setResult({ success: false, error }),
    });

    const [handleUpdate, { loading: updateLoading }] = useProfileUpdate({
        variables:
            'id' in state ? { ...state, id: id || state.id || '' } : undefined,
        onCompleted: (data) => setResult({ success: true, data }),
        onError: (error) => setResult({ success: false, error }),
    });

    const getHoldup = (): string | null => {
        return null;
    };

    return (
        <AppNav loading={updateLoading || createLoading}>
            {result ? (
                <FormResult
                    entity="Profile"
                    clear={() => setResult(null)}
                    onComplete={() => nav('/people/profiles')}
                >
                    {result}
                </FormResult>
            ) : (
                <NavContent>
                    {{
                        header: (
                            <NavHeader back={['Profiles', '/people/profiles']}>
                                <Typography variant="crisp">
                                    {id && !data
                                        ? ''
                                        : data
                                        ? `Update ${data.profile.name}'s Profile`
                                        : 'New Profile'}
                                </Typography>
                                <Box />
                            </NavHeader>
                        ),
                        content: (
                            <Box sx={{ maxWidth: 500 }}>
                                <FormRow>
                                    <TextField
                                        label="Given Name"
                                        fullWidth
                                        value={state.data.given_name}
                                        onChange={(e) => {
                                            if ('id' in state) {
                                                setState({
                                                    ...state,
                                                    data: {
                                                        ...state.data,
                                                        given_name:
                                                            e.target.value ||
                                                            '',
                                                    },
                                                });
                                            } else {
                                                setState({
                                                    ...state,
                                                    data: {
                                                        ...state.data,
                                                        given_name:
                                                            e.target.value ||
                                                            '',
                                                    },
                                                });
                                            }
                                        }}
                                    />
                                    <TextField
                                        label="Family Name"
                                        fullWidth
                                        value={state.data.family_name}
                                        onChange={(e) => {
                                            if ('id' in state) {
                                                setState({
                                                    ...state,
                                                    data: {
                                                        ...state.data,
                                                        family_name:
                                                            e.target.value ||
                                                            '',
                                                    },
                                                });
                                            } else {
                                                setState({
                                                    ...state,
                                                    data: {
                                                        ...state.data,
                                                        family_name:
                                                            e.target.value ||
                                                            '',
                                                    },
                                                });
                                            }
                                        }}
                                    />
                                </FormRow>
                                <FormRow>
                                    <RoleInput
                                        label="User Role"
                                        fullWidth
                                        value={state.data.role}
                                        onChange={(role) => {
                                            if ('id' in state) {
                                                setState({
                                                    ...state,
                                                    data: {
                                                        ...state.data,
                                                        role,
                                                    },
                                                });
                                            } else {
                                                setState({
                                                    ...state,
                                                    data: {
                                                        ...state.data,
                                                        role,
                                                    },
                                                });
                                            }
                                        }}
                                    />
                                    {'id' in state && (
                                        <TextField
                                            InputProps={{
                                                style: {
                                                    color: state.data.blocked
                                                        ? palette.warning.main
                                                        : palette.success.main,
                                                },
                                            }}
                                            value={
                                                state.data.blocked
                                                    ? 'Blocked'
                                                    : 'Active'
                                            }
                                            label="Account Status"
                                            select
                                            fullWidth
                                            onChange={(e) => {
                                                if (
                                                    e.target.value == 'Blocked'
                                                ) {
                                                    setState({
                                                        ...state,
                                                        data: {
                                                            ...state.data,
                                                            blocked: true,
                                                        },
                                                    });
                                                } else {
                                                    setState({
                                                        ...state,
                                                        data: {
                                                            ...state.data,
                                                            blocked: false,
                                                        },
                                                    });
                                                }
                                            }}
                                        >
                                            <MenuItem value={'Active'}>
                                                Active
                                            </MenuItem>
                                            <MenuItem value={'Blocked'}>
                                                Blocked
                                            </MenuItem>
                                        </TextField>
                                    )}
                                </FormRow>
                                <FormRow>
                                    <TextField
                                        select
                                        label="Login Method"
                                        fullWidth
                                        value={
                                            state.data.username !== null &&
                                            state.data.username !== undefined
                                                ? 'Username'
                                                : 'Email'
                                        }
                                        onChange={(e) => {
                                            if (e.target.value == 'Username') {
                                                const copy = { ...state };
                                                copy.data.username = '';
                                                copy.data.email = null;
                                                setState(copy);
                                            } else {
                                                const copy = { ...state };
                                                copy.data.username = null;
                                                copy.data.email = '';
                                                setState(copy);
                                            }
                                        }}
                                    >
                                        <MenuItem value={'Username'}>
                                            Username
                                        </MenuItem>
                                        <MenuItem value={'Email'}>
                                            Email
                                        </MenuItem>
                                    </TextField>
                                    <TextField
                                        label={
                                            state.data.username !== null &&
                                            state.data.username !== undefined
                                                ? 'Username'
                                                : 'Email'
                                        }
                                        fullWidth
                                        value={
                                            state.data.username !== null &&
                                            state.data.username !== undefined
                                                ? state.data.username
                                                : state.data.email
                                        }
                                        onChange={(e) => {
                                            if ('id' in state) {
                                                setState({
                                                    ...state,
                                                    data: {
                                                        ...state.data,
                                                        username:
                                                            state.data
                                                                .username !==
                                                                null &&
                                                            state.data
                                                                .username !==
                                                                undefined
                                                                ? e.target.value
                                                                : null,
                                                        email:
                                                            state.data.email !==
                                                                null &&
                                                            state.data.email !==
                                                                undefined
                                                                ? e.target.value
                                                                : null,
                                                    },
                                                });
                                            } else {
                                                setState({
                                                    ...state,
                                                    data: {
                                                        ...state.data,
                                                        username:
                                                            state.data
                                                                .username !==
                                                                null &&
                                                            state.data
                                                                .username !==
                                                                undefined
                                                                ? e.target.value
                                                                : null,
                                                        email:
                                                            state.data.email !==
                                                                null &&
                                                            state.data.email !==
                                                                undefined
                                                                ? e.target.value
                                                                : null,
                                                    },
                                                });
                                            }
                                        }}
                                    />
                                </FormRow>

                                {'id' in state && (
                                    <FormRow>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    value={
                                                        state.data.password !==
                                                        undefined
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e, checked) => {
                                                        setState({
                                                            ...state,
                                                            data: {
                                                                ...state.data,
                                                                password:
                                                                    checked
                                                                        ? ''
                                                                        : undefined,
                                                            },
                                                        });
                                                    }}
                                                />
                                            }
                                            label="Reset Password"
                                        />
                                    </FormRow>
                                )}
                                <Collapse
                                    in={
                                        !('id' in state) ||
                                        ('id' in state &&
                                            state.data.password !== undefined)
                                    }
                                >
                                    <Box>
                                        <FormRow>
                                            <TextField
                                                type="password"
                                                label={
                                                    'id' in state
                                                        ? 'New password'
                                                        : 'Password'
                                                }
                                                value={
                                                    'id' in state
                                                        ? state.data.password
                                                        : state.data
                                                              .temporary_password
                                                }
                                                onChange={(e) => {
                                                    if (!('id' in state)) {
                                                        setState({
                                                            ...state,
                                                            data: {
                                                                ...state.data,
                                                                temporary_password:
                                                                    e.target
                                                                        .value ||
                                                                    '',
                                                            },
                                                        });
                                                    } else {
                                                        setState({
                                                            ...state,
                                                            data: {
                                                                ...state.data,
                                                                password:
                                                                    e.target
                                                                        .value ||
                                                                    '',
                                                            },
                                                        });
                                                    }
                                                }}
                                                fullWidth
                                            />
                                        </FormRow>
                                        <FormRow>
                                            <TextField
                                                type="password"
                                                label={'Confirm Pasword'}
                                                value={confirmation}
                                                onChange={(e) => {
                                                    setConfirmation(
                                                        e.target.value || ''
                                                    );
                                                }}
                                                fullWidth
                                            />
                                        </FormRow>
                                        <FormRow>
                                            <Box>
                                                {Object.keys(validation).map(
                                                    (key, policyIndex) => (
                                                        <Box
                                                            key={`policy_${policyIndex}`}
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                gap: 1,
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                    paddingBottom: 0.5,
                                                                }}
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        height: 8,
                                                                        width: 8,
                                                                        borderRadius: 16,
                                                                        background:
                                                                            validation[
                                                                                key as PasswordPolicy
                                                                            ]
                                                                                ? palette
                                                                                      .success
                                                                                      .main
                                                                                : palette
                                                                                      .error
                                                                                      .main,
                                                                    }}
                                                                />
                                                            </Box>
                                                            <Typography variant="body2">
                                                                {key}
                                                            </Typography>
                                                        </Box>
                                                    )
                                                )}
                                            </Box>
                                        </FormRow>
                                    </Box>
                                </Collapse>
                                <Box>
                                    <LoadingButton
                                        onClick={() => {
                                            if ('id' in state) handleUpdate();
                                            else handleCreate();
                                        }}
                                        loading={createLoading || updateLoading}
                                        endIcon={
                                            !result ? <MdCheck /> : undefined
                                        }
                                    >
                                        Save Profile
                                    </LoadingButton>
                                </Box>
                            </Box>
                        ),
                    }}
                </NavContent>
            )}
        </AppNav>
    );
};

export default ProfileForm;
