import { gql } from '@apollo/client';
import { Pagination } from '../../utils/types/Pagination';
import { AddressFragment } from '../Address/Address';
import { TinyCompanyFragment } from '../Company/Company';
import { TinyContactFragment } from '../Contact/Contact';
import { TinyItemFragment } from '../Item/Item';
import { CompanyLocationFragment } from '../Location/Location';
import { getLazyQueryHook, getQueryHook } from '../types';
import { TinyLot, TinyLotFragment } from './Lot';
import { LotFilter } from './LotFilter';

export const TinyLots = gql`
    ${TinyLotFragment}
    ${TinyItemFragment}
    ${TinyCompanyFragment}
    ${TinyContactFragment}
    ${CompanyLocationFragment}
    ${AddressFragment}
    query TinyLots($filter: LotFilter!) {
        lots(filter: $filter) {
            count
            items {
                ...TinyLotFragment
            }
        }
    }
`;

export interface TinyLotsRes {
    lots: Pagination<TinyLot>;
}

export interface TinyLotsArgs {
    filter: LotFilter;
}

export const useTinyLots = getQueryHook<TinyLotsRes, TinyLotsArgs>(TinyLots);

export const useLazyLots = getLazyQueryHook<TinyLotsRes, TinyLotsArgs>(
    TinyLots
);
