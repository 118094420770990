import React, { ReactElement } from 'react';
import AppNav from '../../components/Layout/AppNav/components';
import NavContent from '../../components/Layout/AppNav/components/NavContent';
import {
    Box,
    Typography,
    Avatar,
    useTheme,
    MenuItem,
    Divider,
} from '@mui/material';
import { usePasswordReset } from './operations/usePasswordReset';
import { LoginType, useLoginUpdate } from './operations/useLoginUpdate';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from '../Loading';
import { OperationResult } from '../../utils/types/OperationResult';
import { Dialog, Button, IconButton, TextField } from '@mui/material';
import Message from '../../components/feedback/Message';
import { MdClear } from 'react-icons/md';
import FormRow from '../../components/Layout/FormRow';
import { checkPassword, PasswordPolicy } from './checkPassword';
import { LoadingButton } from '@mui/lab';
import { ProfileQuery, useProfile } from '../../graphql/Profile/useProfile';
import ResponsiveDialog from '../../components/Layout/ResponsiveDialog';

const MyAccount = (): ReactElement => {
    const { user, isLoading } = useAuth0();

    const { data, error, loading } = useProfile({
        variables: {
            id: user ? user.sub || '' : '',
        },
        skip: !user,
    });

    const [result, setResult] = React.useState<OperationResult<
        { resetPassword: boolean } | { changeLogin: boolean }
    > | null>(null);

    const [resetPassword, { loading: resetLoading }] = usePasswordReset({
        onCompleted: (data) => setResult({ success: true, data }),
        onError: (error) => setResult({ success: false, error }),
    });
    const [updateLogin, { loading: loginLoading }] = useLoginUpdate({
        onCompleted: (data) => setResult({ success: true, data }),
        onError: (error) => setResult({ success: false, error }),
        refetchQueries: [ProfileQuery],
    });

    const { palette } = useTheme();

    const [state, setState] = React.useState<
        | null
        | { password: string; confirmation: string }
        | { type: LoginType; value: string }
    >(null);

    const [ready, validation] = checkPassword(
        state && 'password' in state ? state.password : '',
        state && 'password' in state ? state.confirmation : ''
    );

    const handleClose = () => {
        setState(null);
        setResult(null);
    };

    if (!user || !data) return <Loading />;

    const { profile } = data;

    return (
        <AppNav loading={loading || isLoading} error={error}>
            <NavContent>
                {{
                    header: (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                            }}
                        >
                            <Avatar src={profile.picture || ''}>
                                {(user.given_name || '')[0]}
                                {(user.family_name || '')[0]}
                            </Avatar>
                            <Typography variant="crisp">My Account</Typography>
                        </Box>
                    ),
                    content: (
                        <Box>
                            <Typography>Account actions</Typography>
                            <Divider />
                            <Box p={1} />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexFlow: 'column',
                                    alignItems: 'flex-start',
                                    gap: 2,
                                }}
                            >
                                <Button
                                    onClick={() =>
                                        setState({
                                            password: '',
                                            confirmation: '',
                                        })
                                    }
                                >
                                    Reset password
                                </Button>
                                <Button
                                    variant="outlined"
                                    onClick={() =>
                                        setState({
                                            type: profile.username
                                                ? LoginType.Username
                                                : LoginType.Email,
                                            value:
                                                profile.username ||
                                                profile.email ||
                                                '',
                                        })
                                    }
                                >
                                    Update login method
                                </Button>
                            </Box>
                        </Box>
                    ),
                }}
            </NavContent>
            <ResponsiveDialog
                title={
                    state && 'password' in state
                        ? 'Reset Password'
                        : 'Change Login Method'
                }
                PaperProps={{ sx: { padding: 2 } }}
                open={Boolean(state || result)}
                onClose={handleClose}
            >
                {result ? (
                    result.success ? (
                        <Message type="Success" onComplete={handleClose}>
                            {'resetPassword' in result.data
                                ? 'Password updated!'
                                : 'Login updated!'}
                        </Message>
                    ) : (
                        <Message
                            type="Error"
                            action={
                                <Button onClick={() => setResult(null)}>
                                    Try again
                                </Button>
                            }
                        >
                            {result.error.message}
                        </Message>
                    )
                ) : state && 'password' in state ? (
                    <Box>
                        <FormRow>
                            <TextField
                                fullWidth
                                label="New password"
                                type="password"
                                value={state.password}
                                onChange={(e) =>
                                    setState({
                                        ...state,
                                        password: e.target.value,
                                    })
                                }
                            />
                        </FormRow>
                        <FormRow>
                            <TextField
                                fullWidth
                                label="Confirm password"
                                type="password"
                                value={state.confirmation}
                                onChange={(e) =>
                                    setState({
                                        ...state,
                                        confirmation: e.target.value,
                                    })
                                }
                            />
                        </FormRow>
                        <FormRow>
                            <Box>
                                {Object.keys(validation).map(
                                    (key, policyIndex) => (
                                        <Box
                                            key={`policy_${policyIndex}`}
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    paddingBottom: 0.5,
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        height: 8,
                                                        width: 8,
                                                        borderRadius: 16,
                                                        background: validation[
                                                            key as PasswordPolicy
                                                        ]
                                                            ? palette.success
                                                                  .main
                                                            : palette.error
                                                                  .main,
                                                    }}
                                                />
                                            </Box>
                                            <Typography variant="body2">
                                                {key}
                                            </Typography>
                                        </Box>
                                    )
                                )}
                            </Box>
                        </FormRow>
                        <Box>
                            <LoadingButton
                                loading={resetLoading}
                                disabled={!ready || resetLoading}
                                fullWidth
                                variant="contained"
                                onClick={() =>
                                    resetPassword({
                                        variables: { password: state.password },
                                    })
                                }
                            >
                                Update
                            </LoadingButton>
                        </Box>
                    </Box>
                ) : state ? (
                    <Box>
                        <FormRow>
                            <TextField
                                select
                                fullWidth
                                label="Login with"
                                value={state.type}
                                onChange={(e) =>
                                    setState({
                                        ...state,
                                        type: e.target.value as LoginType,
                                        value: '',
                                    })
                                }
                            >
                                <MenuItem value={LoginType.Username}>
                                    Username
                                </MenuItem>
                                <MenuItem value={LoginType.Email}>
                                    Email
                                </MenuItem>
                            </TextField>
                        </FormRow>
                        <FormRow>
                            <TextField
                                fullWidth
                                label={
                                    state.type == LoginType.Email
                                        ? 'Email Address'
                                        : 'Username'
                                }
                                value={state.value}
                                onChange={(e) =>
                                    setState({
                                        ...state,
                                        value: e.target.value,
                                    })
                                }
                            />
                        </FormRow>
                        <Box>
                            <LoadingButton
                                loading={loginLoading}
                                fullWidth
                                variant="contained"
                                onClick={() =>
                                    updateLogin({
                                        variables: {
                                            type: state.type,
                                            value: state.value,
                                        },
                                    })
                                }
                            >
                                Update
                            </LoadingButton>
                        </Box>
                    </Box>
                ) : (
                    <Box />
                )}
            </ResponsiveDialog>
        </AppNav>
    );
};

export default MyAccount;
