import React, { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import OrderDetail from './components/OrderDetail';
import OrderList from './components/OrderList';

const Orders = (): ReactElement => {
    return (
        <Routes>
            <Route index element={<OrderList />} />
            <Route path={':id'} element={<OrderDetail />} />
            <Route path={':id/:backto'} element={<OrderDetail />} />
        </Routes>
    );
};

export default Orders;
