import {
    Box,
    Checkbox,
    Collapse,
    IconButton,
    InputAdornment,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    TextField,
    useTheme,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { MdClear, MdExpandMore, MdSearch } from 'react-icons/md';
import Anima from '../../../Layout/Anima';

type BaseProps<T> = {
    autofocus?: boolean;
    default_open?: boolean;
    data: T[];
    label: string;
    getProps: (d: T) => {
        id: string;
        primary: string;
        secondary?: string;
    };
};

export type SelectFieldProps<T, M extends boolean | undefined> = M extends true
    ? BaseProps<T> & {
          multiple: true;
          value: string[];
          onChange: (value: string[]) => void;
      }
    : BaseProps<T> & {
          multiple: false | undefined;
          value: string;
          onChange: (value: string) => void;
      };

const SelectField = <T, M extends boolean | undefined>(
    props: SelectFieldProps<T, M>
): ReactElement => {
    const {
        autofocus = false,
        label,
        multiple,
        data,
        value,
        default_open = false,
        onChange,
        getProps,
    } = props;

    const { palette, shape } = useTheme();

    const [search, setSearch] = React.useState('');
    const [collapsed, setCollapsed] = React.useState(
        default_open ? false : true
    );

    React.useEffect(() => {
        if (search) setCollapsed(false);
    }, [search]);

    const toggle = (id: string) => {
        if (multiple) {
            if (value.includes(id)) {
                onChange(value.filter((v) => v !== id));
            } else {
                onChange([...value, id]);
            }
        } else {
            if (value == id) {
                onChange('');
            } else {
                onChange(id);
            }
        }
    };

    const checkSearch = (d: T): boolean => {
        const { primary, secondary } = getProps(d);
        const check =
            primary.toLowerCase() + (secondary ? secondary.toLowerCase() : '');
        if (check.includes(search.toLowerCase())) return true;
        return false;
    };

    const getFiltered = (): T[] => {
        return data.reduce((stack, item) => {
            const match = checkSearch(item);
            return match ? [item, ...stack] : [...stack, item];
        }, [] as T[]);
    };

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexFlow: 'column',
                ...shape,
                border: `1px solid ${palette.divider}`,
            }}
        >
            <Box
                sx={{
                    borderBottom: `1px solid ${palette.divider}`,
                    padding: 1,
                    background:
                        palette.mode == 'dark'
                            ? palette.action.focus
                            : 'rgba(0, 0, 0, 0.065)',
                }}
            >
                <TextField
                    autoFocus={autofocus}
                    fullWidth
                    placeholder={
                        label + (collapsed ? ` (${value.length} selected)` : '')
                    }
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    variant="standard"
                    InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <MdSearch />
                            </InputAdornment>
                        ),
                        endAdornment: search ? (
                            <InputAdornment position="end">
                                <IconButton
                                    size="small"
                                    onClick={() => setSearch('')}
                                >
                                    <MdClear />
                                </IconButton>
                            </InputAdornment>
                        ) : (
                            <InputAdornment position="end">
                                <IconButton
                                    size="small"
                                    onClick={() => setCollapsed(!collapsed)}
                                >
                                    <Anima in={!collapsed} type="rotate">
                                        <Box sx={{ display: 'flex' }}>
                                            <MdExpandMore />
                                        </Box>
                                    </Anima>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <Collapse in={!collapsed}>
                <Box sx={{ overflow: 'auto', maxHeight: 300 }}>
                    <Box
                        sx={{
                            flex: 1,
                            overflow: 'auto',
                            background: palette.background.paper,
                        }}
                    >
                        <List disablePadding>
                            {getFiltered().map((d) => {
                                const { id, primary, secondary } = getProps(d);
                                const match = checkSearch(d);
                                return (
                                    <ListItemButton
                                        disabled={!match}
                                        divider
                                        key={id}
                                        role={undefined}
                                        onClick={() => toggle(id)}
                                        dense
                                    >
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={value.includes(id)}
                                                tabIndex={-1}
                                                disableRipple
                                                onChange={() => toggle(id)}
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            id={id}
                                            primary={primary}
                                            secondary={secondary}
                                        />
                                    </ListItemButton>
                                );
                            })}
                        </List>
                    </Box>
                </Box>
            </Collapse>
        </Box>
    );
};

export default SelectField;
