import { gql } from '@apollo/client';
import { TinyItem } from '../../../Item/Item';
import { Form } from '../../Form';
import { QualityCheckCategory } from './QualityCheckCategory';

export interface QualityCheckForm extends Form {
    items: TinyItem[] | null;
    category: QualityCheckCategory;
}

export const QualityCheckFormFragment = gql`
    fragment QualityCheckFormFragment on QualityCheckForm {
        ...BaseFragment
        items {
            ...TinyItemFragment
        }
        category
        fields {
            category
            question
            options
            required
            passable_options
        }
    }
`;
