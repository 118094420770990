import { gql } from '@apollo/client';
import { getQueryHook } from '../../types';
import { Recipe, RecipeFragment } from '../Recipe';

export const RecipeQuery = gql`
    ${RecipeFragment}
    query RecipeQuery($id: ObjectId!) {
        recipe(id: $id) {
            ...RecipeFragment
        }
    }
`;

export interface RecipeRes {
    recipe: Recipe;
}

export interface RecipeArgs {
    id: string | null;
}

export const useRecipe = getQueryHook<RecipeRes, RecipeArgs>(RecipeQuery);
