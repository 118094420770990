import { Box, useTheme } from '@mui/material/';
import Tooltip from '@mui/material/Tooltip';
import React, { ReactElement } from 'react';
import { MdRadioButtonUnchecked, MdWarningAmber } from 'react-icons/md';
import VerificationBadge from '../../../../../../components/display/VerificationBadge';
import { Batch } from '../../../../../../graphql/Batch/Batch';

export interface BatchBadgeProps {
    children: Batch;
}

const BatchBadge = (props: BatchBadgeProps): ReactElement => {
    const { children: batch } = props;

    const { palette } = useTheme();

    if (batch.date_abandoned)
        return (
            <Tooltip title="Abandoned" arrow>
                <Box sx={{ display: 'flex' }}>
                    <MdWarningAmber
                        style={{
                            color: palette.warning.main,
                            fontSize: '1.2rem',
                        }}
                    />
                </Box>
            </Tooltip>
        );
    else if (!batch.date_completed)
        return (
            <Tooltip title="In progress" arrow>
                <Box sx={{ display: 'flex' }}>
                    <MdRadioButtonUnchecked
                        style={{
                            color: palette.text.secondary,
                            fontSize: '1.2rem',
                        }}
                    />
                </Box>
            </Tooltip>
        );
    else
        return <VerificationBadge fontSize="1.2rem">{batch}</VerificationBadge>;
};

export default BatchBadge;
