import { gql } from '@apollo/client';
import { TinyItem, TinyItemFragment } from '../Item/Item';
import { getMutationHook } from '../types';
import { UnitConversionInput } from './UnitConversionInput';

export const ConversionUpdate = gql`
    ${TinyItemFragment}
    mutation ConversionUpdate($data: [BulkConversionInput!]!) {
        updateConversions(data: $data) {
            ...TinyItemFragment
        }
    }
`;

export interface ConversionUpdateRes {
    updateConversions: TinyItem[];
}

export interface ConversionUpdateArgs {
    data: { item: string; conversion: UnitConversionInput }[];
}

export const useConversionUpdate = getMutationHook<
    ConversionUpdateRes,
    ConversionUpdateArgs
>(ConversionUpdate);
