import {
    Autocomplete,
    Box,
    ButtonBase,
    Chip,
    Collapse,
    MenuItem,
    Stack,
    TextField,
    TextFieldProps,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    useTheme,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { MdWarning } from 'react-icons/md';
import { ItemType } from '../../../../graphql/Item/Item';
import { useItem } from '../../../../graphql/Item/operations/useItem';
import { useTinyItems } from '../../../../graphql/Item/operations/useTinyItems';
import { useTinyUnits } from '../../../../graphql/Unit/operations/useUnits';
import { TinyUnit } from '../../../../graphql/Unit/Unit';
import { UnitClass } from '../../../../graphql/Unit/UnitClass';
import { niceList } from '../../../../utils/niceList';
import ResponsiveDialog from '../../../Layout/ResponsiveDialog';

export type UnitFieldProps<M extends boolean | undefined> = M extends true
    ? Omit<TextFieldProps, 'value' | 'onChange' | 'multiple'> & {
          multiple: true;
          value: string[];
          onChange: (value: string[]) => void;
          classes?: UnitClass[];
          item_types?: ItemType[];
          plural?: boolean;
          items?: string[];
          onConflictClick?: () => void;
      }
    : Omit<TextFieldProps, 'value' | 'onChange' | 'multiple'> & {
          multiple: false | undefined;
          value: string;
          onChange: (value: string) => void;
          classes?: UnitClass[];
          item_types?: ItemType[];
          plural?: boolean;
          items?: string[];
          onConflictClick?: () => void;
      };

const UnitField = <M extends boolean | undefined>(
    props: UnitFieldProps<M>
): ReactElement => {
    const {
        value,
        classes,
        item_types,
        multiple,
        plural = true,
        onChange,
        items: item_ids,
        onConflictClick,
        ...rest
    } = props;

    const { palette, shape } = useTheme();

    const { data, error, loading } = useTinyUnits({
        variables: {
            filter: {
                skip: 0,
                take: 500,
                classes,
                item_types,
                items: item_ids,
            },
        },
    });

    const units = data ? data.units.items : [];

    const { data: itemData } = useTinyItems({
        skip: !item_ids,
        variables: { filter: { ids: item_ids, skip: 0, take: 500 } },
    });

    const unitError = !loading && item_ids !== undefined && units.length == 0;

    if (unitError)
        return (
            <ButtonBase
                onClick={() => (onConflictClick ? onConflictClick() : null)}
                sx={{
                    p: 1,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    ...shape,
                }}
            >
                <MdWarning style={{ color: palette.warning.main }} />
                Unit Conflict
            </ButtonBase>
        );

    if (multiple)
        return (
            <Autocomplete
                multiple
                fullWidth={rest.fullWidth}
                options={units}
                isOptionEqualToValue={(unit, value) => {
                    return unit._id === value._id;
                }}
                freeSolo
                value={units.filter((val) => value.includes(val._id))}
                onChange={(v, newVal) => {
                    onChange((newVal as TinyUnit[]).map((v) => v._id));
                }}
                renderOption={(props, op) => (
                    <MenuItem {...props}>
                        {op[plural ? 'plural' : 'name']}
                    </MenuItem>
                )}
                renderTags={(val) => (
                    <Stack
                        sx={{
                            paddingTop: 1,
                            paddingBottom: 1,
                            flexWrap: 'wrap',
                        }}
                        direction="row"
                        gap={1}
                    >
                        {val.map((comp) => {
                            return (
                                <Chip
                                    key={'op_' + comp._id}
                                    label={comp[plural ? 'plural' : 'name']}
                                    onDelete={() =>
                                        onChange(
                                            value.filter((v) => v !== comp._id)
                                        )
                                    }
                                />
                            );
                        })}
                    </Stack>
                )}
                renderInput={(params) => (
                    <TextField {...rest} {...params} variant="filled" />
                )}
            />
        );

    return (
        <TextField
            {...rest}
            value={value || 'null'}
            onChange={(e) => onChange(e.target.value || '')}
            variant={rest.variant || 'filled'}
            select
            InputProps={{
                ...rest.InputProps,
                sx: !value
                    ? {
                          color: palette.text.disabled,
                          ...rest.InputProps?.sx,
                      }
                    : { ...rest.InputProps?.sx },
            }}
        >
            <MenuItem disabled value={'null'}>
                Unit
            </MenuItem>
            {units.map((unit) => (
                <MenuItem key={'op_' + unit._id} value={unit._id}>
                    {unit[plural ? 'plural' : 'name']}
                </MenuItem>
            ))}
        </TextField>
    );
};

export default UnitField;
