import { gql } from '@apollo/client';
import { Base, TinyBase } from '../Base/Base';
import { UnitClass } from '../Unit/UnitClass';
import { UnitConversion } from '../UnitConversion/UnitConersion';

export enum ItemType {
    Cookie = 'Cookie',
    Ingredient = 'Ingredient',
    MiscItem = 'MiscItem',
    Package = 'Package',
    Product = 'Product',
}

export interface Item extends Base {
    type: ItemType;
    name: string;
    spanish_name: string;
    unit_class: UnitClass;
    conversions: UnitConversion[];
}

export interface TinyItem extends TinyBase {
    type: ItemType;
    name: string;
    spanish_name: string;
    unit_class: UnitClass;
    conversions: UnitConversion[];
}

export const TinyItemFragment = gql`
    fragment TinyItemFragment on Item {
        type
        _id
        name
        spanish_name
        unit_class
        conversions {
            to
            multiplier
        }
    }
`;
