import { gql } from '@apollo/client';
import { BaseFragment } from '../../Base/Base';
import { getMutationHook } from '../../types';
import {
    CompanyLocationFragment,
    Location,
    LocationFragment,
} from '../Location';
import { CreateLocationInput } from '../inputs/CreateLocationInput';
import { AddressFragment } from '../../Address/Address';
import { TinyCompanyFragment } from '../../Company/Company';
import { TinyContactFragment } from '../../Contact/Contact';

export const CreateLocationMutation = gql`
    ${LocationFragment}
    ${BaseFragment}
    ${AddressFragment}
    ${TinyContactFragment}
    ${CompanyLocationFragment}
    ${TinyCompanyFragment}
    mutation CreateLocationMutation($data: CreateLocationInput!) {
        createLocation(data: $data) {
            ...LocationFragment
        }
    }
`;

export interface CreateLocationRes {
    createLocation: Location;
}

export interface CreateLocationArgs {
    data: CreateLocationInput;
}

export const useLocationCreation = getMutationHook<
    CreateLocationRes,
    CreateLocationArgs
>(CreateLocationMutation);
