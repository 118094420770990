import TextField, { TextFieldProps } from '@mui/material/TextField';
import React, { ReactElement } from 'react';
import { commafy } from '../../../utils/commafy';

export interface QtyInputProps
    extends Omit<TextFieldProps, 'value' | 'onChange' | 'type' | 'children'> {
    value: number | null;
    onChange: (value: number | null) => void;
    int?: boolean;
}

const QtyInput = (props: QtyInputProps): ReactElement => {
    const { value, onChange, int = false, ...rest } = props;

    const [pt, setPt] = React.useState(false);

    return (
        <TextField
            {...rest}
            value={
                value == null
                    ? ''
                    : int
                    ? (commafy(value) + (pt ? '.' : '')).replace('.', '')
                    : commafy(value) + (pt ? '.' : '')
            }
            onChange={(e) => {
                const val = e.target.value;
                if (!val) onChange(null);
                else {
                    if (val.charAt(val.length - 1) == '.' && !int) {
                        setPt(true);
                        const stringVal = val.replaceAll(',', '');
                        const parsed = int
                            ? parseInt(stringVal)
                            : parseFloat(stringVal);

                        if (isNaN(parsed)) {
                            onChange(null);
                        } else {
                            onChange(parsed);
                        }
                    } else {
                        setPt(false);
                        const stringVal = val.replaceAll(',', '');
                        const parsed = int
                            ? parseInt(stringVal)
                            : parseFloat(stringVal);

                        if (isNaN(parsed)) {
                            onChange(null);
                        } else {
                            onChange(parsed);
                        }
                    }
                }
            }}
        />
    );
};

export default QtyInput;
