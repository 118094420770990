import { gql } from '@apollo/client';
import { Base, TinyBase } from '../Base/Base';
import { TinyCompany } from '../Company/Company';
import { TinyItem } from '../Item/Item';
import { Itinerary } from '../Itinerary/Itinerary';

export interface Order extends Base {
    code: string | null;
    customer: TinyCompany | null;
    vendor: TinyCompany | null;
    itineraries: Itinerary[];
}

export interface TinyOrder extends Base {
    code: string | null;
    customer: TinyCompany | null;
    vendor: TinyCompany | null;
    items: TinyItem[];
}

export const OrderFragment = gql`
    fragment OrderFragment on Order {
        ...BaseFragment
        code
        customer {
            ...TinyCompanyFragment
        }
        vendor {
            ...TinyCompanyFragment
        }
        itineraries {
            ...ItineraryFragment
        }
    }
`;

export const TinyOrderFragment = gql`
    fragment TinyOrderFragment on Order {
        ...BaseFragment
        code
        customer {
            ...TinyCompanyFragment
        }
        vendor {
            ...TinyCompanyFragment
        }
        items {
            ...TinyItemFragment
        }
    }
`;
