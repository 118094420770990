import { gql } from '@apollo/client';
import { getMutationHook } from '../../types';

export const DuplicateRecipe = gql`
    mutation DuplicateRecipe($id: ObjectId!) {
        duplicateRecipe(id: $id) {
            _id
        }
    }
`;

export interface DuplicateRecipeArgs {
    id: string;
}

export interface DuplicateRecipeRes {
    duplicateRecipe: {
        _id: string;
    };
}

export const useRecipeDuplication = getMutationHook<
    DuplicateRecipeRes,
    DuplicateRecipeArgs
>(DuplicateRecipe);
