import { Button, TableBody, Theme, Tooltip, useTheme } from '@mui/material';
import {
    Box,
    SxProps,
    Table,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import React, { ReactElement } from 'react';
import AppNav from '../../../../components/Layout/AppNav/components';
import NavContent from '../../../../components/Layout/AppNav/components/NavContent';
import NavHeader from '../../../../components/Layout/NavHeader';
import { useOrderQueue } from '../../../../graphql/OrderQueue/operations/useOrderQueue';
import { useOrderQueueTemplates } from '../../../../graphql/OrderQueue/operations/useOrderQueueTemplates';
import { OrderQueueLine } from '../../../../graphql/OrderQueue/OrderQueueLine';
import ItemDrawer from './components/ItemDrawer';
import { OrderQueueLineInput } from '../../../../graphql/OrderQueue/inputs/OrderQueueLineInput';
import QueueLine from './components/QueueLine';
import { useOrderQueueUpdate } from '../../../../graphql/OrderQueue/operations/useOrderQueueUpdate';
import { OperationResult } from '../../../../utils/types/OperationResult';
import { useOrderQueueProcess } from '../../../../graphql/OrderQueue/operations/useOrderQueueProcess';
import Message from '../../../../components/feedback/Message';
import { MdCheck } from 'react-icons/md';
import { useOurCompany } from '../../../../graphql/Company/operations/useOurCompany';
import { LoadingButton } from '@mui/lab';
import { FulfillmentEvents } from '../../../../graphql/FulfillmentEvent/useFulfillmentEvents';
import QueueDrafts from './components/QueueDrafts';
import { OrderQueueContentInput } from '../../../../graphql/OrderQueue/inputs/OrderQueueContentInput';

import { JSONTree } from 'react-json-tree';

const threshold = 1500;

export const getOrderQueueLineInput = ({
    po,
    contents,
    customer,
    customer_location,
    vendor,
    vendor_location,
    date,
    time,
}: OrderQueueLine): OrderQueueLineInput => ({
    po,
    contents: contents.map(({ item, unit, quantity }) => ({
        item,
        unit,
        quantity,
    })),
    customer,
    customer_location,
    vendor,
    vendor_location,
    date,
    time,
});

const OrderQueue = (): ReactElement => {
    const [opened, setOpened] = React.useState(false);
    const [queue, setQueue] = React.useState<OrderQueueLineInput[]>([]);
    const [savedQueue, setSavedQueue] = React.useState<OrderQueueLineInput[]>(
        []
    );

    const { palette } = useTheme();

    const { data: ourCompanyData, error: companyError } = useOurCompany();

    const ldb = ourCompanyData ? ourCompanyData.ourCompany : null;

    const {
        data: templateData,
        loading: templateLoading,
        error: templateError,
    } = useOrderQueueTemplates();

    const { error, loading } = useOrderQueue({
        fetchPolicy: 'network-only',
        onCompleted: ({ orderQueue }) => {
            const lines: OrderQueueLineInput[] = orderQueue.map((line) =>
                getOrderQueueLineInput(line)
            );
            setQueue(lines);
            setSavedQueue(lines);
            setOpened(true);
        },
        skip: opened,
    });

    const [update, { error: updateError, loading: updateLoading }] =
        useOrderQueueUpdate({
            variables: {
                data: queue,
            },
            onError: (err) => {
                console.log(err);
            },
            onCompleted: ({ updateOrderQueue }) => {
                const lines: OrderQueueLineInput[] = updateOrderQueue.map(
                    (line) => getOrderQueueLineInput(line)
                );
                setSavedQueue(lines);
            },
        });

    const getNeedsUpdate = (): boolean => {
        let res = false;
        if (queue.length != savedQueue.length) return true;
        queue.map((q, i) => {
            for (const key of Object.keys(q)) {
                if (key == 'contents') {
                    if (q.contents.length !== savedQueue[i].contents.length)
                        res = true;
                    else {
                        q.contents.map((content, contentIndex) => {
                            for (const contentKey of Object.keys(content)) {
                                if (
                                    savedQueue[i].contents[contentIndex][
                                        contentKey as keyof OrderQueueContentInput
                                    ] !==
                                    content[
                                        contentKey as keyof OrderQueueContentInput
                                    ]
                                ) {
                                    res = true;
                                }
                            }
                        });
                    }
                } else if (
                    q[key as keyof OrderQueueLine] !=
                    savedQueue[i][key as keyof OrderQueueLine]
                ) {
                    res = true;
                }
            }
        });
        return res;
    };

    const needsUpdate = getNeedsUpdate();

    React.useEffect(() => {
        const timer = setTimeout(() => {
            if (needsUpdate) {
                update();
            }
        }, threshold);
        return () => clearTimeout(timer);
    }, [needsUpdate, queue, savedQueue]);

    const [result, setResult] = React.useState<OperationResult<{
        processOrderQueue: boolean;
    }> | null>(null);

    const [process, { loading: processLoading }] = useOrderQueueProcess({
        onCompleted: (data) => setResult({ success: true, data }),
        onError: (error) => setResult({ success: false, error }),
        variables: {
            data: queue,
        },
        refetchQueries: [FulfillmentEvents],
    });

    const templates = templateData ? templateData.orderQueueTemplates : [];

    const shaded: SxProps<Theme> = { background: palette.tonal };
    const basic: SxProps<Theme> = { background: palette.background.paper };

    const checkContent = (content: OrderQueueContentInput): string | null => {
        if (!content.item) return 'Each order line needs an item.';
        if (!content.quantity) return 'Each order line needs a qty.';
        if (!content.unit) return 'Each order line needs a unit.';
        return null;
    };

    const checkLine = (line: OrderQueueLineInput): string | null => {
        if (!line.customer) return 'Each order needs a customer';
        if (!line.customer_location && ldb && ldb._id == line.customer)
            return 'Each Order to or from LDB needs a plant selection.';
        if (!line.date) return 'Each order needs a delivery date';
        if (!line.po) return 'Each order needs a PO';
        if (!line.vendor) return 'Each order needs a vendor';
        if (ldb !== null && line.vendor === ldb._id && !line.vendor_location)
            return 'Each order from LDB needs a plant origin.';

        const lineErrors = line.contents.map((c) => checkContent(c));

        const lineError = lineErrors.find((l) => l !== null);

        if (lineError) return lineError;

        return null;
    };

    const getHoldup = () => {
        if (loading) return 'Save in progress...';
        if (queue.length == 0) return 'Add an item to your queue';
        const lines = queue.map((q) => checkLine(q));
        if (lines.some((line) => line !== null))
            return lines.find((l) => l !== null);
        return null;
    };

    const holdup = getHoldup();

    return (
        <AppNav
            error={error || templateError || companyError}
            loading={loading || templateLoading}
        >
            {result && result.success == false ? (
                <Message
                    type="Error"
                    action={
                        <Button onClick={() => setResult(null)}>
                            Try again
                        </Button>
                    }
                >
                    {result.error.message}
                </Message>
            ) : result ? (
                <Message
                    type="Success"
                    onComplete={() => {
                        setQueue([]);
                        setSavedQueue([]);
                        setResult(null);
                    }}
                >
                    Orders Processed!
                </Message>
            ) : (
                <NavContent padding={{ header: 3, content: 0, footer: 0 }}>
                    {{
                        header: (
                            <NavHeader back={['Orders', '/supplychain/orders']}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexFlow: 'column',
                                        gap: 0.5,
                                    }}
                                >
                                    <Typography variant="crisp">
                                        Order Queue
                                    </Typography>
                                    {loading || needsUpdate ? (
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                color: palette.text.secondary,
                                            }}
                                        >
                                            Save in progress
                                        </Typography>
                                    ) : (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 0.5,
                                                color: palette.success.main,
                                            }}
                                        >
                                            <Typography variant="caption">
                                                Saved
                                            </Typography>
                                            <MdCheck />
                                        </Box>
                                    )}
                                </Box>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <QueueDrafts>{queue}</QueueDrafts>
                                    <Box>
                                        <ItemDrawer
                                            onClick={(item) => {
                                                const newLine: OrderQueueLineInput =
                                                    {
                                                        po: null,
                                                        contents: [
                                                            {
                                                                item: item._id,
                                                                unit: null,
                                                                quantity: null,
                                                            },
                                                        ],
                                                        customer: null,
                                                        vendor: null,
                                                        customer_location: null,
                                                        vendor_location: null,
                                                        date: null,
                                                        time: null,
                                                    };

                                                setQueue([...queue, newLine]);
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </NavHeader>
                        ),
                        content: (
                            <Box
                                sx={{
                                    height: '100%',
                                    overflow: 'auto',
                                    paddingLeft: 3,
                                    paddingRight: 3,
                                }}
                            >
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ ...shaded }}>
                                                Item
                                            </TableCell>
                                            <TableCell sx={{ ...shaded }}>
                                                Quantity
                                            </TableCell>
                                            <TableCell sx={{ ...basic }}>
                                                Customer
                                            </TableCell>
                                            <TableCell sx={{ ...basic }}>
                                                Vendor
                                            </TableCell>
                                            <TableCell sx={{ ...basic }}>
                                                PO
                                            </TableCell>
                                            <TableCell sx={{ ...basic }}>
                                                Deliver by
                                            </TableCell>
                                            <TableCell sx={{ ...basic }}>
                                                Time
                                            </TableCell>
                                            <TableCell
                                                sx={{ ...basic }}
                                            ></TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {queue.map((line, i) => (
                                            <QueueLine
                                                index={i}
                                                key={'line_' + i}
                                                value={line}
                                                onChange={(l) => {
                                                    if (l) {
                                                        const copy = [...queue];
                                                        copy[i] = l;

                                                        setQueue(copy);
                                                    } else {
                                                        const copy = [...queue];
                                                        copy.splice(i, 1);
                                                        setQueue(copy);
                                                    }
                                                }}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        ),
                        footer: (
                            <Box
                                sx={{
                                    padding: 3,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                {/* <QueueTemplates>{templates}</QueueTemplates> */}
                                <Box>
                                    <Typography
                                        variant="caption"
                                        color="text.disabled"
                                    >
                                        <em>Rev 01</em>
                                    </Typography>
                                </Box>
                                <Tooltip title={holdup || ''} arrow>
                                    <Box>
                                        <LoadingButton
                                            disabled={Boolean(holdup)}
                                            onClick={() => {
                                                process();
                                            }}
                                            endIcon={<MdCheck />}
                                            variant="contained"
                                            size="large"
                                            loading={processLoading}
                                            color="success"
                                        >
                                            Process Orders
                                        </LoadingButton>
                                    </Box>
                                </Tooltip>
                            </Box>
                        ),
                    }}
                </NavContent>
            )}
        </AppNav>
    );
};

export default OrderQueue;
