import React, { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import PackageDetail from './components/PackageDetail';
import PackageForm from './components/PackageForm';
import PackageList from './components/PackageList';

const Packages = (): ReactElement => {
    return (
        <Routes>
            <Route index element={<PackageList />} />
            <Route path={':id'} element={<PackageDetail />} />
            <Route path={':id/edit'} element={<PackageForm />} />
            <Route path={'create'} element={<PackageForm />} />
        </Routes>
    );
};

export default Packages;
