import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import React, { ReactElement } from 'react';
import { useOurCompany } from '../../../../graphql/Company/operations/useOurCompany';

export interface ProductionLineInputProps
    extends Omit<TextFieldProps, 'value' | 'onChange' | 'select' | 'children'> {
    value: string;
    onChange: (value: string) => void;
    plant?: string;
}

const ProductionLineInput = (props: ProductionLineInputProps): ReactElement => {
    const { value, onChange, plant, ...rest } = props;

    const { data } = useOurCompany();

    const lines = data
        ? data.ourCompany.locations
              .filter((location) => (plant ? location._id == plant : true))
              .map((location) => location.production_lines)
              .flat()
        : [];

    return (
        <TextField
            value={value}
            onChange={(e) => onChange(e.target.value)}
            select
            {...rest}
        >
            {lines.map((line) => (
                <MenuItem key={line._id} value={line._id}>
                    {line.name}
                </MenuItem>
            ))}
        </TextField>
    );
};

export default ProductionLineInput;
