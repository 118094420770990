import { gql } from '@apollo/client';
import { Profile } from '../../auth/User';
import { Pagination } from '../../utils/types/Pagination';
import { ProfileFragment } from '../Profile/useProfile';
import { getQueryHook, PaginateArg } from '../types';

export const ProfilesQuery = gql`
    ${ProfileFragment}
    query Profiles($filter: ProfileFilter!) {
        profiles(filter: $filter) {
            count
            items {
                ...ProfileFragment
            }
        }
    }
`;

export interface ProfileFilter extends PaginateArg {
    name?: string;
    blocked?: boolean;
    ids?: string[];
}

export interface ProfilesRes {
    profiles: Pagination<Profile>;
}

export interface ProfilesArgs {
    filter: ProfileFilter;
}

export const useProfiles = getQueryHook<ProfilesRes, ProfilesArgs>(
    ProfilesQuery
);
