import { gql } from '@apollo/client';
import { Pagination } from '../../../utils/types/Pagination';
import { AddressFragment } from '../../Address/Address';
import { BaseFragment } from '../../Base/Base';
import { TinyContactFragment } from '../../Contact/Contact';
import { CompanyLocationFragment } from '../../Location/Location';
import { getQueryHook } from '../../types';
import {
    Company,
    CompanyFragment,
    TinyCompany,
    TinyCompanyFragment,
} from '../Company';
import { CompanyFilter } from '../CompanyFilter';

export const CompaniesQuery = gql`
    ${BaseFragment}
    ${CompanyFragment}
    ${TinyContactFragment}
    ${CompanyLocationFragment}
    ${AddressFragment}
    query CompaniesQuery($filter: CompanyFilter!) {
        companies(filter: $filter) {
            count
            items {
                ...CompanyFragment
            }
        }
    }
`;

export const TinyCompaniesQuery = gql`
    ${TinyCompanyFragment}
    ${TinyContactFragment}
    ${CompanyLocationFragment}
    ${AddressFragment}
    query TinyCompaniesQuery($filter: CompanyFilter!) {
        companies(filter: $filter) {
            count
            items {
                ...TinyCompanyFragment
            }
        }
    }
`;

export const useCompanies = getQueryHook<
    { companies: Pagination<Company> },
    { filter: CompanyFilter }
>(CompaniesQuery);

export const useTinyCompanies = getQueryHook<
    { companies: Pagination<TinyCompany> },
    { filter: CompanyFilter }
>(TinyCompaniesQuery);
