import { gql } from '@apollo/client';
import { BaseFragment } from '../../../../Base/Base';
import { getMutationHook } from '../../../../types';
import { MiscItem, MiscItemFragment } from '../MiscItem';
import { UpdateMiscItemInput } from '../inputs/UpdateMiscItemInput';

export const UpdateMiscItemMutation = gql`
    ${BaseFragment}
    ${MiscItemFragment}
    mutation UpdateMiscItemMutation(
        $id: ObjectId!
        $data: UpdateMiscItemInput!
    ) {
        updateMiscItem(id: $id, data: $data) {
            ...MiscItemFragment
        }
    }
`;

export interface UpdateMiscItemArgs {
    id: string;
    data: UpdateMiscItemInput;
}

export interface UpdateMiscItemRes {
    updateMiscItem: MiscItem;
}

export const useMiscItemUpdate = getMutationHook<
    UpdateMiscItemRes,
    UpdateMiscItemArgs
>(UpdateMiscItemMutation);
