import { Stack, Box, Typography, useTheme } from '@mui/material';
import React, { ReactElement } from 'react';

export interface DetailListProps {
    children: Record<string, { icon: ReactElement; primary: string }>;
    pad?: boolean;
}

const DetailList = (props: DetailListProps): ReactElement => {
    const { children: schema, pad = true } = props;

    const { palette } = useTheme();

    return (
        <Stack
            direction="column"
            sx={
                pad
                    ? { paddingTop: 2, paddingBottom: 2, maxWidth: 400 }
                    : { maxWidth: 400 }
            }
        >
            {Object.keys(schema).map((key) => (
                <Box
                    key={'detail_' + key}
                    sx={{
                        display: 'flex',
                        gap: 2,
                        alignItems: 'center',
                        padding: 2,
                        borderBottom: `1px solid ${palette.divider}`,
                    }}
                >
                    <Box
                        sx={{
                            fontSize: '2.5rem',
                            display: 'flex',
                            color: palette.text.secondary,
                        }}
                    >
                        {schema[key].icon}
                    </Box>
                    <Box>
                        <Typography>{schema[key].primary}</Typography>
                        <Typography color="textSecondary" variant="body2">
                            {key}
                        </Typography>
                    </Box>
                </Box>
            ))}
        </Stack>
    );
};

export default DetailList;
