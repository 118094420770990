import {
    Fade,
    ToggleButton,
    ToggleButtonGroup,
    useTheme,
} from '@mui/material/';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
    endOfDay,
    endOfMonth,
    endOfWeek,
    startOfDay,
    startOfMonth,
    startOfWeek,
} from 'date-fns';
import React from 'react';
import AppNav from '../../components/Layout/AppNav/components';
import NavContent from '../../components/Layout/AppNav/components/NavContent';
import NavHeader from '../../components/Layout/NavHeader';
import {
    DashboardInterval,
    useDashboard,
} from '../../graphql/Dashboard/useDashboard';
import OrderingCard from './components/OrderingCard';
import ProductionCard from './components/ProductionCard';
import ReceivingCard from './components/ReceivingCard';
import ShippingCard from './components/ShippingCard';

const viewLanguage: Record<DashboardInterval, string> = {
    [DashboardInterval.Day]: 'Today',
    [DashboardInterval.Week]: 'This Week',
    [DashboardInterval.Month]: 'This Month',
};

const Dashboard: React.FC = () => {
    const fromStorage = localStorage.getItem('DASHBOARDVIEW');
    const [view, setView] = React.useState<DashboardInterval>(
        fromStorage == 'Month'
            ? DashboardInterval.Month
            : fromStorage == 'Week'
            ? DashboardInterval.Week
            : DashboardInterval.Day
    );

    React.useEffect(() => {
        localStorage.setItem('DASHBOARDVIEW', view);
    }, [view]);

    const { palette, shape } = useTheme();

    const range = {
        start:
            view == DashboardInterval.Day
                ? startOfDay(new Date())
                : view == DashboardInterval.Week
                ? startOfWeek(startOfDay(new Date()))
                : startOfMonth(startOfDay(new Date())),
        end:
            view == DashboardInterval.Day
                ? endOfDay(new Date())
                : view == DashboardInterval.Week
                ? endOfWeek(endOfDay(new Date()))
                : endOfMonth(endOfDay(new Date())),
    };

    const { data, error, loading } = useDashboard({
        variables: { range },
    });

    const cardStyles = {
        flex: 1,
        display: 'flex',
        flexFlow: 'column',
        ...shape,
        background: palette.background.paper,
        minHeight: 0,
    };

    const rowStyles = {
        flex: 1,
        display: 'flex',
        gap: 3,
        minHeight: 0,
    };

    return (
        <AppNav error={error} loading={loading}>
            <NavContent>
                {{
                    header: (
                        <NavHeader>
                            <Typography
                                sx={{ fontSize: '2rem' }}
                                variant="crisp"
                            >{`${viewLanguage[view]} at Little Dutch Boy`}</Typography>
                            <ToggleButtonGroup
                                value={view}
                                exclusive
                                onChange={(e, val) => {
                                    setView(val as DashboardInterval);
                                }}
                            >
                                <ToggleButton
                                    sx={{
                                        textTransform: 'none',
                                        paddingTop: 1,
                                        paddingBottom: 1,
                                    }}
                                    value={DashboardInterval.Day}
                                >
                                    Today
                                </ToggleButton>
                                <ToggleButton
                                    sx={{
                                        textTransform: 'none',
                                        paddingTop: 1,
                                        paddingBottom: 1,
                                    }}
                                    value={DashboardInterval.Week}
                                >
                                    This Week
                                </ToggleButton>
                                <ToggleButton
                                    sx={{
                                        textTransform: 'none',
                                        paddingTop: 1,
                                        paddingBottom: 1,
                                    }}
                                    value={DashboardInterval.Month}
                                >
                                    This Month
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </NavHeader>
                    ),
                    content: (
                        <Box
                            sx={{
                                height: 'calc(100% - 24px)',
                                display: 'flex',
                                flexFlow: 'column',
                                gap: 3,
                            }}
                        >
                            <Box sx={{ ...rowStyles }}>
                                <OrderingCard
                                    loading={loading}
                                    data={data ? data.dashboard.orders : []}
                                    styles={cardStyles}
                                />
                                <ReceivingCard
                                    loading={loading}
                                    data={data ? data.dashboard.receipts : []}
                                    styles={cardStyles}
                                />
                                <ProductionCard
                                    loading={loading}
                                    data={data ? data.dashboard.batches : []}
                                    styles={cardStyles}
                                />
                                <ShippingCard
                                    loading={loading}
                                    data={data ? data.dashboard.shipments : []}
                                    styles={cardStyles}
                                />
                            </Box>
                        </Box>
                    ),
                }}
            </NavContent>
        </AppNav>
    );
};

export default Dashboard;
