import { gql } from '@apollo/client';
import { Permission } from '../../auth/Permission';
import { Profile } from '../../auth/User';
import { Pagination } from '../../utils/types/Pagination';
import { Base, BaseFilter, BaseFragment } from '../Base/Base';
import { getQueryHook } from '../types';

export const TeamFragment = gql`
    fragment TeamFragment on Team {
        ...BaseFragment
        name
        description
        members {
            user_id
            name
            email
            given_name
            family_name
            picture
        }
        permissions
        locations {
            _id
            label
        }
    }
`;

export const TeamsQuery = gql`
    ${BaseFragment}
    ${TeamFragment}
    query TeamsQuery($filter: TeamFilter!) {
        teams(filter: $filter) {
            count
            items {
                ...TeamFragment
            }
        }
    }
`;

export interface Team extends Base {
    name: string;
    description?: string | null;
    members: Profile[];
    permissions: Permission[];
    locations: { _id: string; label: string }[];
}

export interface TeamFilter extends BaseFilter {
    name?: string;
}

export interface TeamsArgs {
    filter: TeamFilter;
}

export interface TeamsRes {
    teams: Pagination<Team>;
}

export const useTeams = getQueryHook<TeamsRes, TeamsArgs>(TeamsQuery);
