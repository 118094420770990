import { Box, useTheme } from '@mui/material';
import React, { ReactElement } from 'react';
import { Order } from '../../../../../../../../graphql/Order/Order';
import OrderBody from './components/OrderBody';
import OrderHeader from './components/OrderHeader';

export interface OrderRenderProps {
    children: Order;
}

const OrderRender = (props: OrderRenderProps): ReactElement => {
    const { children: order } = props;

    const { palette, shape } = useTheme();

    const [locked, setLocked] = React.useState(true);

    return (
        <Box sx={{ background: palette.tonal, ...shape, maxWidth: 900 }}>
            <OrderHeader setLocked={(l) => setLocked(l)} locked={locked}>
                {order}
            </OrderHeader>
            <OrderBody>{order}</OrderBody>
        </Box>
    );
};

export default OrderRender;
