import { Box, Tooltip, useTheme } from '@mui/material';
import React, { ReactElement } from 'react';
import { MdCheck, MdDelete } from 'react-icons/md';
import CarefullButton from '../../../../../../../../../../../../../../components/inputs/Buttons/CarefulButton';
import SuccessButton from '../../../../../../../../../../../../../../components/inputs/Buttons/SuccessButton';
import CodeField from '../../../../../../../../../../../../../../components/inputs/FieldInputs/CodeField';
import CompanyField from '../../../../../../../../../../../../../../components/inputs/FieldInputs/CompanyField';
import ResponsiveDialog from '../../../../../../../../../../../../../../components/Layout/ResponsiveDialog';
import { CodeType } from '../../../../../../../../../../../../../../graphql/Code/useCode';
import {
    UpdateItineraryArgs,
    UpdateItineraryRes,
    useItineraryUpdate,
} from '../../../../../../../../../../../../../../graphql/Itinerary/operations/useItineraryUpdate';
import { OperationResult } from '../../../../../../../../../../../../../../utils/types/OperationResult';

export interface ItineraryFormProps {
    state: null | UpdateItineraryArgs;
    setState: (value: null | UpdateItineraryArgs) => void;
}

const ItineraryForm = (props: ItineraryFormProps): ReactElement => {
    const { state, setState } = props;

    const { palette } = useTheme();

    const [result, setResult] =
        React.useState<null | OperationResult<UpdateItineraryRes>>(null);

    const handleClose = () => {
        setState(null);
        setResult(null);
    };

    const [update, { loading }] = useItineraryUpdate({
        variables: state ? state : undefined,
        onCompleted: () => handleClose(),
    });

    const handleChange = (data: Partial<UpdateItineraryArgs['data']>) => {
        if (state) {
            setState({ ...state, data: { ...state.data, ...data } });
        }
    };

    const getHoldup = (): string | null => {
        return null;
    };

    const holdup = getHoldup();

    return (
        <ResponsiveDialog
            onClose={handleClose}
            open={Boolean(state)}
            maxWidth="xs"
            fullWidth
            padding={0}
        >
            <Box sx={{ background: palette.tonal }}>
                <Box
                    sx={{
                        background: palette.background.paper,
                        padding: 2,
                        display: 'flex',
                        flexFlow: 'column',
                        gap: 2,
                    }}
                >
                    <CodeField
                        label="Pro #"
                        type={CodeType.Itin}
                        value={state ? state.data.code || '' : ''}
                        onChange={(code) => handleChange({ code })}
                    />
                    <CompanyField
                        label="Carrier"
                        multiple={false}
                        value={state ? state.data.carrier || '' : ''}
                        onChange={(carrier) => handleChange({ carrier })}
                    />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: 2,
                    }}
                >
                    <Box />
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <CarefullButton
                            loading={loading}
                            startIcon={<MdDelete />}
                            onClick={() => {
                                if (state) {
                                    update({
                                        variables: {
                                            ...state,
                                            data: {
                                                ...state.data,
                                                deleted: true,
                                            },
                                        },
                                        onCompleted: () => handleClose(),
                                    });
                                }
                            }}
                        >
                            Delete Truck
                        </CarefullButton>
                        <Tooltip arrow title={holdup || ''}>
                            <Box>
                                <SuccessButton
                                    disabled={Boolean(holdup)}
                                    onClick={() => {
                                        if (state) {
                                            update();
                                        }
                                    }}
                                    endIcon={<MdCheck />}
                                    loading={loading}
                                    success={
                                        result && result.success
                                            ? 'Truck saved!'
                                            : null
                                    }
                                    onSuccess={() => {
                                        handleClose();
                                    }}
                                >
                                    Save
                                </SuccessButton>
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
        </ResponsiveDialog>
    );
};

export default ItineraryForm;
