import { DateRange } from '../../utils/types/DateRange';
import { BaseFilter } from '../Base/Base';

export enum FulfillmentEventType {
    Shipping = 'Shipping',
    Receiving = 'Receiving',
}

export interface FulfillmentEventFilter extends BaseFilter {
    plant: string;
    type: FulfillmentEventType;
    date: DateRange;
}
