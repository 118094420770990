import {
    Box,
    Button,
    ButtonGroup,
    IconButton,
    TextField,
    Typography,
    useTheme,
} from '@mui/material/';
import React, { ReactElement, ReactNode } from 'react';
import { RiShieldCheckFill, RiErrorWarningFill } from 'react-icons/ri';
import { BsFlagFill } from 'react-icons/bs';
import {
    Verification,
    VerificationStatus,
} from '../../../../graphql/Verification/Verification';
import { VerificationInput } from '../../../../graphql/Verification/VerificationInput';
import FormRow from '../../../Layout/FormRow';
import { DesktopDatePicker, LoadingButton } from '@mui/lab';
import { MdCheck, MdClear, MdEdit } from 'react-icons/md';
import { format } from 'date-fns';
import { dateFormats } from '../../../../utils/dateFormats';
import useMyProfile from '../../../../auth/useMyProfile';
import { UserRole } from '../../../../auth/UserRole';
import { getUiPermissions, UiPermission } from '../../../../auth/UiPermission';
import AuthGuy from '../../../../auth/components/AuthGuy';

export interface VerificationFormProps {
    verification: Verification | null;
    loading: boolean;
    error: Error | null;
    onSave: (verification: VerificationInput | null) => void;
    entity: string;
}

const VerificationForm = (props: VerificationFormProps): ReactElement => {
    const { verification, entity, loading, error, onSave } = props;

    const { permissions, roles } = useMyProfile();

    const [state, setState] = React.useState<VerificationInput | null>(null);

    const { palette } = useTheme();

    // return form
    if (state)
        return (
            <Box>
                <FormRow>
                    <ButtonGroup
                        fullWidth
                        color="inherit"
                        variant="contained"
                        size="large"
                    >
                        <Button
                            onClick={() => {
                                if (state)
                                    setState({
                                        ...state,
                                        status: VerificationStatus.Approved,
                                    });
                            }}
                            startIcon={
                                <RiShieldCheckFill
                                    style={
                                        state &&
                                        state.status ===
                                            VerificationStatus.Approved
                                            ? { color: palette.success.main }
                                            : undefined
                                    }
                                />
                            }
                            sx={{
                                background: palette.background.paper,
                                color:
                                    state &&
                                    state.status === VerificationStatus.Approved
                                        ? palette.text.primary
                                        : palette.text.secondary,
                            }}
                        >
                            Verified
                        </Button>
                        <Button
                            onClick={() => {
                                if (state)
                                    setState({
                                        ...state,
                                        status: VerificationStatus.Flagged,
                                    });
                            }}
                            startIcon={
                                <BsFlagFill
                                    style={
                                        state &&
                                        state.status ===
                                            VerificationStatus.Flagged
                                            ? { color: palette.primary.main }
                                            : undefined
                                    }
                                />
                            }
                            sx={{
                                background: palette.background.paper,
                                color:
                                    state &&
                                    state.status === VerificationStatus.Flagged
                                        ? palette.text.primary
                                        : palette.text.secondary,
                            }}
                        >
                            Flagged
                        </Button>
                        <Button
                            onClick={() => {
                                if (state)
                                    setState({
                                        ...state,
                                        status: VerificationStatus.Warning,
                                    });
                            }}
                            startIcon={
                                <RiErrorWarningFill
                                    style={
                                        state &&
                                        state.status ===
                                            VerificationStatus.Warning
                                            ? { color: palette.warning.main }
                                            : undefined
                                    }
                                />
                            }
                            sx={{
                                background: palette.background.paper,
                                color:
                                    state &&
                                    state.status === VerificationStatus.Warning
                                        ? palette.text.primary
                                        : palette.text.secondary,
                            }}
                        >
                            Warning
                        </Button>
                    </ButtonGroup>
                </FormRow>
                <FormRow>
                    <DesktopDatePicker
                        label="Date"
                        value={state ? state.date : null}
                        onChange={(date) => {
                            if (date)
                                setState({
                                    ...state,
                                    date,
                                });
                        }}
                        inputFormat="MM/dd/yyyy"
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                InputProps={{
                                    ...params.InputProps,
                                    fullWidth: true,
                                }}
                            />
                        )}
                    />
                </FormRow>
                <FormRow>
                    <TextField
                        multiline
                        maxRows={5}
                        rows={3}
                        label="Notes"
                        fullWidth
                        value={state ? state.note : ''}
                        onChange={(e) => {
                            if (state) {
                                setState({ ...state, note: e.target.value });
                            }
                        }}
                    />
                </FormRow>
                <Box sx={{ display: 'flex', gap: 1 }}>
                    <Button
                        endIcon={<MdClear />}
                        variant="outlined"
                        color="inherit"
                        onClick={() => setState(null)}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        endIcon={<MdCheck />}
                        variant="contained"
                        loading={loading}
                        onClick={() => {
                            if (state) {
                                const copy = { ...state };
                                onSave(copy);
                                setState(null);
                            }
                        }}
                    >
                        Save
                    </LoadingButton>
                </Box>
            </Box>
        );

    // return prompt
    if (!verification)
        return (
            <Box>
                <Typography>{`This ${entity} has not been verified.`}</Typography>
                <Box p={1} />
                <AuthGuy
                    challenge={{
                        permission: UiPermission.WarehouseVerification,
                    }}
                >
                    <Button
                        onClick={() =>
                            setState({
                                status: VerificationStatus.Approved,
                                note: '',
                                date: new Date(),
                            })
                        }
                        startIcon={<RiShieldCheckFill />}
                    >
                        Verify now
                    </Button>
                </AuthGuy>
            </Box>
        );

    const previewData: Record<VerificationStatus, [ReactNode, string]> = {
        [VerificationStatus.Approved]: [
            <RiShieldCheckFill
                key={'icon_Verified'}
                style={{ fontSize: '1.75rem', color: palette.success.main }}
            />,
            'Verified',
        ],
        [VerificationStatus.Flagged]: [
            <BsFlagFill
                key={'icon_Flagged'}
                style={{ fontSize: '1.75rem', color: palette.primary.main }}
            />,
            'Flagged',
        ],
        [VerificationStatus.Warning]: [
            <RiErrorWarningFill
                key={'icon_Warning'}
                style={{ fontSize: '1.75rem', color: palette.warning.main }}
            />,
            'Warning',
        ],
    };

    // return preview
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box sx={{ display: 'flex' }}>
                {previewData[verification.status][0]}
            </Box>
            <Box>
                <Typography>{previewData[verification.status][1]}</Typography>
                <Typography variant="body2" color="textSecondary">{`${
                    verification.verified_by.name
                } - ${format(
                    new Date(verification.date),
                    dateFormats.condensedDate
                )}`}</Typography>
                <Typography variant="body2" color="textSecondary">
                    {verification.note}
                </Typography>
            </Box>
            <Box sx={{ flex: 1 }} />
            <AuthGuy
                challenge={{ permission: UiPermission.WarehouseVerification }}
            >
                <IconButton
                    onClick={() =>
                        setState({
                            date: verification.date,
                            note: verification.note,
                            status: verification.status,
                        })
                    }
                >
                    <MdEdit />
                </IconButton>
            </AuthGuy>
            <AuthGuy
                challenge={{ permission: UiPermission.WarehouseVerification }}
            >
                <IconButton onClick={() => onSave(null)}>
                    <MdClear />
                </IconButton>
            </AuthGuy>
        </Box>
    );
};

export default VerificationForm;
