import { gql } from '@apollo/client';
import { Profile } from '../../auth/User';
import { Bol } from '../Bol/Bol';
import { ItemContent } from '../Content/ItemContent/ItemContent';
import { Itinerary } from '../Itinerary/Itinerary';
import { Order } from '../Order/Order';
import { Ref } from '../types';
import { Verification } from '../Verification/Verification';

export interface FulfillmentEventChild {
    _id: string;
    date_created: Date;
    created_by: Profile;
    verification: Verification | null;
}

export interface FulfillmentEvent {
    order: Ref<Order>;
    itinerary: Ref<Itinerary>;
    bol: Ref<Bol>;
    po: string | null;
    receipts: FulfillmentEventChild[];
    shipments: FulfillmentEventChild[];
    date: Date;
    contents: ItemContent[];
}

export const FulfillmentEventFragment = gql`
    fragment FulfillmentEventFragment on FulfillmentEvent {
        order
        itinerary
        bol
        po
        receipts {
            _id
            date_created
            created_by {
                name
                picture
            }
            verification {
                ...VerificationFragment
            }
        }
        shipments {
            _id
            date_created
            created_by {
                name
                picture
            }
            verification {
                ...VerificationFragment
            }
        }
        date
        contents {
            ...ItemContentFragment
        }
    }
`;
