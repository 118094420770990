import { List, useTheme } from '@mui/material/';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import React, { ReactElement } from 'react';
import SearchInput from '../../../../../../components/inputs/FieldInputs/SearchInput';
import { TinyLot } from '../../../../../../graphql/Lot/Lot';
import { LotFilter } from '../../../../../../graphql/Lot/LotFilter';
import { useTinyLots } from '../../../../../../graphql/Lot/useLots';

const NodeGraphSearch = (props: {
    children: {
        setLot: (lot: TinyLot) => void;
        lots: TinyLot[];
        search: string;
        setSearch: (val: string) => void;
        loading: boolean;
    };
}): ReactElement => {
    const {
        children: { setLot, setSearch, lots, search, loading },
    } = props;
    const { palette, shape } = useTheme();

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box
                sx={{
                    ...shape,
                    overflow: 'hidden',
                    flex: 1,
                    maxWidth: 400,
                }}
            >
                <Box
                    sx={{
                        background: palette.background.paper,
                        p: 2,
                    }}
                >
                    <SearchInput
                        placeholder="Lot Number"
                        variant="standard"
                        InputProps={{ disableUnderline: true }}
                        value={search}
                        onChange={(e) => {
                            setSearch(e || '');
                        }}
                    />
                </Box>
                <Box>
                    <Collapse in={Boolean(search && !loading)}>
                        <Box
                            sx={{
                                background: palette.tonal,
                                maxHeight: 320,
                                overflow: 'auto',
                            }}
                        >
                            <List>
                                {lots.map((lot) => (
                                    <ListItemButton
                                        divider
                                        key={'op_' + lot._id}
                                        onClick={() => setLot(lot)}
                                    >
                                        <ListItemText
                                            primary={lot.code}
                                            secondary={lot.item.name}
                                        />
                                    </ListItemButton>
                                ))}
                            </List>
                        </Box>
                    </Collapse>
                </Box>
            </Box>
        </Box>
    );
};

export default NodeGraphSearch;
