import {
    Avatar,
    Box,
    Button,
    ButtonBase,
    Fab,
    IconButton,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material/';
import React, { ReactElement } from 'react';
import { MdAdd, MdArrowForward, MdEdit } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import AppNav from '../../../../components/Layout/AppNav/components';
import NavContent from '../../../../components/Layout/AppNav/components/NavContent';
import NavHeader from '../../../../components/Layout/NavHeader';
import TabFade from '../../../../components/Layout/TabFade';
import { useOurCompany } from '../../../../graphql/Company/operations/useOurCompany';
import { useMixingCards } from '../../../../graphql/MixingCard/operations/useMixingCards';

const MixingSchedule = (): ReactElement => {
    const { data: companyData, loading: companyLoading } = useOurCompany();

    const nav = useNavigate();

    const { palette, shape } = useTheme();

    const { data, error, loading } = useMixingCards({
        variables: {
            filter: { skip: 0, take: 150 },
        },
        fetchPolicy: 'network-only',
        pollInterval: 5000,
    });

    const cards = data ? data.mixingCardList.items : [];

    return (
        <AppNav loading={companyLoading || loading} error={error}>
            {companyData && (
                <NavContent>
                    {{
                        header: (
                            <NavHeader>
                                <Box>
                                    <Typography variant="crisp">
                                        Mixing Schedule
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexFlow: 'column',
                                        alignItems: 'flex-end',
                                    }}
                                >
                                    <Fab
                                        variant="extended"
                                        onClick={() =>
                                            window.open(
                                                'https://mixing.ldbbakery.com',
                                                '_blank'
                                            )
                                        }
                                    >
                                        Open Mixing App
                                        <MdArrowForward
                                            style={{
                                                fontSize: '1.25rem',
                                                marginLeft: 5,
                                            }}
                                        />
                                    </Fab>
                                </Box>
                            </NavHeader>
                        ),
                        content: (
                            <TabFade>
                                {{
                                    ...companyData.ourCompany.locations.reduce(
                                        (stack, plant) => {
                                            return {
                                                ...stack,
                                                [plant.label]: (
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexFlow: 'column',
                                                            gap: 2,
                                                            paddingTop: 2,
                                                        }}
                                                    >
                                                        {cards
                                                            .filter(
                                                                (card) =>
                                                                    card
                                                                        .location
                                                                        ._id ==
                                                                    plant._id
                                                            )
                                                            .map(
                                                                (
                                                                    card,
                                                                    index
                                                                ) => (
                                                                    <Box
                                                                        key={
                                                                            card._id
                                                                        }
                                                                        sx={{
                                                                            maxWidth: 500,
                                                                            background:
                                                                                palette.tonal,
                                                                            ...shape,
                                                                            overflow:
                                                                                'hidden',
                                                                        }}
                                                                    >
                                                                        <Box
                                                                            sx={{
                                                                                background:
                                                                                    palette
                                                                                        .background
                                                                                        .paper,
                                                                                p: 2,
                                                                                display:
                                                                                    'flex',
                                                                                gap: 2,
                                                                                alignItems:
                                                                                    'center',
                                                                            }}
                                                                        >
                                                                            <Avatar
                                                                                src={
                                                                                    card
                                                                                        .mixer
                                                                                        .picture
                                                                                }
                                                                                alt={
                                                                                    card
                                                                                        .mixer
                                                                                        .name
                                                                                }
                                                                            />
                                                                            <Box>
                                                                                <Typography>
                                                                                    {
                                                                                        card
                                                                                            .mixer
                                                                                            .name
                                                                                    }
                                                                                </Typography>
                                                                                <Typography
                                                                                    variant="body2"
                                                                                    color="text.secondary"
                                                                                >
                                                                                    {`${card.location.label} - ${card.production_line.name}`}
                                                                                </Typography>
                                                                            </Box>
                                                                            <Box
                                                                                sx={{
                                                                                    flex: 1,
                                                                                }}
                                                                            />
                                                                            <Box>
                                                                                <Tooltip
                                                                                    arrow
                                                                                    title="Edit mixing card"
                                                                                >
                                                                                    <IconButton
                                                                                        onClick={() =>
                                                                                            nav(
                                                                                                `/production/schedule/card/${card._id}`
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <MdEdit />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            </Box>
                                                                        </Box>
                                                                        <Box>
                                                                            {card.lines.map(
                                                                                (
                                                                                    line,
                                                                                    lineIndex
                                                                                ) => (
                                                                                    <ButtonBase
                                                                                        key={
                                                                                            'line_' +
                                                                                            lineIndex
                                                                                        }
                                                                                        sx={{
                                                                                            p: 2,
                                                                                            width: '100%',
                                                                                            display:
                                                                                                'flex',
                                                                                            flexFlow:
                                                                                                'row',
                                                                                            textAlign:
                                                                                                'left',
                                                                                            justifyContent:
                                                                                                'space-between',
                                                                                            borderBottom:
                                                                                                lineIndex ==
                                                                                                card
                                                                                                    .lines
                                                                                                    .length -
                                                                                                    1
                                                                                                    ? undefined
                                                                                                    : `1px solid ${palette.divider}`,
                                                                                        }}
                                                                                    >
                                                                                        <Box>
                                                                                            <Typography>
                                                                                                {
                                                                                                    line
                                                                                                        .recipe
                                                                                                        .name
                                                                                                }
                                                                                            </Typography>
                                                                                            <Typography
                                                                                                variant="body2"
                                                                                                color="text.secondary"
                                                                                            >
                                                                                                {
                                                                                                    line
                                                                                                        .recipe
                                                                                                        .item
                                                                                                        .name
                                                                                                }
                                                                                            </Typography>
                                                                                        </Box>
                                                                                        <Box>
                                                                                            {lineIndex ==
                                                                                            0 ? (
                                                                                                <Typography>
                                                                                                    {line.qty ==
                                                                                                    null
                                                                                                        ? '(Going until stopped)'
                                                                                                        : `(${
                                                                                                              line.qty
                                                                                                          } batch${
                                                                                                              line.qty ==
                                                                                                              1
                                                                                                                  ? ''
                                                                                                                  : 'es'
                                                                                                          } left)`}
                                                                                                </Typography>
                                                                                            ) : (
                                                                                                <Typography>
                                                                                                    {line.qty ==
                                                                                                    null
                                                                                                        ? 'Until stopped'
                                                                                                        : `${
                                                                                                              line.qty
                                                                                                          } batch${
                                                                                                              line.qty ==
                                                                                                              1
                                                                                                                  ? ''
                                                                                                                  : 'es'
                                                                                                          }`}
                                                                                                </Typography>
                                                                                            )}
                                                                                        </Box>
                                                                                    </ButtonBase>
                                                                                )
                                                                            )}
                                                                        </Box>
                                                                    </Box>
                                                                )
                                                            )}
                                                        <Box
                                                            sx={{
                                                                position:
                                                                    'absolute',
                                                                bottom: '40px',
                                                                right: '40px',
                                                            }}
                                                        >
                                                            <Button
                                                                size="large"
                                                                startIcon={
                                                                    <MdAdd />
                                                                }
                                                                onClick={() =>
                                                                    nav(
                                                                        `${plant._id}/create`
                                                                    )
                                                                }
                                                            >
                                                                New Mixing Card
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                ),
                                            };
                                        },
                                        {} as Record<string, ReactElement>
                                    ),
                                }}
                            </TabFade>
                        ),
                    }}
                </NavContent>
            )}
        </AppNav>
    );
};

export default MixingSchedule;
