import ButtonBase, { ButtonBaseProps } from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import React, { ReactElement } from 'react';
import { useRecipe } from '../../../graphql/Recipe/operations/useRecipe';
import { Recipe } from '../../../graphql/Recipe/Recipe';

export interface RecipeSelectionButtonProps
    extends Omit<ButtonBaseProps, 'children' | 'value'> {
    value: string | null;
}

const RecipeSelectionButton = (
    props: RecipeSelectionButtonProps
): ReactElement => {
    const { value: id, ...rest } = props;

    const [recipe, setRecipe] = React.useState<Recipe | null>(null);

    useRecipe({
        variables: {
            id: id || '',
        },
        skip: !id,
        onCompleted: ({ recipe: r }) => setRecipe(r),
    });

    return (
        <ButtonBase
            {...rest}
            sx={{
                ...rest.sx,
                flexFlow: 'column',
                textAlign: 'left',
                alignItems: 'flex-start',
            }}
        >
            <Typography color={id && recipe ? 'text.primary' : 'text.disabled'}>
                {id && recipe ? recipe.name : 'Select a recipe'}
            </Typography>
            {recipe && id && (
                <Typography variant="caption" color="text.secondary">
                    {recipe.item.name}
                </Typography>
            )}
        </ButtonBase>
    );
};

export default RecipeSelectionButton;
