import { Box, Button, Skeleton, Typography, useTheme } from '@mui/material';
import React, { ReactElement } from 'react';
import { MdBadge, MdEdit } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import BaseDetails from '../../../../../../components/baseComponents/BaseDetails';
import AppNav from '../../../../../../components/Layout/AppNav/components';
import NavContent from '../../../../../../components/Layout/AppNav/components/NavContent';
import NavHeader from '../../../../../../components/Layout/NavHeader';
import TabFade from '../../../../../../components/Layout/TabFade';
import { useMiscItem } from '../../../../../../graphql/Item/extensions/MiscItem/operations/useMiscItem';

const MiscItemDetail = (): ReactElement => {
    const { id, contact, location } = useParams();
    const nav = useNavigate();

    const { palette } = useTheme();

    const { data, error, loading } = useMiscItem({
        variables: { id: id || '' },
        fetchPolicy: 'network-only',
    });

    const miscitem = data ? data.miscitem : null;

    return (
        <AppNav error={error} loading={loading}>
            <NavContent>
                {{
                    header: (
                        <NavHeader back={['Misc Items', '/library/miscitems']}>
                            {miscitem ? (
                                <Box>
                                    <Typography variant="crisp">
                                        {miscitem.name}
                                    </Typography>
                                    <Typography
                                        sx={{ paddingTop: 1 }}
                                        color="textSecondary"
                                    >
                                        Misc Item
                                    </Typography>
                                </Box>
                            ) : (
                                <Skeleton width={400} height={62} />
                            )}
                            <Box />
                        </NavHeader>
                    ),
                    content: (
                        <TabFade>
                            {{
                                Details: miscitem ? (
                                    <Box>
                                        <BaseDetails
                                            extension={{
                                                Name: {
                                                    icon: <MdBadge />,
                                                    primary: miscitem.name,
                                                },
                                            }}
                                        >
                                            {miscitem}
                                        </BaseDetails>
                                        <Button
                                            onClick={() => nav('edit')}
                                            endIcon={<MdEdit />}
                                        >
                                            Edit
                                        </Button>
                                    </Box>
                                ) : (
                                    <Box />
                                ),
                            }}
                        </TabFade>
                    ),
                }}
            </NavContent>
        </AppNav>
    );
};

export default MiscItemDetail;
