import { Box, Button, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { MdAdd } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import SmartTable from '../../../../../../components/display/SmartTable';
import SearchInput from '../../../../../../components/inputs/FieldInputs/SearchInput';
import AppNav from '../../../../../../components/Layout/AppNav/components';
import NavContent from '../../../../../../components/Layout/AppNav/components/NavContent';
import NavHeader from '../../../../../../components/Layout/NavHeader';
import { useQualityCheckForms } from '../../../../../../graphql/Form/extensions/QualityCheckForm/operations/useQualityCheckForms';
import { QualityCheckCategory } from '../../../../../../graphql/Form/extensions/QualityCheckForm/QualityCheckCategory';
import { QualityCheckFormFilter } from '../../../../../../graphql/Form/extensions/QualityCheckForm/QualityCheckFormFilter';
import QualityCheckPreview from '../QualityCheckPreview';

const QualityCheckList = (): ReactElement => {
    const nav = useNavigate();

    const [filter, setFilter] = React.useState<QualityCheckFormFilter>({
        skip: 0,
        take: 50,
    });

    const { data, error, loading } = useQualityCheckForms({
        variables: { filter },
        fetchPolicy: 'network-only',
    });

    const count = data ? data.qualityCheckForms.count : 0;
    const qualitychecks = data ? data.qualityCheckForms.items : [];

    return (
        <AppNav error={error} loading={loading}>
            <NavContent>
                {{
                    header: (
                        <NavHeader>
                            <Typography variant="crisp">
                                Quality Checks
                            </Typography>
                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <QualityCheckPreview />
                                <Button
                                    onClick={() => nav('create')}
                                    startIcon={<MdAdd />}
                                >
                                    New Quality Check
                                </Button>
                            </Box>
                        </NavHeader>
                    ),
                    content: (
                        <SmartTable
                            data={qualitychecks}
                            getProps={(d) => ({
                                id: d._id,
                                onClick: () => nav(d._id),
                            })}
                            pagination={{
                                count,
                                filter,
                                setFilter: (f) =>
                                    setFilter({ ...filter, ...f }),
                            }}
                            controls={{
                                Items: (
                                    <SearchInput
                                        InputProps={{ disableUnderline: true }}
                                        variant="standard"
                                        placeholder="Items"
                                        value={filter.item_name || ''}
                                        onChange={(val) =>
                                            setFilter({
                                                ...filter,
                                                item_name: val || undefined,
                                            })
                                        }
                                    />
                                ),
                                ['Asked During']: (
                                    <SearchInput
                                        InputProps={{ disableUnderline: true }}
                                        variant="standard"
                                        placeholder="Asked During"
                                        value={filter.category || ''}
                                        onChange={(val) =>
                                            setFilter({
                                                ...filter,
                                                category: val || undefined,
                                            })
                                        }
                                    />
                                ),
                                Questions: (
                                    <SearchInput
                                        InputProps={{ disableUnderline: true }}
                                        variant="standard"
                                        placeholder="Questions"
                                        value={filter.question || ''}
                                        onChange={(val) =>
                                            setFilter({
                                                ...filter,
                                                question: val || undefined,
                                            })
                                        }
                                    />
                                ),
                            }}
                        >
                            {{
                                ['Asked During']: (c) =>
                                    c.category === QualityCheckCategory.Receipt
                                        ? 'Receiving'
                                        : c.category ==
                                          QualityCheckCategory.Shipment
                                        ? 'Shipping'
                                        : 'Production',
                                Items: (c) =>
                                    c.items
                                        ? c.items
                                              .map((it) => it.name)
                                              .join(', ')
                                        : 'All Items',
                                Questions: (c) => (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexFlow: 'column',
                                        }}
                                    >
                                        {c.fields.map((field, i) => (
                                            <Box key={'field_' + i}>
                                                <Typography variant="body2">
                                                    {field.question +
                                                        (field.required
                                                            ? ' *'
                                                            : '')}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Box>
                                ),
                            }}
                        </SmartTable>
                    ),
                }}
            </NavContent>
        </AppNav>
    );
};

export default QualityCheckList;
