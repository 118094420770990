import { gql } from '@apollo/client';
import { Profile } from '../../auth/User';
import { Bol } from '../Bol/Bol';
import { TinyCompany } from '../Company/Company';
import { ItemContent } from '../Content/ItemContent/ItemContent';
import { Itinerary } from '../Itinerary/Itinerary';
import { Order } from '../Order/Order';
import { Ref } from '../types';
import { Verification } from '../Verification/Verification';

export interface FulfillmentRowFulfillment {
    _id: string;
    created_by: Profile;
    date_created: Date;
    verification: Verification | null;
}

export interface FulfillmentRow {
    order: Ref<Order>;
    itinerary: Ref<Itinerary>;
    bol: Ref<Bol>;
    po: string;
    bol_code: string | null;
    customer: { _id: string; name: string } | null;
    vendor: { _id: string; name: string } | null;
    receipts: FulfillmentRowFulfillment[];
    shipments: FulfillmentRowFulfillment[];
    date: Date;
    contents: ItemContent[];
}

export const FulfillmentRowFragment = gql`
    fragment FulfillmentRowFragment on FulfillmentRow {
        order
        itinerary
        bol
        po
        bol_code
        customer {
            _id
            name
        }
        vendor {
            _id
            name
        }
        receipts {
            _id
            verification {
                ...VerificationFragment
            }
            date_created
            created_by {
                email
                name
                username
                user_id
                picture
                given_name
                family_name
                roles
            }
        }
        shipments {
            _id
            verification {
                ...VerificationFragment
            }
            date_created
            created_by {
                email
                name
                username
                user_id
                picture
                given_name
                family_name
                roles
            }
        }
        date
        contents {
            item {
                ...TinyItemFragment
            }
        }
    }
`;
