import {
    CircularProgress,
    InputAdornment,
    MenuItem,
    TextField,
    TextFieldProps,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { useOurCompany } from '../../../../graphql/Company/operations/useOurCompany';
import { CompanyLocation } from '../../../../graphql/Location/Location';
import SelectField from '../SelectField';

type BaseProps = Omit<
    TextFieldProps,
    'value' | 'onChange' | 'select' | 'children' | 'multiple'
>;

export type OurLocationFieldProps<M extends boolean | undefined> =
    M extends true
        ? BaseProps & {
              multiple: true;
              value: string[];
              onChange: (location: CompanyLocation[]) => void;
          }
        : BaseProps & {
              multiple: false | undefined;
              value: string;
              onChange: (location: CompanyLocation | null) => void;
          };

const OurLocationField = <M extends boolean | undefined>(
    props: OurLocationFieldProps<M>
): ReactElement => {
    const { multiple, value, onChange, ...rest } = props;

    const { data, error, loading } = useOurCompany();

    const company = data ? data.ourCompany : null;

    if (multiple === true)
        return (
            <SelectField
                default_open
                label={rest.label ? rest.label.toString() : 'Locations'}
                multiple
                value={value}
                onChange={(ids) => {
                    if (company) {
                        const filtered = company.locations.filter((l) =>
                            ids.includes(l._id)
                        );

                        onChange(filtered);
                    }
                }}
                data={company ? company.locations : []}
                getProps={(l) => ({ id: l._id, primary: l.label })}
            />
        );
    else
        return (
            <TextField
                {...rest}
                select
                label={rest.label}
                variant={rest.variant || 'filled'}
                helperText={error ? error.message : rest.helperText}
                disabled={error ? true : rest.disabled}
                value={value}
                InputProps={{
                    ...rest.InputProps,
                    startAdornment: loading ? (
                        <InputAdornment position="start">
                            <CircularProgress size={20} />
                        </InputAdornment>
                    ) : (
                        rest.InputProps?.startAdornment
                    ),
                }}
                onChange={(e) => {
                    if (company) {
                        const location = company.locations.find(
                            (l) => l._id === e.target.value
                        );

                        onChange(location || null);
                    }
                }}
            >
                {(company ? company.locations : []).map((loc) => (
                    <MenuItem value={loc._id} key={'loc_' + loc._id}>
                        {loc.label}
                    </MenuItem>
                ))}
            </TextField>
        );
};

export default OurLocationField;
