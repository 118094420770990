import { useAuth0 } from '@auth0/auth0-react';
import {
    Avatar,
    Box,
    Button,
    Collapse,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import React, { ReactElement } from 'react';
import {
    MdChevronRight,
    MdDarkMode,
    MdExitToApp,
    MdLightMode,
    MdPerson,
} from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useClickState } from '../../../../../../../hooks/useClickState';
import { useThemeContext } from '../../../../../../../providers/AppThemeProvider';
import Logo from '../../../../../../display/Logo';
import Anima from '../../../../../Anima';
import { useNavRoutes } from '../../../../routes';
import NavChip from './components/NavChip';

const Sidebar = (): ReactElement => {
    const { palette, breakpoints, transitions } = useTheme();
    const small = useMediaQuery(breakpoints.down('sm'));
    const { user } = useAuth0();
    const { mode, setMode } = useThemeContext();
    const nav = useNavigate();

    const [clickState, setClickState] = useClickState();

    const fromStorage = localStorage.getItem('navOpen');

    const [open, setOpen] = React.useState(
        fromStorage == 'false' ? false : true
    );

    React.useEffect(() => {
        localStorage.setItem('navOpen', open ? 'true' : 'false');
    }, [open]);

    const routes = useNavRoutes();

    const activeChip = Object.keys(routes).find((key) =>
        routes[key].children
            .map((s) => s.path)
            .some((link) => window.location.pathname.includes(link))
    );

    const [focusedChip, setFocusedChip] = React.useState(activeChip || '');

    return (
        <Box
            sx={{
                transition: transitions.create('width', { duration: 300 }),
                height: small ? '100%' : undefined,
                width: open ? '200px' : '45px',
                alignItems: 'flex-start',
                display: 'flex',
                flexShrink: 1,
                background:
                    palette.mode == 'dark'
                        ? palette.tonal
                        : palette.background.paper,
                flexFlow: 'column',
                padding: 2.5,
                gap: 0.25,
                borderRight:
                    palette.mode == 'dark'
                        ? undefined
                        : `1px solid ${palette.divider}`,
                overflow: 'auto',
            }}
        >
            {Object.keys(routes).map((key) => {
                const route = routes[key];
                return (
                    <NavChip
                        focus={focusedChip}
                        setFocus={(f) => setFocusedChip(f)}
                        routeKey={key}
                        key={key}
                        label={key}
                        data={route}
                        open={open || small}
                        setOpen={(o) => setOpen(o)}
                    />
                );
            })}
            <Box sx={{ flex: 1 }} />
            <Box
                sx={{
                    display: 'flex',
                    flexFlow: 'column',
                    gap: 1,
                    alignItems: 'center',
                }}
            >
                <Tooltip arrow title="My Account" placement="right">
                    <IconButton
                        onClick={(e) =>
                            setClickState({ target: e.currentTarget })
                        }
                    >
                        <Avatar
                            sx={{ height: '32px', width: '32px' }}
                            src={
                                user && user.picture ? user.picture : undefined
                            }
                            alt={user ? user.name : undefined}
                        ></Avatar>
                    </IconButton>
                </Tooltip>
                <Tooltip
                    placement="right"
                    arrow
                    title={mode == 'dark' ? 'Dark mode' : 'Light mode'}
                >
                    <IconButton
                        onClick={() =>
                            setMode(mode == 'dark' ? 'light' : 'dark')
                        }
                    >
                        {mode == 'dark' ? <MdDarkMode /> : <MdLightMode />}
                    </IconButton>
                </Tooltip>
                <Tooltip
                    placement="right"
                    arrow
                    title={open ? 'Collape sidebar' : 'Open sidebar'}
                >
                    <IconButton onClick={() => setOpen(!open)}>
                        <Anima type="rotate" in={open}>
                            <Box sx={{ display: 'flex' }}>
                                <MdChevronRight />
                            </Box>
                        </Anima>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                open={Boolean(clickState)}
                anchorEl={clickState ? clickState.target : null}
                onClose={() => setClickState(null)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => nav('/account')}>
                    <ListItemIcon>
                        <Avatar
                            alt={user ? user.name : undefined}
                            src={user ? user.picture : ''}
                            sx={{
                                height: '18px',
                                width: '18px',
                            }}
                        />
                    </ListItemIcon>
                    <ListItemText primary="My Account" />
                </MenuItem>
                <MenuItem onClick={() => nav('/logout')}>
                    <ListItemIcon>
                        <MdExitToApp />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default Sidebar;
