import {
    Box,
    Drawer,
    DrawerProps,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { MdClear } from 'react-icons/md';

export interface ResponsiveDrawerProps extends Omit<DrawerProps, 'onClose'> {
    onClose: () => void;
    title?: string;
    padding?: number;
}

const ResponsiveDrawer = (props: ResponsiveDrawerProps): ReactElement => {
    const { title, open, onClose, padding = 2, ...rest } = props;

    const { breakpoints } = useTheme();
    const isSmall = useMediaQuery(breakpoints.down('sm'));

    const [closing, setClosing] = React.useState(false);

    React.useEffect(() => {
        if (closing) {
            const timeout = setTimeout(() => {
                onClose();
                setClosing(false);
            }, 200);

            return () => clearTimeout(timeout);
        }
    }, [closing]);

    return (
        <Drawer
            {...rest}
            open={open && !closing}
            onClose={() => setClosing(true)}
            PaperProps={{ sx: { padding, ...rest.PaperProps?.sx } }}
        >
            {title && (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        paddingBottom: 2,
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography sx={{ paddingRight: 6 }} variant="crisp">
                        {title}
                    </Typography>
                    <IconButton onClick={() => setClosing(true)}>
                        <MdClear />
                    </IconButton>
                </Box>
            )}
            <Box sx={{ height: '100%', display: 'flex', flexFlow: 'column' }}>
                {rest.children}
            </Box>
        </Drawer>
    );
};

export default ResponsiveDrawer;
