import React, { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import UnitDetail from './components/UnitDetail';
import UnitForm from './components/UnitForm';
import UnitList from './components/UnitList';

const Companies = (): ReactElement => {
    return (
        <Routes>
            <Route index element={<UnitList />} />
            <Route path={':item_type'} element={<UnitList />} />
            <Route path={'unit/:id'} element={<UnitDetail />} />
            <Route path={'unit/:id/edit'} element={<UnitForm />} />
            <Route path={'create'} element={<UnitForm />} />
            <Route path={'create/:item_type'} element={<UnitForm />} />
        </Routes>
    );
};

export default Companies;
