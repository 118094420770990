import { gql } from '@apollo/client';
import { BaseFragment } from '../../../../Base/Base';
import { getMutationHook } from '../../../../types';
import { Cookie, CookieFragment } from '../Cookie';
import { UpdateCookieInput } from '../inputs/UpdateCookieInput';

export const UpdateCookieMutation = gql`
    ${BaseFragment}
    ${CookieFragment}
    mutation UpdateCookieMutation($id: ObjectId!, $data: UpdateCookieInput!) {
        updateCookie(id: $id, data: $data) {
            ...CookieFragment
        }
    }
`;

export interface UpdateCookieArgs {
    id: string;
    data: UpdateCookieInput;
}

export interface UpdateCookieRes {
    updateCookie: Cookie;
}

export const useCookieUpdate = getMutationHook<
    UpdateCookieRes,
    UpdateCookieArgs
>(UpdateCookieMutation);
