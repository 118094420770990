import { Box, Button, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { MdAdd } from 'react-icons/md';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SmartTable from '../../../../../../components/display/SmartTable';
import SearchInput from '../../../../../../components/inputs/FieldInputs/SearchInput';
import AppNav from '../../../../../../components/Layout/AppNav/components';
import NavContent from '../../../../../../components/Layout/AppNav/components/NavContent';
import NavHeader from '../../../../../../components/Layout/NavHeader';
import { longList } from '../../../../../../utils/longList';
import { format } from 'date-fns';
import { dateFormats } from '../../../../../../utils/dateFormats';
import { BiAddToQueue } from 'react-icons/bi';
import { TableOrderFilter } from '../../../../../../graphql/TableOrder/TableOrderFilter';
import { useTableOrders } from '../../../../../../graphql/TableOrder/useTableOrders';

const OrderList = (): ReactElement => {
    const nav = useNavigate();

    const fromStorage = localStorage.getItem('TABLEORDER_FILTER_R');

    const [filter, setFilter] = React.useState<TableOrderFilter>(
        fromStorage
            ? { skip: 0, take: 25, ...JSON.parse(fromStorage) }
            : {
                  skip: 0,
                  take: 25,
              }
    );

    React.useEffect(() => {
        localStorage.setItem('TABLEORDER_FILTER_R', JSON.stringify(filter));
    }, [filter]);

    const { data, error, loading } = useTableOrders({
        variables: { filter },
        fetchPolicy: 'network-only',
    });

    const count = data ? data.tableOrders.count : 0;
    const orders = data ? data.tableOrders.items : [];

    return (
        <AppNav error={error} loading={loading}>
            <NavContent>
                {{
                    header: (
                        <NavHeader>
                            <Typography variant="crisp">Orders</Typography>
                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <Button
                                    onClick={() => nav('queue')}
                                    startIcon={<BiAddToQueue />}
                                >
                                    Order
                                </Button>
                            </Box>
                        </NavHeader>
                    ),
                    content: (
                        <SmartTable
                            data={orders}
                            getProps={(d) => ({
                                id: d._id,
                                onClick: () => nav(d._id),
                            })}
                            pagination={{
                                count,
                                filter,
                                setFilter: (f) =>
                                    setFilter({ ...filter, ...f }),
                            }}
                            controls={{
                                ['PO#']: (
                                    <SearchInput
                                        placeholder="PO#"
                                        variant="standard"
                                        InputProps={{ disableUnderline: true }}
                                        value={filter.po || ''}
                                        onChange={(code) =>
                                            setFilter({
                                                ...filter,
                                                po: code || undefined,
                                                skip: 0,
                                            })
                                        }
                                    />
                                ),
                                Customer: (
                                    <SearchInput
                                        placeholder="Customer"
                                        variant="standard"
                                        InputProps={{ disableUnderline: true }}
                                        value={filter.customer || ''}
                                        onChange={(searchVal) =>
                                            setFilter({
                                                ...filter,
                                                customer:
                                                    searchVal || undefined,
                                                skip: 0,
                                            })
                                        }
                                    />
                                ),
                                Vendor: (
                                    <SearchInput
                                        placeholder="Vendor"
                                        variant="standard"
                                        InputProps={{ disableUnderline: true }}
                                        value={filter.vendor || ''}
                                        onChange={(searchVal) =>
                                            setFilter({
                                                ...filter,
                                                vendor: searchVal || undefined,
                                                skip: 0,
                                            })
                                        }
                                    />
                                ),
                                Items: (
                                    <SearchInput
                                        placeholder="Items"
                                        variant="standard"
                                        InputProps={{ disableUnderline: true }}
                                        value={filter.item || ''}
                                        onChange={(searchVal) =>
                                            setFilter({
                                                ...filter,
                                                item: searchVal || undefined,
                                                skip: 0,
                                            })
                                        }
                                    />
                                ),
                                ['Ordered by']: (
                                    <SearchInput
                                        placeholder="Ordered by"
                                        variant="standard"
                                        InputProps={{ disableUnderline: true }}
                                        value={filter.created_by || ''}
                                        onChange={(searchVal) =>
                                            setFilter({
                                                ...filter,
                                                created_by:
                                                    searchVal || undefined,
                                                skip: 0,
                                            })
                                        }
                                    />
                                ),
                            }}
                        >
                            {{
                                ['PO#']: (order) => (
                                    <Typography sx={{ fontWeight: 700 }}>
                                        {order.code || ''}
                                    </Typography>
                                ),
                                Customer: (order) =>
                                    order.customer ? order.customer.name : '',
                                Vendor: (order) =>
                                    order.vendor ? order.vendor.name : '',
                                Items: (order) =>
                                    longList(
                                        order.items.map((item) => item.name),
                                        2
                                    ),
                                ['Ordered by']: (order) =>
                                    order.created_by.name,
                                ['Date ordered']: (order) =>
                                    format(
                                        new Date(order.date_created),
                                        dateFormats.condensedDate
                                    ),
                            }}
                        </SmartTable>
                    ),
                }}
            </NavContent>
        </AppNav>
    );
};

export default OrderList;
