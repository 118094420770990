import { gql } from '@apollo/client';
import { RecipeFragment } from '../../Recipe/Recipe';
import { getQueryHook } from '../../types';
import { Folder, FolderFragment } from '../Folder';

export const FolderQuery = gql`
    ${FolderFragment}
    ${RecipeFragment}
    query FolderQuery($id: ObjectId) {
        folder(id: $id) {
            ...FolderFragment
        }
    }
`;

export interface FolderRes {
    folder: Folder;
}

export interface FolderArgs {
    id: string | null;
}

export const useFolder = getQueryHook<FolderRes, FolderArgs>(FolderQuery);
