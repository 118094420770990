import { gql } from '@apollo/client';
import { Pagination } from '../../../utils/types/Pagination';
import { AddressFragment } from '../../Address/Address';
import { TinyCompanyFragment } from '../../Company/Company';
import { ContactFragment, TinyContactFragment } from '../../Contact/Contact';
import { getQueryHook } from '../../types';
import {
    CompanyLocationFragment,
    TinyLocation,
    TinyLocationFragment,
} from '../Location';
import { LocationFilter } from '../LocationFilter';

export const TinyLocations = gql`
    ${TinyLocationFragment}
    ${TinyCompanyFragment}
    ${AddressFragment}
    ${TinyContactFragment}
    ${CompanyLocationFragment}
    query TinyLocations($filter: LocationFilter!) {
        locations(filter: $filter) {
            count
            items {
                ...TinyLocationFragment
            }
        }
    }
`;

export interface TinyLocationsRes {
    locations: Pagination<TinyLocation>;
}

export interface TinyLocationsArgs {
    filter: LocationFilter;
}

export const useTinyLocations = getQueryHook<
    TinyLocationsRes,
    TinyLocationsArgs
>(TinyLocations);
