import { gql } from '@apollo/client';
import { BaseFragment } from '../../../../Base/Base';
import { getAtomicQueryHook } from '../../../../types';
import { Ingredient, IngredientFragment } from '../Ingredient';

export const IngredientQuery = gql`
    ${BaseFragment}
    ${IngredientFragment}
    query IngredientQuery($id: ObjectId!) {
        ingredient(id: $id) {
            ...IngredientFragment
        }
    }
`;

export const useIngredient = getAtomicQueryHook<{ ingredient: Ingredient }>(
    IngredientQuery
);
