import { gql } from '@apollo/client';
import { BaseFragment } from '../../../../Base/Base';
import { getAtomicQueryHook } from '../../../../types';
import { MiscItem, MiscItemFragment } from '../MiscItem';

export const MiscItemQuery = gql`
    ${BaseFragment}
    ${MiscItemFragment}
    query MiscItemQuery($id: ObjectId!) {
        miscitem(id: $id) {
            ...MiscItemFragment
        }
    }
`;

export const useMiscItem = getAtomicQueryHook<{ miscitem: MiscItem }>(
    MiscItemQuery
);
