import { gql } from '@apollo/client';
import { RecipeStep } from '../RecipeStep/RecipeStep';
import { Ref } from '../types';
import { TinyUnit } from '../Unit/Unit';

export interface BatchLine {
    content: {
        lot: {
            _id: string;
            code: string;
            item: {
                _id: string;
                name: string;
                spanish_name: string;
            };
        };
        client_unit: TinyUnit;
        client_qty: number;
        qty: number;
        receipt_line: string | null;
    };
    recipe_step: Ref<RecipeStep> | null;
}

export const BatchLineFragment = gql`
    fragment BatchLineFragment on BatchLine {
        content {
            lot {
                _id
                code
                item {
                    _id
                    name
                    spanish_name
                }
            }
            client_unit {
                ...TinyUnitFragment
            }
            client_qty
            qty
            receipt_line
        }
        recipe_step
    }
`;
