import { gql } from '@apollo/client';
import { BaseFragment } from '../Base/Base';
import { getAtomicQueryHook } from '../types';
import { Team, TeamFragment } from './useTeams';

export const TeamQuery = gql`
    ${BaseFragment}
    ${TeamFragment}
    query TeamQuery($id: ObjectId!) {
        team(id: $id) {
            ...TeamFragment
        }
    }
`;

export interface TeamRes {
    team: Team;
}

export const useTeam = getAtomicQueryHook<TeamRes>(TeamQuery);
