import {
    Box,
    ButtonBase,
    Grid,
    GridProps,
    Typography,
    useTheme,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { MdFolder } from 'react-icons/md';
import { useNavigate } from 'react-router';
import { Folder } from '../../../../../../graphql/Folder/Folder';
import { Droppable, Draggable } from 'react-beautiful-dnd';

export interface FolderChipProps {
    children: Folder['folders'][number];
    index: number;
    setFocus: (data: {
        coordinates: {
            x: number;
            y: number;
        };
        folder: Folder['folders'][number];
    }) => void;
}

const FolderChip = (props: FolderChipProps): ReactElement => {
    const { children: folder, index, setFocus } = props;
    const nav = useNavigate();

    const { palette, shape } = useTheme();

    const gridProps: GridProps = {
        xs: 12,
    };

    return (
        <Grid {...gridProps} item>
            <Box>
                <Draggable index={index} draggableId={'folder_' + folder._id}>
                    {(provided, snapshot) => (
                        <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <ButtonBase
                                onContextMenu={(event) => {
                                    event.preventDefault();
                                    setFocus({
                                        folder,
                                        coordinates: {
                                            x: event.clientX - 4,
                                            y: event.clientY - 4,
                                        },
                                    });
                                }}
                                onClick={() => nav(`/recipes/${folder._id}`)}
                                sx={{
                                    width: '100%',
                                    textAlign: 'left',
                                    justifyContent: 'flex-start',
                                    padding: 2,
                                    borderBottom: `1px solid ${palette.divider}`,
                                    ...(snapshot.combineWith
                                        ? { opacity: 0.35 }
                                        : snapshot.combineTargetFor
                                        ? {
                                              background:
                                                  palette.background.paper,
                                          }
                                        : undefined),
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 2,
                                    }}
                                >
                                    <Box>
                                        <MdFolder
                                            style={{
                                                fontSize: '2rem',
                                                color: palette.text.secondary,
                                            }}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography>{folder.name}</Typography>
                                    </Box>
                                </Box>
                            </ButtonBase>
                        </Box>
                    )}
                </Draggable>
            </Box>
        </Grid>
    );
};

export default FolderChip;
