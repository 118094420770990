import React, { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import RecipeDetail from './components/RecipeDetail';
import RecipeForm from './components/RecipeDetailForm';
import RecipeNav from './components/RecipeNav';

const Recipes = (): ReactElement => {
    return (
        <Routes>
            <Route index element={<RecipeNav />} />
            <Route path=":id" element={<RecipeNav />} />
            <Route path="recipe/:id" element={<RecipeDetail />} />
            <Route path="recipe/:id/:version" element={<RecipeForm />} />
            <Route path="recipe/:id/new" element={<RecipeForm />} />
        </Routes>
    );
};

export default Recipes;
