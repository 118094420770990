import { gql } from '@apollo/client';
import { getQueryHook } from '../../types';

export const OurCompanyQuery = gql`
    query OurCompanyQuery {
        ourCompany {
            _id
            locations {
                _id
                label
                production_lines {
                    _id
                    name
                }
            }
        }
    }
`;

export interface OurCompanyLocation {
    _id: string;
    label: string;
    production_lines: { _id: string; name: string }[];
}

export interface OurCompany {
    _id: string;
    locations: OurCompanyLocation[];
}

export const useOurCompany = getQueryHook<{ ourCompany: OurCompany }>(
    OurCompanyQuery
);
