import { AvatarGroup } from '@mui/lab';
import Box from '@mui/material/Box';
import { format } from 'date-fns';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import SmartTable from '../../../../../../components/display/SmartTable';
import VerificationBadge from '../../../../../../components/display/VerificationBadge';
import SearchInput from '../../../../../../components/inputs/FieldInputs/SearchInput';
import { FulfillmentEventType } from '../../../../../../graphql/FulfillmentEvent/FulfillmentEventFilter';
import { FulfillmentRowFilter } from '../../../../../../graphql/FulfillmentRow/FulfillmentRowFilter';
import { useFulfillmentRows } from '../../../../../../graphql/FulfillmentRow/useFulfillmentRows';
import { useClickState } from '../../../../../../hooks/useClickState';
import { dateFormats } from '../../../../../../utils/dateFormats';
import { longList } from '../../../../../../utils/longList';
import WarehousePopover from '../WarehouseCalendar/components/WarehousePopover';

const WarehouseTable = (props: {
    view: 'Shipping' | 'Receiving';
    plant: string;
}): ReactElement => {
    const { view, plant } = props;
    const nav = useNavigate();

    const [focus, setFocus] = useClickState<{ bol: string; order: string }>();

    const [filter, setFilter] = React.useState<
        Omit<FulfillmentRowFilter, 'plant' | 'type'>
    >({
        skip: 0,
        take: 50,
    });

    React.useEffect(() => {
        if (filter.skip !== 0) {
            setFilter({ ...filter, skip: 0 });
        }
    }, [plant]);

    React.useEffect(() => {
        localStorage.setItem('WAREHOUSETABLE_FILTER', JSON.stringify(filter));
    }, [filter]);

    const { data, error, loading } = useFulfillmentRows({
        variables: {
            filter: {
                ...filter,
                plant,
                type:
                    view == 'Receiving'
                        ? FulfillmentEventType.Receiving
                        : FulfillmentEventType.Shipping,
            },
        },
        fetchPolicy: 'network-only',
        skip: !plant,
    });

    const count = data ? data.fulfillment_rows.count : 0;
    const rows = data ? data.fulfillment_rows.items : [];

    return (
        <React.Fragment>
            <SmartTable
                loading={loading}
                error={error}
                data={rows}
                getProps={(d) => ({
                    id: d.bol,
                    onClick: (_, e) => {
                        setFocus({
                            element: e.currentTarget,
                            order: d.order,
                            bol: d.bol,
                        });
                    },
                })}
                pagination={{
                    count,
                    filter,
                    setFilter: (f) => setFilter({ ...filter, ...f }),
                }}
                controls={{
                    ['PO#']: (
                        <SearchInput
                            placeholder="PO#"
                            variant="standard"
                            InputProps={{ disableUnderline: true }}
                            value={filter.po || ''}
                            onChange={(code) =>
                                setFilter({
                                    ...filter,
                                    po: code || undefined,
                                    skip: 0,
                                })
                            }
                        />
                    ),
                    Customer: (
                        <SearchInput
                            placeholder="Customer"
                            variant="standard"
                            InputProps={{ disableUnderline: true }}
                            value={filter.customer || ''}
                            onChange={(searchVal) =>
                                setFilter({
                                    ...filter,
                                    customer: searchVal || undefined,
                                    skip: 0,
                                })
                            }
                        />
                    ),
                    Vendor: (
                        <SearchInput
                            placeholder="Vendor"
                            variant="standard"
                            InputProps={{ disableUnderline: true }}
                            value={filter.vendor || ''}
                            onChange={(searchVal) =>
                                setFilter({
                                    ...filter,
                                    vendor: searchVal || undefined,
                                    skip: 0,
                                })
                            }
                        />
                    ),
                    Items: (
                        <SearchInput
                            placeholder="Items"
                            variant="standard"
                            InputProps={{ disableUnderline: true }}
                            value={filter.item || ''}
                            onChange={(searchVal) =>
                                setFilter({
                                    ...filter,
                                    item: searchVal || undefined,
                                    skip: 0,
                                })
                            }
                        />
                    ),
                    ['BOL#']: (
                        <SearchInput
                            placeholder="BOL#"
                            variant="standard"
                            InputProps={{ disableUnderline: true }}
                            value={filter.bol || ''}
                            onChange={(searchVal) =>
                                setFilter({
                                    ...filter,
                                    bol: searchVal || undefined,
                                    skip: 0,
                                })
                            }
                        />
                    ),
                }}
            >
                {{
                    ['PO#']: (row) => row.po,
                    ['BOL#']: (row) => row.bol_code,
                    Customer: (row) => (row.customer ? row.customer.name : ''),
                    Vendor: (row) => (row.vendor ? row.vendor.name : ''),
                    Items: (row) =>
                        longList(
                            row.contents.map((content) => content.item.name),
                            2
                        ),
                    ['Scheduled Date']: (row) =>
                        format(new Date(row.date), dateFormats.condensedDate),
                    [view == 'Receiving' ? 'Date received' : 'Date shipped']: (
                        row
                    ) =>
                        longList(
                            row[
                                view == 'Receiving' ? 'receipts' : 'shipments'
                            ].map((f) =>
                                format(
                                    new Date(f.date_created),
                                    dateFormats.condensedDate
                                )
                            )
                        ),
                    [view == 'Receiving' ? 'Received by' : 'Shipped by']: (
                        row
                    ) =>
                        longList([
                            ...new Set(
                                row[
                                    view == 'Receiving'
                                        ? 'receipts'
                                        : 'shipments'
                                ].map((f) => f.created_by.name)
                            ),
                        ]),

                    Verification: (row) => (
                        <Box sx={{ display: 'flex' }}>
                            <AvatarGroup>
                                {row[
                                    view == 'Receiving'
                                        ? 'receipts'
                                        : 'shipments'
                                ].map((f) => (
                                    <VerificationBadge
                                        fontSize="1.2rem"
                                        key={'v_' + f._id}
                                    >
                                        {{ verification: f.verification }}
                                    </VerificationBadge>
                                ))}
                            </AvatarGroup>
                        </Box>
                    ),
                }}
            </SmartTable>
            <WarehousePopover
                view={view}
                focus={focus}
                onClose={() => setFocus(null)}
            />
        </React.Fragment>
    );
};

export default WarehouseTable;
