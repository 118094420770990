import { gql } from '@apollo/client';
import { TinyItem } from '../../Item/Item';
import { TinyUnit } from '../../Unit/Unit';

export interface ItemPluralContent {
    items: TinyItem[];
    client_unit: TinyUnit;
    client_qty: number;
    qty: number;
}

export const ItemPluralContentFragment = gql`
    fragment ItemPluralContentFragment on ItemPluralContent {
        items {
            ...TinyItemFragment
        }
        client_unit {
            ...TinyUnitFragment
        }
        client_qty
        qty
    }
`;
