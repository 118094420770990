import {
    Box,
    Button,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Skeleton,
    Typography,
    useTheme,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { MdEdit } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import BaseDetails from '../../../../../../components/baseComponents/BaseDetails';
import AppNav from '../../../../../../components/Layout/AppNav/components';
import NavContent from '../../../../../../components/Layout/AppNav/components/NavContent';
import NavHeader from '../../../../../../components/Layout/NavHeader';
import TabFade from '../../../../../../components/Layout/TabFade';
import { useQualityCheckForm } from '../../../../../../graphql/Form/extensions/QualityCheckForm/operations/useQualityCheckForm';
import { CategoryIcons } from '../QualityCheckForm/components/FormFieldInput';

const QualityCheckDetail = (): ReactElement => {
    const { id } = useParams();
    const nav = useNavigate();
    const { palette } = useTheme();

    const { data, error, loading } = useQualityCheckForm({
        variables: { id: id || '' },
        fetchPolicy: 'network-only',
    });

    const qualitycheck = data ? data.qualityCheckForm : null;

    return (
        <AppNav error={error} loading={loading}>
            <NavContent>
                {{
                    header: (
                        <NavHeader
                            back={['Quality Checks', '/qa/qualitychecks']}
                        >
                            {qualitycheck ? (
                                <Typography
                                    sx={{ fontSize: '2rem' }}
                                    variant="crisp"
                                >
                                    {`Quality Check: ${
                                        qualitycheck.category
                                    } for ${
                                        qualitycheck.items
                                            ? qualitycheck.items
                                                  .map((it) => it.name)
                                                  .join(', ')
                                            : 'All Items'
                                    }`}
                                </Typography>
                            ) : (
                                <Skeleton width={400} height={62} />
                            )}
                            <Box />
                        </NavHeader>
                    ),
                    content: (
                        <TabFade>
                            {{
                                Details: qualitycheck ? (
                                    <Box>
                                        <BaseDetails>
                                            {qualitycheck}
                                        </BaseDetails>
                                        <Button
                                            onClick={() => nav('edit')}
                                            endIcon={<MdEdit />}
                                        >
                                            Edit
                                        </Button>
                                    </Box>
                                ) : (
                                    <Box />
                                ),
                                Questions: qualitycheck ? (
                                    <Box>
                                        <List>
                                            {qualitycheck.fields.map(
                                                (field, index) => (
                                                    <ListItem
                                                        divider
                                                        key={'f_' + index}
                                                    >
                                                        <ListItemAvatar
                                                            sx={{
                                                                fontSize:
                                                                    '2rem',
                                                                color: palette
                                                                    .text
                                                                    .secondary,
                                                            }}
                                                        >
                                                            {
                                                                CategoryIcons[
                                                                    field
                                                                        .category
                                                                ]
                                                            }
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={
                                                                field.question
                                                            }
                                                            secondary={
                                                                field.category
                                                            }
                                                        />
                                                    </ListItem>
                                                )
                                            )}
                                        </List>
                                        <Button
                                            onClick={() => nav('edit')}
                                            endIcon={<MdEdit />}
                                        >
                                            Edit
                                        </Button>
                                    </Box>
                                ) : (
                                    <Box />
                                ),
                            }}
                        </TabFade>
                    ),
                }}
            </NavContent>
        </AppNav>
    );
};

export default QualityCheckDetail;
