import { LoadingButton } from '@mui/lab';
import {
    Box,
    Button,
    StepContent,
    TextField,
    Tooltip,
    useTheme,
} from '@mui/material/';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import React, { ReactElement } from 'react';
import Message from '../../../../../../components/feedback/Message';
import ResponsiveDialog from '../../../../../../components/Layout/ResponsiveDialog';
import { TinyItem } from '../../../../../../graphql/Item/Item';
import { UnitClass } from '../../../../../../graphql/Unit/UnitClass';
import {
    ConversionUpdateArgs,
    ConversionUpdateRes,
    useConversionUpdate,
} from '../../../../../../graphql/UnitConversion/useBulkConversionUpdate';
import { OperationResult } from '../../../../../../utils/types/OperationResult';

export interface ConversionFormProps {
    focused: boolean;
    setFocused: (val: boolean) => void;
    required: [TinyItem, UnitClass][];
}

export const baseUnits: Record<UnitClass, [string, string]> = {
    [UnitClass.Count]: ['count', 'count'],
    [UnitClass.Length]: ['feet', 'foot'],
    [UnitClass.Volume]: ['gallons', 'gallon'],
    [UnitClass.Weight]: ['pounds', 'pound'],
};

// From weight to volume => special phrasing and inverse save
// From volume to weight => special phrasing and regular save

const ConversionForm = (props: ConversionFormProps): ReactElement => {
    const { required, focused, setFocused } = props;
    const { palette } = useTheme();
    const [index, setIndex] = React.useState(0);

    const [result, setResult] =
        React.useState<null | OperationResult<ConversionUpdateRes>>(null);

    const [state, setState] = React.useState<ConversionUpdateArgs['data']>([]);

    React.useEffect(() => {
        setState(
            required.map((req) => ({
                item: req[0]._id,
                conversion: { to: req[1], multiplier: null },
            }))
        );
    }, [required]);

    const [handleUpdate, { loading }] = useConversionUpdate({
        onCompleted: (data) => setResult({ success: true, data }),
        onError: (error) => setResult({ success: false, error }),
        variables: {
            data: state.map((con) => {
                const items = required.map(([item, uClass]) => item);
                const thisItem = items.find(
                    (i) => i._id == con.item
                ) as TinyItem;

                if (
                    thisItem &&
                    thisItem.unit_class == UnitClass.Weight &&
                    con.conversion.to == UnitClass.Volume
                ) {
                    return {
                        ...con,
                        conversion: {
                            ...con.conversion,
                            multiplier: 1 / (con.conversion.multiplier || 1),
                        },
                    };
                } else return con;
            }),
        },
    });

    const getHoldup = () => {
        if (state.length == 0) return 'No conversions required';
        if (
            state.some(
                (s) =>
                    s.conversion.multiplier == null ||
                    s.conversion.multiplier <= 0
            )
        )
            return 'Each conversion needs a positive multiplier.';
    };

    const holdup = getHoldup();

    const handleClose = () => {
        setIndex(0);
        setFocused(false);
    };

    return (
        <ResponsiveDialog
            PaperProps={{ sx: { width: 400, background: palette.tonal } }}
            open={focused}
            onClose={handleClose}
        >
            <Box sx={{ position: 'relative' }}>
                {result && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            background: palette.tonal,
                            zIndex: 10,
                        }}
                    >
                        {result.success ? (
                            <Message
                                type="Success"
                                onComplete={() => handleClose()}
                            >
                                Conversions saved!
                            </Message>
                        ) : (
                            <Message
                                type="Error"
                                action={
                                    <Button onClick={() => setResult(null)}>
                                        Try again
                                    </Button>
                                }
                            >
                                {result.error.message}
                            </Message>
                        )}
                    </Box>
                )}
                <Typography variant="h6">Unit Conversions</Typography>
                <Box p={1} />
                <Stepper orientation="vertical" activeStep={index}>
                    {required.map(([item, unitClass], sIndex) => (
                        <Step key={'step_' + sIndex}>
                            <StepLabel>{`${item.name} - ${unitClass}`}</StepLabel>
                            <StepContent>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexFlow: 'column',
                                        gap: 1,
                                        alignItems: 'flex-start',
                                        padding: 1,
                                    }}
                                >
                                    {item.unit_class == UnitClass.Weight &&
                                    unitClass == UnitClass.Volume ? (
                                        <Typography>{`How much does 1 ${baseUnits[unitClass][1]} of ${item.name} weigh? (pounds)`}</Typography>
                                    ) : item.unit_class == UnitClass.Volume &&
                                      unitClass == UnitClass.Weight ? (
                                        <Typography>{`How much does 1 ${
                                            baseUnits[item.unit_class][1]
                                        } of ${
                                            item.name
                                        } weigh? (pounds)`}</Typography>
                                    ) : (
                                        <Typography>{`How many ${
                                            baseUnits[unitClass][0]
                                        } are in one ${
                                            baseUnits[item.unit_class][1]
                                        } of ${item.name}?`}</Typography>
                                    )}
                                    <TextField
                                        variant="standard"
                                        placeholder="Enter value..."
                                        type="number"
                                        value={
                                            state[sIndex]
                                                ? state[sIndex].conversion
                                                      .multiplier == null
                                                    ? ''
                                                    : state[sIndex].conversion
                                                          .multiplier
                                                : ''
                                        }
                                        onChange={(e) => {
                                            const parsed = parseFloat(
                                                e.target.value
                                            );

                                            const copy = [...state];
                                            copy[sIndex].conversion.multiplier =
                                                isNaN(parsed) ? null : parsed;
                                            setState(copy);
                                        }}
                                    />
                                    <Box p={1} />
                                    <Box sx={{ display: 'flex', gap: 1 }}>
                                        {index !== 0 && (
                                            <Button
                                                color="inherit"
                                                variant="outlined"
                                                onClick={() =>
                                                    setIndex(index - 1)
                                                }
                                            >
                                                Previous
                                            </Button>
                                        )}
                                        <Button
                                            onClick={() => setIndex(index + 1)}
                                        >
                                            Next
                                        </Button>
                                    </Box>
                                </Box>
                            </StepContent>
                        </Step>
                    ))}
                    <Step>
                        <StepLabel>Complete</StepLabel>
                        <StepContent>
                            <Box sx={{ display: 'flex', gap: 1, padding: 1 }}>
                                {index !== 0 && (
                                    <Button
                                        disabled={loading}
                                        color="inherit"
                                        variant="outlined"
                                        onClick={() => setIndex(index - 1)}
                                    >
                                        Previous
                                    </Button>
                                )}
                                <Box>
                                    <Tooltip arrow title={holdup || ''}>
                                        <Box>
                                            <LoadingButton
                                                onClick={() => handleUpdate()}
                                                variant="contained"
                                                disabled={Boolean(holdup)}
                                                loading={loading}
                                                color="success"
                                            >
                                                Save
                                            </LoadingButton>
                                        </Box>
                                    </Tooltip>
                                </Box>
                            </Box>
                        </StepContent>
                    </Step>
                </Stepper>
            </Box>
        </ResponsiveDialog>
    );
};

export default ConversionForm;
