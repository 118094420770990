import { Box, Button, Skeleton, Typography, useTheme } from '@mui/material';
import React, { ReactElement } from 'react';
import { MdBadge, MdEdit } from 'react-icons/md';
import { BsTag } from 'react-icons/bs';
import { BiBarcode, BiBarcodeReader } from 'react-icons/bi';
import { useNavigate, useParams } from 'react-router-dom';
import BaseDetails from '../../../../../../components/baseComponents/BaseDetails';
import AppNav from '../../../../../../components/Layout/AppNav/components';
import NavContent from '../../../../../../components/Layout/AppNav/components/NavContent';
import NavHeader from '../../../../../../components/Layout/NavHeader';
import TabFade from '../../../../../../components/Layout/TabFade';
import { useProduct } from '../../../../../../graphql/Item/extensions/Product/operations/useProduct';

const ProductDetail = (): ReactElement => {
    const { id, contact, location } = useParams();
    const nav = useNavigate();

    const { palette } = useTheme();

    const { data, error, loading } = useProduct({
        variables: { id: id || '' },
        fetchPolicy: 'network-only',
    });

    const product = data ? data.product : null;

    return (
        <AppNav error={error} loading={loading}>
            <NavContent>
                {{
                    header: (
                        <NavHeader back={['Products', '/library/products']}>
                            {product ? (
                                <Box>
                                    <Typography variant="crisp">
                                        {product.name}
                                    </Typography>
                                    <Typography
                                        sx={{ paddingTop: 1 }}
                                        color="textSecondary"
                                    >
                                        Product
                                    </Typography>
                                </Box>
                            ) : (
                                <Skeleton width={400} height={62} />
                            )}
                            <Box />
                        </NavHeader>
                    ),
                    content: (
                        <TabFade>
                            {{
                                Details: product ? (
                                    <Box>
                                        <BaseDetails
                                            extension={{
                                                Name: {
                                                    icon: <MdBadge />,
                                                    primary: product.name,
                                                },
                                                UPC: {
                                                    icon: <BiBarcodeReader />,
                                                    primary: product.upc,
                                                },
                                                SKU: {
                                                    icon: <BsTag />,
                                                    primary: product.sku,
                                                },
                                            }}
                                        >
                                            {product}
                                        </BaseDetails>
                                        <Button
                                            onClick={() => nav('edit')}
                                            endIcon={<MdEdit />}
                                        >
                                            Edit
                                        </Button>
                                    </Box>
                                ) : (
                                    <Box />
                                ),
                            }}
                        </TabFade>
                    ),
                }}
            </NavContent>
        </AppNav>
    );
};

export default ProductDetail;
