import { gql } from '@apollo/client';
import { BaseFragment } from '../../../../Base/Base';
import { getMutationHook } from '../../../../types';
import { Package, PackageFragment } from '../Package';
import { UpdatePackageInput } from '../inputs/UpdatePackageInput';

export const UpdatePackageMutation = gql`
    ${BaseFragment}
    ${PackageFragment}
    mutation UpdatePackageMutation($id: ObjectId!, $data: UpdatePackageInput!) {
        updatePackage(id: $id, data: $data) {
            ...PackageFragment
        }
    }
`;

export interface UpdatePackageArgs {
    id: string;
    data: UpdatePackageInput;
}

export interface UpdatePackageRes {
    updatePackage: Package;
}

export const usePackageUpdate = getMutationHook<
    UpdatePackageRes,
    UpdatePackageArgs
>(UpdatePackageMutation);
