import React, { ReactElement } from 'react';
import bb8 from './directory/bb8.json';
import warning from './directory/warning.json';
import info from './directory/info.json';
import success from './directory/success.json';
import notFound from './directory/notFound.json';
import blobPrimary from './directory/blobPrimary.json';
import blobDark from './directory/blobDark.json';
import cubeDark from './directory/cubeDark.json';
import cubeLight from './directory/cubeLight.json';
import gear from './directory/gear.json';
import barcodeLight from './directory/barcodeLight.json';
import barcodeDark from './directory/barcodeDark.json';
import Lottie, { LottieProps } from 'react-lottie-player';

export type AnimationType =
    | 'bb8'
    | 'warning'
    | 'info'
    | 'success'
    | 'notFound'
    | 'blobPrimary'
    | 'blobDark'
    | 'cubeDark'
    | 'cubeLight'
    | 'gear'
    | 'barcodeLight'
    | 'barcodeDark';

export interface AnimationProps extends Omit<LottieProps, 'animationData'> {
    type: AnimationType;
}

export const Animation = (props: AnimationProps): ReactElement => {
    const { type, loop = true, ...rest } = props;

    const schema: Record<AnimationType, Record<string, unknown>> = {
        barcodeLight,
        barcodeDark,
        bb8,
        warning,
        info,
        success,
        notFound,
        blobPrimary,
        blobDark,
        cubeDark,
        cubeLight,
        gear,
    };

    const options: LottieProps = {
        play: true,
        animationData: schema[type],
        loop,
        ...rest,
        onEnded: () => {
            //
        },
    };

    return <Lottie {...options} />;
};
