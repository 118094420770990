import { gql } from '@apollo/client';
import { BaseFragment } from '../../../../Base/Base';
import { getMutationHook } from '../../../../types';
import { Product, ProductFragment } from '../Product';
import { UpdateProductInput } from '../inputs/UpdateProductInput';

export const UpdateProductMutation = gql`
    ${BaseFragment}
    ${ProductFragment}
    mutation UpdateProductMutation($id: ObjectId!, $data: UpdateProductInput!) {
        updateProduct(id: $id, data: $data) {
            ...ProductFragment
        }
    }
`;

export interface UpdateProductArgs {
    id: string;
    data: UpdateProductInput;
}

export interface UpdateProductRes {
    updateProduct: Product;
}

export const useProductUpdate = getMutationHook<
    UpdateProductRes,
    UpdateProductArgs
>(UpdateProductMutation);
