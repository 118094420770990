import {
    Avatar,
    AvatarGroup,
    Button,
    Tooltip,
    Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { ReactElement } from 'react';
import { MdAdd } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { getUiPermissions } from '../../../../../../auth/UiPermission';
import SmartTable from '../../../../../../components/display/SmartTable';
import AppNav from '../../../../../../components/Layout/AppNav/components';
import NavContent from '../../../../../../components/Layout/AppNav/components/NavContent';
import { TeamFilter, useTeams } from '../../../../../../graphql/Team/useTeams';

const TeamList = (props: { create?: true }): ReactElement => {
    const { create } = props;
    const nav = useNavigate();

    const [filter, setFilter] = React.useState<TeamFilter>({
        skip: 0,
        take: 50,
    });

    const { data, error, loading } = useTeams({
        variables: { filter },
        fetchPolicy: 'network-only',
    });

    const teams = data ? data.teams.items : [];
    const count = data ? data.teams.count : 0;

    return (
        <AppNav error={error} loading={loading}>
            <NavContent>
                {{
                    header: (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'flex-end',
                            }}
                        >
                            <Typography variant="crisp">Teams</Typography>
                            <Button
                                disabled={create}
                                onClick={() => nav('create')}
                                startIcon={<MdAdd />}
                            >
                                New Team
                            </Button>
                        </Box>
                    ),
                    content: (
                        <SmartTable
                            data={teams}
                            getProps={(t) => ({
                                id: t._id,
                                onClick: (d) => nav(d._id),
                            })}
                            pagination={{
                                count,
                                filter,
                                setFilter: (f) =>
                                    setFilter({ ...filter, ...f }),
                            }}
                        >
                            {{
                                Name: (team) => (
                                    <Typography sx={{ fontWeight: 600 }}>
                                        {team.name}
                                    </Typography>
                                ),
                                Members: (team) => (
                                    <Box sx={{ display: 'flex' }}>
                                        <AvatarGroup>
                                            {team.members.map((m) => (
                                                <Tooltip
                                                    key={'pic_' + m.user_id}
                                                    title={m.name}
                                                    arrow
                                                >
                                                    <Avatar
                                                        src={m.picture}
                                                        alt={m.name}
                                                    >
                                                        {m.given_name &&
                                                        m.family_name
                                                            ? `${m.given_name[0]}${m.family_name[0]}`
                                                            : ''}
                                                    </Avatar>
                                                </Tooltip>
                                            ))}
                                        </AvatarGroup>
                                    </Box>
                                ),
                                Permissions: (team) =>
                                    getUiPermissions(team.permissions)
                                        .map((p) => p.name)
                                        .join(', '),
                                Locations: (team) =>
                                    team.locations == null
                                        ? 'Any'
                                        : team.locations
                                              .map((l) => l.label)
                                              .join(', '),
                            }}
                        </SmartTable>
                    ),
                }}
            </NavContent>
        </AppNav>
    );
};

export default TeamList;
