import { gql } from '@apollo/client';
import { getMutationHook } from '../../types';
import { Recipe, RecipeFragment } from '../Recipe';
import { CreateRecipeInput } from '../inputs/CreateRecipeInput';

export const CreateRecipe = gql`
    ${RecipeFragment}
    mutation CreateRecipe($data: CreateRecipeInput!) {
        createRecipe(data: $data) {
            ...RecipeFragment
        }
    }
`;

export interface CreateRecipeRes {
    createRecipe: Recipe;
}

export interface CreateRecipeArgs {
    data: CreateRecipeInput;
}

export const useRecipeCreation = getMutationHook<
    CreateRecipeRes,
    CreateRecipeArgs
>(CreateRecipe);
