import { gql } from '@apollo/client';
import { BaseFragment } from '../../../../Base/Base';
import { getMutationHook } from '../../../../types';
import { MiscItem, MiscItemFragment } from '../MiscItem';
import { CreateMiscItemInput } from '../inputs/CreateMiscItemInput';

export const CreateMiscItemMutation = gql`
    ${BaseFragment}
    ${MiscItemFragment}
    mutation CreateMiscItemMutation($data: CreateMiscItemInput!) {
        createMiscItem(data: $data) {
            ...MiscItemFragment
        }
    }
`;

export interface CreateMiscItemArgs {
    data: CreateMiscItemInput;
}

export interface CreateMiscItemRes {
    createMiscItem: MiscItem;
}

export const useMiscItemCreation = getMutationHook<
    CreateMiscItemRes,
    CreateMiscItemArgs
>(CreateMiscItemMutation);
