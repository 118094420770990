import { gql } from '@apollo/client';
import { AppDocument } from '../AppDocument/AppDocument';
import { getQueryHook } from '../types';

export const LotLabelQuery = gql`
    query LotLabelQuery($id: ObjectId!) {
        lot(id: $id) {
            _id
            date_created
            document {
                filename
                mimetype
                data
            }
        }
    }
`;

export const useLotLabel = getQueryHook<
    {
        lot: { _id: string; date_created: Date; document: AppDocument };
    },
    { id: string }
>(LotLabelQuery);
