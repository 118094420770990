import { gql } from '@apollo/client';
import { Pagination } from '../../../utils/types/Pagination';
import { BatchLineFragment } from '../../BatchLine/BatchLine';
import { getQueryHook } from '../../types';
import { TinyUnitFragment } from '../../Unit/Unit';
import { VerificationFragment } from '../../Verification/Verification';
import { Batch, BatchFragment } from '../Batch';
import { BatchFilter } from '../BatchFilter';

export const BatchListQuery = gql`
    ${BatchFragment}
    ${BatchLineFragment}
    ${TinyUnitFragment}
    ${VerificationFragment}
    query BatchListQuery($filter: BatchFilter!) {
        batches(filter: $filter) {
            count
            items {
                ...BatchFragment
            }
        }
    }
`;

export const useBatches = getQueryHook<
    { batches: Pagination<Batch> },
    { filter: BatchFilter }
>(BatchListQuery);
