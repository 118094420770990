import { gql } from '@apollo/client';
import { Pagination } from '../../../../../utils/types/Pagination';
import { BaseFragment } from '../../../../Base/Base';
import { getQueryHook } from '../../../../types';
import {
    Package,
    PackageFragment,
    TinyPackage,
    TinyPackageFragment,
} from '../Package';
import { PackageFilter } from '../PackageFilter';

export const PackagesQuery = gql`
    ${BaseFragment}
    ${PackageFragment}
    query PackagesQuery($filter: PackageFilter!) {
        packages(filter: $filter) {
            count
            items {
                ...PackageFragment
            }
        }
    }
`;

export const TinyPackagesQuery = gql`
    ${TinyPackageFragment}
    query TinyPackagesQuery($filter: PackageFilter!) {
        packages(filter: $filter) {
            count
            items {
                ...TinyPackageFragment
            }
        }
    }
`;

export const usePackages = getQueryHook<
    { packages: Pagination<Package> },
    { filter: PackageFilter }
>(PackagesQuery);

export const useTinyPackages = getQueryHook<
    { packages: Pagination<TinyPackage> },
    { filter: PackageFilter }
>(TinyPackagesQuery);
