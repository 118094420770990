import { Box, TextField, Typography, useTheme } from '@mui/material/';
import React, { ReactElement } from 'react';
import CodeField from '../../../../../../../../components/inputs/FieldInputs/CodeField';
import { Bol } from '../../../../../../../../graphql/Bol/Bol';
import { CodeType } from '../../../../../../../../graphql/Code/useCode';
import { CreateShipmentArgs } from '../../../../../../../../graphql/Shipment/operations/useShipmentCreation';
import { UpdateShipmentArgs } from '../../../../../../../../graphql/Shipment/operations/useShipmentUpdate';
import ShipmentLineInput from './components/ShipmentLineInput';

export interface ShipmentFormPaperProps {
    state: UpdateShipmentArgs['data'] | CreateShipmentArgs['data'];
    setState: (
        data: UpdateShipmentArgs['data'] | CreateShipmentArgs['data']
    ) => void;
    bol: Bol;
}

const ShipmentFormPaper = (props: ShipmentFormPaperProps): ReactElement => {
    const { state, setState, bol } = props;

    const { palette, shape } = useTheme();

    return (
        <Box sx={{ maxWidth: 900 }}>
            <Box sx={{ display: 'flex', flexFlow: 'column', gap: 3 }}>
                {state.lines.map((line, lineIndex) => (
                    <ShipmentLineInput
                        key={`l_${lineIndex}`}
                        line_index={lineIndex}
                        {...props}
                        line={line}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default ShipmentFormPaper;
