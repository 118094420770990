import ButtonBase, { ButtonBaseProps } from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import React, { ReactElement } from 'react';
import { useRecipe } from '../../../graphql/Recipe/operations/useRecipe';
import { Recipe } from '../../../graphql/Recipe/Recipe';
import { useRecipeVersion } from '../../../graphql/RecipeVersion/operations/useRecipeVersion';
import { RecipeVersion } from '../../../graphql/RecipeVersion/RecipeVersion';
import { dateFormats } from '../../../utils/dateFormats';

export interface RecipeVersionButtonProps
    extends Omit<ButtonBaseProps, 'children' | 'value'> {
    value: string | null;
}

const RecipeVersionButton = (props: RecipeVersionButtonProps): ReactElement => {
    const { value: id, ...rest } = props;

    const [version, setVersion] = React.useState<RecipeVersion | null>(null);

    useRecipeVersion({
        variables: {
            id: id || '',
        },
        skip: !id,
        onCompleted: ({ recipeVersion }) => setVersion(recipeVersion),
    });

    return (
        <ButtonBase
            {...rest}
            sx={{
                ...rest.sx,
                flexFlow: 'column',
                textAlign: 'left',
                alignItems: 'flex-start',
            }}
        >
            <Typography
                color={id && version ? 'text.primary' : 'text.disabled'}
            >
                {id && version
                    ? format(
                          new Date(version.date_created),
                          dateFormats.condensedDate
                      )
                    : 'Select a version'}
            </Typography>
            {version && id && (
                <Typography variant="caption" color="text.secondary">
                    {`Version by ${version.created_by.name}`}
                </Typography>
            )}
        </ButtonBase>
    );
};

export default RecipeVersionButton;
