import { gql } from '@apollo/client';
import { Base, TinyBase } from '../Base/Base';
import { ItemType } from '../Item/Item';
import { UnitClass } from './UnitClass';

export interface Unit extends Base {
    name: string;
    plural: string;
    spanish_name: string;
    spanish_plural: string;
    class: UnitClass;
    base_per_unit: number;
    item_type: ItemType;
}

export interface TinyUnit extends TinyBase {
    name: string;
    plural: string;
    spanish_name: string;
    spanish_plural: string;
    class: UnitClass;
    base_per_unit: number;
    item_type: ItemType;
}

export const UnitFragment = gql`
    fragment UnitFragment on Unit {
        ...BaseFragment
        name
        plural
        spanish_name
        spanish_plural
        class
        base_per_unit
        item_type
    }
`;

export const TinyUnitFragment = gql`
    fragment TinyUnitFragment on Unit {
        _id
        name
        plural
        spanish_name
        spanish_plural
        class
        base_per_unit
        item_type
    }
`;
