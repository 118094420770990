import { gql } from '@apollo/client';
import { Item, TinyItem } from '../../Item';

export type MiscItem = Item;
export type TinyMiscItem = TinyItem;

export const MiscItemFragment = gql`
    fragment MiscItemFragment on MiscItem {
        ...BaseFragment
        name
        spanish_name
        unit_class
        conversions {
            to
            multiplier
        }
    }
`;

export const TinyMiscItemFragment = gql`
    fragment TinyMiscItemFragment on MiscItem {
        _id
        name
        spanish_name
        unit_class
        conversions {
            to
            multiplier
        }
    }
`;
