import { gql } from '@apollo/client';
import { Profile } from '../../auth/User';
import { TinyCompany } from '../Company/Company';
import { TinyItem } from '../Item/Item';

export interface TableOrder {
    _id: string;
    code: string;
    date_created: Date;
    origins: Location[];
    destinations: Location[];
    items: TinyItem[];
    customer: TinyCompany | null;
    vendor: TinyCompany | null;
    created_by: Profile;
}

export const TableOrderFragment = gql`
    fragment TableOrderFragment on TableOrder {
        _id
        code
        date_created
        origins {
            ...TinyLocationFragment
        }
        destinations {
            ...TinyLocationFragment
        }
        items {
            ...TinyItemFragment
        }
        customer {
            ...TinyCompanyFragment
        }
        vendor {
            ...TinyCompanyFragment
        }
        created_by {
            email
            name
            username
            user_id
            picture
            given_name
            family_name
            roles
        }
    }
`;
