import { gql } from '@apollo/client';
import { Pagination } from '../../../utils/types/Pagination';
import { getQueryHook } from '../../types';
import { TinyContact, TinyContactFragment } from '../Contact';
import { ContactFilter } from '../ContactFilter';

export const ContactsQuery = gql`
    ${TinyContactFragment}
    query ContactsQuery($filter: ContactFilter!) {
        contacts(filter: $filter) {
            count
            items {
                ...TinyContactFragment
            }
        }
    }
`;

export interface ContactsRes {
    contacts: Pagination<TinyContact>;
}

export interface ContactsArgs {
    filter: ContactFilter;
}

export const useTinyContacts = getQueryHook<ContactsRes, ContactsArgs>(
    ContactsQuery
);
