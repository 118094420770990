import { gql } from '@apollo/client';
import { Base } from '../Base/Base';
import { Bol } from '../Bol/Bol';
import { TinyCompany } from '../Company/Company';
import { ItinerarySchedule } from './ItinerarySchedule';

export interface Itinerary extends Base {
    code: string | null;
    carrier: TinyCompany | null;
    bols: Bol[];
    schedule: ItinerarySchedule;
}

export const ItineraryFragment = gql`
    fragment ItineraryFragment on Itinerary {
        ...BaseFragment
        code
        carrier {
            ...TinyCompanyFragment
        }
        bols {
            ...BolFragment
        }
        schedule {
            ...ItineraryScheduleFragment
        }
    }
`;
