import { gql } from '@apollo/client';
import { getLazyQueryHook, getQueryHook } from '../types';

export enum CodeType {
    PO = 'PO',
    Lot = 'Lot',
    Itin = 'Itin',
    Bol = 'Bol',
}

export const CodeQuery = gql`
    query CodeQuery($type: CodeType!) {
        code(type: $type)
    }
`;

export const useCode = getQueryHook<{ code: string }, { type: CodeType }>(
    CodeQuery
);

export const useLazyCode = getLazyQueryHook<
    { code: string },
    { type: CodeType }
>(CodeQuery);
