import {
    Box,
    Button,
    LinearProgress,
    List,
    ListItem,
    ListItemText,
    Typography,
    useTheme,
} from '@mui/material/';
import { format } from 'date-fns';
import React, { ReactElement } from 'react';
import { MdEdit, MdRestore } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import RecipeVersionPreview from '../../../../../../components/display/RecipeVersionPreview';
import ColumnBox from '../../../../../../components/Layout/ColumnBox';
import { Recipe } from '../../../../../../graphql/Recipe/Recipe';
import { useRecipeVersion } from '../../../../../../graphql/RecipeVersion/operations/useRecipeVersion';
import {
    RecipeVersionsRes,
    useRecipeVersions,
} from '../../../../../../graphql/RecipeVersion/operations/useRecipeVersions';
import { dateFormats } from '../../../../../../utils/dateFormats';
import NoVersionYet from '../NoVersionYet';

const VersionExplorer = (props: { recipe: Recipe }): ReactElement => {
    const { recipe } = props;
    const nav = useNavigate();
    const { palette, shape } = useTheme();

    const [focus, setFocus] = React.useState('');
    const [index, setIndex] = React.useState(0);

    const { data: versionData, loading: versionLoading } = useRecipeVersion({
        variables: focus ? { id: focus } : undefined,
        skip: focus == '',
        fetchPolicy: 'network-only',
    });

    const { data, error, loading } = useRecipeVersions({
        variables: {
            filter: {
                skip: index * 50,
                take: 50,
                recipe: recipe._id,
            },
        },
        fetchPolicy: 'network-only',
    });

    const versions = React.useMemo(
        () => (data ? data.recipeVersions.items : []),
        [data]
    );

    React.useEffect(() => {
        if (focus == '' && versions.length > 0) setFocus(versions[0]._id);
    }, [versions, focus]);

    if (!recipe.active) return <NoVersionYet>{recipe}</NoVersionYet>;

    return (
        <Box
            sx={{
                display: 'flex',
                flexFlow: 'column',
                height: '100%',
            }}
        >
            <Box p={2} />
            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    ...shape,
                    overflow: 'hidden',
                    border: `1px solid ${palette.divider}`,
                    position: 'relative',
                }}
            >
                {loading && (
                    <Box
                        sx={{ position: 'absolute', top: 0, left: 0, right: 0 }}
                    >
                        <LinearProgress />
                    </Box>
                )}
                <Box
                    sx={{
                        background: palette.tonal,
                        minWidth: 280,
                        overflow: 'hidden',
                    }}
                >
                    <ColumnBox>
                        {{
                            content: (
                                <List disablePadding>
                                    {versions.map((version) => (
                                        <ListItem
                                            onClick={() =>
                                                setFocus(version._id)
                                            }
                                            selected={focus === version._id}
                                            button
                                            divider
                                            key={version._id}
                                        >
                                            <ListItemText
                                                primary={format(
                                                    new Date(
                                                        version.date_created
                                                    ),
                                                    dateFormats.condensedDate
                                                )}
                                                secondary={
                                                    <Box>
                                                        <Typography>
                                                            {version.created_by
                                                                .name +
                                                                ' ' +
                                                                format(
                                                                    new Date(
                                                                        version.date_created
                                                                    ),
                                                                    dateFormats.time
                                                                )}
                                                        </Typography>
                                                        {version._id ==
                                                            recipe.active
                                                                ?._id && (
                                                            <Typography
                                                                sx={{
                                                                    color: palette
                                                                        .success
                                                                        .main,
                                                                }}
                                                                variant="body2"
                                                            >
                                                                <em>
                                                                    Current!
                                                                </em>
                                                            </Typography>
                                                        )}
                                                    </Box>
                                                }
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            ),
                            footer: (
                                <Box
                                    p={1}
                                    sx={{
                                        borderTop: `1px solid ${palette.divider}`,
                                    }}
                                >
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        {`Showing ${versions.length} of ${
                                            data
                                                ? data.recipeVersions.count
                                                : 'many'
                                        } versions`}
                                    </Typography>
                                </Box>
                            ),
                        }}
                    </ColumnBox>
                </Box>
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexFlow: 'row',
                        justifyContent: 'center',
                        paddingTop: 4,
                        paddingBottom: 8,
                        overflow: 'auto',
                    }}
                >
                    {versionData && (
                        <RecipeVersionPreview recipe={recipe}>
                            {versionData.recipeVersion}
                        </RecipeVersionPreview>
                    )}
                </Box>
            </Box>
            <Box p={2} />
            {focus !== '' && focus !== recipe.active?._id && (
                <Box
                    sx={{ position: 'absolute', bottom: '72px', right: '72px' }}
                >
                    <Button onClick={() => nav(focus)} endIcon={<MdRestore />}>
                        Restore this version
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default VersionExplorer;
