import { gql } from '@apollo/client';
import { AddressFragment } from '../../Address/Address';
import { TinyCompanyFragment } from '../../Company/Company';
import { TinyContactFragment } from '../../Contact/Contact';
import {
    CompanyLocationFragment,
    TinyLocationFragment,
} from '../../Location/Location';
import { MixingCardLineFragment } from '../../MixingCardLine/MixingCardLine';
import { RecipeFragment } from '../../Recipe/Recipe';
import { getQueryHook } from '../../types';
import { MixingCard, MixingCardFragment } from '../MixingCard';

export const MixingCardQuery = gql`
    ${MixingCardFragment}
    ${RecipeFragment}
    ${MixingCardLineFragment}
    ${RecipeFragment}
    ${TinyLocationFragment}
    ${TinyCompanyFragment}
    ${AddressFragment}
    ${TinyContactFragment}
    ${CompanyLocationFragment}
    query MixingCardQuery($id: ObjectId!) {
        mixingCard(id: $id) {
            ...MixingCardFragment
        }
    }
`;

export interface MixingCardRes {
    mixingCard: MixingCard;
}

export interface MixingCardArgs {
    id: string | null;
}

export const useMixingCard = getQueryHook<MixingCardRes, MixingCardArgs>(
    MixingCardQuery
);
