import React from 'react';
import { ReactNode } from 'react';
import { getUiPermissions, UiPermission } from '../../../../auth/UiPermission';
import { UserRole } from '../../../../auth/UserRole';
import LibraryBooksTwoToneIcon from '@mui/icons-material/LibraryBooksTwoTone';
import LocalShippingTwoToneIcon from '@mui/icons-material/LocalShippingTwoTone';
import AssignmentReturnTwoToneIcon from '@mui/icons-material/AssignmentReturnTwoTone';
import PersonPinTwoToneIcon from '@mui/icons-material/PersonPinTwoTone';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import useMyProfile from '../../../../auth/useMyProfile';
import { SxProps, Theme } from '@mui/material';
import {
    AccountTree,
    Assignment,
    Dashboard,
    DataThresholdingTwoTone,
} from '@mui/icons-material';
import { MdDashboard } from 'react-icons/md';

export interface AppNavRoute {
    icon: ReactNode;
    children: {
        label: string;
        path: string;
        auth:
            | { type: 'role'; role: UserRole }
            | { type: 'permission'; permission: UiPermission }
            | null;
    }[];
    auth:
        | { type: 'role'; role: UserRole }
        | { type: 'permission'; permission: UiPermission }
        | null;
}

const iconStyles: SxProps<Theme> = { fontSize: '1.75rem' };

const AppNavRoutes: Record<string, AppNavRoute> = {
    Dashboard: {
        icon: <Dashboard sx={{ ...iconStyles }} />,
        children: [{ label: 'Dashboard', path: '/dashboard', auth: null }],
        auth: { type: 'permission', permission: UiPermission.Dashboard },
    },
    Library: {
        icon: <LibraryBooksTwoToneIcon sx={{ ...iconStyles }} />,
        children: [
            { label: 'Companies', path: '/library/companies', auth: null },
            { label: 'Cookies', path: '/library/cookies', auth: null },
            { label: 'Ingredients', path: '/library/ingredients', auth: null },
            { label: 'Misc Items', path: '/library/miscitems', auth: null },
            { label: 'Packaging', path: '/library/packaging', auth: null },
            { label: 'Products', path: '/library/products', auth: null },
            { label: 'Units', path: '/library/units', auth: null },
        ],
        auth: { type: 'permission', permission: UiPermission.Library },
    },
    People: {
        icon: <PersonPinTwoToneIcon sx={{ ...iconStyles }} />,
        children: [
            { label: 'Profiles', path: '/people/profiles', auth: null },
            { label: 'Teams', path: '/people/teams', auth: null },
        ],
        auth: { type: 'role', role: UserRole.Manager },
    },
    ['Quality Assurance']: {
        icon: <ContentPasteGoIcon sx={{ ...iconStyles }} />,
        children: [
            { label: 'Quality Checks', path: '/qa/qualitychecks', auth: null },
        ],
        auth: { type: 'role', role: UserRole.Manager },
    },
    ['Supply Chain']: {
        icon: <AssignmentReturnTwoToneIcon sx={{ ...iconStyles }} />,
        children: [
            { label: 'Orders', path: '/supplychain/orders', auth: null },
            {
                label: 'Statistics',
                path: '/supplychain/statistics',
                auth: null,
            },
        ],
        auth: { type: 'permission', permission: UiPermission.Logistics },
    },
    Production: {
        icon: <DataThresholdingTwoTone sx={{ ...iconStyles }} />,
        children: [
            {
                label: 'Mixing Schedule',
                path: '/production/schedule',
                auth: {
                    type: 'permission',
                    permission: UiPermission.ProductionManager,
                },
            },
            {
                label: 'Batch Reports',
                path: '/production/batchreports',
                auth: {
                    type: 'permission',
                    permission: UiPermission.ProductionManager,
                },
            },
        ],
        auth: null,
    },
    Warehouse: {
        icon: <LocalShippingTwoToneIcon sx={{ ...iconStyles }} />,
        children: [
            { label: 'Shipping', path: '/warehouse/shipping', auth: null },
            { label: 'Receiving', path: '/warehouse/receiving', auth: null },
        ],
        auth: {
            type: 'permission',
            permission: UiPermission.WarehouseOperator,
        },
    },
    Recipes: {
        icon: <Assignment sx={{ ...iconStyles }} />,
        children: [{ label: 'Recipes', path: '/recipes', auth: null }],
        auth: {
            type: 'permission',
            permission: UiPermission.Recipes,
        },
    },
    Traceability: {
        icon: <AccountTree sx={{ ...iconStyles }} />,
        children: [
            { label: 'Lots', path: '/traceability/lots', auth: null },
            {
                label: 'Node Graph',
                path: '/traceability/nodegraph',
                auth: null,
            },
        ],
        auth: {
            type: 'role',
            role: UserRole.Manager,
        },
    },
};

export const useNavRoutes = (): Record<string, AppNavRoute> => {
    const { roles, permissions } = useMyProfile();

    const uiPermissions = getUiPermissions(permissions);

    return Object.keys(AppNavRoutes)
        .sort()
        .reduce((stack, item) => {
            const props = AppNavRoutes[item];
            if (!props.auth) {
                stack[item] = props;
            } else {
                if (props.auth.type == 'permission') {
                    if (
                        uiPermissions
                            .map((u) => u.name)
                            .includes(props.auth.permission) ||
                        roles.includes(UserRole.Admin) ||
                        roles.includes(UserRole.Manager)
                    ) {
                        stack[item] = props;
                    }
                } else {
                    switch (props.auth.role) {
                        case UserRole.Admin: {
                            if (roles.includes(UserRole.Admin))
                                stack[item] = props;
                            break;
                        }
                        case UserRole.Manager: {
                            if (
                                roles.includes(UserRole.Admin) ||
                                roles.includes(UserRole.Manager)
                            )
                                stack[item] = props;
                            break;
                        }
                    }
                }
            }
            return stack;
        }, {} as Record<string, AppNavRoute>);
};
