import { LoadingButton } from '@mui/lab';
import { Box, Button, Tooltip, Typography, useTheme } from '@mui/material/';
import React, { ReactElement } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { MdAdd, MdCheck, MdDelete } from 'react-icons/md';
import { JSONTree } from 'react-json-tree';
import { useNavigate, useParams } from 'react-router-dom';
import Message from '../../../../../../components/feedback/Message';
import CarefullButton from '../../../../../../components/inputs/Buttons/CarefulButton';
import OurLocationField from '../../../../../../components/inputs/FieldInputs/OurLocationField';
import ProductionLineInput from '../../../../../../components/inputs/FieldInputs/ProductionLineInput';
import ProfileField from '../../../../../../components/inputs/FieldInputs/ProfileField';
import AppNav from '../../../../../../components/Layout/AppNav/components';
import NavContent from '../../../../../../components/Layout/AppNav/components/NavContent';
import FormRow from '../../../../../../components/Layout/FormRow';
import NavHeader from '../../../../../../components/Layout/NavHeader';
import { useOurCompany } from '../../../../../../graphql/Company/operations/useOurCompany';
import { useMixingCard } from '../../../../../../graphql/MixingCard/operations/useMixingCard';
import {
    CreateMixingCardArgs,
    CreateMixingCardRes,
    useMixingCardCreation,
} from '../../../../../../graphql/MixingCard/operations/useMixingCardCreation';
import { MixingCardsQuery } from '../../../../../../graphql/MixingCard/operations/useMixingCards';
import {
    UpdateMixingCardArgs,
    UpdateMixingCardRes,
    useMixingCardUpdate,
} from '../../../../../../graphql/MixingCard/operations/useMixingCardUpdate';
import { relocate } from '../../../../../../utils/relocate';
import { OperationResult } from '../../../../../../utils/types/OperationResult';
import MixingCardLineForm from './components/MixingCardLineForm';

const MixingCardForm = (): ReactElement => {
    const { id, plant } = useParams();

    const { palette, shape } = useTheme();

    const { data: ourData } = useOurCompany();

    const nav = useNavigate();

    const ldb = ourData ? ourData.ourCompany : null;

    const [result, setResult] = React.useState<null | OperationResult<
        CreateMixingCardRes | UpdateMixingCardRes
    >>(null);

    const [state, setState] = React.useState<
        CreateMixingCardArgs | UpdateMixingCardArgs
    >({
        data: {
            mixer: '',
            location: plant || '',
            production_line: '',
            lines: [],
        },
    });

    useMixingCard({
        variables: { id: id || '' },
        skip: !id,
        onCompleted: ({ mixingCard: card }) =>
            setState({
                id: card._id,
                data: {
                    mixer: card.mixer.user_id || '',
                    location: card.location._id,
                    production_line: card.production_line._id,
                    lines: card.lines.map((line) => ({
                        recipe: line.recipe._id,
                        recipe_version: line.recipe_version
                            ? line.recipe_version._id
                            : null,
                        qty: line.qty,
                    })),
                },
            }),
    });

    const [handleCreate, { loading: createLoading, error: createError }] =
        useMixingCardCreation({
            onCompleted: (data) => setResult({ data, success: true }),
            onError: (error) => setResult({ error, success: false }),
            variables: 'id' in state ? undefined : state,
            refetchQueries: [MixingCardsQuery],
        });

    const [handleUpdate, { loading: updateLoading, error: updateError }] =
        useMixingCardUpdate({
            onCompleted: (data) => setResult({ data, success: true }),
            onError: (error) => setResult({ error, success: false }),
            variables: 'id' in state ? state : undefined,
            refetchQueries: [MixingCardsQuery],
        });

    const getHoldup = () => {
        if (!state.data.mixer) return 'Please select a mixer.';
        if (!state.data.location) return 'Please select a plant.';
        if (!state.data.production_line)
            return 'Please select a production line.';

        let res = null;

        for (const l of state.data.lines) {
            if (l.recipe === '') res = 'Each batch needs a recipe selection.';
            if (l.recipe_version == '')
                res = 'Please specify version, or use lastest.';
        }

        return res;
    };

    const holdup = getHoldup();

    const submit = () => {
        if ('id' in state) {
            handleUpdate();
        } else {
            handleCreate();
        }
    };

    return (
        <AppNav>
            {result ? (
                result.success ? (
                    <Message
                        type="Success"
                        onComplete={() => nav('/production/schedule')}
                    >
                        Mixing card saved!
                    </Message>
                ) : (
                    <Message
                        action={
                            <Button onClick={() => setResult(null)}>
                                Try again
                            </Button>
                        }
                        type="Error"
                        error={result.error}
                    />
                )
            ) : (
                <NavContent>
                    {{
                        header: (
                            <NavHeader>
                                <Box>
                                    <Typography variant="crisp">
                                        {`${
                                            id ? 'Update' : 'Create'
                                        } Mixing Card`}
                                    </Typography>
                                </Box>
                                <Box></Box>
                            </NavHeader>
                        ),
                        content: (
                            <Box>
                                <Box sx={{ maxWidth: 300 }}>
                                    <FormRow>
                                        <ProfileField
                                            label="Mixer"
                                            fullWidth
                                            multiple={false}
                                            value={state.data.mixer || ''}
                                            onChange={(e) => {
                                                setState({
                                                    ...state,
                                                    data: {
                                                        ...state.data,
                                                        mixer: e,
                                                    },
                                                });
                                            }}
                                        />
                                    </FormRow>
                                    <FormRow>
                                        <OurLocationField
                                            label="Plant"
                                            fullWidth
                                            multiple={false}
                                            value={state.data.location || ''}
                                            onChange={(e) => {
                                                setState({
                                                    ...state,
                                                    data: {
                                                        ...state.data,
                                                        location: e
                                                            ? e._id
                                                            : '',
                                                        production_line: '',
                                                    },
                                                });
                                            }}
                                        />
                                    </FormRow>
                                    <FormRow>
                                        <ProductionLineInput
                                            label="Line"
                                            fullWidth
                                            value={
                                                state.data.production_line || ''
                                            }
                                            plant={
                                                state.data.location || undefined
                                            }
                                            onChange={(e) => {
                                                setState({
                                                    ...state,
                                                    data: {
                                                        ...state.data,
                                                        production_line: e,
                                                    },
                                                });
                                            }}
                                        />
                                    </FormRow>
                                </Box>
                                <Box
                                    sx={{
                                        overflow: 'hidden',
                                        ...shape,
                                        width: state.data.lines.some(
                                            (l) => l.recipe_version !== null
                                        )
                                            ? 1050
                                            : 800,
                                        background: palette.tonal,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            background:
                                                palette.background.paper,
                                            p: 2,
                                        }}
                                    >
                                        <Typography>Batches</Typography>
                                        <Typography
                                            variant="caption"
                                            color="text.secondary"
                                        >
                                            Sorted in order for the mixer to
                                            follow
                                        </Typography>
                                    </Box>
                                    <DragDropContext
                                        onDragEnd={(e) => {
                                            if (e.destination) {
                                                const prevIndex =
                                                    e.source.index;
                                                const nextIndex =
                                                    e.destination.index;

                                                const copy = { ...state };

                                                relocate(
                                                    copy.data.lines,
                                                    prevIndex,
                                                    nextIndex
                                                );

                                                setState(copy);
                                            }
                                        }}
                                    >
                                        <Droppable droppableId="cards">
                                            {(provided, snapshot) => (
                                                <Box
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    {state.data.lines.map(
                                                        (line, lineIndex) => (
                                                            <MixingCardLineForm
                                                                index={
                                                                    lineIndex
                                                                }
                                                                key={
                                                                    'lineRender_' +
                                                                    lineIndex
                                                                }
                                                                line={line}
                                                                setLine={(
                                                                    e
                                                                ) => {
                                                                    const copy =
                                                                        {
                                                                            ...state,
                                                                        };
                                                                    if (e) {
                                                                        copy.data.lines[
                                                                            lineIndex
                                                                        ] = e;
                                                                        setState(
                                                                            copy
                                                                        );
                                                                    } else {
                                                                        copy.data.lines.splice(
                                                                            lineIndex,
                                                                            1
                                                                        );
                                                                        setState(
                                                                            copy
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        )
                                                    )}
                                                    {provided.placeholder}
                                                </Box>
                                            )}
                                        </Droppable>
                                    </DragDropContext>

                                    <Box sx={{ p: 1 }}>
                                        <Button
                                            variant="text"
                                            startIcon={<MdAdd />}
                                            onClick={() =>
                                                setState({
                                                    ...state,
                                                    data: {
                                                        ...state.data,
                                                        lines: [
                                                            ...state.data.lines,
                                                            {
                                                                recipe: '',
                                                                recipe_version:
                                                                    null,
                                                                qty: null,
                                                            },
                                                        ],
                                                    },
                                                })
                                            }
                                        >
                                            Batch
                                        </Button>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        paddingTop: 4,
                                        display: 'flex',
                                        gap: 2,
                                    }}
                                >
                                    {'id' in state && (
                                        <CarefullButton
                                            loading={
                                                updateLoading || createLoading
                                            }
                                            onClick={() => {
                                                handleUpdate({
                                                    onCompleted: (data) =>
                                                        setResult({
                                                            data,
                                                            success: true,
                                                        }),
                                                    onError: (error) =>
                                                        setResult({
                                                            error,
                                                            success: false,
                                                        }),
                                                    variables:
                                                        'id' in state
                                                            ? {
                                                                  ...state,
                                                                  data: {
                                                                      ...state.data,
                                                                      deleted:
                                                                          true,
                                                                  },
                                                              }
                                                            : undefined,
                                                });
                                            }}
                                            endIcon={<MdDelete />}
                                        >
                                            Delete Card
                                        </CarefullButton>
                                    )}
                                    <Tooltip arrow title={holdup || ''}>
                                        <Box>
                                            <LoadingButton
                                                onClick={() => submit()}
                                                loading={
                                                    updateLoading ||
                                                    createLoading
                                                }
                                                disabled={Boolean(holdup)}
                                                endIcon={<MdCheck />}
                                                variant="contained"
                                            >
                                                Save Card
                                            </LoadingButton>
                                        </Box>
                                    </Tooltip>
                                </Box>
                            </Box>
                        ),
                    }}
                </NavContent>
            )}
        </AppNav>
    );
};

export default MixingCardForm;
