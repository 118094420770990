import React, { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import CompanyDetail from './components/CompanyDetail';
import CompanyForm from './components/CompanyForm';
import CompanyList from './components/CompanyList';

const Companies = (): ReactElement => {
    return (
        <Routes>
            <Route index element={<CompanyList />} />
            <Route path={':id'} element={<CompanyDetail />} />
            <Route path={':id/:contact'} element={<CompanyDetail />} />
            <Route
                path={':id/location/:location'}
                element={<CompanyDetail />}
            />
            <Route path={':id/edit'} element={<CompanyForm />} />
            <Route path={'create'} element={<CompanyForm />} />
        </Routes>
    );
};

export default Companies;
