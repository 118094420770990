import {
    Box,
    Button,
    Collapse,
    MenuItem,
    TextField,
    useTheme,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { MdPreview } from 'react-icons/md';
import Message from '../../../../../../components/feedback/Message';
import ItemField from '../../../../../../components/inputs/FieldInputs/ItemField';
import QualityCheckResponseForm from '../../../../../../components/inputs/LightForms/FormResponsePreview';
import FormRow from '../../../../../../components/Layout/FormRow';
import ResponsiveDialog from '../../../../../../components/Layout/ResponsiveDialog';
import { useQualityCheckForms } from '../../../../../../graphql/Form/extensions/QualityCheckForm/operations/useQualityCheckForms';
import { QualityCheckCategory } from '../../../../../../graphql/Form/extensions/QualityCheckForm/QualityCheckCategory';
import { QualityCheckFormFilter } from '../../../../../../graphql/Form/extensions/QualityCheckForm/QualityCheckFormFilter';

const QualityCheckPreview = (): ReactElement => {
    const [open, setOpen] = React.useState(false);

    const { shape, palette } = useTheme();

    const [filter, setFilter] = React.useState<QualityCheckFormFilter>({
        skip: 0,
        take: 50,
        category: QualityCheckCategory.Receipt,
    });

    const { data, loading } = useQualityCheckForms({
        variables: { filter },
        skip: !filter.item,
        fetchPolicy: 'network-only',
    });

    const forms = data ? data.qualityCheckForms.items : [];

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Button
                onClick={() => setOpen(true)}
                startIcon={<MdPreview />}
                color="inherit"
            >
                Item Preview
            </Button>
            <ResponsiveDialog
                title="Quality Check Preview"
                open={open}
                onClose={handleClose}
                maxWidth="md"
                fullWidth
            >
                <Box>
                    <FormRow>
                        <ItemField
                            fullWidth
                            label="Item"
                            multiple={false}
                            value={filter.item || ''}
                            onChange={(item) => setFilter({ ...filter, item })}
                        />
                        <TextField
                            fullWidth
                            label="Asked During"
                            select
                            value={filter.category}
                            onChange={(e) => {
                                const category = e.target
                                    .value as QualityCheckCategory;

                                setFilter({ ...filter, category });
                            }}
                        >
                            <MenuItem value={QualityCheckCategory.Receipt}>
                                Receiving
                            </MenuItem>
                            <MenuItem value={QualityCheckCategory.Shipment}>
                                Shipping
                            </MenuItem>
                            <MenuItem value={QualityCheckCategory.Production}>
                                Production
                            </MenuItem>
                        </TextField>
                    </FormRow>
                    <Collapse in={forms.length > 0 && Boolean(filter.item)}>
                        <Box
                            sx={{
                                ...shape,
                                background: palette.background.paper,
                            }}
                        >
                            <QualityCheckResponseForm forms={forms} />
                        </Box>
                    </Collapse>
                    <Collapse
                        in={
                            forms.length == 0 &&
                            Boolean(filter.item) &&
                            !loading
                        }
                    >
                        <Box>
                            <Message type="Info">
                                No Quality Checks Found
                            </Message>
                        </Box>
                    </Collapse>
                </Box>
            </ResponsiveDialog>
        </React.Fragment>
    );
};

export default QualityCheckPreview;
