import { gql } from '@apollo/client';
import { BatchLineFragment } from '../../BatchLine/BatchLine';
import { getQueryHook } from '../../types';
import { TinyUnitFragment } from '../../Unit/Unit';
import { VerificationFragment } from '../../Verification/Verification';
import { Batch, BatchFragment } from '../Batch';

export const BatchQuery = gql`
    ${BatchFragment}
    ${BatchLineFragment}
    ${TinyUnitFragment}
    ${VerificationFragment}
    query BatchQuery($id: ObjectId!) {
        batch(id: $id) {
            ...BatchFragment
        }
    }
`;

export const useBatch = getQueryHook<{ batch: Batch }, { id: string }>(
    BatchQuery
);
