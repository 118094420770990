import { LoadingButton } from '@mui/lab';
import { Box, Button, Divider, Typography, useTheme } from '@mui/material';
import React, { ReactElement } from 'react';
import { MdAdd } from 'react-icons/md';
import { useItineraryCreation } from '../../../../../../../../../../graphql/Itinerary/operations/useItineraryCreation';
import { Order } from '../../../../../../../../../../graphql/Order/Order';
import ItineraryRender from './components/ItineraryRender';

export interface OrderBodyProps {
    children: Order;
}

const OrderBody = (props: OrderBodyProps): ReactElement => {
    const { children: order } = props;

    const { palette, shape } = useTheme();

    const [createItinerary, { loading: createItineraryLoading }] =
        useItineraryCreation({
            variables: {
                order: order._id,
                data: {
                    code: null,
                    carrier: null,
                    bols: [
                        {
                            code: null,
                            on_behalf: null,
                            from: {
                                company: null,
                                location: null,
                                date: null,
                                time: null,
                            },
                            to: {
                                company: null,
                                location: null,
                                date: null,
                                time: null,
                            },
                            contents: [],
                        },
                    ],
                },
            },
        });

    return (
        <Box
            sx={{
                display: 'flex',
                flexFlow: 'column',
                background: palette.tonal,
                padding: 2,
                paddingLeft: 4,
                paddingRight: 4,
            }}
        >
            <Typography variant="caption" color="textSecondary">
                {`There ${order.itineraries.length == 1 ? 'is' : 'are'} ${
                    order.itineraries.length
                } ${
                    order.itineraries.length == 1
                        ? 'truck assigned to this order'
                        : 'trucks assigned to this order'
                }`}
            </Typography>
            <Box p={0.5} />
            <Box sx={{ display: 'flex', flexFlow: 'column', gap: 4 }}>
                {order.itineraries.map((itin, index) => (
                    <ItineraryRender key={'itin_' + index}>
                        {itin}
                    </ItineraryRender>
                ))}
            </Box>
            <Box p={1} />
            <Box>
                <LoadingButton
                    loading={createItineraryLoading}
                    size="small"
                    variant="text"
                    startIcon={<MdAdd />}
                    onClick={() => createItinerary()}
                >
                    Truck to order
                </LoadingButton>
            </Box>
            <Box p={1} />
        </Box>
    );
};

export default OrderBody;
