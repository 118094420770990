import { gql } from '@apollo/client';
import { getQueryHook } from '../../types';
import { OrderQueue } from '../OrderQueue';

export const OrderQueueTemplates = gql`
    query OrderQueueTemplates($name: String) {
        orderQueueTemplates(name: $name) {
            _id
            date
            name
            status
            lines {
                po
                contents {
                    unit
                    item
                    quantity
                }
                customer
                customer_location
                vendor
                vendor_location
                date
                time
            }
        }
    }
`;

export const useOrderQueueTemplates = getQueryHook<
    {
        orderQueueTemplates: OrderQueue[];
    },
    { name?: string }
>(OrderQueueTemplates);
