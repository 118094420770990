import { format } from 'date-fns';
import React, { ReactElement } from 'react';
import { MdCalendarToday, MdPerson } from 'react-icons/md';
import { Base } from '../../../graphql/Base/Base';
import { dateFormats } from '../../../utils/dateFormats';
import DetailList, { DetailListProps } from '../../display/DetailList';

export interface BaseDetailsProps {
    children: Base;
    extension?: DetailListProps['children'];
}

const BaseDetails = (props: BaseDetailsProps): ReactElement => {
    const { children: base, extension = {} } = props;

    return (
        <DetailList>
            {{
                ...extension,
                ['Date created']: {
                    icon: <MdCalendarToday />,
                    primary: format(
                        new Date(base.date_created),
                        dateFormats.condensedDate
                    ),
                },
                ['Created by']: {
                    icon: <MdPerson />,
                    primary: base.created_by.name,
                },
            }}
        </DetailList>
    );
};

export default BaseDetails;
