import { Box, Button, Divider, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { MdChevronRight } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import Message from '../../../../../../components/feedback/Message';
import AppNav from '../../../../../../components/Layout/AppNav/components';
import NavContent from '../../../../../../components/Layout/AppNav/components/NavContent';
import { FulfillmentEvents } from '../../../../../../graphql/FulfillmentEvent/useFulfillmentEvents';
import { useOrder } from '../../../../../../graphql/Order/operations/useOrder';
import { Order } from '../../../../../../graphql/Order/Order';
import {
    CreateReceiptArgs,
    CreateReceiptRes,
    useReceiptCreation,
} from '../../../../../../graphql/Receipt/operations/useReceiptCreation';
import {
    UpdateReceiptArgs,
    UpdateReceiptRes,
    useReceiptUpdate,
} from '../../../../../../graphql/Receipt/operations/useReceiptUpdate';
import { Receipt } from '../../../../../../graphql/Receipt/Receipt';
import { niceList } from '../../../../../../utils/niceList';
import { OperationResult } from '../../../../../../utils/types/OperationResult';
import ReceiptStepper from './components/ReceiptStepper';

const getStateFromOrder = (
    order: Order,
    bol_id: string,
    receipt: Receipt | null
): UpdateReceiptArgs | CreateReceiptArgs => {
    if (receipt) {
        const bol =
            order.itineraries
                .map((itin) => itin.bols)
                .flat()
                .find((bol) => bol._id === bol_id) || null;

        const res: UpdateReceiptArgs = {
            id: receipt._id,
            data: {
                date_created: receipt.date_created,
                bol: bol ? bol.code || '' : '',
                lines: (bol ? bol.contents : []).map((content) => {
                    const line = receipt.lines.find(
                        (line) => line.item._id === content.item._id
                    );
                    return {
                        pallet_count: line ? line.pallet_count : 0,
                        item: content.item._id,
                        lots: line
                            ? line.lots.map((lot) => ({
                                  unit: lot.client_unit._id,
                                  quantity: lot.client_qty,
                                  code: lot.lot.code,
                              }))
                            : [{ unit: '', quantity: null, code: '' }],
                        form_response: {
                            responses: line
                                ? line.form_response.responses.map(
                                      ({
                                          response,
                                          category,
                                          question,
                                          options,
                                          required,
                                          passable_options,
                                      }) => ({
                                          response,
                                          category,
                                          question,
                                          options,
                                          required,
                                          passable_options,
                                      })
                                  )
                                : [],
                        },
                    };
                }),
                seal: receipt.seal,
            },
        };

        return res;
    } else {
        const bol =
            order.itineraries
                .map((itin) => itin.bols)
                .flat()
                .find((bol) => bol._id === bol_id) || null;

        if (!bol) throw new Error('Failed to index BOL in Order.');

        const res: CreateReceiptArgs = {
            bol: bol._id,
            data: {
                date_created: new Date(),
                bol: bol.code || '',
                lines: bol.contents.map((content) => ({
                    pallet_count: 0,
                    item: content.item._id,
                    lots: [
                        {
                            code: '',
                            quantity: content.client_qty,
                            unit: content.client_unit._id,
                        },
                    ],
                    form_response: { responses: [] },
                })),
                seal: '',
            },
        };

        return res;
    }
};

const ReceiptForm = (): ReactElement => {
    const { order: order_id, bol: bol_id, id } = useParams();

    const nav = useNavigate();

    const [state, setState] = React.useState<
        CreateReceiptArgs | UpdateReceiptArgs
    >({
        bol: '',
        data: {
            date_created: new Date(),
            bol: '',
            seal: '',
            lines: [],
        },
    });

    const { data, error, loading } = useOrder({
        variables: { id: order_id || '' },
        onCompleted: ({ order }) => {
            const bol =
                order.itineraries
                    .map((itin) => itin.bols)
                    .flat()
                    .find((bol) => bol._id === bol_id) || null;

            const receipt = bol
                ? bol.receipts.find((f) => f._id === id) || null
                : null;

            const defaultState =
                !order || !bol
                    ? null
                    : getStateFromOrder(order, bol._id, receipt);

            if (defaultState) {
                setState(defaultState);
            }
        },
    });

    const bol = data
        ? data.order.itineraries
              .map((itin) => itin.bols)
              .flat()
              .find((bol) => bol._id === bol_id) || null
        : null;

    const [result, setResult] = React.useState<OperationResult<
        CreateReceiptRes | UpdateReceiptRes
    > | null>(null);

    const [handleCreate, { loading: createLoading, error: createError }] =
        useReceiptCreation({
            variables: 'id' in state ? undefined : state,
            onCompleted: (data) => setResult({ success: true, data }),
            onError: (error) => setResult({ success: false, error }),
            refetchQueries: [FulfillmentEvents],
        });

    const [handleUpdate, { loading: updateLoading, error: updateError }] =
        useReceiptUpdate({
            variables: 'id' in state ? state : undefined,
            onCompleted: (data) => setResult({ success: true, data }),
            onError: (error) => setResult({ success: false, error }),
            refetchQueries: [FulfillmentEvents],
        });

    const submit = () => {
        if ('id' in state) handleUpdate();
        else handleCreate();
    };

    return (
        <AppNav
            error={error}
            loading={loading || createLoading || updateLoading}
        >
            {result && result.success ? (
                <Message
                    type="Success"
                    onComplete={() => {
                        const order: Order = Object.values(result.data)[0];

                        const receipts = order.itineraries
                            .map((itin) => itin.bols.map((bol) => bol.receipts))
                            .flat()
                            .flat();

                        const receiptIndex = receipts.findIndex(
                            (r) => r._id == id
                        );

                        if ('id' in state) {
                            // if deleted, return to receiving
                            // if not deleted, return to panel

                            if (receiptIndex == -1) {
                                // deleted
                                nav('/warehouse/receiving');
                            } else {
                                nav(`/warehouse/receiving/${id}`);
                            }
                        } else {
                            nav(
                                `/warehouse/receiving/${
                                    receipts[receipts.length - 1]._id
                                }/labels`
                            );
                        }
                    }}
                >
                    Saved
                </Message>
            ) : result ? (
                <Message
                    type="Error"
                    action={
                        <Button onClick={() => setResult(null)}>
                            Try again
                        </Button>
                    }
                >
                    {result.error.message}
                </Message>
            ) : (
                <NavContent>
                    {{
                        header: (
                            <Box sx={{ display: 'flex', flexFlow: 'column' }}>
                                <Typography variant="crisp">{`${
                                    id ? 'Update' : 'New'
                                } Receipt`}</Typography>
                                {bol && (
                                    <Box>
                                        <Typography
                                            sx={{ paddingTop: 1 }}
                                            variant="h6"
                                        >
                                            {niceList(
                                                bol.contents.map(
                                                    (content) =>
                                                        content.item.name
                                                )
                                            )}
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                paddingTop: 1.5,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexFlow: 'column',
                                                    gap: 0.25,
                                                }}
                                            >
                                                <Typography>
                                                    {bol.from.company
                                                        ? bol.from.company.name
                                                        : ''}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                >
                                                    {bol.from.location
                                                        ? bol.from.location
                                                              .label
                                                        : ''}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    paddingLeft: 2,
                                                    paddingRight: 2,
                                                }}
                                            >
                                                <MdChevronRight
                                                    style={{ fontSize: '2rem' }}
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexFlow: 'column',
                                                    gap: 0.25,
                                                }}
                                            >
                                                <Typography>
                                                    {bol.to.company
                                                        ? bol.to.company.name
                                                        : ''}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                >
                                                    {bol.to.location
                                                        ? bol.to.location.label
                                                        : ''}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box p={1} />
                                        <Divider />
                                    </Box>
                                )}
                            </Box>
                        ),
                        content: (
                            <Box>
                                {state && bol && (
                                    <ReceiptStepper
                                        bol={bol}
                                        state={state.data}
                                        setState={(d) => {
                                            setState({
                                                ...state,
                                                data: d,
                                            });
                                        }}
                                        handleSave={submit}
                                    />
                                )}
                            </Box>
                        ),
                    }}
                </NavContent>
            )}
        </AppNav>
    );
};

export default ReceiptForm;
