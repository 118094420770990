import { gql, useMutation } from '@apollo/client';
import { getMutationHook } from '../../../graphql/types';

const PasswordReset = gql`
    mutation ResetPassword($password: String!) {
        resetPassword(password: $password)
    }
`;

export const usePasswordReset = getMutationHook<
    { resetPassword: boolean },
    { password: string }
>(PasswordReset);
