import { Box, ButtonBase, useTheme } from '@mui/material/';
import React, { ReactElement, ReactNode } from 'react';
import { Receipt } from '../../../../../../../../graphql/Receipt/Receipt';
import ReceiptLineDocument from '../../../../../../../../components/Documents/ReceiptLineDocument';

export interface ReceiptLabelsProps {
    receipt: Receipt;
}

const ReceiptLabels = (props: ReceiptLabelsProps): ReactElement => {
    const { receipt } = props;

    return (
        <Box
            sx={{
                padding: 3,
                display: 'flex',
                flexFlow: 'column',
                gap: 3,
                alignItems: 'flex-start',
            }}
        >
            {receipt.lines.map((line) => (
                <ReceiptLineDocument
                    key={'label_' + line._id}
                    identifier={line.identifier}
                />
            ))}
        </Box>
    );
};

export default ReceiptLabels;
