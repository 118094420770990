import React from 'react';

const BARCODE_CHAR_TOLERANCE_MS = 80;

interface IBarcodeScannedState {
    data: string;
    lastCharacterTime: number;
    isBarcode: boolean;
}

export const useScanner = (props: { onScan: (code: string) => void }) => {
    const { onScan } = props;
    const [state, setState] = React.useState<IBarcodeScannedState>({
        data: '',
        lastCharacterTime: Date.now(),
        isBarcode: false,
    });

    React.useEffect(() => {
        const handleKeyPress = (ev: KeyboardEvent) => {
            const time = Date.now();

            if (
                state.lastCharacterTime + BARCODE_CHAR_TOLERANCE_MS > time &&
                !state.isBarcode
            ) {
                if (ev.key === 'Enter' && state.data.length > 0) {
                    const copy = { ...state };
                    onScan(copy.data);
                    setState({
                        data: '',
                        lastCharacterTime: Date.now(),
                        isBarcode: false,
                    });
                } else
                    setState({
                        ...state,
                        data: state.data + ev.key,
                        lastCharacterTime: time,
                    });
            } else {
                setState({
                    data: ev.key,
                    lastCharacterTime: time,
                    isBarcode: false,
                });
            }
        };

        window.addEventListener('keypress', handleKeyPress);

        return () => {
            window.removeEventListener('keypress', handleKeyPress);
        };
    }, [state]);
};
