import { gql } from '@apollo/client';
import { AddressFragment } from '../../Address/Address';
import { BaseFragment } from '../../Base/Base';
import { TinyContactFragment } from '../../Contact/Contact';
import { CompanyLocationFragment } from '../../Location/Location';
import { getMutationHook } from '../../types';
import { Company, CompanyFragment } from '../Company';
import { CreateCompanyInput } from '../inputs/CreateCompanyInput';

export const CreateCompanyMutation = gql`
    ${BaseFragment}
    ${CompanyFragment}
    ${TinyContactFragment}
    ${CompanyLocationFragment}
    ${AddressFragment}
    mutation CreateCompanyMutation($data: CreateCompanyInput!) {
        createCompany(data: $data) {
            ...CompanyFragment
        }
    }
`;

export interface CreateCompanyArgs {
    data: CreateCompanyInput;
}

export interface CreateCompanyRes {
    createCompany: Company;
}

export const useCompanyCreation = getMutationHook<
    CreateCompanyRes,
    CreateCompanyArgs
>(CreateCompanyMutation);
