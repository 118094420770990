import { Box, TextField } from '@mui/material/';
import React, { ReactElement } from 'react';
import { Recipe } from '../../../../../../graphql/Recipe/Recipe';
import { CreateRecipeVersionArgs } from '../../../../../../graphql/RecipeVersion/operations/useRecipeVersionCreation';
import { UpdateRecipeVersionArgs } from '../../../../../../graphql/RecipeVersion/operations/useRecipeVersionUpdate';
import { UnitClass } from '../../../../../../graphql/Unit/UnitClass';
import { baseUnits } from '../ConversionForm';

export interface OutputContentFormProps {
    recipe: Recipe;
    data: CreateRecipeVersionArgs['data'] | UpdateRecipeVersionArgs['data'];
    onChange: (val: number | null) => void;
}

const OutputContentForm = (props: OutputContentFormProps): ReactElement => {
    const { recipe, data, onChange } = props;

    return (
        <Box>
            <TextField
                value={
                    data.base_unit_produced == null
                        ? ''
                        : data.base_unit_produced
                }
                onChange={(e) => {
                    const parsed = parseFloat(e.target.value);
                    onChange(isNaN(parsed) ? null : parsed);
                }}
                fullWidth
                type="number"
                label={
                    recipe.item.unit_class == UnitClass.Count
                        ? `How many ${recipe.item.name}${
                              recipe.item.name.slice(-1) == 's' ? '' : 's'
                          } does this recipe produce?`
                        : `How many ${
                              baseUnits[recipe.item.unit_class][0]
                          } of ${recipe.item.name} does this recipe produce?`
                }
            />
        </Box>
    );
};

export default OutputContentForm;
