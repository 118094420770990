import React, { ReactElement } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import BatchReports from './components/BatchReports';
import MixingCardForm from './components/MixingSchedule/components/MixingCardForm';
import MixingSchedule from './components/MixingSchedule';
import BatchDetail from './components/BatchDetail';
import PermissionRoute from '../../routing/components/PermissionRoute';
import { getUiPermissions, UiPermission } from '../../auth/UiPermission';
import { useProfileContext } from '../../providers/ProfileProvider';
import Loading from '../Loading';
import NotFound from '../NotFound';

const Production = (): ReactElement => {
    const { data, loading } = useProfileContext();
    const permissions = data ? data.permissions : [];

    if (loading) return <Loading message="Production loading" />;

    const uiPermissions = getUiPermissions(permissions);
    return (
        <Routes>
            <Route index element={<Navigate to={'batchreports'} />} />
            <Route
                path="batchreports"
                element={
                    <PermissionRoute
                        permission={UiPermission.ProductionManager}
                    >
                        <BatchReports />
                    </PermissionRoute>
                }
            />
            <Route
                path="batchreports/:id"
                element={
                    <PermissionRoute
                        permission={UiPermission.ProductionManager}
                    >
                        <BatchDetail />
                    </PermissionRoute>
                }
            />
            <Route
                path="schedule"
                element={
                    <PermissionRoute
                        permission={UiPermission.ProductionManager}
                    >
                        <MixingSchedule />
                    </PermissionRoute>
                }
            />
            <Route
                path="schedule/:plant/create"
                element={
                    <PermissionRoute
                        permission={UiPermission.ProductionManager}
                    >
                        <MixingCardForm />
                    </PermissionRoute>
                }
            />
            <Route
                path="schedule/card/:id"
                element={
                    <PermissionRoute
                        permission={UiPermission.ProductionManager}
                    >
                        <MixingCardForm />
                    </PermissionRoute>
                }
            />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default Production;
