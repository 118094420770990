import { gql } from '@apollo/client';
import { BaseFragment } from '../../Base/Base';
import { getMutationHook } from '../../types';
import { Contact, ContactFragment } from '../Contact';
import { UpdateContactInput } from '../inputs/UpdateContactInput';

export const UpdateContactMutation = gql`
    ${ContactFragment}
    ${BaseFragment}
    mutation UpdateContactMutation($id: ObjectId!, $data: UpdateContactInput!) {
        updateContact(id: $id, data: $data) {
            ...ContactFragment
        }
    }
`;

export interface UpdateContactRes {
    updateContact: Contact;
}

export interface UpdateContactArgs {
    id: string;
    data: UpdateContactInput;
}

export const useContactUpdate = getMutationHook<
    UpdateContactRes,
    UpdateContactArgs
>(UpdateContactMutation);
