import { gql } from '@apollo/client';
import { TinyItem } from '../Item/Item';
import { TinyLocation } from '../Location/Location';
import { UnitClass } from '../Unit/UnitClass';

export interface OrderStat {
    month: number;
    year: number;
    item: TinyItem;
    location: TinyLocation;
    unit_class: UnitClass;
    qty: number;
}

export const OrderStatFragment = gql`
    fragment OrderStatFragment on OrderStat {
        month
        year
        item {
            _id
            name
        }
        location {
            _id
            label
        }
        unit_class
        qty
    }
`;
