import {
    Box,
    Drawer,
    FormControlLabel,
    IconButton,
    Skeleton,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { MdClear, MdDelete } from 'react-icons/md';
import { CompanyQuery } from '../../../../graphql/Company/operations/useCompany';
import { useContact } from '../../../../graphql/Contact/operations/useContact';
import {
    CreateContactArgs,
    CreateContactRes,
    useContactCreation,
} from '../../../../graphql/Contact/operations/useContactCreation';
import {
    UpdateContactArgs,
    UpdateContactRes,
    useContactUpdate,
} from '../../../../graphql/Contact/operations/useContactUpdate';
import { validateEmail } from '../../../../scenes/MyAccount/checkPassword';
import { OperationResult } from '../../../../utils/types/OperationResult';
import FormResult from '../../../feedback/FormResult';
import FormRow from '../../../Layout/FormRow';
import CarefullButton from '../../Buttons/CarefulButton';
import SuccessButton from '../../Buttons/SuccessButton';
import CompanyField from '../../FieldInputs/CompanyField';

export interface ContactDrawerProps {
    default_asc: string[];
    focus: string | null | true;
    onClose: () => void;
}

const ContactDrawer = (props: ContactDrawerProps): ReactElement => {
    const { focus, default_asc, onClose } = props;

    const [state, setState] = React.useState<
        CreateContactArgs | UpdateContactArgs
    >({
        data: {
            name: '',
            email: '',
            phone: '',
            associations: default_asc,
            send_cc: false,
            send_to: false,
        },
    });

    const [result, setResult] = React.useState<null | OperationResult<
        CreateContactRes | UpdateContactRes
    >>(null);

    const [handleCreate, { loading: createLoading }] = useContactCreation({
        variables: 'id' in state ? undefined : state,
        onCompleted: (data) => setResult({ success: true, data }),
        onError: (error) => setResult({ success: false, error }),
        refetchQueries: [CompanyQuery],
    });

    const [handleUpdate, { loading: updateLoading }] = useContactUpdate({
        variables:
            'id' in state
                ? {
                      ...state,
                      id: focus !== null && focus !== true ? focus : '',
                  }
                : undefined,
        onCompleted: (data) => setResult({ success: true, data }),
        onError: (error) => setResult({ success: false, error }),
        refetchQueries: [CompanyQuery],
    });

    const { data } = useContact({
        variables: focus !== true && focus !== null ? { id: focus } : undefined,
        skip: focus == true || focus == null,
        fetchPolicy: 'network-only',
        onCompleted: ({ contact }) => {
            setState({
                id: contact._id,
                data: {
                    name: contact.name,
                    email: contact.email,
                    send_cc: contact.send_cc,
                    send_to: contact.send_to,
                    associations: contact.associations.map((a) => a._id),
                },
            });
        },
    });

    const handleClose = () => {
        setResult(null);
        setState({
            data: {
                name: '',
                email: '',
                phone: '',
                associations: default_asc,
                send_cc: false,
                send_to: false,
            },
        });
        onClose();
    };

    const getHoldup = () => {
        if (!validateEmail(state.data.email))
            return 'Please enter a valid email';
        else if (!state.data.name) return 'Please enter a name.';
        return null;
    };

    return (
        <Drawer
            PaperProps={{ sx: { padding: 2 } }}
            open={focus !== null}
            anchor="right"
            onClose={handleClose}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingBottom: 2,
                    width: 400,
                }}
            >
                <Typography variant="crisp">
                    {focus !== true && focus !== null ? (
                        data ? (
                            <Typography variant="h4">
                                {data.contact.name}
                            </Typography>
                        ) : (
                            <Skeleton height={60} width={100} />
                        )
                    ) : (
                        <Typography variant="h4">New Contact</Typography>
                    )}
                </Typography>
                <IconButton onClick={handleClose}>
                    <MdClear />
                </IconButton>
            </Box>
            {result ? (
                <FormResult
                    entity="Contact"
                    clear={() => setResult(null)}
                    onComplete={handleClose}
                >
                    {result}
                </FormResult>
            ) : (
                <Box>
                    <FormRow>
                        <TextField
                            fullWidth
                            label="Name"
                            value={state.data.name}
                            onChange={(e) =>
                                setState({
                                    ...state,
                                    data: {
                                        ...state.data,
                                        name: e.target.value,
                                    },
                                })
                            }
                        />
                    </FormRow>
                    <FormRow>
                        <TextField
                            fullWidth
                            label="Email"
                            value={state.data.email}
                            onChange={(e) =>
                                setState({
                                    ...state,
                                    data: {
                                        ...state.data,
                                        email: e.target.value,
                                    },
                                })
                            }
                        />
                    </FormRow>
                    <FormRow>
                        <CompanyField
                            fullWidth
                            multiple
                            label="Associations"
                            value={state.data.associations}
                            onChange={(associations) =>
                                setState({
                                    ...state,
                                    data: {
                                        ...state.data,
                                        associations,
                                    },
                                })
                            }
                        />
                    </FormRow>
                    <FormRow>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={state.data.send_to}
                                    onChange={(e, send_to) => {
                                        setState({
                                            ...state,
                                            data: {
                                                ...state.data,
                                                send_to,
                                            },
                                        });
                                    }}
                                />
                            }
                            label="Email on orders"
                        />
                    </FormRow>
                    <FormRow>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={state.data.send_cc}
                                    onChange={(e, send_cc) => {
                                        setState({
                                            ...state,
                                            data: {
                                                ...state.data,
                                                send_cc,
                                            },
                                        });
                                    }}
                                />
                            }
                            label="CC on orders"
                        />
                    </FormRow>
                    <FormRow>
                        {data ? (
                            <CarefullButton
                                startIcon={<MdDelete />}
                                loading={createLoading || updateLoading}
                                onClick={() =>
                                    handleUpdate({
                                        variables: {
                                            id: data.contact._id,
                                            data: {
                                                deleted: true,
                                                name: data.contact.name,
                                                email: data.contact.email,
                                                send_cc: data.contact.send_cc,
                                                send_to: data.contact.send_to,
                                                associations:
                                                    data.contact.associations.map(
                                                        (a) => a._id
                                                    ),
                                            },
                                        },
                                        onCompleted: (data) =>
                                            setResult({ success: true, data }),
                                        onError: (error) =>
                                            setResult({
                                                success: false,
                                                error,
                                            }),
                                        refetchQueries: [CompanyQuery],
                                    })
                                }
                            >
                                Delete Contact
                            </CarefullButton>
                        ) : (
                            <Box sx={{ flex: 1 }} />
                        )}
                        <SuccessButton
                            holdup={getHoldup()}
                            loading={createLoading || updateLoading}
                            success={result ? 'Contact saved' : null}
                            onSuccess={handleClose}
                            onClick={() =>
                                'id' in state ? handleUpdate() : handleCreate()
                            }
                        >
                            Save Contact
                        </SuccessButton>
                    </FormRow>
                </Box>
            )}
        </Drawer>
    );
};

export default ContactDrawer;
