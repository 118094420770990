import {
    Box,
    ButtonBase,
    Grid,
    GridProps,
    Typography,
    useTheme,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router';
import { Draggable } from 'react-beautiful-dnd';
import { Folder } from '../../../../../../graphql/Folder/Folder';
import { MdAssignment } from 'react-icons/md';
import { format } from 'date-fns';
import { dateFormats } from '../../../../../../utils/dateFormats';

export interface RecipeChipProps {
    children: Folder['recipes'][number];
    index: number;
    setFocus: (data: {
        coordinates: {
            x: number;
            y: number;
        };
        recipe: Folder['recipes'][number];
    }) => void;
}

const RecipeChip = (props: RecipeChipProps): ReactElement => {
    const { children: recipe, index, setFocus } = props;
    const nav = useNavigate();

    const { palette, shape } = useTheme();

    const gridProps: GridProps = {
        xs: 12,
    };

    return (
        <Grid {...gridProps} item>
            <Box>
                <Draggable index={index} draggableId={'recipe_' + recipe._id}>
                    {(provided, snapshot) => (
                        <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <ButtonBase
                                onContextMenu={(event) => {
                                    event.preventDefault();
                                    setFocus({
                                        recipe,
                                        coordinates: {
                                            x: event.clientX - 4,
                                            y: event.clientY - 4,
                                        },
                                    });
                                }}
                                onClick={() =>
                                    nav(`/recipes/recipe/${recipe._id}`)
                                }
                                sx={{
                                    width: '100%',
                                    textAlign: 'left',
                                    justifyContent: 'flex-start',
                                    padding: 2,
                                    borderBottom: `1px solid ${palette.divider}`,
                                    ...(snapshot.combineWith
                                        ? { opacity: 0.35 }
                                        : snapshot.combineTargetFor
                                        ? {
                                              background:
                                                  palette.background.paper,
                                          }
                                        : undefined),
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 2,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 2,
                                            width: '360px',
                                        }}
                                    >
                                        <Box>
                                            <MdAssignment
                                                style={{
                                                    fontSize: '2rem',
                                                    color: palette.primary.main,
                                                }}
                                            />
                                        </Box>
                                        <Box>
                                            <Typography>
                                                {recipe.name}
                                            </Typography>
                                            <Typography
                                                variant="caption"
                                                color="text.secondary"
                                            >
                                                {recipe.item.name}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    {recipe.active && (
                                        <Box>
                                            <Typography
                                                color="text.secondary"
                                                variant="caption"
                                            >{`Modified by ${
                                                recipe.active.created_by.name
                                            } on ${format(
                                                new Date(
                                                    recipe.active.date_created
                                                ),
                                                dateFormats.condensedDate +
                                                    ' @ ' +
                                                    dateFormats.time
                                            )}`}</Typography>
                                        </Box>
                                    )}
                                </Box>
                            </ButtonBase>
                        </Box>
                    )}
                </Draggable>
            </Box>
        </Grid>
    );
};

export default RecipeChip;
