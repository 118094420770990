import {
    Box,
    Button,
    Collapse,
    Divider,
    FormControlLabel,
    IconButton,
    InputAdornment,
    ListItemSecondaryAction,
    MenuItem,
    Switch,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import React, { ReactElement, ReactNode } from 'react';
import {
    MdAdd,
    MdCalendarToday,
    MdCheckBox,
    MdCheckBoxOutlineBlank,
    MdClear,
    MdExpandLess,
    MdExpandMore,
    MdRadioButtonUnchecked,
    MdArrowDropDown,
    MdUpload,
    MdLinearScale,
    MdCalculate,
    MdShortText,
    MdViewHeadline,
    MdAccessTime,
} from 'react-icons/md';
import { FieldCategory } from '../../../../../../../../graphql/Form/FieldCategory';
import { FormFieldInput } from '../../../../../../../../graphql/Form/inputs/FormFieldInput';

export interface FormFieldLineProps {
    value: FormFieldInput;
    onChange: (value: FormFieldInput | null) => void;
    index: number;
    length: number;
    reIndex: (direction: 1 | -1) => void;
}

export const CategoryLanguage: Record<FieldCategory, string> = {
    [FieldCategory.CheckBoxes]: 'Checkboxes',
    [FieldCategory.Date]: 'Date',
    [FieldCategory.Dropdown]: 'Dropdown',
    [FieldCategory.LinearScale]: 'Linear Scale',
    [FieldCategory.Number]: 'Number',
    [FieldCategory.Paragraph]: 'Paragraph',
    [FieldCategory.ShortAnswer]: 'Short Answer',
    [FieldCategory.Time]: 'Time',
};

export const CategoryIcons: Record<FieldCategory, ReactNode> = {
    [FieldCategory.CheckBoxes]: <MdCheckBox />,
    [FieldCategory.Date]: <MdCalendarToday />,
    [FieldCategory.Dropdown]: <MdArrowDropDown />,
    [FieldCategory.LinearScale]: <MdLinearScale />,
    [FieldCategory.Number]: <MdCalculate />,
    [FieldCategory.Paragraph]: <MdViewHeadline />,
    [FieldCategory.ShortAnswer]: <MdShortText />,
    [FieldCategory.Time]: <MdAccessTime />,
};

const FormFieldLine = (props: FormFieldLineProps): ReactElement => {
    const {
        value: state,
        onChange: setState,
        index: opIndex,
        length,
        reIndex,
    } = props;

    const { palette, shape } = useTheme();

    const defaultOptions: Record<FieldCategory, [string[], string[]]> = {
        [FieldCategory.CheckBoxes]:
            state.category === FieldCategory.Dropdown
                ? [state.options, state.passable_options]
                : [[''], []],
        [FieldCategory.Date]: [[], []],
        [FieldCategory.Dropdown]:
            state.category === FieldCategory.CheckBoxes
                ? [state.options, state.passable_options]
                : [[''], []],
        [FieldCategory.LinearScale]: [
            ['', ''],
            ['', ''],
        ],
        [FieldCategory.Number]: [[''], []],
        [FieldCategory.Paragraph]: [[], []],
        [FieldCategory.ShortAnswer]: [[], []],
        [FieldCategory.Time]: [[], []],
    };

    const controls: Record<FieldCategory, ReactElement | null> = {
        [FieldCategory.CheckBoxes]: (
            <Box>
                {state.options.map((option, index) => (
                    <Box
                        key={'op_' + index}
                        sx={{
                            paddingTop: 1,
                            paddingBottom: 1,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                        }}
                    >
                        <IconButton
                            size="small"
                            onClick={() => {
                                const copy = { ...state };
                                copy.options = copy.options.filter(
                                    (op) => op !== option
                                );
                                copy.passable_options =
                                    copy.passable_options.filter(
                                        (op) => op !== option
                                    );
                                setState(copy);
                            }}
                        >
                            <MdClear />
                        </IconButton>
                        <TextField
                            value={option}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (state.passable_options.includes(option)) {
                                    // update both
                                    const copy = [...state.options];
                                    copy[index] = value;
                                    const passCopy = [
                                        ...state.passable_options,
                                    ];
                                    const passIndex = passCopy.indexOf(option);
                                    passCopy[passIndex] = value;
                                    setState({
                                        ...state,
                                        options: copy,
                                        passable_options: passCopy.filter(
                                            (f) => f !== ''
                                        ),
                                    });
                                } else {
                                    // update single
                                    const copy = [...state.options];
                                    copy[index] = value;
                                    setState({ ...state, options: copy });
                                }
                            }}
                            fullWidth
                            placeholder="Option"
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <MdRadioButtonUnchecked />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <FormControlLabel
                                            label={
                                                <Typography
                                                    sx={{ paddingLeft: 1 }}
                                                    variant="body2"
                                                    color="textSecondary"
                                                >
                                                    Acceptable
                                                </Typography>
                                            }
                                            control={
                                                <Switch
                                                    disabled={!option}
                                                    size="small"
                                                    checked={state.passable_options.includes(
                                                        option
                                                    )}
                                                    onChange={(e, checked) => {
                                                        if (checked) {
                                                            setState({
                                                                ...state,
                                                                passable_options:
                                                                    [
                                                                        ...new Set(
                                                                            [
                                                                                ...state.passable_options,
                                                                                option,
                                                                            ]
                                                                        ),
                                                                    ],
                                                            });
                                                        } else {
                                                            setState({
                                                                ...state,
                                                                passable_options:
                                                                    state.passable_options.filter(
                                                                        (op) =>
                                                                            op !==
                                                                            option
                                                                    ),
                                                            });
                                                        }
                                                    }}
                                                />
                                            }
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                ))}
                <Button
                    sx={{ marginLeft: -1, marginTop: 1 }}
                    variant="text"
                    size="small"
                    color="inherit"
                    startIcon={<MdAdd />}
                    onClick={() =>
                        setState({ ...state, options: [...state.options, ''] })
                    }
                >
                    Option
                </Button>
            </Box>
        ),
        [FieldCategory.Date]: null,
        [FieldCategory.Dropdown]: (
            <Box>
                {state.options.map((option, index) => (
                    <Box
                        key={'op_' + index}
                        sx={{
                            paddingTop: 1,
                            paddingBottom: 1,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                        }}
                    >
                        <IconButton
                            size="small"
                            onClick={() => {
                                const copy = { ...state };
                                copy.options = copy.options.filter(
                                    (op) => op !== option
                                );
                                copy.passable_options =
                                    copy.passable_options.filter(
                                        (op) => op !== option
                                    );
                                setState(copy);
                            }}
                        >
                            <MdClear />
                        </IconButton>
                        <TextField
                            value={option}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (state.passable_options.includes(option)) {
                                    // update both
                                    const copy = [...state.options];
                                    copy[index] = value;
                                    const passCopy = [
                                        ...state.passable_options,
                                    ];
                                    const passIndex = passCopy.indexOf(option);
                                    passCopy[passIndex] = value;
                                    setState({
                                        ...state,
                                        options: copy,
                                        passable_options: passCopy.filter(
                                            (f) => f !== ''
                                        ),
                                    });
                                } else {
                                    // update single
                                    const copy = [...state.options];
                                    copy[index] = value;
                                    setState({ ...state, options: copy });
                                }
                            }}
                            fullWidth
                            placeholder="Option"
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <MdCheckBoxOutlineBlank />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <FormControlLabel
                                            label={
                                                <Typography
                                                    sx={{ paddingLeft: 1 }}
                                                    variant="body2"
                                                    color="textSecondary"
                                                >
                                                    Acceptable
                                                </Typography>
                                            }
                                            control={
                                                <Switch
                                                    disabled={!option}
                                                    size="small"
                                                    checked={state.passable_options.includes(
                                                        option
                                                    )}
                                                    onChange={(e, checked) => {
                                                        if (checked) {
                                                            setState({
                                                                ...state,
                                                                passable_options:
                                                                    [
                                                                        ...new Set(
                                                                            [
                                                                                ...state.passable_options,
                                                                                option,
                                                                            ]
                                                                        ),
                                                                    ],
                                                            });
                                                        } else {
                                                            setState({
                                                                ...state,
                                                                passable_options:
                                                                    state.passable_options.filter(
                                                                        (op) =>
                                                                            op !==
                                                                            option
                                                                    ),
                                                            });
                                                        }
                                                    }}
                                                />
                                            }
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                ))}
                <Button
                    sx={{ marginLeft: -1, marginTop: 1 }}
                    variant="text"
                    size="small"
                    color="inherit"
                    startIcon={<MdAdd />}
                    onClick={() =>
                        setState({ ...state, options: [...state.options, ''] })
                    }
                >
                    Option
                </Button>
            </Box>
        ),
        [FieldCategory.LinearScale]: (
            <Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <TextField
                        value={state.options[0]}
                        variant="standard"
                        InputProps={{ disableUnderline: true }}
                        label="From"
                        type="number"
                        onChange={(e) => {
                            const val = e.target.value.replaceAll('.', '');
                            const parsed = val ? parseInt(val).toString() : '';
                            setState({
                                ...state,
                                options: [parsed, state.options[1]],
                            });
                        }}
                        inputProps={{
                            step: 1,
                        }}
                    />
                    <TextField
                        value={state.options[1]}
                        variant="standard"
                        InputProps={{ disableUnderline: true }}
                        label="To"
                        type="number"
                        onChange={(e) => {
                            const val = e.target.value.replaceAll('.', '');
                            const parsed = val ? parseInt(val).toString() : '';
                            setState({
                                ...state,
                                options: [state.options[0], parsed],
                            });
                        }}
                        inputProps={{
                            step: 1,
                        }}
                    />
                </Box>
                <Box sx={{ display: 'flex', gap: 2, paddingTop: 2 }}>
                    <TextField
                        value={state.passable_options[0]}
                        variant="standard"
                        InputProps={{ disableUnderline: true }}
                        label="From label"
                        onChange={(e) => {
                            const val = e.target.value;
                            setState({
                                ...state,
                                passable_options: [
                                    val,
                                    state.passable_options[1],
                                ],
                            });
                        }}
                    />
                    <TextField
                        value={state.passable_options[1]}
                        variant="standard"
                        InputProps={{ disableUnderline: true }}
                        label="To label"
                        onChange={(e) => {
                            const val = e.target.value;
                            setState({
                                ...state,
                                passable_options: [
                                    state.passable_options[0],
                                    val,
                                ],
                            });
                        }}
                    />
                </Box>
            </Box>
        ),
        [FieldCategory.Number]: (
            <Box>
                <FormControlLabel
                    label="Specify acceptable range"
                    control={<Switch checked={state.options.length == 2} />}
                    onChange={(e, checked) => {
                        if (checked) {
                            setState({ ...state, options: ['0', '10'] });
                        } else {
                            setState({ ...state, options: [] });
                        }
                    }}
                />
                <Collapse in={state.options.length == 2}>
                    <Box sx={{ marginTop: 2, display: 'flex', gap: 2 }}>
                        <TextField
                            error={
                                state.options.length == 2 &&
                                parseFloat(state.options[0]) >=
                                    parseFloat(state.options[1])
                            }
                            sx={{ maxWidth: 80 }}
                            variant="standard"
                            InputProps={{ disableUnderline: true }}
                            label="Min"
                            type="number"
                            value={
                                state.options[0] !== undefined
                                    ? state.options[0]
                                    : ''
                            }
                            onChange={(e) =>
                                setState({
                                    ...state,
                                    options: [e.target.value, state.options[1]],
                                })
                            }
                        />
                        <TextField
                            error={
                                state.options.length == 2 &&
                                parseFloat(state.options[0]) >=
                                    parseFloat(state.options[1])
                            }
                            sx={{ maxWidth: 80 }}
                            variant="standard"
                            InputProps={{ disableUnderline: true }}
                            label="Max"
                            type="number"
                            value={
                                state.options[1] !== undefined
                                    ? state.options[1]
                                    : ''
                            }
                            onChange={(e) =>
                                setState({
                                    ...state,
                                    options: [state.options[0], e.target.value],
                                })
                            }
                        />
                    </Box>
                </Collapse>
            </Box>
        ),
        [FieldCategory.Paragraph]: null,
        [FieldCategory.ShortAnswer]: null,
        [FieldCategory.Time]: null,
    };

    return (
        <Box
            sx={{
                padding: 2,
                background: palette.background.paper,
                ...shape,
                position: 'relative',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    gap: 3,
                }}
            >
                <TextField
                    autoFocus
                    fullWidth
                    label="Question"
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    value={state.question}
                    onChange={(e) => {
                        setState({ ...state, question: e.target.value });
                    }}
                />
                <TextField
                    InputProps={{ disableUnderline: true }}
                    variant="standard"
                    fullWidth
                    value={state.category}
                    onChange={(e) => {
                        setState({
                            ...state,
                            category: e.target.value as FieldCategory,
                            options:
                                defaultOptions[
                                    e.target.value as FieldCategory
                                ][0],
                            passable_options:
                                defaultOptions[
                                    e.target.value as FieldCategory
                                ][1],
                        });
                    }}
                    label="Type"
                    select
                >
                    {Object.keys(FieldCategory).map((cat) => (
                        <MenuItem value={cat} key={'cat_' + cat}>
                            <ListItemSecondaryAction sx={{ paddingRight: 2 }}>
                                {CategoryIcons[cat as FieldCategory]}
                            </ListItemSecondaryAction>
                            {CategoryLanguage[cat as FieldCategory]}
                        </MenuItem>
                    ))}
                </TextField>
                <FormControlLabel
                    label={
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{ paddingLeft: 1, paddingTop: 0.5 }}
                        >
                            Required
                        </Typography>
                    }
                    control={
                        <Switch
                            size="small"
                            checked={state.required}
                            onChange={(e, required) => {
                                setState({
                                    ...state,
                                    required,
                                });
                            }}
                        />
                    }
                />
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                        onClick={() => reIndex(-1)}
                        disabled={opIndex == 0}
                        size="small"
                    >
                        <MdExpandLess />
                    </IconButton>
                    <IconButton
                        onClick={() => reIndex(1)}
                        disabled={opIndex == length - 1}
                        size="small"
                    >
                        <MdExpandMore />
                    </IconButton>
                    <IconButton
                        disabled={length === 1}
                        onClick={() => setState(null)}
                        size="small"
                    >
                        <MdClear />
                    </IconButton>
                </Box>
            </Box>
            <Collapse in={controls[state.category] !== null}>
                <Box sx={{ paddingTop: 2 }}>
                    <Divider sx={{ marginBottom: 2 }} />
                    {controls[state.category]}
                </Box>
            </Collapse>
        </Box>
    );
};

export default FormFieldLine;
