import React, { ReactElement } from 'react';
import { RecipeStepInput } from '../../../../../../graphql/RecipeStep/RecipeStepInput';
import { Draggable } from 'react-beautiful-dnd';
import {
    Box,
    Button,
    IconButton,
    Popover,
    TextField,
    Tooltip,
    useTheme,
} from '@mui/material';
import { MdClear, MdDragHandle } from 'react-icons/md';
import { useClickState } from '../../../../../../hooks/useClickState';
import { useTinyItems } from '../../../../../../graphql/Item/operations/useTinyItems';
import { niceList } from '../../../../../../utils/niceList';
import SelectField from '../../../../../../components/inputs/FieldInputs/SelectField';
import { ItemType } from '../../../../../../graphql/Item/Item';
import UnitField from '../../../../../../components/inputs/FieldInputs/UnitField';

export interface StepFormProps {
    children: {
        section_index: number;
        index: number;
        step: RecipeStepInput;
        setStep: (data: RecipeStepInput | null) => void;
        convert: () => void;
    };
}

const StepForm = (props: StepFormProps): ReactElement => {
    const {
        children: { step, index, section_index, setStep, convert },
    } = props;

    const [focus, setFocus] = useClickState();

    const { palette } = useTheme();

    const { data: itemData } = useTinyItems({
        variables: {
            filter: { skip: 0, take: 500, types: [ItemType.Ingredient] },
        },
    });

    const items = itemData ? itemData.items.items : [];

    return (
        <Draggable
            draggableId={`section${section_index}_${index}`}
            index={index}
        >
            {(provided, snapshot) => (
                <Box
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: 1,
                        paddingLeft: 2,
                        paddingRight: 2,
                        gap: 1,
                    }}
                >
                    <Box {...provided.dragHandleProps}>
                        <MdDragHandle />
                    </Box>
                    {step.content !== null ? (
                        <Box sx={{ flex: 1 }}>
                            <Button
                                onClick={(e) =>
                                    setFocus({ element: e.currentTarget })
                                }
                                variant="text"
                                color="inherit"
                                sx={{
                                    color:
                                        step.content.items.length == 0
                                            ? palette.text.disabled
                                            : undefined,
                                    fontWeight: 400,
                                }}
                            >
                                {step.content.items.length == 0
                                    ? 'Select an item'
                                    : niceList(
                                          items
                                              .filter((i) =>
                                                  step.content?.items.includes(
                                                      i._id
                                                  )
                                              )
                                              .map((i) => i.name),
                                          'or'
                                      )}
                            </Button>
                            <Popover
                                open={Boolean(focus)}
                                anchorEl={focus ? focus.element : null}
                                PaperProps={{ sx: { minWidth: 300 } }}
                                onClose={() => setFocus(null)}
                            >
                                <SelectField
                                    autofocus
                                    default_open
                                    data={items}
                                    getProps={(d) => ({
                                        id: d._id,
                                        primary: d.name,
                                    })}
                                    label="Item"
                                    multiple
                                    value={step.content.items}
                                    onChange={(e) => {
                                        if (step.content)
                                            setStep({
                                                ...step,
                                                content: {
                                                    ...step.content,
                                                    items: e,
                                                },
                                            });
                                    }}
                                />
                            </Popover>
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                paddingLeft: 1,
                                width: '100%',
                            }}
                        >
                            <TextField
                                fullWidth
                                value={step.english || ''}
                                onChange={(e) => {
                                    setStep({
                                        ...step,
                                        english: e.target.value,
                                        spanish: e.target.value,
                                    });
                                }}
                                placeholder="Enter an instruction"
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                    sx: { fontSize: '.85rem' },
                                }}
                            />
                        </Box>
                    )}
                    {step.content !== null && (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                            }}
                        >
                            <TextField
                                value={
                                    step.content !== null &&
                                    step.content.quantity !== null
                                        ? step.content.quantity
                                        : ''
                                }
                                onChange={(e) => {
                                    const parsed = e.target.value
                                        ? parseFloat(e.target.value)
                                        : null;
                                    if (step.content)
                                        setStep({
                                            ...step,
                                            content: {
                                                ...step.content,
                                                quantity:
                                                    parsed == null
                                                        ? null
                                                        : isNaN(parsed)
                                                        ? null
                                                        : parsed,
                                            },
                                        });
                                }}
                                type="number"
                                placeholder="Qty"
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                    sx: { fontSize: '.85rem', maxWidth: 72 },
                                }}
                            />
                            <UnitField
                                disabled={step.content.items.length == 0}
                                InputProps={{ disableUnderline: true }}
                                variant="standard"
                                multiple={false}
                                value={step.content.unit}
                                onConflictClick={convert}
                                item_types={[ItemType.Ingredient]}
                                onChange={(val) => {
                                    if (step.content) {
                                        setStep({
                                            ...step,
                                            content: {
                                                ...step.content,
                                                unit: val,
                                            },
                                        });
                                    }
                                }}
                            />
                        </Box>
                    )}
                    <Box>
                        <Tooltip arrow title="Remove Step">
                            <IconButton
                                onClick={() => setStep(null)}
                                size="small"
                            >
                                <MdClear />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
            )}
        </Draggable>
    );
};

export default StepForm;
