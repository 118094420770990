import {
    Box,
    Button,
    LinearProgress,
    List,
    ListItem,
    ListItemText,
    Popover,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material/';
import { format } from 'date-fns';
import React, { ReactElement } from 'react';
import { MdRestore } from 'react-icons/md';
import { useRecipe } from '../../../graphql/Recipe/operations/useRecipe';
import { Recipe } from '../../../graphql/Recipe/Recipe';
import { useRecipeVersion } from '../../../graphql/RecipeVersion/operations/useRecipeVersion';
import { useRecipeVersions } from '../../../graphql/RecipeVersion/operations/useRecipeVersions';
import { dateFormats } from '../../../utils/dateFormats';
import RecipeVersionPreview from '../../display/RecipeVersionPreview';
import ColumnBox from '../../Layout/ColumnBox';
import ResponsiveDialog from '../../Layout/ResponsiveDialog';

export interface RecipeVersionSelectionProps {
    recipe: string;
    value: string;
    onChange: (value: string) => void;
    children: (provided: {
        setTarget: (element: Element) => void;
    }) => ReactElement;
}

const RecipeVersionSelection = (
    props: RecipeVersionSelectionProps
): ReactElement => {
    const { recipe: recipe_id, value, onChange, children } = props;
    const { palette, shape } = useTheme();

    const [target, setTarget] = React.useState<Element | null>(null);

    const [focus, setFocus] = React.useState('');
    const [index, setIndex] = React.useState(0);

    const [recipe, setRecipe] = React.useState<Recipe | null>(null);

    useRecipe({
        variables: {
            id: recipe_id,
        },
        onCompleted: ({ recipe: r }) => setRecipe(r),
    });

    const { data: versionData, loading: versionLoading } = useRecipeVersion({
        variables: focus ? { id: focus } : undefined,
        skip: focus == '',
        fetchPolicy: 'network-only',
    });

    const { data, error, loading } = useRecipeVersions({
        variables: {
            filter: {
                skip: index * 50,
                take: 50,
                recipe: recipe_id,
            },
        },
        fetchPolicy: 'network-only',
    });

    const versions = React.useMemo(
        () => (data ? data.recipeVersions.items : []),
        [data]
    );

    React.useEffect(() => {
        if (focus == '' && versions.length > 0) setFocus(versions[0]._id);
    }, [versions, focus]);

    const handleClose = (newVal?: string) => {
        if (newVal) {
            setTarget(null);
            setFocus(newVal);
            onChange(newVal);
        } else {
            setTarget(null);
            setFocus(value);
        }
    };

    return (
        <React.Fragment>
            <ResponsiveDialog
                maxWidth="lg"
                fullWidth
                open={Boolean(target)}
                onClose={() => handleClose()}
                PaperProps={{
                    sx: {
                        background: palette.background.default,
                        padding: 0,
                    },
                }}
            >
                <Box
                    sx={{
                        flex: 1,
                        display: 'flex',
                        ...shape,
                        overflow: 'hidden',
                        position: 'relative',
                        height: '80vh',
                    }}
                >
                    {loading && (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                            }}
                        >
                            <LinearProgress />
                        </Box>
                    )}
                    <Box
                        sx={{
                            background: palette.tonal,
                            minWidth: 280,
                            overflow: 'hidden',
                        }}
                    >
                        <ColumnBox>
                            {{
                                content: (
                                    <List disablePadding>
                                        {recipe !== null &&
                                            versions.map((version) => (
                                                <ListItem
                                                    onClick={() =>
                                                        setFocus(version._id)
                                                    }
                                                    selected={
                                                        focus === version._id
                                                    }
                                                    button
                                                    divider
                                                    key={version._id}
                                                >
                                                    <ListItemText
                                                        primary={format(
                                                            new Date(
                                                                version.date_created
                                                            ),
                                                            dateFormats.condensedDate
                                                        )}
                                                        secondary={
                                                            <Box>
                                                                <Typography>
                                                                    {version
                                                                        .created_by
                                                                        .name +
                                                                        ' ' +
                                                                        format(
                                                                            new Date(
                                                                                version.date_created
                                                                            ),
                                                                            dateFormats.time
                                                                        )}
                                                                </Typography>
                                                                {version._id ==
                                                                    recipe
                                                                        .active
                                                                        ?._id && (
                                                                    <Typography
                                                                        sx={{
                                                                            color: palette
                                                                                .success
                                                                                .main,
                                                                        }}
                                                                        variant="body2"
                                                                    >
                                                                        <em>
                                                                            Current!
                                                                        </em>
                                                                    </Typography>
                                                                )}
                                                            </Box>
                                                        }
                                                    />
                                                </ListItem>
                                            ))}
                                    </List>
                                ),
                                footer: (
                                    <Box
                                        p={1}
                                        sx={{
                                            borderTop: `1px solid ${palette.divider}`,
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                            {`Showing ${versions.length} of ${
                                                data
                                                    ? data.recipeVersions.count
                                                    : 'many'
                                            } versions`}
                                        </Typography>
                                    </Box>
                                ),
                            }}
                        </ColumnBox>
                    </Box>
                    <Box
                        sx={{
                            flex: 1,
                            display: 'flex',
                            flexFlow: 'row',
                            justifyContent: 'center',
                            paddingTop: 4,
                            paddingBottom: 8,
                            overflow: 'auto',
                        }}
                    >
                        {versionData && recipe && (
                            <RecipeVersionPreview recipe={recipe}>
                                {versionData.recipeVersion}
                            </RecipeVersionPreview>
                        )}
                    </Box>
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            padding: 4,
                        }}
                    >
                        <Tooltip
                            arrow
                            title={!focus ? 'Please select a version' : ''}
                        >
                            <Box>
                                <Button onClick={() => handleClose(focus)}>
                                    Use this version
                                </Button>
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>
            </ResponsiveDialog>
            {children({
                setTarget: (element) => {
                    setTarget(element);
                },
            })}
        </React.Fragment>
    );
};

export default RecipeVersionSelection;
