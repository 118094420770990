import {
    Box,
    Checkbox,
    Collapse,
    IconButton,
    InputAdornment,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    TextField,
    useTheme,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { MdSearch, MdClear, MdExpandMore } from 'react-icons/md';
import { Permission } from '../../../../auth/Permission';
import {
    getUiPermissions,
    UiPermissionData,
    UiPermissions,
} from '../../../../auth/UiPermission';
import Anima from '../../../Layout/Anima';

export interface PermissionFieldProps {
    default_open?: boolean;
    value: Permission[];
    onChange: (value: Permission[]) => void;
}

const PermissionField = (props: PermissionFieldProps): ReactElement => {
    const { default_open = true, value, onChange } = props;

    const { palette, shape } = useTheme();

    const [search, setSearch] = React.useState('');
    const [collapsed, setCollapsed] = React.useState(!default_open);

    const uiValue = getUiPermissions(value);

    const checkSearch = (d: UiPermissionData): boolean => {
        const { name, description } = d;
        const check = name.toLowerCase() + description.toLowerCase();
        if (check.includes(search.toLowerCase())) return true;
        return false;
    };

    const getFiltered = (): UiPermissionData[] => {
        return UiPermissions.reduce((stack, item) => {
            const match = checkSearch(item);
            return match ? [item, ...stack] : [...stack, item];
        }, [] as UiPermissionData[]);
    };

    const toggle = (data: UiPermissionData) => {
        if (uiValue.map((v) => v.name).includes(data.name)) {
            const filteredPerms = Object.keys(Permission).filter(
                (p) => !data.permissions.includes(p as Permission)
            ) as Permission[];

            const remainingUiPerms = UiPermissions.filter((ui) =>
                filteredPerms.every((req) => ui.permissions.includes(req))
            );

            const newVal = [
                ...new Set(remainingUiPerms.map((p) => p.permissions).flat()),
            ];

            onChange(newVal);
        } else {
            const newVal = [...new Set([...value, ...data.permissions])];
            onChange(newVal);
        }
    };

    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexFlow: 'column',
                ...shape,
                border: `1px solid ${palette.divider}`,
            }}
        >
            <Box
                sx={{
                    borderBottom: `1px solid ${palette.divider}`,
                    padding: 1,
                    background:
                        palette.mode == 'dark'
                            ? palette.action.focus
                            : 'rgba(0, 0, 0, 0.065)',
                }}
            >
                <TextField
                    fullWidth
                    placeholder={
                        !collapsed
                            ? 'Permissions'
                            : `Permissions (${uiValue.length} selected)`
                    }
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    variant="standard"
                    InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                <MdSearch />
                            </InputAdornment>
                        ),
                        endAdornment: search ? (
                            <InputAdornment position="end">
                                <IconButton
                                    size="small"
                                    onClick={() => setSearch('')}
                                >
                                    <MdClear />
                                </IconButton>
                            </InputAdornment>
                        ) : (
                            <InputAdornment position="end">
                                <IconButton
                                    size="small"
                                    onClick={() => setCollapsed(!collapsed)}
                                >
                                    <Anima in={!collapsed} type="rotate">
                                        <Box sx={{ display: 'flex' }}>
                                            <MdExpandMore />
                                        </Box>
                                    </Anima>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
            <Collapse in={!collapsed}>
                <Box sx={{ overflow: 'auto', maxHeight: 300 }}>
                    <Box
                        sx={{
                            flex: 1,
                            overflow: 'auto',
                            background: palette.background.paper,
                        }}
                    >
                        <List disablePadding>
                            {getFiltered().map((d) => {
                                const match = checkSearch(d);
                                const selected = uiValue
                                    .map((v) => v.name)
                                    .includes(d.name);

                                return (
                                    <ListItemButton
                                        disabled={!match}
                                        divider
                                        key={'perm_' + d.name}
                                        role={undefined}
                                        onClick={() => toggle(d)}
                                        dense
                                    >
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={selected}
                                                tabIndex={-1}
                                                disableRipple
                                                onChange={() => toggle(d)}
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={d.name}
                                            secondary={d.description}
                                        />
                                    </ListItemButton>
                                );
                            })}
                        </List>
                    </Box>
                </Box>
            </Collapse>
        </Box>
    );
};

export default PermissionField;
