import { gql } from '@apollo/client';
import { RecipeSection } from '../RecipeSection/RecipeSection';

export interface RecipeVersion {
    _id: string;
    note: string | null;
    base_unit_produced: number;
    parameters: string[];
    sections: RecipeSection[];
    date_created: Date;
    created_by: {
        user_id: string;
        name: string;
        picture: string | null | undefined;
    };
}

export const RecipeVersionFragment = gql`
    fragment RecipeVersionFragment on RecipeVersion {
        _id
        date_created
        created_by {
            user_id
            name
            picture
        }
        note
        base_unit_produced
        parameters
        sections {
            ...RecipeSectionFragment
        }
    }
`;
