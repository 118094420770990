import React from 'react';
import {
    Avatar,
    Box,
    ButtonBase,
    Collapse,
    IconButton,
    SxProps,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import { DashboardBatch } from '../../../../graphql/Dashboard/entities/DashboardBatch';
import Anima from '../../../../components/Layout/Anima';
import { MdExpandMore } from 'react-icons/md';

const BatchHighlight: React.FC<{
    data: DashboardBatch[];
    profile: DashboardBatch['profile'];
}> = ({ data, profile }) => {
    const { palette, shape } = useTheme();
    const [open, setOpen] = React.useState(false);
    return (
        <Box sx={{ marginBottom: 1 }}>
            <Box
                sx={{
                    paddingLeft: 2,
                    paddingRight: 2,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                }}
            >
                <ButtonBase
                    onClick={() => setOpen(!open)}
                    sx={{ width: '100%', ...shape }}
                >
                    <Avatar
                        sx={{
                            height: '28px',
                            width: '28px',
                            marginRight: 1,
                        }}
                        src={profile.picture || ''}
                        alt={profile.name}
                    />
                    <Typography>
                        <b>{profile.name + ' '}</b>
                        {`(${data.length})`}
                    </Typography>
                    <Box sx={{ flex: 1 }} />
                    <Anima type="rotate" in={open}>
                        <IconButton size="small" onClick={() => setOpen(!open)}>
                            <MdExpandMore />
                        </IconButton>
                    </Anima>
                </ButtonBase>
            </Box>
            <Collapse in={open}>
                <Box sx={{ paddingLeft: 6.5 }}>
                    {Object.entries(
                        data
                            .sort((a, b) => {
                                const a_ = a.item.name.toLowerCase();
                                const b_ = b.item.name.toLowerCase();
                                if (a_ > b_) return 1;
                                else return -1;
                            })
                            .reduce((stack, batch) => {
                                if (stack[batch.item.name]) {
                                    const copy = { ...stack };
                                    copy[batch.item.name] =
                                        copy[batch.item.name] + 1;
                                    return copy;
                                } else {
                                    return { ...stack, [batch.item.name]: 1 };
                                }
                            }, {} as Record<string, number>)
                    ).map((item, index) => (
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            key={`batch_${index}`}
                        >
                            {`${item[0]}${item[1] == 1 ? '' : ` (${item[1]})`}`}
                        </Typography>
                    ))}
                </Box>
            </Collapse>
        </Box>
    );
};

const ProductionCard: React.FC<{
    loading: boolean;
    data: DashboardBatch[];
    styles: SxProps<Theme>;
}> = ({ loading, data, styles }) => {
    const grouped: Record<
        string,
        { data: DashboardBatch[]; profile: DashboardBatch['profile'] }
    > = {};

    for (const batch of data) {
        if (grouped[batch.profile.user_id]) {
            grouped[batch.profile.user_id].data.push(batch);
        } else {
            grouped[batch.profile.user_id] = {
                profile: batch.profile,
                data: [batch],
            };
        }
    }

    return (
        <Box sx={{ ...styles }}>
            <Box p={2}>
                <Typography variant="crisp" sx={{ fontSize: '1.5rem' }}>
                    Production
                </Typography>
            </Box>
            <Box sx={{ flex: 1, overflow: 'auto' }}>
                {Object.values(grouped).map((data) => (
                    <BatchHighlight
                        key={`production_${data.profile.user_id}`}
                        {...data}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default ProductionCard;
