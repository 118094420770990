import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import React, { ReactElement } from 'react';
import { JSONTree } from 'react-json-tree';
import { useNavigate } from 'react-router-dom';
import { Animation } from '../../../media/Animation';

export interface MessageProps {
    type: 'Error' | 'Warning' | 'Success' | 'Loading' | 'Info' | 'No Data';
    children?: string;
    action?: ReactElement;
    onComplete?: () => void;
    error?: Error;
    size?: number;
}

const Message = (props: MessageProps): ReactElement => {
    const {
        type,
        children: message,
        action,
        error,
        onComplete,
        size = 100,
    } = props;

    const {
        palette: { mode },
    } = useTheme();

    const nav = useNavigate();

    const [done, setDone] = React.useState(false);

    React.useEffect(() => {
        if (done && onComplete) {
            const timeout = setTimeout(() => {
                setDone(false);
                onComplete();
            }, 500);

            return () => clearTimeout(timeout);
        }
    }, [done, onComplete]);

    const iconMap: Record<MessageProps['type'], ReactElement> = {
        Error: (
            <Animation
                style={{ height: size, width: size }}
                loop={false}
                type="warning"
                onComplete={() => {
                    if (message == 'Login required') {
                        nav('/logout');
                    } else {
                        setDone(true);
                    }
                }}
            />
        ),
        Warning: (
            <Animation
                style={{ height: size, width: size }}
                loop={false}
                type="warning"
                onComplete={() => {
                    if (message == 'Login required') {
                        nav('/logout');
                    } else {
                        setDone(true);
                    }
                }}
            />
        ),
        Success: (
            <Animation
                style={{ height: size, width: size }}
                loop={false}
                type="success"
                onComplete={() => setDone(true)}
            />
        ),
        Loading: <CircularProgress sx={{ maxHeight: size, maxWidth: size }} />,
        Info: (
            <Animation
                onComplete={() => setDone(true)}
                style={{ height: size, width: size }}
                loop={false}
                type="info"
            />
        ),
        ['No Data']: (
            <Animation
                style={{ height: size, width: size }}
                loop={true}
                type="blobPrimary"
            />
        ),
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexFlow: 'column',
                gap: 1,
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                textAlign: 'center',
            }}
        >
            <Box sx={{ fontSize: '2.5rem' }}>{iconMap[type]}</Box>
            {message && <Typography>{message}</Typography>}
            {action}
            {(type == 'Error' || type == 'Warning') && error && (
                <Box
                    sx={{
                        textAlign: 'left',
                    }}
                >
                    <JSONTree data={error} />
                </Box>
            )}
        </Box>
    );
};

export default Message;
