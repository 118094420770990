import {
    Box,
    IconButton,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material/';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import React, { ReactElement } from 'react';
import {
    HiClipboardCopy,
    HiOutlineClipboard,
    HiOutlineClipboardCopy,
} from 'react-icons/hi';
import { IoMdClipboard, IoMdCopy } from 'react-icons/io';
import {
    MdContentPaste,
    MdContentPasteOff,
    MdCopyAll,
    MdCopyright,
    MdEmail,
} from 'react-icons/md';
import ResponsiveDialog from '../../../../../../components/Layout/ResponsiveDialog';
import { OrderQueueLineInput } from '../../../../../../graphql/OrderQueue/inputs/OrderQueueLineInput';
import { OrderQueueLine } from '../../../../../../graphql/OrderQueue/OrderQueueLine';
import { useClickState } from '../../../../../../hooks/useClickState';
import { useEmails } from '../../useEmails';

export interface QueueDraftsProps {
    children: OrderQueueLineInput[];
}

const QueueDrafts = (props: QueueDraftsProps): ReactElement => {
    const { children: queue } = props;

    const [draftFocus, setDraftFocus] = useClickState();

    const { palette, shape } = useTheme();

    const [copyEmail, setCopyEmail] = React.useState('');

    const [copied, setCopied] = React.useState(false);

    React.useEffect(() => {
        if (copied) {
            const timeout = setTimeout(() => {
                setCopied(false);
            }, 1500);

            return () => clearTimeout(timeout);
        }
    }, [copied]);

    const {
        drafts,
        error: emailError,
        loading: emailLoading,
    } = useEmails(queue);

    return (
        <React.Fragment>
            <Button
                onClick={(e) => setDraftFocus({ target: e.currentTarget })}
                startIcon={<MdEmail />}
                variant="outlined"
                color="inherit"
            >
                Emails
            </Button>
            <Popover
                PaperProps={{ sx: { p: 0 } }}
                open={Boolean(draftFocus)}
                onClose={() => setDraftFocus(null)}
                anchorEl={draftFocus ? draftFocus.target : null}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Box sx={{ minWidth: 300 }}>
                    <Box sx={{ background: palette.background.paper, p: 2 }}>
                        <Typography>Emails</Typography>
                    </Box>
                    <Box sx={{ background: palette.tonal }}>
                        {drafts.map((draft, draftIndex) => (
                            <Box
                                sx={{
                                    p: 2,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                                key={'draft_' + draftIndex}
                            >
                                <Typography
                                    sx={{ paddingRight: 6 }}
                                >{`${draft.company.name}`}</Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Tooltip
                                        arrow
                                        title={draft.holdup || 'Draft an email'}
                                    >
                                        <Box sx={{ display: 'flex' }}>
                                            <IconButton
                                                disabled={
                                                    !Boolean(draft.link) ||
                                                    Boolean(draft.holdup)
                                                }
                                                onClick={() =>
                                                    window.open(
                                                        draft.link,
                                                        '_blank'
                                                    )
                                                }
                                            >
                                                <MdEmail />
                                            </IconButton>
                                        </Box>
                                    </Tooltip>
                                    <Tooltip
                                        arrow
                                        title={draft.holdup || 'Copy Email'}
                                    >
                                        <Box sx={{ display: 'flex' }}>
                                            <IconButton
                                                disabled={
                                                    !Boolean(draft.link) ||
                                                    Boolean(draft.holdup)
                                                }
                                                onClick={() =>
                                                    setCopyEmail(draft.body)
                                                }
                                            >
                                                <HiOutlineClipboardCopy />
                                            </IconButton>
                                        </Box>
                                    </Tooltip>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Popover>
            <ResponsiveDialog
                fullWidth
                maxWidth="md"
                open={Boolean(copyEmail)}
                onClose={() => setCopyEmail('')}
                PaperProps={{ sx: { background: palette.tonal, padding: 0 } }}
            >
                <Box
                    sx={{
                        background: palette.background.paper,
                        p: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography variant="h6">Email Draft</Typography>
                    <Tooltip arrow open={copied} title="Copied!">
                        <Button
                            endIcon={<HiOutlineClipboardCopy />}
                            onClick={() => {
                                navigator.clipboard.writeText(copyEmail || '');
                                setCopied(true);
                            }}
                        >
                            Copy text
                        </Button>
                    </Tooltip>
                </Box>
                <Box sx={{ p: 2 }}>
                    <TextField
                        fullWidth
                        variant="standard"
                        InputProps={{ disableUnderline: true }}
                        minRows={30}
                        multiline
                        value={copyEmail}
                        onChange={(e) => {
                            setCopyEmail(e.target.value);
                        }}
                    />
                </Box>
            </ResponsiveDialog>
        </React.Fragment>
    );
};

export default QueueDrafts;
