import { gql } from '@apollo/client';
import { TinyLot } from '../../Lot/Lot';
import { TinyUnit } from '../../Unit/Unit';

export interface LotContent {
    lot: TinyLot;
    client_unit: TinyUnit;
    client_qty: number;
    qty: number;
    receipt_line: string | null;
}

export const LotContentFragment = gql`
    fragment LotContentFragment on LotContent {
        lot {
            ...TinyLotFragment
        }
        client_unit {
            ...TinyUnitFragment
        }
        client_qty
        receipt_line
        qty
    }
`;
