import { StaticDatePicker } from '@mui/lab';
import {
    Box,
    MenuItem,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material/';
import Typography from '@mui/material/Typography';
import { getYear } from 'date-fns';
import React, { ReactElement } from 'react';
import SmartTable from '../../../../components/display/SmartTable';
import AppNav from '../../../../components/Layout/AppNav/components';
import NavContent from '../../../../components/Layout/AppNav/components/NavContent';
import NavHeader from '../../../../components/Layout/NavHeader';
import { useOurCompany } from '../../../../graphql/Company/operations/useOurCompany';
import {
    OrderStatsArgs,
    useOrderStats,
} from '../../../../graphql/OrderStat/useOrderStats';
import StatTable from './components/StatTable';
import StaatTable from './components/StatTable';

const OrderStats = (): ReactElement => {
    const locationsFromStorage = localStorage.getItem('ORDERSTATS_LOCATION');
    const yearFromStorage = localStorage.getItem('ORDERSTATS_YEAR');

    const [filter, setFilter] = React.useState<OrderStatsArgs>({
        year:
            yearFromStorage && !isNaN(parseInt(yearFromStorage))
                ? parseInt(yearFromStorage)
                : getYear(new Date()),
        locations: locationsFromStorage ? locationsFromStorage.split(',') : [],
    });

    const { data: ourData } = useOurCompany();

    const company = ourData ? ourData.ourCompany : null;

    React.useEffect(() => {
        if (filter.locations.length == 0 && company) {
            setFilter({
                ...filter,
                locations: [...company.locations.map((l) => l._id)],
            });
        }
    }, [company, filter]);

    const { data, error, loading } = useOrderStats({
        variables: filter,
        skip: filter.locations.length == 0,
    });

    const plants = company ? company.locations : [];

    return (
        <AppNav error={error} loading={loading}>
            <NavContent>
                {{
                    header: (
                        <NavHeader>
                            <Typography variant="crisp">
                                Order Statistics
                            </Typography>
                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <TextField
                                    sx={{ width: '200px' }}
                                    select
                                    label="Year"
                                    value={filter.year}
                                    onChange={(e) => {
                                        const year = parseInt(e.target.value);
                                        setFilter({ ...filter, year });
                                    }}
                                >
                                    {[
                                        2030, 2029, 2028, 2027, 2026, 2025,
                                        2024, 2023, 2022, 2021, 2020,
                                    ]
                                        .filter((y) => y <= getYear(new Date()))
                                        .map((year) => (
                                            <MenuItem
                                                value={year}
                                                key={'y_' + year}
                                            >
                                                {year}
                                            </MenuItem>
                                        ))}
                                </TextField>
                                <ToggleButtonGroup
                                    value={
                                        filter.locations.length !== 1
                                            ? 'all'
                                            : filter.locations[0]
                                    }
                                >
                                    <ToggleButton
                                        sx={{
                                            textTransform: 'none',
                                            paddingLeft: 3,
                                            paddingRight: 3,
                                        }}
                                        value="all"
                                        onClick={() =>
                                            setFilter({
                                                ...filter,
                                                locations: plants.map(
                                                    (p) => p._id
                                                ),
                                            })
                                        }
                                    >
                                        All Locations
                                    </ToggleButton>
                                    {plants.map((plant) => (
                                        <ToggleButton
                                            onClick={() =>
                                                setFilter({
                                                    ...filter,
                                                    locations: [plant._id],
                                                })
                                            }
                                            key={'pl_' + plant._id}
                                            sx={{
                                                textTransform: 'none',
                                                paddingLeft: 3,
                                                paddingRight: 3,
                                            }}
                                            value={plant._id}
                                        >
                                            {plant.label}
                                        </ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                            </Box>
                        </NavHeader>
                    ),
                    content: (
                        <StatTable
                            year={filter.year}
                            itemName={filter.itemName}
                            setItemName={(n) =>
                                setFilter({
                                    ...filter,
                                    itemName: n || undefined,
                                })
                            }
                        >
                            {data ? data.orderStats : []}
                        </StatTable>
                    ),
                }}
            </NavContent>
        </AppNav>
    );
};
export default OrderStats;
