import { IconButton, InputAdornment, useTheme } from '@mui/material/';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { ReactElement } from 'react';
import { MdCheck, MdClear } from 'react-icons/md';
import { ShipmentFormPaperProps } from '../..';
import UnitField from '../../../../../../../../../../components/inputs/FieldInputs/UnitField';
import QtyInput from '../../../../../../../../../../components/inputs/QtyInput';
import { FulfillmentLineInput } from '../../../../../../../../../../graphql/FulfillmentLine/inputs/FulfillmentLineInput';
import { useProduct } from '../../../../../../../../../../graphql/Item/extensions/Product/operations/useProduct';
import { ItemType } from '../../../../../../../../../../graphql/Item/Item';
import { useItem } from '../../../../../../../../../../graphql/Item/operations/useItem';

export interface ShipmentLineInputProps extends ShipmentFormPaperProps {
    line: FulfillmentLineInput;
    line_index: number;
}

const ShipmentLineInput = (props: ShipmentLineInputProps): ReactElement => {
    const { line, line_index, bol, state, setState } = props;

    const { palette, shape } = useTheme();

    const { data } = useItem({
        variables: {
            id: line.item,
        },
    });

    const item = data ? data.item : null;

    const { data: productData } = useProduct({
        variables:
            item && item.type == ItemType.Product
                ? { id: item._id }
                : undefined,
        skip: !item || item.type != ItemType.Product,
    });

    const product = productData ? productData.product : null;

    const content = bol.contents.find((c) => c.item._id == line.item);

    const lineQty = line.lots.reduce((stack, item) => {
        return stack + (item.quantity || 0);
    }, 0);

    const checkCompletion = (): boolean => {
        if (!content) return true;
        if (lineQty >= content.client_qty) return true;
        return false;
    };

    const complete = checkCompletion();

    React.useEffect(() => {
        if (product) {
            const lineSum = line.lots
                .map((l) => l.quantity)
                .reduce((stack, qty) => {
                    return (qty || 0) + (stack || 0);
                }, 0);

            let caseCount = 0;

            if (product.cases_per_pallet.length == 1)
                caseCount = product.cases_per_pallet[0];
            else
                caseCount =
                    product.cases_per_pallet.reduce(
                        (stack, item) => stack + item,
                        0
                    ) / product.cases_per_pallet.length;

            const palletCount = !lineSum
                ? 0
                : Math.round(((lineSum || 0) / caseCount) * 100) / 100;

            if (line.pallet_count !== palletCount) {
                const copy = { ...state };
                copy.lines[line_index].pallet_count = palletCount;
                setState(copy);
            }
        }
    }, [product, line.lots, line.pallet_count, line_index, state, setState]);

    return (
        <Box sx={{ background: palette.tonal, ...shape, overflow: 'hidden' }}>
            <Box
                sx={{
                    p: 2,
                    background: palette.background.paper,
                }}
            >
                {item && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'Center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                            }}
                        >
                            <Typography variant="body2">{`${item.name}`}</Typography>
                            {content && (
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                >{`${content.client_qty} ${
                                    content.client_unit[
                                        content.client_qty == 1
                                            ? 'name'
                                            : 'plural'
                                    ]
                                }`}</Typography>
                            )}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                width: 270,
                            }}
                        >
                            <QtyInput
                                disabled={Boolean(product)}
                                variant="standard"
                                InputProps={{
                                    disableUnderline: true,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Typography
                                                variant="body2"
                                                color={'text.secondary'}
                                            >
                                                Pallet Count:
                                            </Typography>
                                        </InputAdornment>
                                    ),
                                }}
                                value={line.pallet_count}
                                onChange={(val) => {
                                    const copy = { ...state };
                                    copy.lines[line_index].pallet_count = val;
                                    setState(copy);
                                }}
                            />
                            {content && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                    }}
                                >
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                    >{`${lineQty}/${content.client_qty}`}</Typography>
                                    {complete && (
                                        <MdCheck
                                            style={{
                                                color: palette.success.main,
                                            }}
                                        />
                                    )}
                                </Box>
                            )}
                        </Box>
                    </Box>
                )}
            </Box>
            <Box sx={{ paddingBottom: 4 }}>
                {line.lots.map((lot, index) => (
                    <Box
                        sx={{
                            p: 1,
                            paddingLeft: 2,
                            display: 'flex',
                            alignItems: 'flex-end',
                        }}
                        key={`line_${line_index}_lot_${index}`}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                paddingBottom: 0.45,
                                paddingRight: 6,
                            }}
                        >
                            <Typography color="text.disabled">Lot</Typography>
                            <Typography>{lot.code}</Typography>
                        </Box>
                        <Box>
                            <QtyInput
                                sx={{
                                    textAlign: 'right',
                                    width: '100px',
                                }}
                                placeholder="Qty"
                                variant="standard"
                                value={lot.quantity}
                                onChange={(val) => {
                                    const copy = { ...state };
                                    copy.lines[line_index].lots[
                                        index
                                    ].quantity = val;
                                    setState(copy);
                                }}
                                InputProps={{ disableUnderline: true }}
                            />
                        </Box>
                        <Box>
                            <UnitField
                                InputProps={{ disableUnderline: true }}
                                disabled={Boolean(content)}
                                placeholder="Unit"
                                variant="standard"
                                multiple={false}
                                onChange={(val) => {
                                    const copy = { ...state };
                                    copy.lines[line_index].lots[index].unit =
                                        val;
                                    setState(copy);
                                }}
                                value={lot.unit}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', paddingLeft: 2 }}>
                            <IconButton
                                size="small"
                                onClick={() => {
                                    const copy = { ...state };
                                    copy.lines[line_index].lots.splice(
                                        index,
                                        1
                                    );
                                    setState(copy);
                                }}
                            >
                                <MdClear />
                            </IconButton>
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default ShipmentLineInput;
