import { gql } from '@apollo/client';
import { AddressFragment } from '../Address/Address';
import { BaseFragment } from '../Base/Base';
import { TinyCompanyFragment } from '../Company/Company';
import { TinyContactFragment } from '../Contact/Contact';
import { TinyItemFragment } from '../Item/Item';
import { CompanyLocationFragment } from '../Location/Location';
import { getAtomicQueryHook, getLazyQueryHook } from '../types';
import { Lot, LotFragment, TinyLot } from './Lot';

export const LotQuery = gql`
    ${BaseFragment}
    ${LotFragment}
    ${TinyItemFragment}
    ${TinyCompanyFragment}
    ${TinyContactFragment}
    ${CompanyLocationFragment}
    ${AddressFragment}
    query LotQuery($id: ObjectId!) {
        lot(id: $id) {
            ...LotFragment
        }
    }
`;

export const useLot = getAtomicQueryHook<{ lot: Lot }>(LotQuery);

export const useLazyLot = getLazyQueryHook<{ lot: TinyLot }, { id: string }>(
    LotQuery
);
