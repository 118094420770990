import { Box, ButtonBase, Typography, useTheme } from '@mui/material';
import React, { ReactElement, ReactNode } from 'react';
import { CalendarView } from '../../../../../hooks/useCalendarRange';

export interface CalendarEventProps {
    icon?: ReactNode | undefined;
    primary: string;
    secondary?: string | undefined;
    onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
    view?: CalendarView;
}

const CalendarEvent = (props: CalendarEventProps): ReactElement => {
    const { onClick, icon, primary, secondary, view = 'week' } = props;

    const { palette, shape } = useTheme();

    return (
        <ButtonBase
            sx={{
                p: 0.75,
                ...shape,
                display: 'flex',
                justifyContent: 'flex-start',
                gap: 1,
                background: palette.background.paper,
            }}
            onClick={onClick}
        >
            {icon}
            <Box
                sx={{
                    display: 'flex',
                    flexFlow: 'column',
                    alignItems: 'flex-start',
                    textAlign: 'left',
                }}
            >
                <Typography
                    sx={{ lineHeight: 1.25 }}
                    variant={view == 'month' ? 'caption' : 'body2'}
                >
                    {primary}
                </Typography>
                <Typography
                    sx={{ lineHeight: 1.25 }}
                    variant="caption"
                    color="textSecondary"
                >
                    {secondary}
                </Typography>
            </Box>
        </ButtonBase>
    );
};

export default CalendarEvent;
