import { gql } from '@apollo/client';
import { BaseFragment } from '../../Base/Base';
import { getMutationHook } from '../../types';
import { Contact, ContactFragment } from '../Contact';
import { CreateContactInput } from '../inputs/CreateContactInput';

export const CreateContactMutation = gql`
    ${ContactFragment}
    ${BaseFragment}
    mutation CreateContactMutation($data: CreateContactInput!) {
        createContact(data: $data) {
            ...ContactFragment
        }
    }
`;

export interface CreateContactRes {
    createContact: Contact;
}

export interface CreateContactArgs {
    data: CreateContactInput;
}

export const useContactCreation = getMutationHook<
    CreateContactRes,
    CreateContactArgs
>(CreateContactMutation);
