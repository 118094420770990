import { gql } from '@apollo/client';
import { Pagination } from '../../../utils/types/Pagination';
import { AddressFragment } from '../../Address/Address';
import { TinyCompanyFragment } from '../../Company/Company';
import { TinyContactFragment } from '../../Contact/Contact';
import {
    TinyLocationFragment,
    CompanyLocationFragment,
} from '../../Location/Location';
import { MixingCardLineFragment } from '../../MixingCardLine/MixingCardLine';
import { RecipeFragment } from '../../Recipe/Recipe';
import { getQueryHook } from '../../types';
import { MixingCard, MixingCardFragment } from '../MixingCard';
import { MixingCardFilter } from '../MixingCardFilter';

export const MixingCardsQuery = gql`
    ${MixingCardFragment}
    ${RecipeFragment}
    ${MixingCardLineFragment}
    ${RecipeFragment}
    ${TinyLocationFragment}
    ${TinyCompanyFragment}
    ${AddressFragment}
    ${TinyContactFragment}
    ${CompanyLocationFragment}
    query MixingCardsQuery($filter: MixingCardFilter!) {
        mixingCardList(filter: $filter) {
            count
            items {
                ...MixingCardFragment
            }
        }
    }
`;

export interface MixingCardsRes {
    mixingCardList: Pagination<MixingCard>;
}

export interface MixingCardsArgs {
    filter: MixingCardFilter;
}

export const useMixingCards = getQueryHook<MixingCardsRes, MixingCardsArgs>(
    MixingCardsQuery
);
