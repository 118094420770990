import { gql } from '@apollo/client';
import { Pagination } from '../../../../../utils/types/Pagination';
import { BaseFragment } from '../../../../Base/Base';
import { getQueryHook } from '../../../../types';
import {
    Cookie,
    CookieFragment,
    TinyCookie,
    TinyCookieFragment,
} from '../Cookie';
import { CookieFilter } from '../CookieFilter';

export const CookiesQuery = gql`
    ${BaseFragment}
    ${CookieFragment}
    query CookiesQuery($filter: CookieFilter!) {
        cookies(filter: $filter) {
            count
            items {
                ...CookieFragment
            }
        }
    }
`;

export const TinyCookiesQuery = gql`
    ${TinyCookieFragment}
    query TinyCookiesQuery($filter: CookieFilter!) {
        cookies(filter: $filter) {
            count
            items {
                ...TinyCookieFragment
            }
        }
    }
`;

export const useCookies = getQueryHook<
    { cookies: Pagination<Cookie> },
    { filter: CookieFilter }
>(CookiesQuery);

export const useTinyCookies = getQueryHook<
    { cookies: Pagination<TinyCookie> },
    { filter: CookieFilter }
>(TinyCookiesQuery);
