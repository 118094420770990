import { ItemType } from '../Item/Item';
import { UnitClass } from './UnitClass';

export const permissableUnits: Record<ItemType, UnitClass[]> = {
    [ItemType.Cookie]: [UnitClass.Count],
    [ItemType.Ingredient]: [UnitClass.Weight, UnitClass.Volume],
    [ItemType.MiscItem]: [
        UnitClass.Count,
        UnitClass.Length,
        UnitClass.Volume,
        UnitClass.Weight,
    ],
    [ItemType.Package]: [UnitClass.Count],
    [ItemType.Product]: [UnitClass.Count],
};
