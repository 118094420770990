import { Button, IconButton, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import React, { ReactElement } from 'react';
import { MdArrowBack, MdChevronLeft } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

export interface NavHeaderProps {
    children: [ReactElement, ReactElement];
    back?: [string, string];
}

const NavHeader = (props: NavHeaderProps): ReactElement => {
    const { children, back } = props;
    const nav = useNavigate();

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'space-between',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    gap: 1,
                    alignItems: 'center',
                }}
            >
                {back && (
                    <Tooltip arrow title={back[0]}>
                        <IconButton onClick={() => nav(back[1])}>
                            <MdArrowBack />
                        </IconButton>
                    </Tooltip>
                )}
                {children[0]}
            </Box>
            <Box>{children[1]}</Box>
        </Box>
    );
};

export default NavHeader;
