import React, { ReactElement } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import LotList from './components/LotList';
import NodeGraph from './components/NodeGraph';
import NodeGraphRender from './components/NodeGraph/components/NodeGraphRender';

const Traceability = (): ReactElement => {
    return (
        <Routes>
            <Route index element={<Navigate to="nodegraph" />} />
            <Route path="nodegraph" element={<NodeGraph />} />
            <Route path="nodegraph/:id" element={<NodeGraphRender />} />
            <Route path="nodegraph/:id/:backto" element={<NodeGraphRender />} />
            <Route path="lots" element={<LotList />} />
            <Route path="lots/:id" element={<LotList />} />
        </Routes>
    );
};

export default Traceability;
