import { gql } from '@apollo/client';
import { AddressFragment } from '../../Address/Address';
import { BaseFragment } from '../../Base/Base';
import { TinyContactFragment } from '../../Contact/Contact';
import { CompanyLocationFragment } from '../../Location/Location';
import { getAtomicQueryHook } from '../../types';
import { Company, CompanyFragment } from '../Company';

export const CompanyQuery = gql`
    ${BaseFragment}
    ${CompanyFragment}
    ${TinyContactFragment}
    ${CompanyLocationFragment}
    ${AddressFragment}
    query CompanyQuery($id: ObjectId!) {
        company(id: $id) {
            ...CompanyFragment
        }
    }
`;

export const useCompany = getAtomicQueryHook<{ company: Company }>(
    CompanyQuery
);
