import {
    Box,
    Collapse,
    FormControlLabel,
    Switch,
    TextField,
    Typography,
    useTheme,
} from '@mui/material/';
import React, { ReactElement } from 'react';
import { UnitClass } from '../../../../../../../../graphql/Unit/UnitClass';
import { UnitConversionInput } from '../../../../../../../../graphql/UnitConversion/UnitConversionInput';
import { baseUnits } from '../../../../../../../Recipes/components/RecipeDetailForm/components/ConversionForm';

export interface UnitConversionFormProps {
    item_name: string;
    unit_class: UnitClass;
    value: UnitConversionInput[];
    onChange: (value: UnitConversionInput[]) => void;
}

const UnitConversionForm = (props: UnitConversionFormProps): ReactElement => {
    const { value, item_name, unit_class, onChange } = props;

    const { palette, shape } = useTheme();

    const getVal = (cl: UnitClass) => value.find((v) => v.to == cl);

    return (
        <Box sx={{ ...shape, overflow: 'hidden' }}>
            <Box sx={{ background: palette.background.paper, p: 2 }}>
                <Typography>Unit Conversions</Typography>
            </Box>
            <Box sx={{ background: palette.tonal, padding: 2 }}>
                <Box sx={{ display: 'flex', flexFlow: 'column', gap: 2 }}>
                    {Object.keys(UnitClass)
                        .filter((cl) => (cl as UnitClass) !== unit_class)
                        .map((cl) => {
                            return (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: 2,
                                        alignItems: 'center',
                                    }}
                                    key={'cl_' + cl}
                                >
                                    <Box sx={{ minWidth: 120 }}>
                                        <FormControlLabel
                                            label={cl}
                                            control={<Switch />}
                                            checked={
                                                getVal(cl as UnitClass) !==
                                                undefined
                                            }
                                            onChange={(e, checked) => {
                                                if (checked) {
                                                    onChange([
                                                        ...value,
                                                        {
                                                            to: cl as UnitClass,
                                                            multiplier: null,
                                                        },
                                                    ]);
                                                } else {
                                                    onChange([
                                                        ...value.filter(
                                                            (v) =>
                                                                v.to !==
                                                                (cl as UnitClass)
                                                        ),
                                                    ]);
                                                }
                                            }}
                                        />
                                    </Box>
                                    <Box sx={{ minWidth: 260 }}>
                                        <Collapse
                                            in={
                                                getVal(cl as UnitClass) !==
                                                undefined
                                            }
                                        >
                                            <TextField
                                                type="number"
                                                fullWidth
                                                InputProps={{
                                                    disableUnderline: true,
                                                }}
                                                label={`${
                                                    baseUnits[
                                                        cl as UnitClass
                                                    ][0]
                                                } per ${
                                                    baseUnits[unit_class][1]
                                                }`}
                                                variant="standard"
                                                value={
                                                    getVal(cl as UnitClass)
                                                        ? getVal(
                                                              cl as UnitClass
                                                          )?.multiplier || ''
                                                        : ''
                                                }
                                                onChange={(e) => {
                                                    const parsed = parseFloat(
                                                        e.target.value
                                                    );

                                                    const updateIndex = value
                                                        .map((v) => v.to)
                                                        .indexOf(
                                                            cl as UnitClass
                                                        );

                                                    if (updateIndex !== -1) {
                                                        const copy = [...value];
                                                        copy[
                                                            updateIndex
                                                        ].multiplier = isNaN(
                                                            parsed
                                                        )
                                                            ? null
                                                            : parsed;
                                                        onChange(copy);
                                                    }
                                                }}
                                            />
                                        </Collapse>
                                    </Box>
                                </Box>
                            );
                        })}
                </Box>
            </Box>
        </Box>
    );
};

export default UnitConversionForm;
