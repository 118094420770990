import { gql } from '@apollo/client';
import { Profile } from '../../auth/User';
import { getQueryHook } from '../types';

export const ProfileFragment = gql`
    fragment ProfileFragment on Profile {
        email
        roles
        name
        email_verified
        username
        phone_number
        phone_verified
        user_id
        created_at
        updated_at
        picture
        nickname
        last_ip
        last_login
        last_password_reset
        logins_count
        blocked
        given_name
        family_name
    }
`;

export const ProfileQuery = gql`
    ${ProfileFragment}
    query Profile($id: String!) {
        profile(id: $id) {
            ...ProfileFragment
        }
    }
`;

export interface ProfileArgs {
    id: string;
}

export interface ProfileRes {
    profile: Profile;
}

export const useProfile = getQueryHook<ProfileRes, ProfileArgs>(ProfileQuery);
