import { DesktopDatePicker } from '@mui/lab';
import {
    Box,
    FormControlLabel,
    MenuItem,
    Stack,
    TextField,
    Typography,
    Checkbox,
    useTheme,
    TextFieldProps,
    Slider,
    Button,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { FieldCategory } from '../../../../graphql/Form/FieldCategory';
import { Form } from '../../../../graphql/Form/Form';
import { FormField } from '../../../../graphql/Form/FormField';
import TimePicker from '../../FieldInputs/TimePicker';

export interface FormResponsePreviewProps {
    forms: Form[];
}

const FormResponsePreview = (props: FormResponsePreviewProps): ReactElement => {
    const { forms } = props;

    const { palette } = useTheme();

    const allFields = forms.map((form) => form.fields).flat();

    const keyedFields = allFields.reduce((stack, field) => {
        if (!stack[field.question]) {
            return { ...stack, [field.question]: field };
        } else {
            if (stack[field.question].required) {
                return { ...stack };
            } else {
                return { ...stack, [field.question]: field };
            }
        }
    }, {} as Record<string, FormField>);

    const cleansedFields = Object.values(keyedFields);

    const fieldProps: TextFieldProps = {
        variant: 'standard',
        InputProps: { disableUnderline: true },
    };

    const getComponent = (field: FormField) => {
        const ComponentMap: Record<FieldCategory, ReactElement> = {
            [FieldCategory.CheckBoxes]: (
                <Stack>
                    {field.options.map((option, opIndex) => (
                        <FormControlLabel
                            key={field.question + '_op_' + opIndex}
                            label={option}
                            control={<Checkbox />}
                        />
                    ))}
                </Stack>
            ),
            [FieldCategory.Date]: (
                <Box>
                    <DesktopDatePicker
                        value={new Date()}
                        onChange={(date) => {
                            //
                        }}
                        inputFormat="MM/dd/yyyy"
                        renderInput={(params) => (
                            <TextField
                                variant="standard"
                                {...{
                                    ...params,
                                    InputProps: {
                                        ...params.InputProps,
                                        disableUnderline: true,
                                    },
                                }}
                            />
                        )}
                    />
                </Box>
            ),
            [FieldCategory.Dropdown]: (
                <TextField {...fieldProps} fullWidth label="Select" select>
                    {field.options.map((option, opIndex) => (
                        <MenuItem
                            value={option}
                            key={field.question + '_op_' + opIndex}
                        >
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
            ),
            [FieldCategory.LinearScale]: (
                <Box>
                    <Slider
                        defaultValue={parseInt(field.options[0])}
                        step={1}
                        marks
                        min={parseInt(field.options[0])}
                        max={parseInt(field.options[1])}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography color="textSecondary" variant="body2">
                            {field.passable_options[0]}
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                            {field.passable_options[1]}
                        </Typography>
                    </Box>
                </Box>
            ),
            [FieldCategory.Number]: (
                <TextField
                    {...fieldProps}
                    type="number"
                    fullWidth
                    placeholder="Enter a number"
                />
            ),
            [FieldCategory.Paragraph]: (
                <TextField
                    {...fieldProps}
                    multiline
                    minRows={3}
                    maxRows={6}
                    placeholder="Type..."
                />
            ),
            [FieldCategory.ShortAnswer]: (
                <TextField {...fieldProps} placeholder="Type..." />
            ),
            [FieldCategory.Time]: (
                <TimePicker
                    {...fieldProps}
                    value={12 * 60}
                    onChange={() => {
                        //
                    }}
                />
            ),
        };

        return ComponentMap[field.category];
    };

    return (
        <Box>
            {cleansedFields.map((field, index) => (
                <Box
                    key={'field_' + index}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: 2,
                        borderBottom: `1px solid ${palette.divider}`,
                        alignItems: 'center',
                    }}
                >
                    <Box>
                        <Typography>{field.question}</Typography>
                        <Typography variant="body2" color="textSecondary">
                            {field.required ? 'Required' : ''}
                        </Typography>
                    </Box>
                    <Box sx={{ minWidth: 240 }}>{getComponent(field)}</Box>
                </Box>
            ))}
        </Box>
    );
};

export default FormResponsePreview;
