import { ApolloError } from '@apollo/client';
import React, { ReactElement } from 'react';
import useMyProfile, { MyProfile } from '../../auth/useMyProfile';
import Loading from '../../scenes/Loading';

export interface ProfileContext {
    data: MyProfile | null;
    error: ApolloError | undefined;
    loading: boolean;
}

export const Context = React.createContext<ProfileContext>({
    data: null,
    loading: false,
    error: undefined,
});

const ProfileProvider = (props: { children: ReactElement }): ReactElement => {
    const { profile, permissions, roles, error, loading } = useMyProfile();
    return (
        <Context.Provider
            value={{
                data: profile ? { profile, permissions, roles } : null,
                error,
                loading,
            }}
        >
            {loading ? <Loading message="Profile Loading" /> : props.children}
        </Context.Provider>
    );
};

export default ProfileProvider;

export const useProfileContext = (): ProfileContext =>
    React.useContext(Context);
