import { gql } from '@apollo/client';
import { BaseFragment } from '../../../../Base/Base';
import { getMutationHook } from '../../../../types';
import { Package, PackageFragment } from '../Package';
import { CreatePackageInput } from '../inputs/CreatePackageInput';

export const CreatePackageMutation = gql`
    ${BaseFragment}
    ${PackageFragment}
    mutation CreatePackageMutation($data: CreatePackageInput!) {
        createPackage(data: $data) {
            ...PackageFragment
        }
    }
`;

export interface CreatePackageArgs {
    data: CreatePackageInput;
}

export interface CreatePackageRes {
    createPackage: Package;
}

export const usePackageCreation = getMutationHook<
    CreatePackageRes,
    CreatePackageArgs
>(CreatePackageMutation);
