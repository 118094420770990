import { DesktopDatePicker } from '@mui/lab';
import { Box, MenuItem, TextField, Typography, useTheme } from '@mui/material';
import React, { ReactElement } from 'react';
import CompanyField from '../../../../../../../../../../../../../../../../components/inputs/FieldInputs/CompanyField';
import { AppointmentInput } from '../../../../../../../../../../../../../../../../graphql/Appointment/AppointmentInput';
import { Company } from '../../../../../../../../../../../../../../../../graphql/Company/Company';
import { useCompany } from '../../../../../../../../../../../../../../../../graphql/Company/operations/useCompany';

const AppointmentForm = (props: {
    mode: 'from' | 'to';
    value: AppointmentInput;
    onChange: (value: AppointmentInput) => void;
}): ReactElement => {
    const { mode, value, onChange } = props;

    const { palette } = useTheme();

    const [company, setCompany] = React.useState<null | Company>(null);

    const { loading } = useCompany({
        variables: {
            id: value.company || '',
        },
        skip: !value.company,
        onCompleted: ({ company: c }) => setCompany(c),
    });

    return (
        <Box
            sx={{
                display: 'flex',
                flexFlow: 'column',
                alignItems: 'flex-start',
            }}
        >
            <Typography color="textSecondary" variant="caption">
                {mode == 'from' ? 'From' : 'To'}
            </Typography>
            <CompanyField
                variant="standard"
                InputProps={{ disableUnderline: true }}
                multiple={false}
                value={value.company || ''}
                onChange={(e) =>
                    onChange({ ...value, company: e || null, location: null })
                }
            />
            <TextField
                select
                variant="standard"
                placeholder="Select a location"
                InputProps={{
                    disableUnderline: true,
                    sx:
                        !value.company || !value.location
                            ? {
                                  color: palette.text.disabled,
                                  fontSize: '.9rem',
                              }
                            : {
                                  color: palette.text.secondary,
                                  fontSize: '.9rem',
                              },
                }}
                value={
                    value.location !== null &&
                    !loading &&
                    company &&
                    !company.locations
                        .map((l) => l._id)
                        .includes(value.location)
                        ? null
                        : value.location || 'null'
                }
                onChange={(e) => {
                    onChange({ ...value, location: e.target.value || null });
                }}
            >
                <MenuItem disabled value={'null'}>
                    Select a location
                </MenuItem>
                {(company ? company.locations : []).map((location) => (
                    <MenuItem value={location._id} key={`l_${location._id}`}>
                        {location.label}
                    </MenuItem>
                ))}
            </TextField>
            <DesktopDatePicker
                value={value.date}
                onChange={(date) => {
                    onChange({ ...value, date });
                }}
                inputFormat="MM/dd/yyyy"
                renderInput={(params) => (
                    <TextField
                        size="small"
                        variant="standard"
                        {...{
                            ...params,
                            InputProps: {
                                ...params.InputProps,
                                disableUnderline: true,
                                sx: {
                                    fontSize: '.9rem',
                                    color: palette.text.secondary,
                                    maxWidth: 120,
                                },
                            },
                        }}
                    />
                )}
            />
        </Box>
    );
};

export default AppointmentForm;
