import Box from '@mui/material/Box';
import React, { ReactElement } from 'react';
import { LotNode } from '../../../../../../../../graphql/LotGraph/LotNode/LotNode';
import ReactFlow, {
    Node,
    Edge,
    OnNodesChange,
    NodeChange,
    applyNodeChanges,
    Handle,
    NodeProps,
    Position,
} from 'react-flow-renderer';
import { Typography, useTheme } from '@mui/material/';

export interface LotNodeRenderProps extends NodeProps {
    data: LotNode;
}

const LotNodeRender = (props: LotNodeRenderProps): ReactElement => {
    const {
        data: { item, company, code },
    } = props;

    const { palette, shape } = useTheme();

    return (
        <Box sx={{ p: 2, ...shape, background: palette.background.paper }}>
            <Handle type="target" position={Position.Top} />
            <Handle type="source" position={Position.Bottom} />
            <Typography>{code}</Typography>
            <Typography variant="body2" color="text.secondary">
                {!item ? '' : item.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                {!company ? '' : company.name}
            </Typography>
        </Box>
    );
};

export default LotNodeRender;
