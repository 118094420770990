import { gql } from '@apollo/client';
import { Recipe } from '../Recipe/Recipe';

export interface MixingCardLine {
    recipe: Recipe;
    recipe_version: {
        _id: string;
        date_created: Date;
        note: string | null;
    } | null;
    qty: number | null;
}

export const MixingCardLineFragment = gql`
    fragment MixingCardLineFragment on MixingCardLine {
        recipe {
            ...RecipeFragment
        }
        recipe_version {
            _id
            date_created
            note
        }
        qty
    }
`;
