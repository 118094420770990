import { MenuItem, TextField, TextFieldProps } from '@mui/material';
import React, { ReactElement } from 'react';
import useMyProfile from '../../../../auth/useMyProfile';
import { UserRole } from '../../../../auth/UserRole';

export type RoleInputProps = Omit<
    TextFieldProps,
    'value' | 'onChange' | 'select'
> & {
    value: UserRole;
    onChange: (role: UserRole) => void;
};

const RoleInput = (props: RoleInputProps): ReactElement => {
    const { onChange, ...rest } = props;

    const { roles } = useMyProfile();

    const getRoles = (): UserRole[] => {
        if (roles.includes(UserRole.Admin))
            return [UserRole.Admin, UserRole.Manager, UserRole.User];
        else if (roles.includes(UserRole.Admin))
            return [UserRole.Manager, UserRole.User];
        else return [UserRole.User];
    };

    return (
        <TextField
            {...rest}
            select
            onChange={(e) => onChange(e.target.value as UserRole)}
        >
            {getRoles().map((role) => (
                <MenuItem key={'op_' + role} value={role}>
                    {role}
                </MenuItem>
            ))}
        </TextField>
    );
};

export default RoleInput;
