import { gql } from '@apollo/client';
import { Pagination } from '../../../utils/types/Pagination';
import { ProfileFilter } from '../../People/useProfiles';
import { getQueryHook } from '../../types';
import { TinyProfile, TinyProfileFragment } from '../Profile';

export const TinyProfiles = gql`
    ${TinyProfileFragment}
    query Profiles($filter: ProfileFilter!) {
        profiles(filter: $filter) {
            count
            items {
                ...TinyProfileFragment
            }
        }
    }
`;

export const useTinyProfiles = getQueryHook<
    {
        profiles: Pagination<TinyProfile>;
    },
    { filter: ProfileFilter }
>(TinyProfiles);
