import { Box } from '@mui/material';
import React, { ReactElement } from 'react';
import { useQualityCheckForms } from '../../../../graphql/Form/extensions/QualityCheckForm/operations/useQualityCheckForms';
import { QualityCheckCategory } from '../../../../graphql/Form/extensions/QualityCheckForm/QualityCheckCategory';
import { QualityCheckFormFilter } from '../../../../graphql/Form/extensions/QualityCheckForm/QualityCheckFormFilter';
import { FieldCategory } from '../../../../graphql/Form/FieldCategory';
import { FormField } from '../../../../graphql/Form/FormField';
import { FormResponseInput } from '../../../../graphql/FormResponse/inputs/FormResponseInput';
import { chunkify } from '../../../../utils/chunkify';
import FormRow from '../../../Layout/FormRow';
import QualityCheckFormField from './components/QualityCheckFormField';

export interface QualityCheckFormProps {
    item: string;
    category: QualityCheckCategory;
    value: FormResponseInput;
    onChange: (value: FormResponseInput) => void;
    setHoldup: (holdup: string | null) => void;
    rows?: number;
}

const QualityCheckForm = (props: QualityCheckFormProps): ReactElement => {
    const { item, category, value, onChange, setHoldup, rows = 1 } = props;

    const getHoldup = (): string | null => {
        if (value.responses.length == 0) return null;
        let holdup = null;

        const requiredFields = value.responses.filter((r) => r.required);

        for (const field of requiredFields) {
            if (!field.response) holdup = field.question;
        }

        return holdup;
    };

    const holdup = getHoldup();

    React.useEffect(() => {
        setHoldup(holdup);
    }, [holdup]);

    const filter: QualityCheckFormFilter = {
        skip: 0,
        take: 50,
        category,
        item,
    };

    const { data } = useQualityCheckForms({
        variables: { filter },
        fetchPolicy: 'network-only',
        onCompleted: ({ qualityCheckForms }) => {
            const allFields = qualityCheckForms.items
                .map((form) => form.fields)
                .flat();

            const keyedFields = allFields.reduce((stack, field) => {
                if (!stack[field.question]) {
                    return { ...stack, [field.question]: field };
                } else {
                    if (stack[field.question].required) {
                        return { ...stack };
                    } else {
                        return { ...stack, [field.question]: field };
                    }
                }
            }, {} as Record<string, FormField>);

            const newFields = Object.values(keyedFields);

            if (value.responses.length == 0) {
                // include the new fields
                const SortKeys: Record<FieldCategory, number> = {
                    [FieldCategory.Date]: 0,
                    [FieldCategory.Dropdown]: 0,
                    [FieldCategory.Paragraph]: 0,
                    [FieldCategory.ShortAnswer]: 0,
                    [FieldCategory.Number]: 0,
                    [FieldCategory.Time]: 0,
                    [FieldCategory.LinearScale]: -2,
                    [FieldCategory.CheckBoxes]: -3,
                };

                const responses = newFields.map((f) => ({
                    ...f,
                    response: '',
                    __typename: undefined,
                }));

                const sortedResponses = responses.sort((a, b) =>
                    SortKeys[a.category] > SortKeys[b.category] ? -1 : 1
                );

                onChange({
                    responses: sortedResponses,
                });
            }
        },
    });

    const components = value.responses.map((field, index) => (
        <QualityCheckFormField
            key={'field_' + index}
            value={field}
            onChange={(v) => {
                const copy = { ...value };
                copy.responses[index] = v;
                onChange(copy);
            }}
        />
    ));

    const groupedComponents = chunkify(components, rows);

    return (
        <Box sx={{ maxWidth: 500 }}>
            {groupedComponents.map((comps, i) => (
                <FormRow key={'row_' + i}>{comps}</FormRow>
            ))}
        </Box>
    );
};

export default QualityCheckForm;
