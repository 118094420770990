import { ApolloError } from '@apollo/client';
import {
    Box,
    ListItemButton,
    useTheme,
    List,
    ListItemText,
} from '@mui/material/';
import React, { ReactElement } from 'react';
import SearchInput from '../../../../../../../../components/inputs/FieldInputs/SearchInput';
import ResponsiveDialog from '../../../../../../../../components/Layout/ResponsiveDialog';
import { FulfillmentLineLotInput } from '../../../../../../../../graphql/FulfillmentLine/inputs/FulfillmentLineLotInput';
import { TinyLot } from '../../../../../../../../graphql/Lot/Lot';

export interface LotDecisionProps {
    state_lots: FulfillmentLineLotInput[];
    decision: boolean;
    lots: TinyLot[];
    loading: boolean;
    error: ApolloError | undefined;
    search: string;
    setSearch: (val: string) => void;
    onSelect: (lot: TinyLot | null) => void;
}

const LotDecision = (props: LotDecisionProps): ReactElement => {
    const {
        decision,
        lots,
        loading,
        error,
        search,
        state_lots,
        setSearch,
        onSelect,
    } = props;

    const { palette } = useTheme();

    const handleClose = () => {
        onSelect(null);
    };

    return (
        <ResponsiveDialog
            open={decision}
            onClose={() => handleClose()}
            PaperProps={{ sx: { p: 0 } }}
            maxWidth="xs"
            fullWidth
        >
            <Box sx={{ p: 2 }}>
                <SearchInput
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    loading={loading}
                    error={Boolean(error)}
                    label={`Add lot`}
                    helperText={error ? error.message : undefined}
                    value={search}
                    onChange={(val) => setSearch(val || '')}
                />
            </Box>
            <Box
                sx={{
                    height: 300,
                    background: palette.tonal,
                    overflow: 'auto',
                }}
            >
                <List disablePadding>
                    {lots.map((lot) => {
                        const stateMatch = state_lots.find(
                            (l) =>
                                l.code == lot.code &&
                                l.company == lot.company._id
                        );
                        return (
                            <ListItemButton
                                disabled={Boolean(stateMatch)}
                                onClick={() => onSelect(lot)}
                                divider
                                key={`lot_${lot._id}`}
                            >
                                <ListItemText
                                    primary={lot.code}
                                    secondary={lot.item.name}
                                />
                            </ListItemButton>
                        );
                    })}
                </List>
            </Box>
        </ResponsiveDialog>
    );
};

export default LotDecision;
