import { gql } from '@apollo/client';
import { AddressFragment } from '../../Address/Address';
import { AppointmentFragment } from '../../Appointment/Appointment';
import { BaseFragment } from '../../Base/Base';
import { BolFragment } from '../../Bol/Bol';
import { TinyCompanyFragment } from '../../Company/Company';
import { TinyContactFragment } from '../../Contact/Contact';
import { ItemContentFragment } from '../../Content/ItemContent/ItemContent';
import { LotContentFragment } from '../../Content/LotContent/LotContent';
import { FormResponseFragment } from '../../FormResponse/FormResponse';
import { FulfillmentLineFragment } from '../../FulfillmentLine/FulfillmentLine';
import { TinyItemFragment } from '../../Item/Item';
import {
    CompanyLocationFragment,
    TinyLocationFragment,
} from '../../Location/Location';
import { TinyLotFragment } from '../../Lot/Lot';
import { Order, OrderFragment } from '../../Order/Order';
import { ReceiptFragment } from '../../Receipt/Receipt';
import { ShipmentFragment } from '../../Shipment/Shipment';
import { getMutationHook } from '../../types';
import { TinyUnitFragment } from '../../Unit/Unit';
import { VerificationFragment } from '../../Verification/Verification';
import { CreateItineraryInput } from '../inputs/CreateItineraryInput';
import { ItineraryFragment } from '../Itinerary';
import { ItineraryScheduleFragment } from '../ItinerarySchedule';

export const CreateItineraryMutation = gql`
    ${BaseFragment}
    ${OrderFragment}
    ${TinyCompanyFragment}
    ${TinyContactFragment}
    ${CompanyLocationFragment}
    ${ItineraryFragment}
    ${BolFragment}
    ${AppointmentFragment}
    ${ItemContentFragment}
    ${ReceiptFragment}
    ${ShipmentFragment}
    ${TinyLocationFragment}
    ${TinyItemFragment}
    ${TinyUnitFragment}
    ${FulfillmentLineFragment}
    ${FormResponseFragment}
    ${LotContentFragment}
    ${TinyLotFragment}
    ${AddressFragment}
    ${ItineraryScheduleFragment}
    ${VerificationFragment}
    mutation CreateItinerary($data: CreateItineraryInput!, $order: ObjectId!) {
        createItinerary(data: $data, order: $order) {
            ...OrderFragment
        }
    }
`;

export interface CreateItineraryArgs {
    data: CreateItineraryInput;
    order: string;
}

export interface CreateItineraryRes {
    createItinerary: Order;
}

export const useItineraryCreation = getMutationHook<
    CreateItineraryRes,
    CreateItineraryArgs
>(CreateItineraryMutation);
