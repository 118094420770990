import React, { ReactElement } from 'react';
import { useProfiles } from '../../../../graphql/People/useProfiles';
import SelectField from '../SelectField';

export type UserListSelectProps<M extends boolean | undefined> = M extends true
    ? {
          label?: string;
          multiple: true;
          value: string[];
          onChange: (value: string[]) => void;
      }
    : {
          label?: string;
          multiple: false | undefined;
          value: string;
          onChange: (value: string) => void;
      };

const UserListSelect = <M extends boolean | undefined>(
    props: UserListSelectProps<M>
): ReactElement => {
    const { label = 'Accounts', multiple, value, onChange } = props;

    const { data, error, loading } = useProfiles({
        variables: {
            filter: {
                skip: 0,
                take: 500,
            },
        },
    });

    const users = data ? data.profiles.items : [];

    return multiple ? (
        <SelectField
            label={label}
            data={users}
            getProps={(user) => ({
                id: user.user_id,
                primary: user.name,
                secondary: user.username || user.email,
            })}
            multiple={true}
            value={value}
            onChange={onChange}
        />
    ) : (
        <SelectField
            label={label}
            data={users}
            getProps={(user) => ({
                id: user.user_id,
                primary: user.name,
                secondary: user.username || user.email,
            })}
            multiple={false}
            value={value}
            onChange={onChange}
        />
    );
};

export default UserListSelect;
