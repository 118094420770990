import React from 'react';
import { useTheme, Box, ButtonBase, styled } from '@mui/material/';
import { ReactElement } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useShipmentDocument } from '../../../graphql/Shipment/operations/useShipmentDocument';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export interface ShipmentDocumentProps {
    id: string;
}

const docSize = 40;

const ShipmentDocument = (props: ShipmentDocumentProps): ReactElement => {
    const { id } = props;

    const { data, error, loading } = useShipmentDocument({
        variables: { id },
        fetchPolicy: 'network-only',
    });

    const [numPages, setNumPages] = React.useState<number | null>(null);
    const [pageNumber, setPageNumber] = React.useState(1);
    const { shape } = useTheme();

    const shipment = data ? data.shipment : null;

    const click = () => {
        if (shipment) {
            window.open(
                (process.env.REACT_APP_API_URL || '').replace(
                    '/graphql',
                    `/labels/shipment/${shipment._id}`
                ),
                '_blank'
            );
        }
    };

    return (
        <Box
            sx={{
                padding: 2,
                '& .pdf': {
                    canvas: {
                        width: `${8.5 * docSize}px !important`,
                        height: `${11 * docSize}px !important`,
                    },
                },
            }}
        >
            {shipment && (
                <ButtonBase
                    sx={{
                        display: 'inline-block',
                        ...shape,
                        overflow: 'hidden',
                    }}
                    onClick={() => {
                        click();
                    }}
                >
                    <Document
                        className="pdf"
                        file={`data:application/pdf;base64,${shipment.document.data}`}
                        onLoadSuccess={({ numPages }) => {
                            setNumPages(numPages);
                        }}
                    >
                        <Page pageNumber={pageNumber} />
                    </Document>
                </ButtonBase>
            )}
        </Box>
    );
};

export default ShipmentDocument;
