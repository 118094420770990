import { gql } from '@apollo/client';
import { Pagination } from '../../../../../utils/types/Pagination';
import { BaseFragment } from '../../../../Base/Base';
import { getQueryHook } from '../../../../types';
import {
    Product,
    ProductFragment,
    TinyProduct,
    TinyProductFragment,
} from '../Product';
import { ProductFilter } from '../ProductFilter';

export const ProductsQuery = gql`
    ${BaseFragment}
    ${ProductFragment}
    query ProductsQuery($filter: ProductFilter!) {
        products(filter: $filter) {
            count
            items {
                ...ProductFragment
            }
        }
    }
`;

export const TinyProductsQuery = gql`
    ${TinyProductFragment}
    query TinyProductsQuery($filter: ProductFilter!) {
        products(filter: $filter) {
            count
            items {
                ...TinyProductFragment
            }
        }
    }
`;

export const useProducts = getQueryHook<
    { products: Pagination<Product> },
    { filter: ProductFilter }
>(ProductsQuery);

export const useTinyProducts = getQueryHook<
    { products: Pagination<TinyProduct> },
    { filter: ProductFilter }
>(TinyProductsQuery);
