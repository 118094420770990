import { Box, Typography } from '@mui/material/';
import { format } from 'date-fns';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import SmartTable from '../../../../components/display/SmartTable';
import VerificationBadge from '../../../../components/display/VerificationBadge';
import SearchInput from '../../../../components/inputs/FieldInputs/SearchInput';
import AppNav from '../../../../components/Layout/AppNav/components';
import NavContent from '../../../../components/Layout/AppNav/components/NavContent';
import NavHeader from '../../../../components/Layout/NavHeader';
import { BatchFilter } from '../../../../graphql/Batch/BatchFilter';
import { useBatches } from '../../../../graphql/Batch/operations/useBatches';
import { dateFormats } from '../../../../utils/dateFormats';
import BatchBadge from './components/BatchBadge';

const BatchReports = (): ReactElement => {
    const nav = useNavigate();

    const [filter, setFilter] = React.useState<BatchFilter>({
        skip: 0,
        take: 25,
    });

    const { data, error, loading } = useBatches({
        variables: { filter },
        fetchPolicy: 'network-only',
    });

    const batches = data ? data.batches.items : [];

    return (
        <AppNav error={error} loading={loading}>
            <NavContent>
                {{
                    header: (
                        <NavHeader>
                            <Typography variant="crisp">
                                Batch Reports
                            </Typography>
                            <Box></Box>
                        </NavHeader>
                    ),
                    content: (
                        <SmartTable
                            getProps={(d) => ({
                                id: d._id,
                                onClick: () => nav(d._id),
                            })}
                            data={batches}
                            pagination={{
                                count: data ? data.batches.count : 0,
                                filter,
                                setFilter: (f) => setFilter(f),
                            }}
                            controls={{
                                Plant: (
                                    <SearchInput
                                        placeholder="Plant"
                                        InputProps={{ disableUnderline: true }}
                                        variant="standard"
                                        value={filter.location_name || ''}
                                        onChange={(val) => {
                                            setFilter({
                                                ...filter,
                                                location_name: val || undefined,
                                                skip: 0,
                                            });
                                        }}
                                    />
                                ),
                                Line: (
                                    <SearchInput
                                        placeholder="Line"
                                        InputProps={{ disableUnderline: true }}
                                        variant="standard"
                                        value={
                                            filter.production_line_name || ''
                                        }
                                        onChange={(val) => {
                                            setFilter({
                                                ...filter,
                                                production_line_name:
                                                    val || undefined,
                                                skip: 0,
                                            });
                                        }}
                                    />
                                ),
                                Mixer: (
                                    <SearchInput
                                        placeholder="Mixer"
                                        InputProps={{ disableUnderline: true }}
                                        variant="standard"
                                        value={filter.created_by_name || ''}
                                        onChange={(val) => {
                                            setFilter({
                                                ...filter,
                                                created_by_name:
                                                    val || undefined,
                                                skip: 0,
                                            });
                                        }}
                                    />
                                ),
                            }}
                        >
                            {{
                                Plant: (d) => d.location.label,
                                Line: (d) => d.production_line.name,
                                Mixer: (d) => d.created_by.name,
                                Date: (d) =>
                                    format(
                                        new Date(d.date_created),
                                        dateFormats.condensedDate +
                                            ' - ' +
                                            dateFormats.time
                                    ),
                                Status: (d) => (
                                    <Box sx={{ display: 'flex' }}>
                                        <BatchBadge key={'v_' + d._id}>
                                            {d}
                                        </BatchBadge>
                                    </Box>
                                ),
                            }}
                        </SmartTable>
                    ),
                }}
            </NavContent>
        </AppNav>
    );
};

export default BatchReports;
