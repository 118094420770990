import { gql } from '@apollo/client';
import { BaseFragment } from '../../Base/Base';
import { getMutationHook } from '../../types';
import { Unit, UnitFragment } from '../Unit';
import { CreateUnitInput } from '../inputs/CreateUnitInput';

export const CreateUnitMutation = gql`
    ${BaseFragment}
    ${UnitFragment}
    mutation CreateUnitMutation($data: CreateUnitInput!) {
        createUnit(data: $data) {
            ...UnitFragment
        }
    }
`;

export interface CreateUnitArgs {
    data: CreateUnitInput;
}

export interface CreateUnitRes {
    createUnit: Unit;
}

export const useUnitCreation = getMutationHook<CreateUnitRes, CreateUnitArgs>(
    CreateUnitMutation
);
