import { gql } from '@apollo/client';
import { AddressFragment } from '../../Address/Address';
import { AppointmentFragment } from '../../Appointment/Appointment';
import { BaseFragment } from '../../Base/Base';
import { TinyCompanyFragment } from '../../Company/Company';
import { TinyContactFragment } from '../../Contact/Contact';
import { ItemContentFragment } from '../../Content/ItemContent/ItemContent';
import { LotContentFragment } from '../../Content/LotContent/LotContent';
import { FormResponseFragment } from '../../FormResponse/FormResponse';
import { FulfillmentLineFragment } from '../../FulfillmentLine/FulfillmentLine';
import { TinyItemFragment } from '../../Item/Item';
import { ItineraryFragment } from '../Itinerary';
import { ItineraryScheduleFragment } from '../ItinerarySchedule';
import {
    CompanyLocationFragment,
    TinyLocationFragment,
} from '../../Location/Location';
import { TinyLotFragment } from '../../Lot/Lot';
import { Order, OrderFragment } from '../../Order/Order';
import { ReceiptFragment } from '../../Receipt/Receipt';
import { ShipmentFragment } from '../../Shipment/Shipment';
import { getMutationHook } from '../../types';
import { TinyUnitFragment } from '../../Unit/Unit';
import { BolFragment } from '../../Bol/Bol';
import { UpdateItineraryInput } from '../inputs/UpdateItineraryInput';
import { VerificationFragment } from '../../Verification/Verification';

export const UpdateItineraryMutation = gql`
    ${BaseFragment}
    ${OrderFragment}
    ${TinyCompanyFragment}
    ${TinyContactFragment}
    ${CompanyLocationFragment}
    ${ItineraryFragment}
    ${BolFragment}
    ${AppointmentFragment}
    ${ItemContentFragment}
    ${ReceiptFragment}
    ${ShipmentFragment}
    ${TinyLocationFragment}
    ${TinyItemFragment}
    ${TinyUnitFragment}
    ${FulfillmentLineFragment}
    ${FormResponseFragment}
    ${LotContentFragment}
    ${TinyLotFragment}
    ${AddressFragment}
    ${ItineraryScheduleFragment}
    ${VerificationFragment}
    mutation UpdateItineraryMutation(
        $data: UpdateItineraryInput!
        $id: ObjectId!
    ) {
        updateItinerary(data: $data, id: $id) {
            ...OrderFragment
        }
    }
`;

export interface UpdateItineraryArgs {
    id: string;
    data: UpdateItineraryInput;
}

export interface UpdateItineraryRes {
    updateItinerary: Order;
}

export const useItineraryUpdate = getMutationHook<
    UpdateItineraryRes,
    UpdateItineraryArgs
>(UpdateItineraryMutation);
