import {
    Box,
    Button,
    Fade,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material/';
import Popover from '@mui/material/Popover';
import React, { ReactElement } from 'react';
import {
    MdChevronLeft,
    MdChevronRight,
    MdClear,
    MdFolder,
    MdAssignment,
} from 'react-icons/md';
import { useFolder } from '../../../graphql/Folder/operations/useFolder';
import { useRecipe } from '../../../graphql/Recipe/operations/useRecipe';

export interface RecipeSelectionProps {
    value: string;
    onChange: (value: string) => void;
    children: (provided: {
        setTarget: (element: Element) => void;
    }) => ReactElement;
}

const RecipeSelection = (props: RecipeSelectionProps): ReactElement => {
    const { children, value, onChange } = props;

    const [hover, setHover] = React.useState('');

    const { palette, shape } = useTheme();

    const [target, setTarget] = React.useState<Element | null>(null);

    const [proposedValue, setProposedValue] = React.useState(value);

    const [folderTarget, setFolderTarget] = React.useState<
        null | string | undefined
    >(undefined);

    const handleClose = (newVal?: string) => {
        if (newVal) {
            onChange(newVal);
            setProposedValue(newVal ? newVal : value);
            setTarget(null);
        } else {
            if (!value) {
                setFolderTarget(null);
                setProposedValue(value);
                setTarget(null);
            } else {
                setProposedValue(value);
                setTarget(null);
            }
        }
    };

    React.useEffect(() => {
        if (value == '' && folderTarget == undefined) {
            setFolderTarget(null);
        }
    }, [value, folderTarget]);

    useRecipe({
        variables: {
            id: value,
        },
        skip: value == '',
        onCompleted: ({ recipe }) => {
            if (folderTarget === undefined) {
                setFolderTarget(
                    recipe.folder.name == 'Home' ? null : recipe.folder._id
                );
            }
        },
    });

    const { data: folderData, loading: folderLoading } = useFolder({
        variables: {
            id: folderTarget === undefined ? '' : folderTarget,
        },
        skip: folderTarget === undefined,
    });

    const folder = folderData ? folderData.folder : null;

    return (
        <React.Fragment>
            <Popover
                open={Boolean(target)}
                onClose={() => handleClose()}
                anchorEl={target}
                anchorReference="anchorEl"
                PaperProps={{
                    sx: {
                        background: palette.tonal,
                        height: '340px',
                        width: '400px',
                        display: 'flex',
                        flexFlow: 'column',
                    },
                }}
            >
                <Box
                    sx={{
                        p: 2,
                        background: palette.background.paper,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {folder && folder.name !== 'Home' && (
                        <IconButton
                            onClick={() => {
                                setProposedValue('');
                                setFolderTarget(
                                    folder.parent &&
                                        folder.parent.name !== 'Home'
                                        ? folder.parent._id
                                        : null
                                );
                            }}
                            sx={{ marginRight: 1 }}
                            size="small"
                        >
                            <MdChevronLeft />
                        </IconButton>
                    )}
                    <Typography
                        variant="h6"
                        color={!folder ? 'text.disabled' : undefined}
                    >
                        {folder ? folder.name : 'Loading...'}
                    </Typography>
                    <Box sx={{ flex: 1 }} />
                    <IconButton size="small" onClick={() => handleClose()}>
                        <MdClear />
                    </IconButton>
                </Box>
                <Box sx={{ flex: 1, overflow: 'auto' }}>
                    <List dense>
                        {(folder ? folder.folders : []).map((f, index) => (
                            <ListItemButton
                                onClick={() => {
                                    setFolderTarget(f._id);
                                    setProposedValue('');
                                }}
                                onMouseEnter={() => setHover(f._id)}
                                onMouseLeave={() => setHover('')}
                                key={'folder_' + f._id}
                            >
                                <ListItemIcon
                                    sx={{ minWidth: 0, paddingRight: 2 }}
                                >
                                    <MdFolder />
                                </ListItemIcon>
                                <ListItemText primary={f.name} />
                                <Fade in={hover === f._id}>
                                    <Box sx={{ display: 'flex' }}>
                                        <ListItemSecondaryAction>
                                            <MdChevronRight />
                                        </ListItemSecondaryAction>
                                    </Box>
                                </Fade>
                            </ListItemButton>
                        ))}
                        {(folder ? folder.recipes : []).map((recipe, index) => (
                            <ListItemButton
                                selected={proposedValue === recipe._id}
                                onMouseEnter={() => setHover(recipe._id)}
                                onMouseLeave={() => setHover('')}
                                key={'recipe_' + recipe._id}
                                onClick={() => setProposedValue(recipe._id)}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        paddingRight: 2,
                                        color: palette.primary.main,
                                    }}
                                >
                                    <MdAssignment />
                                </ListItemIcon>
                                <ListItemText
                                    primary={recipe.name}
                                    secondary={recipe.item.name}
                                />
                            </ListItemButton>
                        ))}
                    </List>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        p: 2,
                        borderTop: `1px solid ${palette.divider}`,
                    }}
                >
                    <Box />
                    <Tooltip
                        title={!proposedValue ? 'Please select a recipe' : ''}
                    >
                        <Box>
                            <Button
                                disabled={!proposedValue}
                                onClick={() => handleClose(proposedValue)}
                            >
                                Use Recipe
                            </Button>
                        </Box>
                    </Tooltip>
                </Box>
            </Popover>
            {children({
                setTarget: (element) => {
                    setTarget(element);
                },
            })}
        </React.Fragment>
    );
};

export default RecipeSelection;
