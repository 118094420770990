import { Box, Tab, Tabs, useTheme } from '@mui/material';
import React, { ReactElement } from 'react';
import ViewFade from '../ViewFade';
import { nanoid } from 'nanoid';

export interface TabFadeProps {
    children: Record<string, ReactElement>;
    action?: ReactElement;
    start_index?: number;
    onChange?: (index: number) => void;
    paper?: boolean;
    tonal?: boolean;
    tabPadding?: number;
}

const TabFade = (props: TabFadeProps): ReactElement => {
    const theme = useTheme();
    const {
        children,
        action,
        start_index = 0,
        paper = false,
        tonal = false,
        onChange,
        tabPadding = 0,
    } = props;

    const [active, setActive] = React.useState(start_index);

    return (
        <Box
            sx={{
                height: '100%',
                overflow: 'hidden',
                display: 'flex',
                flexFlow: 'column',
            }}
        >
            <Box
                sx={{
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    display: 'flex',
                    justifyContent: 'space-between',
                    background: paper
                        ? theme.palette.background.paper
                        : tonal
                        ? theme.palette.tonal
                        : undefined,
                    paddingLeft: tabPadding,
                    paddingRight: tabPadding,
                }}
            >
                <Tabs value={active}>
                    {Object.keys(children).map((key, index) => (
                        <Tab
                            key={'tab_' + index}
                            label={key}
                            onClick={() => {
                                setActive(index);
                                if (onChange) {
                                    onChange(index);
                                }
                            }}
                        />
                    ))}
                </Tabs>
                <Box>{action}</Box>
            </Box>
            <Box sx={{ flex: 1, overflow: 'auto' }}>
                <ViewFade index={active}>{Object.values(children)}</ViewFade>
            </Box>
        </Box>
    );
};

export default TabFade;
