import { AvatarGroup, Box, Tooltip, useTheme } from '@mui/material';
import { addDays, endOfDay, isAfter, startOfDay } from 'date-fns';
import React, { ReactElement } from 'react';
import {
    MdCalendarToday,
    MdCheck,
    MdCheckCircle,
    MdChecklist,
    MdRadioButtonUnchecked,
    MdWarning,
} from 'react-icons/md';
import { WarehouseDashboardProps } from '../..';
import Calendar from '../../../../../../components/display/Calendar';
import CalendarEvent from '../../../../../../components/display/Calendar/components/CalendarEvent';
import VerificationBadge from '../../../../../../components/display/VerificationBadge';
import OurLocationField from '../../../../../../components/inputs/FieldInputs/OurLocationField';
import { useOurCompany } from '../../../../../../graphql/Company/operations/useOurCompany';
import { FulfillmentEvent } from '../../../../../../graphql/FulfillmentEvent/FulfillmentEvent';
import { FulfillmentEventType } from '../../../../../../graphql/FulfillmentEvent/FulfillmentEventFilter';
import { useFulfillmentEvents } from '../../../../../../graphql/FulfillmentEvent/useFulfillmentEvents';
import {
    CalendarView,
    useCalendarRange,
} from '../../../../../../hooks/useCalendarRange';
import { useClickState } from '../../../../../../hooks/useClickState';
import { longList } from '../../../../../../utils/longList';
import WarehousePopover from './components/WarehousePopover';

const WarehouseIcon = (props: {
    children: FulfillmentEvent;
    view: 'Shipping' | 'Receiving';
}): ReactElement => {
    const { receipts, shipments, date } = props.children;
    const { palette } = useTheme();
    const now = new Date();
    const isLate = !isAfter(
        startOfDay(new Date(date)),
        startOfDay(addDays(now, -1))
    );
    let icon = null;
    if ((props.view == 'Receiving' ? receipts : shipments).length > 0) {
        icon = (
            <AvatarGroup>
                {(props.view == 'Receiving' ? receipts : shipments).map((f) => (
                    <VerificationBadge fontSize="1rem" key={'badge_' + f._id}>
                        {f}
                    </VerificationBadge>
                ))}
            </AvatarGroup>
        );
    } else if (isLate)
        icon = (
            <MdWarning
                style={{ color: palette.warning.main, fontSize: '1rem' }}
            />
        );
    else icon = <MdRadioButtonUnchecked style={{ fontSize: '1rem' }} />;

    return (
        <Tooltip
            arrow
            title={
                (props.view == 'Receiving' ? receipts : shipments).length > 0
                    ? ''
                    : isLate
                    ? 'Late!'
                    : 'Pending'
            }
        >
            <Box sx={{ display: 'flex' }}>{icon}</Box>
        </Tooltip>
    );
};

const WarehouseCalendar = (props: {
    children: (loading: boolean) => void;
    view: WarehouseDashboardProps['view'];
    plant: string;
}): ReactElement => {
    const { children: loadHandler, plant, view } = props;

    const [focus, setFocus] = useClickState<{ bol: string; order: string }>(
        null
    );

    const viewFromStorage = localStorage.getItem('WAREHOUSE_CALENDAR_VIEW');

    const [calView, setCalView] = React.useState<CalendarView>(
        viewFromStorage ? (viewFromStorage as CalendarView) : 'month'
    );

    React.useEffect(() => {
        localStorage.setItem('WAREHOUSE_CALENDAR_VIEW', calView);
    }, [calView]);

    const indexFromStorage = localStorage.getItem('WAREHOUSE_CALENDAR_INDEX');

    const [index, setIndex, range, now] = useCalendarRange(
        calView,
        indexFromStorage && !isNaN(parseFloat(indexFromStorage))
            ? parseFloat(indexFromStorage)
            : undefined
    );

    React.useEffect(() => {
        localStorage.setItem('WAREHOUSE_CALENDAR_INDEX', index.toString());
    }, [index]);

    const { data, error, loading } = useFulfillmentEvents({
        variables: {
            filter: {
                plant: plant || '',
                date: range,
                type:
                    view == 'Receiving'
                        ? FulfillmentEventType.Receiving
                        : FulfillmentEventType.Shipping,
                skip: 0,
                take: 500,
            },
        },
        skip: !plant,
        fetchPolicy: 'network-only',
    });

    const events = data ? data.fulfillmentEvents.items : [];

    const maxLength = 20;

    const str = (val: string) => {
        if (calView == 'month') {
            if (val.length > maxLength)
                return val.substring(0, maxLength - 1) + '...';
            else return val;
        } else return val;
    };

    return (
        <React.Fragment>
            <Calendar
                error={error}
                loading={loading}
                index={index}
                view={calView}
                setView={(v) => setCalView(v)}
                onIndex={(i, range) => {
                    setIndex(i);
                }}
                getProps={(data) => ({
                    date: new Date(data.date),
                    element: (
                        <CalendarEvent
                            view={calView}
                            onClick={(e) =>
                                setFocus({
                                    element: e.currentTarget,
                                    bol: data.bol,
                                    order: data.order,
                                })
                            }
                            key={data.bol}
                            icon={
                                <WarehouseIcon view={view}>
                                    {data}
                                </WarehouseIcon>
                            }
                            primary={str(
                                longList(
                                    data.contents.map(
                                        (content) => content.item.name
                                    ),
                                    1
                                )
                            )}
                            secondary={
                                calView == 'month'
                                    ? undefined
                                    : data.contents.length == 1
                                    ? `${data.contents[0].client_qty} ${
                                          data.contents[0].client_unit[
                                              data.contents[0].client_qty == 1
                                                  ? 'name'
                                                  : 'plural'
                                          ]
                                      }`
                                    : ''
                            }
                        />
                    ),
                })}
            >
                {events}
            </Calendar>
            <WarehousePopover
                view={view}
                focus={focus}
                onClose={() => setFocus(null)}
            />
        </React.Fragment>
    );
};

export default WarehouseCalendar;
