import React, { ReactElement } from 'react';
import { TinyLot } from '../../../../../../graphql/Lot/Lot';

import ReactFlow, {
    Node,
    Edge,
    NodeChange,
    applyNodeChanges,
    Position,
} from 'react-flow-renderer';
import { useLotGraph } from '../../../../../../graphql/LotGraph/useLotGraph';
import { LotNode } from '../../../../../../graphql/LotGraph/LotNode/LotNode';
import { LotEdge } from '../../../../../../graphql/LotGraph/LotEdge/LotEdge';
import { LotNodeType } from '../../../../../../graphql/LotGraph/LotNode/LotNodeType';
import LotNodeRender from './components/LotNodeRender';
import { useNavigate, useParams } from 'react-router-dom';
import AppNav from '../../../../../../components/Layout/AppNav/components';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/';
import ReceiptNodeRender from './components/ReceiptNodeRender';
import BatchNodeRender from './components/BatchNodeRender';
import PalletNodeRender from './components/PalletNodeRender';
import ShipmentNodeRender from './components/ShipmentNodeRender';

import dagre from 'dagre';

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 100;
const nodeHeight = 100;

const getLayoutedElements = (
    nodes: Node<LotNode>[],
    edges: Edge<LotEdge>[],
    direction = 'TB'
) => {
    const isHorizontal = direction === 'LR';
    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node) => {
        dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node) => {
        const nodeWithPosition = dagreGraph.node(node.id);
        node.targetPosition = isHorizontal ? Position.Left : Position.Top;
        node.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

        // We are shifting the dagre node position (anchor=center center) to the top left
        // so it matches the React Flow node anchor point (top left).
        node.position = {
            x: nodeWithPosition.x - nodeWidth / 2,
            y: nodeWithPosition.y - nodeHeight / 2,
        };

        return node;
    });

    return { nodes, edges };
};

const NodeGraphRender = (): ReactElement => {
    const { id, backto } = useParams();
    const nav = useNavigate();
    const { palette, spacing, shape } = useTheme();

    const [nodes, setNodes] = React.useState<Node<LotNode>[]>([]);
    const [edges, setEdges] = React.useState<Edge<LotEdge>[]>([]);

    const { data, error, loading } = useLotGraph({
        variables: {
            id: id || '',
            direction: false,
        },
        skip: !id,
        onCompleted: ({ lot: { graph } }) => {
            const { nodes: layoutedNodes, edges: layoutedEdges } =
                getLayoutedElements(
                    graph.nodes.map((node, index) => ({
                        id: node._id,
                        position: { x: 50 * (index + 1), y: 50 * (index + 1) },
                        data: node,
                        type: node.type,
                    })),
                    graph.edges
                );

            setNodes(layoutedNodes);
            setEdges(layoutedEdges);
        },
    });

    const onNodesChange = React.useCallback(
        (changes: NodeChange[]) =>
            setNodes((nds) => applyNodeChanges(changes, nds)),
        [setNodes]
    );

    // const onEdgesChange = React.useCallback(
    //     (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    //     [setEdges]
    // );

    const nodeTypes = React.useMemo(
        () => ({
            [LotNodeType.Batch]: BatchNodeRender,
            [LotNodeType.Lot]: LotNodeRender,
            [LotNodeType.Pallet]: PalletNodeRender,
            [LotNodeType.Receipt]: ReceiptNodeRender,
            [LotNodeType.Shipment]: ShipmentNodeRender,
        }),
        []
    );

    return (
        <AppNav loading={loading} error={error}>
            {data && (
                <Box sx={{ position: 'relative', height: '100%' }}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: spacing(3),
                            left: spacing(3),
                            zIndex: 5,
                            padding: 2,
                            ...shape,
                            background:
                                palette.mode == 'dark'
                                    ? 'rgba(51, 51, 51, .7)'
                                    : 'rgba(255, 255, 255, .85)',
                        }}
                    >
                        <Typography>{`${data.lot.item.name} Node Graph`}</Typography>
                        <Typography variant="crisp">
                            {data.lot.code + ' ->'}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            height: '100%',
                            flex: 1,
                            backgroundColor: palette.background.default,
                            backgroundImage: `radial-gradient(circle, ${palette.background.paper} 20%, transparent 10%), radial-gradient(circle, ${palette.background.paper} 20%, transparent 10%)`,
                            backgroundSize: '8px 8px',
                            backgroundPosition: '0 0, 8px 8px',
                        }}
                    >
                        <ReactFlow
                            nodeTypes={nodeTypes}
                            nodes={nodes}
                            edges={edges}
                            fitView
                            onNodesChange={onNodesChange}

                            // onEdgesChange={onEdgesChange}
                        />
                    </Box>
                </Box>
            )}
        </AppNav>
    );
};

export default NodeGraphRender;
