import {
    Box,
    Button,
    IconButton,
    TextField,
    Tooltip,
    useTheme,
} from '@mui/material/';
import React, { ReactElement } from 'react';
import { MdAdd, MdClear, MdDragHandle } from 'react-icons/md';
import { getSectionStyles } from '../..';
import { RecipeSectionInput } from '../../../../../../graphql/RecipeSection/RecipeSectionInput';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import StepForm from '../StepForm';

export interface SectionFormProps {
    index: number;
    children: {
        section: RecipeSectionInput;
        setSection: (data: RecipeSectionInput | null) => void;
    };
    convert: () => void;
}

const SectionForm = (props: SectionFormProps): ReactElement => {
    const {
        index,
        children: { section, setSection },
        convert,
    } = props;

    const theme = useTheme();

    return (
        <Draggable index={index} draggableId={'section_' + index}>
            {(provided, snapshot) => (
                <Box
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    sx={{ ...getSectionStyles(theme) }}
                >
                    <Box
                        sx={{
                            background: theme.palette.background.paper,
                            p: 1,
                            paddingLeft: 2,
                            paddingRight: 2,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                        }}
                    >
                        <Box
                            sx={{ display: 'flex' }}
                            {...provided.dragHandleProps}
                        >
                            <MdDragHandle />
                        </Box>
                        <TextField
                            value={section.label || ''}
                            onChange={(e) =>
                                setSection({
                                    ...section,
                                    label: e.target.value || null,
                                })
                            }
                            variant="standard"
                            InputProps={{ disableUnderline: true }}
                            placeholder={'Section ' + (index + 1)}
                        />
                        <Box sx={{ flex: 1 }} />
                        <Box>
                            <Tooltip arrow title="Remove Section">
                                <IconButton
                                    onClick={() => setSection(null)}
                                    size="small"
                                >
                                    <MdClear />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box sx={{ background: theme.palette.tonal }}>
                        <Droppable type="step" droppableId={'section_' + index}>
                            {(dropProvided, dropSnap) => (
                                <Box
                                    ref={dropProvided.innerRef}
                                    {...dropProvided.droppableProps}
                                    sx={{ minHeight: '16px' }}
                                >
                                    {section.steps.map((step, stepIndex) => (
                                        <StepForm
                                            key={
                                                'section_' +
                                                index +
                                                '_' +
                                                stepIndex
                                            }
                                        >
                                            {{
                                                index: stepIndex,
                                                section_index: index,
                                                step,
                                                convert,
                                                setStep: (d) => {
                                                    const copy = { ...section };
                                                    if (d) {
                                                        copy.steps[stepIndex] =
                                                            d;
                                                    } else {
                                                        copy.steps.splice(
                                                            stepIndex,
                                                            1
                                                        );
                                                    }
                                                    setSection(copy);
                                                },
                                            }}
                                        </StepForm>
                                    ))}
                                    {dropProvided.placeholder}
                                </Box>
                            )}
                        </Droppable>
                        <Box sx={{ p: 1, display: 'flex', gap: 1 }}>
                            <Button
                                variant="text"
                                size="small"
                                startIcon={<MdAdd />}
                                onClick={() => {
                                    setSection({
                                        ...section,
                                        steps: [
                                            ...section.steps,
                                            {
                                                content: {
                                                    quantity: null,
                                                    unit: '',
                                                    items: [],
                                                },
                                                english: null,
                                                spanish: null,
                                            },
                                        ],
                                    });
                                }}
                            >
                                Ingredient
                            </Button>
                            <Button
                                variant="text"
                                size="small"
                                startIcon={<MdAdd />}
                                onClick={() => {
                                    setSection({
                                        ...section,
                                        steps: [
                                            ...section.steps,
                                            {
                                                content: null,
                                                english: '',
                                                spanish: '',
                                            },
                                        ],
                                    });
                                }}
                            >
                                Instruction
                            </Button>
                        </Box>
                    </Box>
                </Box>
            )}
        </Draggable>
    );
};

export default SectionForm;
