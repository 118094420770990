import { Permission } from './Permission';

export enum UiPermission {
    WarehouseOperator = 'Warehouse Operator',
    WarehouseVerification = 'Warehouse Verification',
    Logistics = 'Logistics',
    Library = 'Library',
    Mixing = 'Mixing',
    Packing = 'Packing',
    ProductionManager = 'ProductionManager',
    Recipes = 'Recipes',
    Dashboard = 'Dashboard',
}

export interface UiPermissionData {
    name: UiPermission;
    description: string;
    permissions: Permission[];
}

const allPermissions = [
    Permission.GetDashboard,
    Permission.GetBatches,
    Permission.CreateBatch,
    Permission.UpdateBatch,
    Permission.GetMixingCards,
    Permission.CreateMixingCard,
    Permission.UpdateMixingCard,
    Permission.GetFulfillments,
    Permission.CreateFulfillment,
    Permission.UpdateFulfillment,
    Permission.VerifyFulfillments,
    Permission.GetCompanies,
    Permission.CreateCompany,
    Permission.UpdateCompany,
    Permission.GetContacts,
    Permission.CreateContact,
    Permission.UpdateContact,
    Permission.GetFolders,
    Permission.CreateFolders,
    Permission.UpdateFolders,
    Permission.GetItems,
    Permission.CreateItem,
    Permission.UpdateItem,
    Permission.GetLocations,
    Permission.CreateLocation,
    Permission.UpdateLocation,
    Permission.GetLots,
    Permission.CreateLot,
    Permission.UpdateLot,
    Permission.GetOrders,
    Permission.CreateOrder,
    Permission.UpdateOrder,
    Permission.GetPackingSessions,
    Permission.CreatePackingSession,
    Permission.UpdatePackingSession,
    Permission.GetQualityChecks,
    Permission.CreateQualityCheck,
    Permission.UpdateQualityCheck,
    Permission.GetRecipes,
    Permission.CreateRecipe,
    Permission.UpdateRecipe,
    Permission.GetRecipeVersions,
    Permission.CreateRecipeVersion,
    Permission.UpdateRecipeVersion,
    Permission.GetUnits,
    Permission.CreateUnit,
    Permission.UpdateUnit,
];

const warehousePermissions = [
    Permission.GetFulfillments,
    Permission.CreateFulfillment,
    Permission.UpdateFulfillment,
    Permission.GetCompanies,
    Permission.GetContacts,
    Permission.GetItems,
    Permission.GetLocations,
    Permission.GetLots,
    Permission.GetOrders,
    Permission.CreateOrder,
    Permission.UpdateOrder,
    Permission.GetQualityChecks,
    Permission.GetUnits,
];

export const UiPermissions: UiPermissionData[] = [
    {
        name: UiPermission.WarehouseOperator,
        description: 'Perform the duties of shipping and receiving.',
        permissions: warehousePermissions,
    },
    {
        name: UiPermission.Dashboard,
        description: 'View the plant operations dashboard.',
        permissions: [Permission.GetDashboard],
    },
    {
        name: UiPermission.WarehouseVerification,
        description: 'Verify shipments and receipts.',
        permissions: [...warehousePermissions, Permission.VerifyFulfillments],
    },
    {
        name: UiPermission.Logistics,
        description: 'Manage inbound and outbound product.',
        permissions: [
            Permission.GetFulfillments,
            Permission.CreateFulfillment,
            Permission.UpdateFulfillment,
            Permission.VerifyFulfillments,
            Permission.GetCompanies,
            Permission.CreateCompany,
            Permission.UpdateCompany,
            Permission.GetContacts,
            Permission.CreateContact,
            Permission.UpdateContact,
            Permission.GetItems,
            Permission.CreateItem,
            Permission.UpdateItem,
            Permission.GetLocations,
            Permission.CreateLocation,
            Permission.UpdateLocation,
            Permission.GetLots,
            Permission.CreateLot,
            Permission.UpdateLot,
            Permission.GetOrders,
            Permission.CreateOrder,
            Permission.UpdateOrder,
            Permission.GetQualityChecks,
            Permission.CreateQualityCheck,
            Permission.UpdateQualityCheck,
            Permission.GetUnits,
            Permission.CreateUnit,
            Permission.UpdateUnit,
        ],
    },
    {
        name: UiPermission.Library,
        description: 'Manage data like items and units in the library.',
        permissions: [
            Permission.GetCompanies,
            Permission.CreateCompany,
            Permission.UpdateCompany,
            Permission.GetContacts,
            Permission.CreateContact,
            Permission.UpdateContact,
            Permission.GetItems,
            Permission.CreateItem,
            Permission.UpdateItem,
            Permission.GetLocations,
            Permission.CreateLocation,
            Permission.UpdateLocation,
            Permission.GetQualityChecks,
            Permission.CreateQualityCheck,
            Permission.UpdateQualityCheck,
            Permission.GetUnits,
            Permission.CreateUnit,
            Permission.UpdateUnit,
        ],
    },
    {
        name: UiPermission.Recipes,
        description: 'Create and organize recipes.',
        permissions: [
            Permission.GetItems,
            Permission.GetUnits,
            Permission.GetFolders,
            Permission.UpdateFolders,
            Permission.CreateFolders,
            Permission.GetRecipes,
            Permission.CreateRecipe,
            Permission.UpdateRecipe,
            Permission.GetRecipeVersions,
            Permission.CreateRecipeVersion,
            Permission.UpdateRecipeVersion,
        ],
    },
    {
        name: UiPermission.Mixing,
        description: 'Mix batches.',
        permissions: [
            Permission.GetBatches,
            Permission.CreateBatch,
            Permission.UpdateBatch,
            Permission.GetMixingCards,
            Permission.GetFulfillments,
            Permission.GetCompanies,
            Permission.GetFolders,
            Permission.GetItems,
            Permission.GetLocations,
            Permission.GetLots,
            Permission.CreateLot,
            Permission.UpdateLot,
            Permission.GetOrders,
            Permission.GetQualityChecks,
            Permission.GetRecipes,
            Permission.GetRecipeVersions,
            Permission.GetUnits,
        ],
    },
    {
        name: UiPermission.Packing,
        description: 'Pack pallets.',
        permissions: [
            Permission.GetMixingCards,
            Permission.GetBatches,
            Permission.GetFulfillments,
            Permission.GetCompanies,
            Permission.GetItems,
            Permission.GetLocations,
            Permission.GetLots,
            Permission.CreateLot,
            Permission.UpdateLot,
            Permission.GetOrders,
            Permission.GetPackingSessions,
            Permission.CreatePackingSession,
            Permission.UpdatePackingSession,
            Permission.GetQualityChecks,
            Permission.GetUnits,
        ],
    },
    {
        name: UiPermission.ProductionManager,
        description: 'Schedule Mixing and Verify Batches.',
        permissions: [
            Permission.GetBatches,
            Permission.CreateBatch,
            Permission.UpdateBatch,
            Permission.GetMixingCards,
            Permission.CreateMixingCard,
            Permission.UpdateMixingCard,
            Permission.GetFulfillments,
            Permission.GetCompanies,
            Permission.GetContacts,
            Permission.GetFolders,
            Permission.CreateFolders,
            Permission.UpdateFolders,
            Permission.GetItems,
            Permission.CreateItem,
            Permission.UpdateItem,
            Permission.GetLocations,
            Permission.GetLots,
            Permission.CreateLot,
            Permission.UpdateLot,
            Permission.GetPackingSessions,
            Permission.CreatePackingSession,
            Permission.UpdatePackingSession,
            Permission.GetQualityChecks,
            Permission.CreateQualityCheck,
            Permission.UpdateQualityCheck,
            Permission.GetRecipes,
            Permission.CreateRecipe,
            Permission.UpdateRecipe,
            Permission.GetRecipeVersions,
            Permission.CreateRecipeVersion,
            Permission.UpdateRecipeVersion,
            Permission.GetUnits,
            Permission.CreateUnit,
            Permission.UpdateUnit,
        ],
    },
];

export const getUiPermissions = (
    permissions: Permission[]
): UiPermissionData[] => {
    const res: UiPermissionData[] = [];

    for (const ui of UiPermissions) {
        if (ui.permissions.every((required) => permissions.includes(required)))
            res.push(ui);
    }

    return res;
};

export const getPermissions = (
    uiPermissions: UiPermissionData[]
): Permission[] => {
    return [...new Set(uiPermissions.map((perm) => perm.permissions).flat())];
};
