import { gql } from '@apollo/client';
import { Base } from '../Base/Base';
import { FulfillmentLine } from '../FulfillmentLine/FulfillmentLine';
import { Verification } from '../Verification/Verification';

export interface Receipt extends Base {
    lines: FulfillmentLine[];
    seal: string;
    verification: Verification | null;
}

export const ReceiptFragment = gql`
    fragment ReceiptFragment on Receipt {
        ...BaseFragment
        lines {
            ...FulfillmentLineFragment
        }
        seal
        verification {
            ...VerificationFragment
        }
    }
`;
