import { gql } from '@apollo/client';
import { Base } from '../Base/Base';
import { BatchLine } from '../BatchLine/BatchLine';
import { TinyProfile } from '../Profile/Profile';
import { UnitClass } from '../Unit/UnitClass';
import { Verification } from '../Verification/Verification';

export interface Batch extends Base {
    location: { _id: string; label: string };
    production_line: { _id: string; name: string };
    recipe_version: {
        _id: string;
        date_created: Date;
        created_by: TinyProfile;
    };
    date_completed: Date | null;
    date_abandoned: Date | null;
    lot_result: { _id: string };
    lines: BatchLine[];
    item: { _id: string; name: string; spanish_name: string };
    recipe: { _id: string; name: string };
    qty: number;
    unit_class: UnitClass;
    verification: Verification | null;
}

export const BatchFragment = gql`
    fragment BatchFragment on Batch {
        _id
        deletion {
            timestamp
            user {
                email
                name
                username
                user_id
                picture
                given_name
                family_name
                roles
            }
        }
        date_created
        created_by {
            email
            name
            username
            user_id
            picture
            given_name
            family_name
            roles
        }
        note
        recipe {
            _id
            name
        }
        item {
            _id
            name
            spanish_name
        }
        qty
        unit_class
        location {
            _id
            label
        }
        production_line {
            _id
            name
        }
        recipe_version {
            _id
            date_created
            created_by {
                email
                name
                user_id
                picture
                given_name
                family_name
            }
        }
        date_completed
        date_abandoned
        lot_result {
            _id
        }
        lines {
            ...BatchLineFragment
        }
        verification {
            ...VerificationFragment
        }
    }
`;
