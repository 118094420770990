import { Button, Fab, useTheme } from '@mui/material/';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React, { ReactElement } from 'react';
import { MdArrowLeft, MdDeleteForever } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import VerificationBadge from '../../../../components/display/VerificationBadge';
import CarefullButton from '../../../../components/inputs/Buttons/CarefulButton';
import VerificationForm from '../../../../components/inputs/LightForms/VerificationForm';
import AppNav from '../../../../components/Layout/AppNav/components';
import NavContent from '../../../../components/Layout/AppNav/components/NavContent';
import NavHeader from '../../../../components/Layout/NavHeader';
import ResponsiveDialog from '../../../../components/Layout/ResponsiveDialog';
import TabFade from '../../../../components/Layout/TabFade';
import { Batch } from '../../../../graphql/Batch/Batch';
import {
    BatchQuery,
    useBatch,
} from '../../../../graphql/Batch/operations/useBatch';
import { BatchListQuery } from '../../../../graphql/Batch/operations/useBatches';
import {
    UpdateBatchArgs,
    useBatchUpdate,
} from '../../../../graphql/Batch/operations/useBatchUpdate';
import { BatchLine } from '../../../../graphql/BatchLine/BatchLine';
import { useRecipeVersion } from '../../../../graphql/RecipeVersion/operations/useRecipeVersion';
import { RecipeVersion } from '../../../../graphql/RecipeVersion/RecipeVersion';
import BatchDetailForm from './components/BatchDetailForm';

export const getStateFromBatch = (batch: Batch): UpdateBatchArgs['data'] => {
    return {
        lines: batch.lines.map((line) => ({
            recipe_step: line.recipe_step,
            content: {
                quantity: line.content.client_qty,
                unit: line.content.client_unit._id,
                lot: line.content.lot._id,
                receipt_line: line.content.receipt_line,
            },
        })),
    };
};

export const getGroupedCrumbs = (
    batch: Batch
): Record<
    string,
    { item: { _id: string; name: string }; lines: BatchLine[] }
> => {
    const res: Record<
        string,
        { item: { _id: string; name: string }; lines: BatchLine[] }
    > = {};

    const filtered = batch.lines.filter((l) => !l.recipe_step);

    for (const line of filtered) {
        if (line.content) {
            if (!res[line.content.lot.item._id]) {
                res[line.content.lot.item._id] = {
                    item: line.content.lot.item,
                    lines: [line],
                };
            } else {
                res[line.content.lot.item._id].lines.push(line);
            }
        }
    }

    return res;
};

export const useGate = (batch: Batch, recipe: RecipeVersion): number => {
    let gate: number | null = null;

    recipe.sections.map((section, sectionIndex) => {
        if (gate == null) {
            section.steps.map((s) => {
                if (s.content) {
                    const lots = batch.lines.filter(
                        (l) => l.recipe_step == s._id
                    );

                    if (lots.length == 0) gate = sectionIndex;
                }
            });
        }
    });

    return gate == null ? recipe.sections.length : gate;
};

export const isSectionComplete = (
    section_index: number,
    gate: number,
    batch: Batch,
    recipe: RecipeVersion
): boolean => {
    // if (gate <= section_index) return false;
    const section = recipe.sections[section_index];

    let complete = true;

    section.steps.map((step, index) => {
        if (!step.content) complete = true;
        else if (complete == true) {
            const applicable = batch.lines.filter(
                (l) => l.recipe_step == step._id
            );

            if (applicable.length == 0) complete = false;
        }
    });

    return complete;
};

const BatchDetail = (): ReactElement => {
    const { id } = useParams();
    const nav = useNavigate();
    const { data, error, loading } = useBatch({
        variables: {
            id: id || '',
        },
    });

    const [deletion, setDeletion] = React.useState(false);

    const { palette } = useTheme();

    const batch = data ? data.batch : null;

    const { data: recipeData, loading: recipeLoading } = useRecipeVersion({
        variables: batch ? { id: batch.recipe_version._id } : undefined,
        skip: !batch,
    });

    const recipe = recipeData ? recipeData.recipeVersion : null;

    const [handleUpdate, { loading: updateLoading, error: updateError }] =
        useBatchUpdate();

    return (
        <AppNav error={error} loading={loading || recipeLoading}>
            <NavContent>
                {{
                    header: (
                        <NavHeader
                            back={['Batch Reports', '/production/batchreports']}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                }}
                            >
                                {batch && (
                                    <VerificationBadge>
                                        {batch}
                                    </VerificationBadge>
                                )}
                                <Typography variant="crisp">Batch</Typography>
                            </Box>
                            <Box />
                        </NavHeader>
                    ),
                    content: (
                        <TabFade>
                            {{
                                Details: (
                                    <Box
                                        sx={{ paddingTop: 2, paddingBottom: 8 }}
                                    >
                                        {recipe && batch && (
                                            <BatchDetailForm
                                                recipe={recipe}
                                                batch={batch}
                                            />
                                        )}
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                bottom: '48px',
                                                right: '48px',
                                            }}
                                        >
                                            <Fab
                                                onClick={() =>
                                                    setDeletion(true)
                                                }
                                                sx={{
                                                    background:
                                                        palette.background
                                                            .paper,
                                                }}
                                                variant="extended"
                                            >
                                                Delete Batch
                                                <MdDeleteForever
                                                    style={{
                                                        marginLeft: '16px',
                                                        fontSize: '1.25rem',
                                                    }}
                                                />
                                            </Fab>
                                        </Box>
                                        <ResponsiveDialog
                                            open={deletion}
                                            onClose={() => {
                                                setDeletion(false);
                                            }}
                                        >
                                            <Typography variant="h6">
                                                Are you sure you want to delete
                                                this batch?
                                            </Typography>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    gap: 2,
                                                    paddingTop: 2,
                                                }}
                                            >
                                                <Button
                                                    fullWidth
                                                    color="inherit"
                                                    startIcon={<MdArrowLeft />}
                                                    onClick={() => {
                                                        setDeletion(false);
                                                    }}
                                                >
                                                    No, go back
                                                </Button>
                                                <Button
                                                    color="error"
                                                    fullWidth
                                                    onClick={() => {
                                                        if (batch)
                                                            handleUpdate({
                                                                variables: {
                                                                    id: batch._id,
                                                                    data: {
                                                                        ...getStateFromBatch(
                                                                            batch
                                                                        ),
                                                                        deleted:
                                                                            true,
                                                                    },
                                                                },
                                                                refetchQueries:
                                                                    [
                                                                        BatchQuery,
                                                                        BatchListQuery,
                                                                    ],
                                                                onCompleted:
                                                                    () =>
                                                                        nav(
                                                                            '/production/batchreports'
                                                                        ),
                                                            });
                                                    }}
                                                >
                                                    Yes, delete forever
                                                </Button>
                                            </Box>
                                        </ResponsiveDialog>
                                    </Box>
                                ),
                                Verification: (
                                    <Box>
                                        {batch && (
                                            <Box
                                                sx={{
                                                    maxWidth: 400,
                                                    paddingTop: 3,
                                                }}
                                            >
                                                <VerificationForm
                                                    entity="batch"
                                                    verification={
                                                        batch.verification
                                                    }
                                                    loading={updateLoading}
                                                    error={updateError || null}
                                                    onSave={(v) => {
                                                        handleUpdate({
                                                            variables: {
                                                                id: batch._id,
                                                                data: {
                                                                    ...getStateFromBatch(
                                                                        batch
                                                                    ),
                                                                    verification:
                                                                        v,
                                                                },
                                                            },
                                                            refetchQueries: [
                                                                BatchQuery,
                                                                BatchListQuery,
                                                            ],
                                                        });
                                                    }}
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                ),
                            }}
                        </TabFade>
                    ),
                }}
            </NavContent>
        </AppNav>
    );
};

export default BatchDetail;
