import { gql } from '@apollo/client';
import { BaseFragment } from '../../../../Base/Base';
import { getMutationHook } from '../../../../types';
import { Ingredient, IngredientFragment } from '../Ingredient';
import { UpdateIngredientInput } from '../inputs/UpdateIngredientInput';

export const UpdateIngredientMutation = gql`
    ${BaseFragment}
    ${IngredientFragment}
    mutation UpdateIngredientMutation(
        $id: ObjectId!
        $data: UpdateIngredientInput!
    ) {
        updateIngredient(id: $id, data: $data) {
            ...IngredientFragment
        }
    }
`;

export interface UpdateIngredientArgs {
    id: string;
    data: UpdateIngredientInput;
}

export interface UpdateIngredientRes {
    updateIngredient: Ingredient;
}

export const useIngredientUpdate = getMutationHook<
    UpdateIngredientRes,
    UpdateIngredientArgs
>(UpdateIngredientMutation);
