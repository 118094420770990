export enum Permission {
    GetDashboard = 'GetDashboard',

    GetBatches = 'GetBatches',
    CreateBatch = 'CreateBatch',
    UpdateBatch = 'UpdateBatch',

    GetMixingCards = 'GetMixingCards',
    CreateMixingCard = 'CreateMixingCard',
    UpdateMixingCard = 'UpdateMixingCard',

    GetFulfillments = 'GetFulfillments',
    CreateFulfillment = 'CreateFulfillment',
    UpdateFulfillment = 'UpdateFulfillment',

    VerifyFulfillments = 'VerifyFulfillments',

    GetCompanies = 'GetCompanies',
    CreateCompany = 'CreateCompany',
    UpdateCompany = 'UpdateCompany',

    GetContacts = 'GetContacts',
    CreateContact = 'CreateContact',
    UpdateContact = 'UpdateContact',

    GetFolders = 'GetFolders',
    CreateFolders = 'CreateFolders',
    UpdateFolders = 'UpdateFolders',

    GetItems = 'GetItems',
    CreateItem = 'CreateItem',
    UpdateItem = 'UpdateItem',

    GetLocations = 'GetLocations',
    CreateLocation = 'CreateLocation',
    UpdateLocation = 'UpdateLocation',

    GetLots = 'GetLots',
    CreateLot = 'CreateLot',
    UpdateLot = 'UpdateLot',

    GetOrders = 'GetOrders',
    CreateOrder = 'CreateOrder',
    UpdateOrder = 'UpdateOrder',

    GetPackingSessions = 'GetPackingSessions',
    CreatePackingSession = 'CreatePackingSession',
    UpdatePackingSession = 'UpdatePackingSession',

    GetQualityChecks = 'GetQualityChecks',
    CreateQualityCheck = 'CreateQualityCheck',
    UpdateQualityCheck = 'UpdateQualityCheck',

    GetRecipes = 'GetRecipes',
    CreateRecipe = 'CreateRecipe',
    UpdateRecipe = 'UpdateRecipe',

    GetRecipeVersions = 'GetRecipeVersions',
    CreateRecipeVersion = 'CreateRecipeVersion',
    UpdateRecipeVersion = 'UpdateRecipeVersion',

    GetUnits = 'GetUnits',
    CreateUnit = 'CreateUnit',
    UpdateUnit = 'UpdateUnit',
}
