import { gql } from '@apollo/client';
import { Permission } from '../../auth/Permission';
import { BaseFragment, UpdateBaseInput } from '../Base/Base';
import { getMutationHook } from '../types';
import { Team, TeamFragment } from './useTeams';

export const UpdateTeamMutation = gql`
    ${BaseFragment}
    ${TeamFragment}
    mutation UpdateTeamMutation($id: ObjectId!, $data: UpdateTeamInput!) {
        updateTeam(id: $id, data: $data) {
            ...TeamFragment
        }
    }
`;

export interface UpdateTeamRes {
    updateTeam: Team;
}

export interface UpdateTeamInput extends UpdateBaseInput {
    name: string;
    description?: string | null;
    members: string[];
    permissions: Permission[];
    locations: string[] | null;
}

export interface UpdateTeamArgs {
    id: string;
    data: UpdateTeamInput;
}

export const useTeamUpdate = getMutationHook<UpdateTeamRes, UpdateTeamArgs>(
    UpdateTeamMutation
);
