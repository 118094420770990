import { gql } from '@apollo/client';
import { Profile } from '../../auth/User';
import { UserRole } from '../../auth/UserRole';
import { ProfileFragment } from '../Profile/useProfile';
import { getMutationHook } from '../types';

export const UpdateProfile = gql`
    ${ProfileFragment}
    mutation UpdateProfile($id: String!, $data: UpdateProfileInput!) {
        updateProfile(id: $id, data: $data) {
            ...ProfileFragment
        }
    }
`;

export interface UpdateProfileInput {
    role: UserRole;
    given_name: string;
    family_name: string;
    password?: string;
    blocked?: boolean;
    email?: string | undefined | null;
    username?: string | undefined | null;
}

export interface UpdateProfileRes {
    updateProfile: Profile;
}

export interface UpdateProfileArgs {
    id: string;
    data: UpdateProfileInput;
}

export const useProfileUpdate = getMutationHook<
    UpdateProfileRes,
    UpdateProfileArgs
>(UpdateProfile);
