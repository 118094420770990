import {
    Box,
    Button,
    IconButton,
    Step,
    StepContent,
    StepLabel,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material/';
import React, { ReactElement } from 'react';
import { MdAdd, MdArrowDownward, MdArrowUpward, MdClear } from 'react-icons/md';
import UnitField from '../../../../../../../../../../components/inputs/FieldInputs/UnitField';
import QualityCheckForm from '../../../../../../../../../../components/inputs/LightForms/QualityCheckForm';
import { ItemContent } from '../../../../../../../../../../graphql/Content/ItemContent/ItemContent';
import { QualityCheckCategory } from '../../../../../../../../../../graphql/Form/extensions/QualityCheckForm/QualityCheckCategory';
import { FulfillmentLineInput } from '../../../../../../../../../../graphql/FulfillmentLine/inputs/FulfillmentLineInput';
import { TinyItem } from '../../../../../../../../../../graphql/Item/Item';

export interface ReceiptLineProps {
    content: ItemContent | undefined;
    item: TinyItem;
    value: FulfillmentLineInput;
    index: number;
    onChange: (value: FulfillmentLineInput) => void;
    moveIndex: (dir: -1 | 1) => void;
}

const ReceiptLine = (props: ReceiptLineProps): ReactElement => {
    const { content, index, item, value, onChange, moveIndex } = props;

    const [formHoldup, setFormHoldup] = React.useState<string | null>(null);

    const getHoldup = () => {
        let holdup = null;
        for (const lot of value.lots) {
            if (!lot.code) holdup = 'Each lot needs a number.';
            if (!lot.unit) holdup = 'Each lot needs a unit.';
            if (!lot.quantity) holdup = 'Each lot needs a quantity.';
        }
        return holdup;
    };

    const holdup = getHoldup();

    return (
        <Step index={index}>
            <StepLabel>
                {content
                    ? `${content.item.name} - ${content.client_qty} ${
                          content.client_unit[
                              content.client_qty == 1 ? 'name' : 'plural'
                          ]
                      }`
                    : item.name}
            </StepLabel>
            <StepContent>
                <Box sx={{ padding: 2 }}>
                    <Box sx={{ paddingBottom: 2 }}>
                        <Typography
                            sx={{ paddingBottom: 1 }}
                            color="textSecondary"
                        >
                            Lot info
                        </Typography>
                        <Box>
                            {value.lots.map((lot, index) => (
                                <Box
                                    sx={{
                                        width: 550,
                                        display: 'flex',
                                        marginBottom: 2,
                                    }}
                                    key={'fromLine_' + index}
                                >
                                    <TextField
                                        fullWidth
                                        value={lot.code}
                                        label="Lot Number"
                                        onChange={(e) => {
                                            const copy = {
                                                ...value,
                                            };
                                            copy.lots[index].code =
                                                e.target.value;

                                            onChange(copy);
                                        }}
                                    />
                                    <TextField
                                        fullWidth
                                        onChange={(e) => {
                                            const parsed = !e.target.value
                                                ? null
                                                : isNaN(
                                                      parseFloat(e.target.value)
                                                  )
                                                ? null
                                                : parseFloat(e.target.value);

                                            const copy = {
                                                ...value,
                                            };

                                            copy.lots[index].quantity = parsed;

                                            onChange(copy);
                                        }}
                                        value={
                                            lot.quantity == null
                                                ? ''
                                                : lot.quantity
                                        }
                                        label="Quantity"
                                        type="number"
                                    />
                                    <UnitField
                                        classes={[item.unit_class]}
                                        fullWidth
                                        label="Unit"
                                        multiple={false}
                                        value={lot.unit}
                                        onChange={(val) => {
                                            const copy = {
                                                ...value,
                                            };
                                            copy.lots[index].unit = val;

                                            onChange(copy);
                                        }}
                                    />
                                    {value.lots.length > 1 && (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                paddingLeft: 1,
                                            }}
                                        >
                                            <IconButton
                                                onClick={() => {
                                                    const copy = {
                                                        ...value,
                                                    };
                                                    copy.lots.splice(index, 1);
                                                    onChange(copy);
                                                }}
                                            >
                                                <MdClear />
                                            </IconButton>
                                        </Box>
                                    )}
                                </Box>
                            ))}
                            <Button
                                variant={
                                    value.lots.length == 0
                                        ? 'contained'
                                        : 'text'
                                }
                                startIcon={<MdAdd />}
                                onClick={() => {
                                    const copy = { ...value };
                                    copy.lots.push({
                                        unit: '',
                                        quantity: null,
                                        code: '',
                                    });
                                    onChange(copy);
                                }}
                            >
                                Add lot
                            </Button>
                        </Box>
                    </Box>
                    <Box>
                        {value.form_response &&
                            value.form_response.responses.length > 0 && (
                                <Typography
                                    sx={{ paddingBottom: 1 }}
                                    color="textSecondary"
                                >
                                    Quality Checks
                                </Typography>
                            )}
                        <QualityCheckForm
                            item={item._id}
                            category={QualityCheckCategory.Receipt}
                            value={value.form_response}
                            onChange={(val) => {
                                const copy = { ...value };
                                copy.form_response = val;
                                onChange(copy);
                            }}
                            setHoldup={(r) => setFormHoldup(r)}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <Button
                            variant="outlined"
                            color="inherit"
                            startIcon={<MdArrowUpward />}
                            onClick={() => moveIndex(-1)}
                        >
                            Previous
                        </Button>
                        <Tooltip arrow title={formHoldup || holdup || ''}>
                            <Box>
                                <Button
                                    disabled={Boolean(formHoldup || holdup)}
                                    startIcon={<MdArrowDownward />}
                                    onClick={() => moveIndex(+1)}
                                >
                                    Next
                                </Button>
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>
            </StepContent>
        </Step>
    );
};

export default ReceiptLine;
