import { gql } from '@apollo/client';
import { BaseFragment } from '../../../../Base/Base';
import { getAtomicQueryHook } from '../../../../types';
import { Cookie, CookieFragment } from '../Cookie';

export const CookieQuery = gql`
    ${BaseFragment}
    ${CookieFragment}
    query CookieQuery($id: ObjectId!) {
        cookie(id: $id) {
            ...CookieFragment
        }
    }
`;

export const useCookie = getAtomicQueryHook<{ cookie: Cookie }>(CookieQuery);
