import { gql } from '@apollo/client';
import { BaseFragment } from '../../Base/Base';
import { getMutationHook } from '../../types';
import { Unit, UnitFragment } from '../Unit';
import { UpdateUnitInput } from '../inputs/UpdateUnitInput';

export const UpdateUnitMutation = gql`
    ${BaseFragment}
    ${UnitFragment}
    mutation UpdateUnitMutation($id: ObjectId!, $data: UpdateUnitInput!) {
        updateUnit(id: $id, data: $data) {
            ...UnitFragment
        }
    }
`;

export interface UpdateUnitArgs {
    id: string;
    data: UpdateUnitInput;
}

export interface UpdateUnitRes {
    updateUnit: Unit;
}

export const useUnitUpdate = getMutationHook<UpdateUnitRes, UpdateUnitArgs>(
    UpdateUnitMutation
);
