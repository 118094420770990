import { gql } from '@apollo/client';
import { getQueryHook } from '../../types';
import { OrderQueueLine } from '../OrderQueueLine';

export const OrderQueueQuery = gql`
    query OrderQueue {
        orderQueue {
            po
            contents {
                unit
                item
                quantity
            }
            customer
            customer_location
            vendor
            vendor_location
            date
            time
        }
    }
`;

export const useOrderQueue = getQueryHook<{ orderQueue: OrderQueueLine[] }>(
    OrderQueueQuery
);
