import {
    CircularProgress,
    IconButton,
    InputAdornment,
    TextField,
    TextFieldProps,
    Tooltip,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { MdRefresh } from 'react-icons/md';
import { CodeType, useCode } from '../../../../graphql/Code/useCode';
import { OperationResult } from '../../../../utils/types/OperationResult';

export type CodeFieldProps = Omit<
    TextFieldProps,
    'value' | 'onChange' | 'type'
> & {
    type: CodeType;
    value: string;
    onChange: (value: string) => void;
    autofill?: boolean;
};

const CodeField = (props: CodeFieldProps): ReactElement => {
    const { type, value, onChange, autofill = false, ...rest } = props;

    // false = static
    // true = fetch
    // result
    const [state, setState] = React.useState<
        boolean | OperationResult<{ code: string }>
    >(autofill);

    React.useEffect(() => {
        if (state !== false && state !== true && state.success == true) {
            onChange(state.data.code);
            setState(false);
        }
    }, [state, onChange]);

    const { loading } = useCode({
        variables: { type },
        skip: state !== true,
        onCompleted: (data) => setState({ success: true, data }),
        onError: (error) => setState({ success: false, error }),
        fetchPolicy: 'network-only',
    });

    const lables: Record<CodeType, string> = {
        PO: 'PO Number',
        Lot: 'Lot Number',
        Itin: 'Itinerary Number',
        Bol: 'BOL Number',
    };

    return (
        <TextField
            {...rest}
            fullWidth
            value={value}
            onChange={(e) => onChange(e.target.value)}
            InputProps={{
                ...rest.InputProps,
                endAdornment: rest.disabled ? undefined : (
                    <InputAdornment position="end">
                        {loading ? (
                            <CircularProgress size={24} />
                        ) : (
                            <Tooltip title="Get code" arrow placement="top">
                                <IconButton
                                    size="small"
                                    disabled={state !== false}
                                    onClick={() => setState(true)}
                                >
                                    <MdRefresh />
                                </IconButton>
                            </Tooltip>
                        )}
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default CodeField;
