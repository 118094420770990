import { gql } from '@apollo/client';
import { BatchLineFragment } from '../../BatchLine/BatchLine';
import { getMutationHook } from '../../types';
import { TinyUnitFragment } from '../../Unit/Unit';
import { VerificationFragment } from '../../Verification/Verification';
import { Batch, BatchFragment } from '../Batch';
import { UpdateBatchInput } from '../inputs/UpdateBatchInput';

export const UpdateBatch = gql`
    ${BatchFragment}
    ${BatchLineFragment}
    ${TinyUnitFragment}
    ${VerificationFragment}
    mutation UpdateBatch($data: UpdateBatchInput!, $id: ObjectId!) {
        updateBatch(data: $data, id: $id) {
            ...BatchFragment
        }
    }
`;

export interface UpdateBatchArgs {
    id: string;
    data: UpdateBatchInput;
}

export interface UpdateBatchRes {
    updateBatch: Batch;
}

export const useBatchUpdate = getMutationHook<UpdateBatchRes, UpdateBatchArgs>(
    UpdateBatch
);
