import { gql } from '@apollo/client';
import { BaseFragment } from '../../../../Base/Base';
import { getMutationHook } from '../../../../types';
import {
    QualityCheckForm,
    QualityCheckFormFragment,
} from '../QualityCheckForm';
import { CreateQualityCheckFormInput } from '../inputs/CreateQualityCheckFormInput';
import { TinyItemFragment } from '../../../../Item/Item';

export const CreateQualityCheckFormMutation = gql`
    ${BaseFragment}
    ${QualityCheckFormFragment}
    ${TinyItemFragment}
    mutation CreateQualityCheckFormMutation(
        $data: CreateQualityCheckFormInput!
    ) {
        createQualityCheckForm(data: $data) {
            ...QualityCheckFormFragment
        }
    }
`;

export interface CreateQualityCheckFormArgs {
    data: CreateQualityCheckFormInput;
}

export interface CreateQualityCheckFormRes {
    createQualityCheckForm: QualityCheckForm;
}

export const useQualityCheckFormCreation = getMutationHook<
    CreateQualityCheckFormRes,
    CreateQualityCheckFormArgs
>(CreateQualityCheckFormMutation);
