import { gql } from '@apollo/client';
import { Item, TinyItem } from '../../Item';

export type Ingredient = Item;
export type TinyIngredient = TinyItem;

export const IngredientFragment = gql`
    fragment IngredientFragment on Ingredient {
        ...BaseFragment
        name
        spanish_name
        unit_class
        conversions {
            to
            multiplier
        }
    }
`;

export const TinyIngredientFragment = gql`
    fragment TinyIngredientFragment on Ingredient {
        _id
        name
        spanish_name
        unit_class
        conversions {
            to
            multiplier
        }
    }
`;
