import { useAuth0 } from '@auth0/auth0-react';
import React, { ReactElement } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useIndexRoute } from '../../router';

const UnauthenticatedRoute = (props: {
    children: ReactElement | ReactElement[];
}): ReactElement => {
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth0();
    const { path } = useIndexRoute();

    if (isAuthenticated) return <Navigate to={path} />;
    return <React.Fragment>{props.children}</React.Fragment>;
};

export default UnauthenticatedRoute;
