import { gql } from '@apollo/client';
import { RecipeFragment } from '../../Recipe/Recipe';
import { getMutationHook } from '../../types';
import { MixingCard, MixingCardFragment } from '../MixingCard';
import { UpdateMixingCardInput } from '../inputs/UpdateMixingCardInput';
import { AddressFragment } from '../../Address/Address';
import { TinyCompanyFragment } from '../../Company/Company';
import { TinyContactFragment } from '../../Contact/Contact';
import {
    TinyLocationFragment,
    CompanyLocationFragment,
} from '../../Location/Location';
import { MixingCardLineFragment } from '../../MixingCardLine/MixingCardLine';

export const UpdateMixingCard = gql`
    ${MixingCardFragment}
    ${RecipeFragment}
    ${MixingCardLineFragment}
    ${RecipeFragment}
    ${TinyLocationFragment}
    ${TinyCompanyFragment}
    ${AddressFragment}
    ${TinyContactFragment}
    ${CompanyLocationFragment}
    mutation UpdateMixingCard($id: ObjectId!, $data: UpdateMixingCardInput!) {
        updateMixingCard(id: $id, data: $data) {
            ...MixingCardFragment
        }
    }
`;

export interface UpdateMixingCardRes {
    updateMixingCard: MixingCard;
}

export interface UpdateMixingCardArgs {
    id: string;
    data: UpdateMixingCardInput;
}

export const useMixingCardUpdate = getMutationHook<
    UpdateMixingCardRes,
    UpdateMixingCardArgs
>(UpdateMixingCard);
