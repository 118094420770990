import React, { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import CookieDetail from './components/CookieDetail';
import CookieForm from './components/CookieForm';
import CookieList from './components/CookieList';

const Cookies = (): ReactElement => {
    return (
        <Routes>
            <Route index element={<CookieList />} />
            <Route path={':id'} element={<CookieDetail />} />
            <Route path={':id/edit'} element={<CookieForm />} />
            <Route path={'create'} element={<CookieForm />} />
        </Routes>
    );
};

export default Cookies;
