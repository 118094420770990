import Box from '@mui/material/Box';
import React, { ReactElement } from 'react';
import { LotNode } from '../../../../../../../../graphql/LotGraph/LotNode/LotNode';
import ReactFlow, {
    Node,
    Edge,
    OnNodesChange,
    NodeChange,
    applyNodeChanges,
    Handle,
    NodeProps,
    Position,
} from 'react-flow-renderer';
import {
    Button,
    Collapse,
    IconButton,
    Typography,
    useTheme,
} from '@mui/material/';
import { useOrder } from '../../../../../../../../graphql/Order/operations/useOrder';
import { useHeavyOrders } from '../../../../../../../../graphql/Order/operations/useOrders';
import { Order } from '../../../../../../../../graphql/Order/Order';
import Anima from '../../../../../../../../components/Layout/Anima';
import { MdArrowForward, MdExpandMore } from 'react-icons/md';
import { setDefaultResultOrder } from 'dns';
import { format } from 'date-fns';
import { dateFormats } from '../../../../../../../../utils/dateFormats';

export interface ShipmentNodeRenderProps extends NodeProps {
    data: LotNode;
}

const ShipmentNodeRender = (props: ShipmentNodeRenderProps): ReactElement => {
    const {
        data: { item, company, code, _id },
    } = props;

    const { palette, shape } = useTheme();

    const [showDetail, setShowDetail] = React.useState(false);

    const [order, setOrder] = React.useState<Order | null>(null);

    const { data, error, loading } = useHeavyOrders({
        variables: showDetail
            ? { filter: { skip: 0, take: 1, shipment: _id } }
            : undefined,
        skip: !showDetail,
        onCompleted: ({ orders }) => {
            if (orders.items[0]) setOrder(orders.items[0]);
        },
    });

    const itineraries = order ? order.itineraries : [];
    const bols = itineraries.map((i) => i.bols).flat();
    const shipments = bols.map((b) => b.shipments).flat();

    const bol =
        bols.find((b) => b.shipments.map((r) => r._id).includes(_id)) || null;
    const shipment = !bol
        ? null
        : bol.shipments.find((r) => r._id == _id) || null;

    return (
        <Box sx={{ p: 2, ...shape, background: palette.background.paper }}>
            <Handle type="target" position={Position.Top} />
            <Handle type="source" position={Position.Bottom} />
            <Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>Shipment</Typography>
                    <Anima type="rotate" in={showDetail}>
                        <IconButton
                            size="small"
                            onClick={() => setShowDetail(!showDetail)}
                        >
                            <MdExpandMore />
                        </IconButton>
                    </Anima>
                </Box>
                <Collapse
                    unmountOnExit
                    in={order !== null && showDetail && shipment !== null}
                >
                    {order && shipment && bol && (
                        <Box sx={{ paddingTop: 1 }}>
                            <Typography variant="body2" color="text.secondary">
                                {`PO ${order.code || ''}`}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {`From ${
                                    bol.from.company
                                        ? bol.from.company.name
                                        : ''
                                } ${
                                    bol.from.location
                                        ? ` (${bol.from.location.label})`
                                        : ''
                                }`}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {`To ${
                                    bol.to.company ? bol.to.company.name : ''
                                } ${
                                    bol.to.location
                                        ? ` (${bol.to.location.label})`
                                        : ''
                                }`}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {`Shipped by ${shipment.created_by.name}`}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {format(
                                    new Date(shipment.date_created),
                                    dateFormats.condensedDate
                                )}
                            </Typography>
                            <Button
                                size="small"
                                variant="text"
                                endIcon={<MdArrowForward />}
                                sx={{ marginLeft: -0.5, marginTop: 1 }}
                                onClick={() =>
                                    window.open(
                                        `${window.location.origin}/warehouse/shipping/${shipment._id}`,
                                        '_blank'
                                    )
                                }
                            >
                                Go to shipment
                            </Button>
                        </Box>
                    )}
                </Collapse>
            </Box>
        </Box>
    );
};

export default ShipmentNodeRender;
