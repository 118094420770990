import { gql } from '@apollo/client';
import { AppDocument } from '../../AppDocument/AppDocument';
import { getQueryHook } from '../../types';

export const ShipmentDocumentQuery = gql`
    query ShipmentDocumentQuery($id: ObjectId!) {
        shipment(id: $id) {
            _id
            document {
                filename
                mimetype
                data
            }
        }
    }
`;

export interface ShipmentDocumentRes {
    shipment: {
        _id: string;
        document: AppDocument;
    };
}

export interface ShipmentDocumentArgs {
    id: string;
}

export const useShipmentDocument = getQueryHook<
    ShipmentDocumentRes,
    ShipmentDocumentArgs
>(ShipmentDocumentQuery);
