import {
    Box,
    Checkbox,
    LinearProgress,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Popover,
    Tab,
    Tabs,
    useTheme,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { ItemType } from '../../../../graphql/Item/Item';
import { ItemFilter } from '../../../../graphql/Item/ItemFilter';
import { useTinyItems } from '../../../../graphql/Item/operations/useTinyItems';
import SearchInput from '../SearchInput';

export interface ItemPopoverProps {
    target: (EventTarget & Element) | null;
    value: string | null;
    onChange: (value: string | null) => void;
    onClose: () => void;
    exclude?: ItemType[];
}

export const ItemLanguage: Record<ItemType, string> = {
    [ItemType.Cookie]: 'Cookies',
    [ItemType.Ingredient]: 'Ingredients',
    [ItemType.MiscItem]: 'Misc Items',
    [ItemType.Package]: 'Packaging',
    [ItemType.Product]: 'Products',
};

const ItemPopover = (props: ItemPopoverProps): ReactElement => {
    const {
        target,
        value,
        onChange,
        onClose,
        exclude = [ItemType.Cookie],
    } = props;

    const { palette } = useTheme();

    const [filter, setFilter] = React.useState<ItemFilter>({
        skip: 0,
        take: 500,
        types: [ItemType.Ingredient],
    });

    const { data, error, loading } = useTinyItems({ variables: { filter } });

    const items = data ? data.items.items : [];

    React.useEffect(() => {
        const item = items.find((it) => it._id === value);
        if (item && filter.types && filter.types[0] !== item.type) {
            setFilter({ ...filter, types: [item.type] });
        }
    }, [value, target, items, filter]);

    return (
        <Popover onClose={onClose} open={Boolean(target)} anchorEl={target}>
            <Box sx={{ background: palette.tonal }}>
                <Box
                    sx={{
                        p: 2,
                        background: palette.background.paper,
                        paddingBottom: 0,
                    }}
                >
                    <SearchInput
                        variant="standard"
                        InputProps={{
                            disableUnderline: true,
                        }}
                        size="small"
                        placeholder="Search"
                        value={filter.name || ''}
                        onChange={(s) =>
                            setFilter({ ...filter, name: s || '' })
                        }
                    />
                </Box>
                <Box
                    sx={{
                        position: 'relative',
                        borderBottom: `1px solid ${palette.divider}`,
                        background: palette.background.paper,
                        paddingLeft: 2,
                        paddingRight: 2,
                    }}
                >
                    <Tabs
                        value={Object.keys(ItemType)
                            .filter((key) => !exclude.includes(key as ItemType))
                            .findIndex(
                                (k) =>
                                    (k as ItemType) ==
                                    (filter.types ? filter.types[0] : '')
                            )}
                    >
                        {Object.keys(ItemType)
                            .filter((key) => !exclude.includes(key as ItemType))
                            .map((key) => (
                                <Tab
                                    key={'i_' + key}
                                    label={ItemLanguage[key as ItemType]}
                                    onClick={() =>
                                        setFilter({
                                            ...filter,
                                            types: [key as ItemType],
                                        })
                                    }
                                />
                            ))}
                    </Tabs>
                    {loading && (
                        <Box
                            sx={{
                                position: 'asbolute',
                                bottom: 0,
                                left: 0,
                                right: 0,
                            }}
                        >
                            <LinearProgress />
                        </Box>
                    )}
                </Box>

                <Box sx={{ height: 200, overflow: 'auto' }}>
                    <List>
                        {items.map((it) => (
                            <ListItem
                                button
                                divider
                                key={'itemPop_' + it._id}
                                onClick={() => {
                                    if (it._id === value) onChange(null);
                                    else onChange(it._id);
                                }}
                            >
                                <ListItemAvatar>
                                    <Checkbox
                                        checked={it._id === value}
                                        onClick={() => {
                                            if (it._id === value)
                                                onChange(null);
                                            else onChange(it._id);
                                        }}
                                    />
                                </ListItemAvatar>
                                <ListItemText primary={it.name} />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Box>
        </Popover>
    );
};

export default ItemPopover;
