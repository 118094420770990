import { Button, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { MdAdd } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import SmartTable from '../../../../../../components/display/SmartTable';
import SearchInput from '../../../../../../components/inputs/FieldInputs/SearchInput';
import AppNav from '../../../../../../components/Layout/AppNav/components';
import NavContent from '../../../../../../components/Layout/AppNav/components/NavContent';
import NavHeader from '../../../../../../components/Layout/NavHeader';
import { MiscItemFilter } from '../../../../../../graphql/Item/extensions/MiscItem/MiscItemFilter';
import { useMiscItems } from '../../../../../../graphql/Item/extensions/MiscItem/operations/useMiscItems';

const MiscItemList = (): ReactElement => {
    const nav = useNavigate();

    const [filter, setFilter] = React.useState<MiscItemFilter>({
        skip: 0,
        take: 50,
    });

    const { data, error, loading } = useMiscItems({
        variables: { filter },
        fetchPolicy: 'network-only',
    });

    const count = data ? data.miscitems.count : 0;
    const miscitems = data ? data.miscitems.items : [];

    return (
        <AppNav error={error} loading={loading}>
            <NavContent>
                {{
                    header: (
                        <NavHeader>
                            <Typography variant="crisp">Misc Items</Typography>
                            <Button
                                onClick={() => nav('create')}
                                startIcon={<MdAdd />}
                            >
                                New Item
                            </Button>
                        </NavHeader>
                    ),
                    content: (
                        <SmartTable
                            data={miscitems}
                            getProps={(d) => ({
                                id: d._id,
                                onClick: () => nav(d._id),
                            })}
                            pagination={{
                                count,
                                filter,
                                setFilter: (f) =>
                                    setFilter({ ...filter, ...f }),
                            }}
                            controls={{
                                Name: (
                                    <SearchInput
                                        placeholder="Name"
                                        variant="standard"
                                        InputProps={{ disableUnderline: true }}
                                        value={filter.name || ''}
                                        onChange={(name) =>
                                            setFilter({
                                                ...filter,
                                                name: name || undefined,
                                                skip: 0,
                                            })
                                        }
                                    />
                                ),
                            }}
                        >
                            {{
                                Name: (c) => (
                                    <Typography sx={{ fontWeight: 700 }}>
                                        {c.name}
                                    </Typography>
                                ),
                            }}
                        </SmartTable>
                    ),
                }}
            </NavContent>
        </AppNav>
    );
};

export default MiscItemList;
