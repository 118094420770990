import { Box, useMediaQuery, useTheme } from '@mui/material';
import React, { ReactElement } from 'react';
import ColumnBox, { ColumnBoxProps } from '../../../ColumnBox';

export interface NavContentProps extends ColumnBoxProps {
    padding?: { header: number; content: number; footer: number };
}

const NavContent = (props: NavContentProps): ReactElement => {
    const {
        children: { header, content, footer },
        animate,
        padding,
    } = props;

    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <ColumnBox animate={animate}>
            {{
                header: header ? (
                    <Box
                        sx={{
                            padding: padding ? padding.header : small ? 2 : 4,
                            paddingBottom: padding
                                ? padding.header
                                : small
                                ? 1
                                : 2,
                            paddingTop: padding
                                ? padding.header
                                : small
                                ? 3
                                : undefined,
                        }}
                    >
                        {header}
                    </Box>
                ) : undefined,
                content: content ? (
                    <Box
                        sx={{
                            height: '100%',
                            padding: padding ? padding.content : small ? 2 : 4,
                            paddingTop: 0,
                            paddingBottom: 0,
                        }}
                    >
                        {content}
                    </Box>
                ) : undefined,
                footer: footer ? (
                    <Box
                        sx={{
                            padding: padding ? padding.footer : small ? 2 : 6,
                        }}
                    >
                        {footer}
                    </Box>
                ) : undefined,
            }}
        </ColumnBox>
    );
};

export default NavContent;
