import { gql } from '@apollo/client';
import { Pagination } from '../../../utils/types/Pagination';
import { AddressFragment } from '../../Address/Address';
import { AppointmentFragment } from '../../Appointment/Appointment';
import { BaseFragment } from '../../Base/Base';
import { BolFragment } from '../../Bol/Bol';
import { TinyCompanyFragment } from '../../Company/Company';
import { TinyContactFragment } from '../../Contact/Contact';
import { ItemContentFragment } from '../../Content/ItemContent/ItemContent';
import { LotContentFragment } from '../../Content/LotContent/LotContent';
import { FormResponseFragment } from '../../FormResponse/FormResponse';
import { FulfillmentLineFragment } from '../../FulfillmentLine/FulfillmentLine';
import { TinyItemFragment } from '../../Item/Item';
import { ItineraryFragment } from '../../Itinerary/Itinerary';
import { ItineraryScheduleFragment } from '../../Itinerary/ItinerarySchedule';
import {
    CompanyLocationFragment,
    TinyLocationFragment,
} from '../../Location/Location';
import { TinyLotFragment } from '../../Lot/Lot';
import { ReceiptFragment } from '../../Receipt/Receipt';
import { ShipmentFragment } from '../../Shipment/Shipment';
import { getQueryHook } from '../../types';
import { TinyUnitFragment } from '../../Unit/Unit';
import { VerificationFragment } from '../../Verification/Verification';
import { Order, OrderFragment, TinyOrder, TinyOrderFragment } from '../Order';
import { OrderFilter } from '../OrderFilter';

export const TinyOrdersQuery = gql`
    ${TinyOrderFragment}
    ${BaseFragment}
    ${TinyCompanyFragment}
    ${TinyItemFragment}
    ${TinyContactFragment}
    ${CompanyLocationFragment}
    ${AddressFragment}
    query TinyOrdersQuery($filter: OrderFilter!) {
        orders(filter: $filter) {
            count
            items {
                ...TinyOrderFragment
            }
        }
    }
`;

export const useTinyOrders = getQueryHook<
    { orders: Pagination<TinyOrder> },
    { filter: OrderFilter }
>(TinyOrdersQuery);

export const HeavyOrdersQuery = gql`
    ${BaseFragment}
    ${OrderFragment}
    ${TinyCompanyFragment}
    ${TinyContactFragment}
    ${CompanyLocationFragment}
    ${ItineraryFragment}
    ${BolFragment}
    ${AppointmentFragment}
    ${ItemContentFragment}
    ${ReceiptFragment}
    ${ShipmentFragment}
    ${TinyLocationFragment}
    ${TinyItemFragment}
    ${TinyUnitFragment}
    ${FulfillmentLineFragment}
    ${FormResponseFragment}
    ${LotContentFragment}
    ${TinyLotFragment}
    ${AddressFragment}
    ${ItineraryScheduleFragment}
    ${VerificationFragment}
    query HeavyOrdersQuery($filter: OrderFilter!) {
        orders(filter: $filter) {
            count
            items {
                ...OrderFragment
            }
        }
    }
`;

export const useHeavyOrders = getQueryHook<
    { orders: Pagination<Order> },
    { filter: OrderFilter }
>(HeavyOrdersQuery);
