import { gql } from '@apollo/client';

export interface DashboardReceipt {
    profile: { user_id: string; name: string; picture?: string | null };
    item: { _id: string; name: string };
}

export const DashboardReceiptFragment = gql`
    fragment DashboardReceiptFragment on DashboardReceipt {
        profile {
            user_id
            name
            picture
        }
        item {
            _id
            name
        }
    }
`;
