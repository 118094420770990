import {
    Box,
    Collapse,
    FormControlLabel,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { MdCheck, MdDelete, MdRefresh } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import FormResult from '../../../../../../components/feedback/FormResult';
import CarefullButton from '../../../../../../components/inputs/Buttons/CarefulButton';
import SuccessButton from '../../../../../../components/inputs/Buttons/SuccessButton';
import OurLocationField from '../../../../../../components/inputs/FieldInputs/OurLocationField';
import PermissionField from '../../../../../../components/inputs/FieldInputs/PermissionField';
import UserListSelect from '../../../../../../components/inputs/FieldInputs/UserListSelect';
import AppNav from '../../../../../../components/Layout/AppNav/components';
import NavContent from '../../../../../../components/Layout/AppNav/components/NavContent';
import FormRow from '../../../../../../components/Layout/FormRow';
import NavHeader from '../../../../../../components/Layout/NavHeader';
import { useTeam } from '../../../../../../graphql/Team/useTeam';
import {
    CreateTeamArgs,
    CreateTeamRes,
    useTeamCreation,
} from '../../../../../../graphql/Team/useTeamCreation';
import {
    UpdateTeamArgs,
    UpdateTeamRes,
    useTeamUpdate,
} from '../../../../../../graphql/Team/useTeamUpdate';
import { OperationResult } from '../../../../../../utils/types/OperationResult';

const TeamForm = (): ReactElement => {
    const { id } = useParams();
    const nav = useNavigate();

    const [state, setState] = React.useState<UpdateTeamArgs | CreateTeamArgs>({
        data: {
            name: '',
            description: '',
            members: [],
            permissions: [],
            locations: null,
        },
    });

    const { data } = useTeam({
        variables: { id: id || '' },
        skip: !id,
        fetchPolicy: 'network-only',
        onCompleted: ({ team }) => {
            setState({
                id: team._id,
                data: {
                    name: team.name,
                    description: team.description,
                    members: team.members.map((m) => m.user_id),
                    permissions: team.permissions,
                    locations:
                        team.locations.length > 0
                            ? team.locations.map((l) => l._id)
                            : null,
                },
            });
        },
    });

    const [result, setResult] = React.useState<null | OperationResult<
        CreateTeamRes | UpdateTeamRes
    >>(null);

    const [handleCreate, { loading: createLoading }] = useTeamCreation({
        variables: 'id' in state ? undefined : state,
        onCompleted: (data) => setResult({ success: true, data }),
        onError: (error) => setResult({ success: false, error }),
    });

    const [handleUpdate, { loading: updateLoading }] = useTeamUpdate({
        variables:
            'id' in state ? { ...state, id: id || state.id || '' } : undefined,
        onCompleted: (data) => setResult({ success: true, data }),
        onError: (error) => setResult({ success: false, error }),
    });

    const getHoldup = (): string | null => {
        if (!state.data.name) return 'Please enter a team name.';
        if (state.data.locations !== null && state.data.locations.length == 0)
            return 'If Team is Location Specific, please specify at least one location';
        return null;
    };

    return (
        <AppNav loading={updateLoading || createLoading}>
            {result ? (
                <FormResult
                    entity="Team"
                    clear={() => setResult(null)}
                    onComplete={() => nav('/people/teams')}
                >
                    {result}
                </FormResult>
            ) : (
                <NavContent>
                    {{
                        header: (
                            <NavHeader
                                back={
                                    data
                                        ? [
                                              data.team.name,
                                              `/people/teams/${data.team._id}`,
                                          ]
                                        : ['Teams', '/people/teams']
                                }
                            >
                                <Typography variant="crisp">
                                    {id && !data
                                        ? ''
                                        : data
                                        ? `Update ${data.team.name}`
                                        : 'New Team'}
                                </Typography>
                                <Box />
                            </NavHeader>
                        ),
                        content: (
                            <Box sx={{ maxWidth: 400 }}>
                                <Box>
                                    <FormRow>
                                        <TextField
                                            autoFocus
                                            fullWidth
                                            label="Name"
                                            value={state.data.name}
                                            onChange={(e) =>
                                                setState({
                                                    ...state,
                                                    data: {
                                                        ...state.data,
                                                        name: e.target.value,
                                                    },
                                                })
                                            }
                                        />
                                    </FormRow>
                                    <FormRow>
                                        <TextField
                                            fullWidth
                                            label="Description"
                                            value={state.data.description}
                                            onChange={(e) =>
                                                setState({
                                                    ...state,
                                                    data: {
                                                        ...state.data,
                                                        description:
                                                            e.target.value,
                                                    },
                                                })
                                            }
                                        />
                                    </FormRow>
                                    <FormRow>
                                        <UserListSelect
                                            multiple
                                            value={state.data.members}
                                            onChange={(members) =>
                                                setState({
                                                    ...state,
                                                    data: {
                                                        ...state.data,
                                                        members,
                                                    },
                                                })
                                            }
                                        />
                                    </FormRow>
                                    <FormRow>
                                        <PermissionField
                                            value={state.data.permissions}
                                            onChange={(permissions) =>
                                                setState({
                                                    ...state,
                                                    data: {
                                                        ...state.data,
                                                        permissions,
                                                    },
                                                })
                                            }
                                        />
                                    </FormRow>
                                    <FormRow>
                                        <FormControlLabel
                                            label="Location specific"
                                            control={
                                                <Switch
                                                    checked={
                                                        state.data.locations !==
                                                        null
                                                    }
                                                    onChange={(e, checked) => {
                                                        setState({
                                                            ...state,
                                                            data: {
                                                                ...state.data,
                                                                locations:
                                                                    checked
                                                                        ? []
                                                                        : null,
                                                            },
                                                        });
                                                    }}
                                                />
                                            }
                                        />
                                    </FormRow>
                                    <Collapse
                                        in={state.data.locations !== null}
                                    >
                                        <FormRow>
                                            <OurLocationField
                                                multiple
                                                value={
                                                    state.data.locations == null
                                                        ? []
                                                        : state.data.locations
                                                }
                                                onChange={(locations) => {
                                                    setState({
                                                        ...state,
                                                        data: {
                                                            ...state.data,
                                                            locations:
                                                                locations.map(
                                                                    (l) => l._id
                                                                ),
                                                        },
                                                    });
                                                }}
                                            />
                                        </FormRow>
                                    </Collapse>
                                </Box>
                                <FormRow>
                                    <SuccessButton
                                        holdup={getHoldup()}
                                        success={null}
                                        onSuccess={() => null}
                                        endIcon={<MdCheck />}
                                        onClick={() => {
                                            if ('id' in state) {
                                                handleUpdate();
                                            } else {
                                                handleCreate();
                                            }
                                        }}
                                    >
                                        Save
                                    </SuccessButton>
                                    {data && (
                                        <CarefullButton
                                            endIcon={
                                                data.team.deletion ? (
                                                    <MdRefresh />
                                                ) : (
                                                    <MdDelete />
                                                )
                                            }
                                            onClick={() => {
                                                handleUpdate({
                                                    variables: {
                                                        id: data.team._id,
                                                        data: {
                                                            name: data.team
                                                                .name,
                                                            description:
                                                                data.team
                                                                    .description,
                                                            members:
                                                                data.team.members.map(
                                                                    (m) =>
                                                                        m.user_id
                                                                ),
                                                            permissions:
                                                                data.team
                                                                    .permissions,
                                                            locations: data.team
                                                                .locations
                                                                ? data.team.locations.map(
                                                                      (l) =>
                                                                          l._id
                                                                  )
                                                                : null,
                                                            deleted: true,
                                                        },
                                                    },
                                                    onCompleted: (data) =>
                                                        setResult({
                                                            success: true,
                                                            data,
                                                        }),
                                                    onError: (error) =>
                                                        setResult({
                                                            success: false,
                                                            error,
                                                        }),
                                                });
                                            }}
                                        >
                                            {data.team.deletion
                                                ? 'Restore Team'
                                                : 'Delete Team'}
                                        </CarefullButton>
                                    )}
                                </FormRow>
                            </Box>
                        ),
                    }}
                </NavContent>
            )}
        </AppNav>
    );
};

export default TeamForm;
