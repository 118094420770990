import { gql } from '@apollo/client';
import { Address } from '../Address/Address';
import { Base, TinyBase } from '../Base/Base';
import { TinyCompany } from '../Company/Company';

export interface Location extends Base {
    company: TinyCompany;
    label: string;
    address?: Address | null;
}

export interface TinyLocation extends TinyBase {
    company: TinyCompany;
    label: string;
    address?: Address | null;
}

export interface CompanyLocation extends TinyBase {
    label: string;
    address?: Address | null;
}

export const LocationFragment = gql`
    fragment LocationFragment on Location {
        ...BaseFragment
        company {
            ...TinyCompanyFragment
        }
        label
        address {
            ...AddressFragment
        }
    }
`;

export const TinyLocationFragment = gql`
    fragment TinyLocationFragment on Location {
        _id
        company {
            ...TinyCompanyFragment
        }
        label
        address {
            ...AddressFragment
        }
    }
`;

export const CompanyLocationFragment = gql`
    fragment CompanyLocationFragment on Location {
        _id
        label
        address {
            ...AddressFragment
        }
    }
`;
