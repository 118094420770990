import { gql } from '@apollo/client';
import { getMutationHook } from '../../types';
import { Recipe, RecipeFragment } from '../Recipe';
import { UpdateRecipeInput } from '../inputs/UpdateRecipeInput';

export const UpdateRecipe = gql`
    ${RecipeFragment}
    mutation UpdateRecipe($id: ObjectId!, $data: UpdateRecipeInput!) {
        updateRecipe(id: $id, data: $data) {
            ...RecipeFragment
        }
    }
`;

export interface UpdateRecipeRes {
    updateRecipe: Recipe;
}

export interface UpdateRecipeArgs {
    id: string;
    data: UpdateRecipeInput;
}

export const useRecipeUpdate = getMutationHook<
    UpdateRecipeRes,
    UpdateRecipeArgs
>(UpdateRecipe);
