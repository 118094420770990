import { TypographyProps, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { useLanguageContext } from '../../../providers/LanguageProvider';

export interface SpanishProps extends Omit<TypographyProps, 'children'> {
    children: string | [string, string];
}

const Spanish = (props: SpanishProps): ReactElement => {
    const { children, ...rest } = props;

    const { preference } = useLanguageContext();

    return (
        <Typography {...rest}>
            {children instanceof Array
                ? children[preference == 'sp' ? 0 : 1]
                : children}
        </Typography>
    );
};

export default Spanish;
