import { gql } from '@apollo/client';
import { getMutationHook } from '../../types';
import { OrderQueueLineInput } from '../inputs/OrderQueueLineInput';

export const ProcessOrderQueue = gql`
    mutation ProcessOrderQueue($data: [OrderQueueLineInput!]!) {
        processOrderQueue(data: $data)
    }
`;

export const useOrderQueueProcess = getMutationHook<
    {
        processOrderQueue: boolean;
    },
    {
        data: OrderQueueLineInput[];
    }
>(ProcessOrderQueue);
