import { gql } from '@apollo/client';
import { Pagination } from '../../utils/types/Pagination';
import { ItemContentFragment } from '../Content/ItemContent/ItemContent';
import { TinyItemFragment } from '../Item/Item';
import { getQueryHook } from '../types';
import { TinyUnitFragment } from '../Unit/Unit';
import { VerificationFragment } from '../Verification/Verification';
import { FulfillmentEvent, FulfillmentEventFragment } from './FulfillmentEvent';
import { FulfillmentEventFilter } from './FulfillmentEventFilter';

export const FulfillmentEvents = gql`
    ${FulfillmentEventFragment}
    ${ItemContentFragment}
    ${VerificationFragment}
    ${TinyItemFragment}
    ${TinyUnitFragment}
    query FulfillmentEvents($filter: FulfillmentEventFilter!) {
        fulfillmentEvents(filter: $filter) {
            count
            items {
                ...FulfillmentEventFragment
            }
        }
    }
`;

export interface FulfillmentEventsArgs {
    filter: FulfillmentEventFilter;
}

export interface FulfillmentEventsRes {
    fulfillmentEvents: Pagination<FulfillmentEvent>;
}

export const useFulfillmentEvents = getQueryHook<
    FulfillmentEventsRes,
    FulfillmentEventsArgs
>(FulfillmentEvents);
