import { MenuItem, TextField, TextFieldProps } from '@mui/material';
import React, { ReactElement } from 'react';
import { ItemType } from '../../../../graphql/Item/Item';
import { permissableUnits } from '../../../../graphql/Unit/permissableUnits';
import { UnitClass } from '../../../../graphql/Unit/UnitClass';

export type UnitClassInputProps = Omit<
    TextFieldProps,
    'value' | 'onChange' | 'children' | 'select'
> & {
    value: UnitClass;
    onChange: (unit_class: UnitClass) => void;
    item_type?: ItemType;
};

const UnitClassInput = (props: UnitClassInputProps): ReactElement => {
    const { value, onChange, item_type, ...rest } = props;

    const filtered = Object.keys(UnitClass).filter((key) => {
        if (!item_type) return true;
        else if (permissableUnits[item_type].includes(key as UnitClass))
            return true;
        else return false;
    });

    return (
        <TextField
            {...rest}
            value={value}
            select
            onChange={(e) => {
                onChange(e.target.value as UnitClass);
            }}
        >
            {filtered.map((uClass) => (
                <MenuItem key={'class_' + uClass} value={uClass}>
                    {uClass}
                </MenuItem>
            ))}
        </TextField>
    );
};

export default UnitClassInput;
