import {
    Box,
    Button,
    Collapse,
    Divider,
    FormControlLabel,
    MenuItem,
    Switch,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { MdAdd, MdCheck, MdDelete, MdRefresh } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import FormResult from '../../../../../../components/feedback/FormResult';
import CarefullButton from '../../../../../../components/inputs/Buttons/CarefulButton';
import SuccessButton from '../../../../../../components/inputs/Buttons/SuccessButton';
import ItemField from '../../../../../../components/inputs/FieldInputs/ItemField';
import AppNav from '../../../../../../components/Layout/AppNav/components';
import NavContent from '../../../../../../components/Layout/AppNav/components/NavContent';
import FormRow from '../../../../../../components/Layout/FormRow';
import NavHeader from '../../../../../../components/Layout/NavHeader';
import { useQualityCheckForm } from '../../../../../../graphql/Form/extensions/QualityCheckForm/operations/useQualityCheckForm';
import {
    CreateQualityCheckFormArgs,
    CreateQualityCheckFormRes,
    useQualityCheckFormCreation,
} from '../../../../../../graphql/Form/extensions/QualityCheckForm/operations/useQualityCheckFormCreation';
import {
    UpdateQualityCheckFormArgs,
    UpdateQualityCheckFormRes,
    useQualityCheckFormUpdate,
} from '../../../../../../graphql/Form/extensions/QualityCheckForm/operations/useQualityCheckFormUpdate';
import { QualityCheckCategory } from '../../../../../../graphql/Form/extensions/QualityCheckForm/QualityCheckCategory';
import { QualityCheckForm } from '../../../../../../graphql/Form/extensions/QualityCheckForm/QualityCheckForm';
import { FieldCategory } from '../../../../../../graphql/Form/FieldCategory';
import { ItemType } from '../../../../../../graphql/Item/Item';
import { relocate } from '../../../../../../utils/relocate';
import { OperationResult } from '../../../../../../utils/types/OperationResult';
import FormFieldLine from './components/FormFieldInput';

const getStateFromCheck = (
    qc: QualityCheckForm
): UpdateQualityCheckFormArgs => {
    return {
        id: qc._id,
        data: {
            items: qc.items ? qc.items.map((i) => i._id) : null,
            category: qc.category,
            fields: qc.fields.map(
                ({
                    category,
                    question,
                    options,
                    required,
                    passable_options,
                }) => ({
                    category,
                    question,
                    options,
                    required,
                    passable_options,
                })
            ),
        },
    };
};

const QualityCheckFormRender = (): ReactElement => {
    const { id } = useParams();
    const nav = useNavigate();

    const { palette, shape } = useTheme();

    const [state, setState] = React.useState<
        (UpdateQualityCheckFormArgs | CreateQualityCheckFormArgs) & {
            item_types: ItemType[];
        }
    >({
        data: {
            items: null,
            category: QualityCheckCategory.Receipt,
            fields: [
                {
                    category: FieldCategory.ShortAnswer,
                    question: '',
                    options: [],
                    passable_options: [],
                    required: true,
                },
            ],
        },
        item_types: [ItemType.Ingredient, ItemType.Package],
    });

    const { data } = useQualityCheckForm({
        variables: { id: id || '' },
        skip: !id,
        fetchPolicy: 'network-only',
        onCompleted: ({ qualityCheckForm: qc }) => {
            setState({
                item_types: qc.items
                    ? qc.items[0].type == ItemType.Ingredient ||
                      qc.items[0].type == ItemType.Package
                        ? [ItemType.Ingredient, ItemType.Package]
                        : [qc.items[0].type]
                    : [ItemType.Ingredient],
                ...getStateFromCheck(qc),
            });
        },
    });

    const [result, setResult] = React.useState<null | OperationResult<
        CreateQualityCheckFormRes | UpdateQualityCheckFormRes
    >>(null);

    const [handleCreate, { loading: createLoading }] =
        useQualityCheckFormCreation({
            variables:
                'id' in state
                    ? undefined
                    : {
                          data: state.data,
                      },
            onCompleted: (data) => setResult({ success: true, data }),
            onError: (error) => setResult({ success: false, error }),
        });

    const [handleUpdate, { loading: updateLoading }] =
        useQualityCheckFormUpdate({
            variables:
                'id' in state
                    ? { id: id || state.id || '', data: state.data }
                    : undefined,
            onCompleted: (data) => setResult({ success: true, data }),
            onError: (error) => setResult({ success: false, error }),
        });

    const getHoldup = (): string | null => {
        if (state.data.items !== null && state.data.items.length == 0)
            return 'If check is item specific, select at least one item.';

        if (state.data.fields.some((s) => s.question == ''))
            return 'Please enter a question for each field.';

        if (
            state.data.fields.some(
                (s) =>
                    (s.category == FieldCategory.CheckBoxes ||
                        s.category == FieldCategory.Dropdown) &&
                    s.options.length == 0
            )
        )
            return 'Please enter options for each selection field.';

        if (
            state.data.fields.some(
                (s) =>
                    s.category === FieldCategory.LinearScale &&
                    (!s.options[0] ||
                        !s.options[1] ||
                        !s.passable_options[0] ||
                        !s.passable_options[1])
            )
        )
            return 'Please enter linear scale info.';

        return null;
    };

    return (
        <AppNav loading={updateLoading || createLoading}>
            {result ? (
                <FormResult
                    entity="QualityCheck"
                    clear={() => setResult(null)}
                    onComplete={() => nav('/qa/qualitychecks')}
                >
                    {result}
                </FormResult>
            ) : (
                <NavContent>
                    {{
                        header: (
                            <NavHeader
                                back={
                                    data
                                        ? [
                                              `Quality Check: ${
                                                  data.qualityCheckForm.items
                                                      ? data.qualityCheckForm.items
                                                            .map((i) => i.name)
                                                            .join(', ')
                                                      : 'All Items'
                                              }`,
                                              `/qa/qualitychecks/${data.qualityCheckForm._id}`,
                                          ]
                                        : [
                                              'Quality Checks',
                                              '/qa/qualitychecks',
                                          ]
                                }
                            >
                                <Typography variant="crisp">
                                    {id && !data
                                        ? ''
                                        : data
                                        ? `Update Quality Check`
                                        : 'New Quality Check'}
                                </Typography>
                                <Box />
                            </NavHeader>
                        ),
                        content: (
                            <Box sx={{ maxWidth: 720, paddingBottom: 10 }}>
                                <Box sx={{ maxWidth: 350 }}>
                                    <FormRow>
                                        <FormControlLabel
                                            label="Item Specific"
                                            control={
                                                <Switch
                                                    checked={
                                                        state.data.items !==
                                                        null
                                                    }
                                                    onChange={(e, checked) => {
                                                        if (checked)
                                                            setState({
                                                                ...state,
                                                                data: {
                                                                    ...state.data,
                                                                    items: [],
                                                                },
                                                            });
                                                        else {
                                                            setState({
                                                                ...state,
                                                                data: {
                                                                    ...state.data,
                                                                    items: null,
                                                                },
                                                            });
                                                        }
                                                    }}
                                                />
                                            }
                                        />
                                    </FormRow>
                                    <FormRow>
                                        <TextField
                                            fullWidth
                                            label="Asked During"
                                            select
                                            value={state.data.category}
                                            onChange={(e) => {
                                                const category = e.target
                                                    .value as QualityCheckCategory;

                                                setState({
                                                    ...state,
                                                    data: {
                                                        ...state.data,
                                                        category,
                                                        items: state.data.items
                                                            ? []
                                                            : null,
                                                    },
                                                    item_types:
                                                        category ===
                                                        QualityCheckCategory.Production
                                                            ? [ItemType.Cookie]
                                                            : category ===
                                                              QualityCheckCategory.Receipt
                                                            ? [
                                                                  ItemType.Ingredient,
                                                                  ItemType.Package,
                                                              ]
                                                            : [
                                                                  ItemType.Product,
                                                              ],
                                                });
                                            }}
                                        >
                                            <MenuItem
                                                value={
                                                    QualityCheckCategory.Receipt
                                                }
                                            >
                                                Receiving
                                            </MenuItem>
                                            <MenuItem
                                                value={
                                                    QualityCheckCategory.Shipment
                                                }
                                            >
                                                Shipping
                                            </MenuItem>
                                            <MenuItem
                                                value={
                                                    QualityCheckCategory.Production
                                                }
                                            >
                                                Production
                                            </MenuItem>
                                        </TextField>
                                    </FormRow>
                                    <Collapse in={state.data.items !== null}>
                                        <Box>
                                            <FormRow>
                                                <ItemField
                                                    types={state.item_types}
                                                    label={
                                                        state.data.category ===
                                                        QualityCheckCategory.Production
                                                            ? 'Cookies'
                                                            : state.data
                                                                  .category ===
                                                              QualityCheckCategory.Receipt
                                                            ? 'Items'
                                                            : 'Products'
                                                    }
                                                    fullWidth
                                                    multiple
                                                    value={
                                                        state.data.items || []
                                                    }
                                                    onChange={(items) => {
                                                        setState({
                                                            ...state,
                                                            data: {
                                                                ...state.data,
                                                                items,
                                                            },
                                                        });
                                                    }}
                                                />
                                            </FormRow>
                                        </Box>
                                    </Collapse>
                                </Box>
                                <Box sx={{ paddingBottom: 1, paddingTop: 1 }}>
                                    <Typography>
                                        Quality Check Fields
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexFlow: 'column',
                                        gap: 2,
                                    }}
                                >
                                    {state.data.fields.map((field, index) => (
                                        <FormFieldLine
                                            reIndex={(dir) => {
                                                const copy = { ...state.data };
                                                relocate(
                                                    copy.fields,
                                                    index,
                                                    index + dir
                                                );
                                                setState({
                                                    ...state,
                                                    data: copy,
                                                });
                                            }}
                                            length={state.data.fields.length}
                                            index={index}
                                            key={'field_' + index}
                                            value={field}
                                            onChange={(val) => {
                                                if (!val) {
                                                    const fields = [
                                                        ...state.data.fields,
                                                    ];
                                                    fields.splice(index, 1);

                                                    setState({
                                                        ...state,
                                                        data: {
                                                            ...state.data,
                                                            fields,
                                                        },
                                                    });
                                                } else {
                                                    const fields = [
                                                        ...state.data.fields,
                                                    ];
                                                    fields[index] = val;

                                                    setState({
                                                        ...state,
                                                        data: {
                                                            ...state.data,
                                                            fields,
                                                        },
                                                    });
                                                }
                                            }}
                                        />
                                    ))}
                                </Box>
                                <Button
                                    onClick={() =>
                                        setState({
                                            ...state,
                                            data: {
                                                ...state.data,
                                                fields: [
                                                    ...state.data.fields,
                                                    {
                                                        category:
                                                            FieldCategory.ShortAnswer,
                                                        question: '',
                                                        options: [],
                                                        required: true,
                                                        passable_options: [],
                                                    },
                                                ],
                                            },
                                        })
                                    }
                                    color="inherit"
                                    variant="contained"
                                    sx={{ marginTop: 2 }}
                                    startIcon={<MdAdd />}
                                >
                                    Field
                                </Button>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        gap: 2,
                                        paddingTop: 3,
                                    }}
                                >
                                    <SuccessButton
                                        holdup={getHoldup()}
                                        success={null}
                                        onSuccess={() => null}
                                        endIcon={<MdCheck />}
                                        onClick={() => {
                                            if ('id' in state) {
                                                handleUpdate();
                                            } else {
                                                handleCreate();
                                            }
                                        }}
                                    >
                                        Save
                                    </SuccessButton>
                                    {data && (
                                        <CarefullButton
                                            endIcon={
                                                data.qualityCheckForm
                                                    .deletion ? (
                                                    <MdRefresh />
                                                ) : (
                                                    <MdDelete />
                                                )
                                            }
                                            onClick={() => {
                                                handleUpdate({
                                                    variables: {
                                                        id: data
                                                            .qualityCheckForm
                                                            ._id,
                                                        data: {
                                                            ...getStateFromCheck(
                                                                data.qualityCheckForm
                                                            ).data,
                                                            deleted: true,
                                                        },
                                                    },
                                                    onCompleted: (data) =>
                                                        setResult({
                                                            success: true,
                                                            data,
                                                        }),
                                                    onError: (error) =>
                                                        setResult({
                                                            success: false,
                                                            error,
                                                        }),
                                                });
                                            }}
                                        >
                                            {data.qualityCheckForm.deletion
                                                ? 'Restore Quality Check'
                                                : 'Delete Quality Check'}
                                        </CarefullButton>
                                    )}
                                </Box>
                            </Box>
                        ),
                    }}
                </NavContent>
            )}
        </AppNav>
    );
};

export default QualityCheckFormRender;
