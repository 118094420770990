import React, { ReactElement } from 'react';
import {
    BrowserRouter,
    Navigate,
    PathRouteProps,
    Route,
    Routes,
} from 'react-router-dom';
import { getUiPermissions, UiPermission } from '../auth/UiPermission';
import useMyProfile from '../auth/useMyProfile';
import { UserRole } from '../auth/UserRole';
import Warehouse from '../scenes/Warehouse';
import Library from '../scenes/Library';
import Login from '../scenes/Login';
import Logout from '../scenes/Logout';
import MyAccount from '../scenes/MyAccount';
import NoPermissions from '../scenes/NoPermissions';
import NotFound from '../scenes/NotFound';
import People from '../scenes/People';
import QualityAssurance from '../scenes/QualityAssurance';
import SupplyChain from '../scenes/SupplyChain';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';
import Recipes from '../scenes/Recipes';
import Production from '../scenes/Production';
import Traceability from '../scenes/Traceability';
import PermissionRoute from './components/PermissionRoute';
import AdminRoute from './components/AdminRoute';
import ManagerRoute from './components/ManagerRoute';
import Dashboard from '../scenes/Dashboard';

const routes: {
    props: PathRouteProps;
    auth:
        | { _type: 'permission'; permission: UiPermission }
        | { _type: 'role'; role: UserRole }
        | { _type: 'authenticated' }
        | null;
}[] = [
    {
        props: {
            path: 'dashboard/*',
            element: <Dashboard />,
        },
        auth: {
            _type: 'permission',
            permission: UiPermission.Dashboard,
        },
    },
    {
        props: {
            path: 'warehouse/*',
            element: <Warehouse />,
        },
        auth: {
            _type: 'permission',
            permission: UiPermission.WarehouseOperator,
        },
    },
    {
        props: {
            path: 'supplychain/*',
            element: <SupplyChain />,
        },
        auth: {
            _type: 'permission',
            permission: UiPermission.Logistics,
        },
    },
    {
        props: {
            path: 'recipes/*',
            element: <Recipes />,
        },
        auth: {
            _type: 'permission',
            permission: UiPermission.Recipes,
        },
    },
    {
        props: {
            path: 'people/*',
            element: <People />,
        },
        auth: { _type: 'role', role: UserRole.Manager },
    },
    {
        props: {
            path: 'library/*',
            element: <Library />,
        },
        auth: { _type: 'permission', permission: UiPermission.Library },
    },
    {
        props: {
            path: 'qa/*',
            element: <QualityAssurance />,
        },
        auth: {
            _type: 'permission',
            permission: UiPermission.Library,
        },
    },
    {
        props: {
            path: 'production/*',
            element: <Production />,
        },
        auth: null,
    },
    {
        props: {
            path: 'traceability/*',
            element: <Traceability />,
        },
        auth: {
            _type: 'role',
            role: UserRole.Manager,
        },
    },
];

export const useIndexRoute = (): { path: string } => {
    const { roles, permissions, loading } = useMyProfile();

    const uiPermissions = getUiPermissions(permissions);

    const match = routes.find((route) => {
        if (roles.includes(UserRole.Admin)) return true;
        else if (roles.includes(UserRole.Manager))
            return (
                !route.auth ||
                route.auth._type == 'authenticated' ||
                route.auth._type == 'permission' ||
                route.auth.role == UserRole.Manager ||
                route.auth.role == UserRole.User
            );
        else {
            return (
                !route.auth ||
                (route.auth._type == 'permission' &&
                    uiPermissions
                        .map((p) => p.name)
                        .includes(route.auth.permission))
            );
        }
    });

    if (!match) {
        return { path: '/nopermissions' };
    } else return { path: match.props.path.replace('*', '') };
};

export const Router = (): ReactElement => {
    const { path } = useIndexRoute();
    return (
        <Routes>
            <Route path="/" element={<Navigate to={path} />} />
            <Route
                path="/login"
                element={
                    <UnauthenticatedRoute>
                        <Login />
                    </UnauthenticatedRoute>
                }
            />
            <Route />
            {routes.map(({ props: rProps, auth }) => {
                if (!auth)
                    return <Route {...rProps} key={'route' + rProps.path} />;
                else if (auth._type == 'authenticated')
                    return (
                        <Route
                            {...rProps}
                            key={'route' + rProps.path}
                            element={
                                <AuthenticatedRoute>
                                    {rProps.element}
                                </AuthenticatedRoute>
                            }
                        />
                    );
                else if (auth._type == 'permission')
                    return (
                        <Route
                            {...rProps}
                            key={'route' + rProps.path}
                            element={
                                <PermissionRoute permission={auth.permission}>
                                    {rProps.element}
                                </PermissionRoute>
                            }
                        />
                    );
                else if (auth.role == UserRole.Admin) {
                    return (
                        <Route
                            {...rProps}
                            key={'route' + rProps.path}
                            element={<AdminRoute>{rProps.element}</AdminRoute>}
                        />
                    );
                } else if (auth.role == UserRole.Manager) {
                    return (
                        <Route
                            {...rProps}
                            key={'route' + rProps.path}
                            element={
                                <ManagerRoute>{rProps.element}</ManagerRoute>
                            }
                        />
                    );
                } else
                    return (
                        <Route
                            {...rProps}
                            key={'route' + rProps.path}
                            element={
                                <AuthenticatedRoute>
                                    {rProps.element}
                                </AuthenticatedRoute>
                            }
                        />
                    );
            })}
            <Route
                path="/account"
                element={
                    <AuthenticatedRoute>
                        <MyAccount />
                    </AuthenticatedRoute>
                }
            />
            <Route
                path="/nopermissions"
                element={
                    <AuthenticatedRoute>
                        <NoPermissions />
                    </AuthenticatedRoute>
                }
            />
            <Route
                path="/logout"
                element={
                    <AuthenticatedRoute>
                        <Logout />
                    </AuthenticatedRoute>
                }
            />

            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};
