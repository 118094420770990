import { gql } from '@apollo/client';
import { BaseFragment } from '../../Base/Base';
import { getMutationHook } from '../../types';
import {
    CompanyLocationFragment,
    Location,
    LocationFragment,
} from '../Location';
import { UpdateLocationInput } from '../inputs/UpdateLocationInput';
import { AddressFragment } from '../../Address/Address';
import { TinyCompanyFragment } from '../../Company/Company';
import { TinyContactFragment } from '../../Contact/Contact';

export const UpdateLocationMutation = gql`
    ${LocationFragment}
    ${BaseFragment}
    ${AddressFragment}
    ${TinyContactFragment}
    ${CompanyLocationFragment}
    ${TinyCompanyFragment}
    mutation UpdateLocationMutation(
        $id: ObjectId!
        $data: UpdateLocationInput!
    ) {
        updateLocation(id: $id, data: $data) {
            ...LocationFragment
        }
    }
`;

export interface UpdateLocationRes {
    updateLocation: Location;
}

export interface UpdateLocationArgs {
    id: string;
    data: UpdateLocationInput;
}

export const useLocationUpdate = getMutationHook<
    UpdateLocationRes,
    UpdateLocationArgs
>(UpdateLocationMutation);
