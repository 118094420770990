import React, { ReactElement } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import WarehouseDashboard from '../Warehouse/components/WarehouseDashboard';
import ReceiptForm from '../Warehouse/components/WarehouseDashboard/components/ReceiptForm';
import ShipmentForm from '../Warehouse/components/WarehouseDashboard/components/ShipmentForm';

const Warehouse = (): ReactElement => {
    return (
        <Routes>
            <Route index element={<Navigate to="receiving" />} />
            <Route
                path="shipping/*"
                element={<WarehouseDashboard view="Shipping" />}
            />
            <Route
                path="receiving/*"
                element={<WarehouseDashboard view="Receiving" />}
            />
            <Route
                path="shipping/:shipment"
                element={<WarehouseDashboard view="Shipping" />}
            />
            <Route
                path="receiving/:receipt"
                element={<WarehouseDashboard view="Receiving" />}
            />
            <Route
                path="shipping/:shipment/:tab"
                element={<WarehouseDashboard view="Shipping" />}
            />
            <Route
                path="receiving/:receipt/:tab"
                element={<WarehouseDashboard view="Receiving" />}
            />
            <Route
                path="shipping/form/:order/:bol"
                element={<ShipmentForm />}
            />
            <Route
                path="receiving/form/:order/:bol"
                element={<ReceiptForm />}
            />
            <Route
                path="shipping/form/:order/:bol/:id"
                element={<ShipmentForm />}
            />
            <Route
                path="receiving/form/:order/:bol/:id"
                element={<ReceiptForm />}
            />
        </Routes>
    );
};

export default Warehouse;
