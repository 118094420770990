import { Box } from '@mui/material/';
import Typography from '@mui/material/Typography';
import React, { ReactElement, ReactNode } from 'react';
import SmartTable from '../../../../../../components/display/SmartTable';
import SearchInput from '../../../../../../components/inputs/FieldInputs/SearchInput';
import { TinyItem } from '../../../../../../graphql/Item/Item';
import { useTinyItems } from '../../../../../../graphql/Item/operations/useTinyItems';
import { OrderStat } from '../../../../../../graphql/OrderStat/OrderStat';
import { UnitClass } from '../../../../../../graphql/Unit/UnitClass';
import { months } from '../../../../../../utils/months';
import { numberfy } from '../../../../../../utils/numberfy';
import { baseUnits } from '../../../../../Recipes/components/RecipeDetailForm/components/ConversionForm';

export interface StatTableProps {
    itemName?: string | undefined;
    setItemName: (name: string) => void;
    children: OrderStat[];
    year: number;
}

const StatTable = (props: StatTableProps): ReactElement => {
    const { children: stats, itemName, setItemName, year } = props;

    const { data, error, loading } = useTinyItems({
        variables: { filter: { skip: 0, take: 500, name: itemName } },
    });

    return (
        <React.Fragment>
            <SmartTable
                data={data ? data.items.items : []}
                getProps={(item) => ({
                    id: item._id,
                })}
                controls={{
                    Item: (
                        <SearchInput
                            variant="standard"
                            placeholder="Item"
                            InputProps={{ disableUnderline: true }}
                            value={itemName || ''}
                            onChange={(val) => setItemName(val || '')}
                        />
                    ),
                }}
            >
                {{
                    Item: (d) => d.name,
                    [`${year} Total`]: (d) => {
                        const matches = stats.filter(
                            (stat) => stat.item._id == d._id
                        );

                        const unitMap: Record<UnitClass, number> = {
                            [UnitClass.Count]: 0,
                            [UnitClass.Length]: 0,
                            [UnitClass.Volume]: 0,
                            [UnitClass.Weight]: 0,
                        };

                        for (const match of matches) {
                            unitMap[match.unit_class] =
                                unitMap[match.unit_class] + match.qty;
                        }

                        return (
                            <Box>
                                {Object.entries(unitMap).reduce(
                                    (stack, [key, qty], i) => {
                                        if (qty == 0) return stack;
                                        else
                                            return [
                                                ...stack,
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexFlow: 'column',
                                                    }}
                                                    key={
                                                        key +
                                                        '_' +
                                                        d._id +
                                                        '_' +
                                                        i
                                                    }
                                                >
                                                    <Typography>
                                                        {numberfy(
                                                            parseFloat(
                                                                qty.toFixed(2)
                                                            )
                                                        )}
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        color="text.secondary"
                                                    >
                                                        {
                                                            baseUnits[
                                                                key as UnitClass
                                                            ][0]
                                                        }
                                                    </Typography>
                                                </Box>,
                                            ];
                                    },
                                    [] as ReactNode[]
                                )}
                            </Box>
                        );
                    },
                    ...months.reduce((stack, month, index) => {
                        return {
                            ...stack,
                            [month.abbreviation]: (d) => {
                                const matches = stats.filter(
                                    (stat) =>
                                        stat.item._id == d._id &&
                                        stat.month == index + 1
                                );

                                const unitMap: Record<UnitClass, number> = {
                                    [UnitClass.Count]: 0,
                                    [UnitClass.Length]: 0,
                                    [UnitClass.Volume]: 0,
                                    [UnitClass.Weight]: 0,
                                };

                                for (const match of matches) {
                                    unitMap[match.unit_class] =
                                        unitMap[match.unit_class] + match.qty;
                                }

                                return (
                                    <Box>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                            {Object.entries(unitMap).reduce(
                                                (stack, [key, qty], i) => {
                                                    if (qty == 0) return stack;
                                                    else
                                                        return [
                                                            ...stack,
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    flexFlow:
                                                                        'column',
                                                                }}
                                                                key={
                                                                    key +
                                                                    '_' +
                                                                    d._id +
                                                                    '_' +
                                                                    key +
                                                                    '_' +
                                                                    i
                                                                }
                                                            >
                                                                <Typography>
                                                                    {numberfy(
                                                                        parseFloat(
                                                                            qty.toFixed(
                                                                                2
                                                                            )
                                                                        )
                                                                    )}
                                                                </Typography>
                                                                <Typography
                                                                    variant="caption"
                                                                    color="text.secondary"
                                                                >
                                                                    {
                                                                        baseUnits[
                                                                            key as UnitClass
                                                                        ][0]
                                                                    }
                                                                </Typography>
                                                            </Box>,
                                                        ];
                                                },
                                                [] as ReactNode[]
                                            )}
                                        </Typography>
                                    </Box>
                                );
                            },
                        };
                    }, {} as Record<string, (d: TinyItem) => ReactNode>),
                }}
            </SmartTable>
        </React.Fragment>
    );
};

export default StatTable;
