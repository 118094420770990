import {
    Avatar,
    Box,
    Button,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Skeleton,
    Typography,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { MdDescription, MdEdit } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { getUiPermissions } from '../../../../../../auth/UiPermission';
import BaseDetails from '../../../../../../components/baseComponents/BaseDetails';
import AppNav from '../../../../../../components/Layout/AppNav/components';
import NavContent from '../../../../../../components/Layout/AppNav/components/NavContent';
import NavHeader from '../../../../../../components/Layout/NavHeader';
import TabFade from '../../../../../../components/Layout/TabFade';
import { useTeam } from '../../../../../../graphql/Team/useTeam';

const Team = (): ReactElement => {
    const { id } = useParams();
    const nav = useNavigate();

    const { data, error, loading } = useTeam({
        variables: { id: id || '' },
    });

    const team = data ? data.team : null;

    return (
        <AppNav error={error} loading={loading}>
            <NavContent>
                {{
                    header: (
                        <NavHeader back={['Teams', '/people/teams']}>
                            {team ? (
                                <Typography variant="crisp">
                                    {team.name}
                                </Typography>
                            ) : (
                                <Skeleton width={320} height={72} />
                            )}
                            <Button
                                startIcon={<MdEdit />}
                                onClick={() => nav('edit')}
                            >
                                Edit team
                            </Button>
                        </NavHeader>
                    ),
                    content: (
                        <TabFade>
                            {{
                                Details: team ? (
                                    <BaseDetails
                                        extension={
                                            team.description
                                                ? {
                                                      Description: {
                                                          icon: (
                                                              <MdDescription />
                                                          ),
                                                          primary:
                                                              team.description,
                                                      },
                                                  }
                                                : undefined
                                        }
                                    >
                                        {team}
                                    </BaseDetails>
                                ) : (
                                    <Box />
                                ),
                                Members: (
                                    <List>
                                        {(team ? team.members : []).map(
                                            (member) => (
                                                <ListItem
                                                    divider
                                                    key={member.user_id}
                                                >
                                                    <ListItemAvatar>
                                                        <Avatar
                                                            src={member.picture}
                                                            alt={member.name}
                                                        />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={member.name}
                                                        secondary={
                                                            member.username ||
                                                            member.email
                                                        }
                                                    />
                                                </ListItem>
                                            )
                                        )}
                                    </List>
                                ),
                                Permissions: (
                                    <List>
                                        {(team
                                            ? getUiPermissions(team.permissions)
                                            : []
                                        ).map((permission) => (
                                            <ListItem
                                                divider
                                                key={
                                                    'permList_' +
                                                    permission.name
                                                }
                                            >
                                                <ListItemText
                                                    primary={permission.name}
                                                    secondary={
                                                        permission.description
                                                    }
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                ),
                            }}
                        </TabFade>
                    ),
                }}
            </NavContent>
        </AppNav>
    );
};

export default Team;
