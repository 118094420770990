import { Collapse, Typography, useTheme } from '@mui/material/';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import { format } from 'date-fns';
import React, { ReactElement } from 'react';
import { Lot } from '../../../graphql/Lot/Lot';
import { useLot } from '../../../graphql/Lot/useLot';
import { dateFormats } from '../../../utils/dateFormats';

export interface LotPopoverProps {
    children: { target: Element; lot: { _id: string; code: string } } | null;
    onClose: () => void;
}

const LotPopover = (props: LotPopoverProps): ReactElement => {
    const { children: focus } = props;

    const { palette } = useTheme();

    const { data, error, loading } = useLot({
        variables: {
            id: focus ? focus.lot._id : '',
        },
        skip: !focus,
    });

    const getLotDetails = (lot: Lot): [string, string][] => {
        return [
            ['Item', lot.item.name],
            ['Produced by', lot.company.name],
            [
                'Date Entered',
                format(new Date(lot.date_created), dateFormats.condensedDate),
            ],
            ['Entered By', lot.created_by.name],
        ];
    };

    return (
        <Popover
            open={Boolean(focus)}
            onClose={props.onClose}
            anchorEl={focus ? focus.target : null}
            PaperProps={{ sx: { p: 0, background: palette.tonal } }}
        >
            <Box>
                <Box
                    sx={{
                        background: palette.success.main,
                        p: 2,
                        color: palette.success.contrastText,
                    }}
                >
                    <Typography>{focus ? focus.lot.code : ''}</Typography>
                </Box>
                <Collapse
                    in={Boolean(focus) && !error && !loading && Boolean(data)}
                >
                    <Box>
                        {(data ? getLotDetails(data.lot) : []).map(
                            ([key, val], index) => (
                                <Box
                                    key={`d_${index}`}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        p: 1,
                                        paddingLeft: 2,
                                        paddingRight: 2,
                                    }}
                                >
                                    <Typography
                                        color="text.secondary"
                                        sx={{ paddingRight: 10 }}
                                    >
                                        {key}
                                    </Typography>
                                    <Typography>{val}</Typography>
                                </Box>
                            )
                        )}
                    </Box>
                </Collapse>
            </Box>
        </Popover>
    );
};

export default LotPopover;
