import { gql } from '@apollo/client';
import { Profile } from '../../auth/User';
import { Base } from '../Base/Base';
import { TinyLocation } from '../Location/Location';
import { MixingCardLine } from '../MixingCardLine/MixingCardLine';
import { ProductionLine } from '../ProductionLine/ProductionLine';
import { Ref } from '../types';

export interface MixingCard extends Base {
    mixer: Profile;
    location: TinyLocation;
    production_line: ProductionLine;
    lines: MixingCardLine[];
}

export const MixingCardFragment = gql`
    fragment MixingCardFragment on MixingCard {
        _id
        mixer {
            name
            user_id
            picture
            given_name
            family_name
        }
        location {
            ...TinyLocationFragment
        }
        production_line {
            _id
            name
        }
        lines {
            ...MixingCardLineFragment
        }
    }
`;
