import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { Box, Tooltip } from '@mui/material';
import React, { ReactElement } from 'react';
import { Animation } from '../../../../media/Animation';

export interface SuccessButtonProps extends LoadingButtonProps {
    success: string | null;
    onSuccess: () => void;
    holdup?: string | null;
}

const SuccessButton = (props: SuccessButtonProps): ReactElement => {
    const {
        success,
        holdup,
        onSuccess,
        variant = 'contained',
        ...rest
    } = props;

    return (
        <Tooltip title={holdup || ''} arrow placement="top">
            <Box>
                <LoadingButton
                    disabled={Boolean(holdup)}
                    onClick={success ? undefined : rest.onClick}
                    variant={success ? 'contained' : variant}
                    color={success ? 'success' : rest.color}
                    startIcon={
                        success ? (
                            <Box sx={{ display: 'flex', height: '100%' }}>
                                <Animation
                                    style={{ height: 24 }}
                                    type="success"
                                    loop={false}
                                    onComplete={onSuccess}
                                />
                            </Box>
                        ) : (
                            rest.startIcon
                        )
                    }
                    {...rest}
                >
                    {success || rest.children}
                </LoadingButton>
            </Box>
        </Tooltip>
    );
};

export default SuccessButton;
