import { Address } from './Address';

export const useAddressValidation = (
    address: Address | null | undefined
): null | string => {
    if (!address) return null;
    if (!address.city) return 'Please enter a City';
    if (!address.state) return 'Please enter a State';
    if (!address.postal) return 'Please enter a Postal Code';
    if (!address.line_1) return 'Please enter Line 1 of Address';
    return null;
};
