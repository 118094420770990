import { gql } from '@apollo/client';
import { getQueryHook } from '../../types';
import { TinyItem, TinyItemFragment } from '../Item';

const ItemQuery = gql`
    ${TinyItemFragment}
    query ItemQuery($id: ObjectId!) {
        item(id: $id) {
            ...TinyItemFragment
        }
    }
`;

export interface ItemQueryRes {
    item: TinyItem;
}

export interface ItemQueryArgs {
    id: string;
}

export const useItem = getQueryHook<ItemQueryRes, ItemQueryArgs>(ItemQuery);
