import {
    Box,
    Collapse,
    Drawer,
    FormControlLabel,
    IconButton,
    Skeleton,
    Switch,
    TextField,
    Typography,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { MdClear, MdDelete } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { Address } from '../../../../graphql/Address/Address';
import { useAddressValidation } from '../../../../graphql/Address/useAddressValidation';
import { CompanyQuery } from '../../../../graphql/Company/operations/useCompany';
import { useLocation } from '../../../../graphql/Location/operations/useLocation';
import {
    CreateLocationArgs,
    CreateLocationRes,
    useLocationCreation,
} from '../../../../graphql/Location/operations/useLocationCreation';
import {
    UpdateLocationArgs,
    UpdateLocationRes,
    useLocationUpdate,
} from '../../../../graphql/Location/operations/useLocationUpdate';
import { validateEmail } from '../../../../scenes/MyAccount/checkPassword';
import { OperationResult } from '../../../../utils/types/OperationResult';
import FormResult from '../../../feedback/FormResult';
import FormRow from '../../../Layout/FormRow';
import CarefullButton from '../../Buttons/CarefulButton';
import SuccessButton from '../../Buttons/SuccessButton';
import CompanyField from '../../FieldInputs/CompanyField';

export interface LocationDrawerProps {
    default_company?: string;
    focus: string | null | true;
    onClose: () => void;
}

const LocationDrawer = (props: LocationDrawerProps): ReactElement => {
    const { focus, default_company = '', onClose } = props;

    const [memorized, setMemorized] = React.useState<Address>({
        line_1: '',
        city: '',
        state: '',
        postal: '',
    });

    const [state, setState] = React.useState<
        CreateLocationArgs | UpdateLocationArgs
    >({
        data: {
            label: '',
            address: null,
            company: default_company,
        },
    });

    const addressValidation = useAddressValidation(state.data.address);

    React.useEffect(() => {
        if (state.data.address) setMemorized(state.data.address);
    }, [state]);

    const [result, setResult] = React.useState<null | OperationResult<
        CreateLocationRes | UpdateLocationRes
    >>(null);

    const [handleCreate, { loading: createLoading }] = useLocationCreation({
        variables: 'id' in state ? undefined : state,
        onCompleted: (data) => setResult({ success: true, data }),
        onError: (error) => setResult({ success: false, error }),
        refetchQueries: [CompanyQuery],
    });

    const [handleUpdate, { loading: updateLoading }] = useLocationUpdate({
        variables:
            'id' in state
                ? {
                      ...state,
                      id: focus !== null && focus !== true ? focus : '',
                  }
                : undefined,
        onCompleted: (data) => setResult({ success: true, data }),
        onError: (error) => setResult({ success: false, error }),
        refetchQueries: [CompanyQuery],
    });

    const { data } = useLocation({
        variables: focus !== true && focus !== null ? { id: focus } : undefined,
        skip: focus == true || focus == null,
        fetchPolicy: 'network-only',
        onCompleted: ({ location: { _id: id, label, address, company } }) => {
            setState({
                id,
                data: {
                    label,
                    address: address
                        ? {
                              line_1: address.line_1,
                              line_2: address.line_2,
                              line_3: address.line_3,
                              city: address.city,
                              state: address.state,
                              postal: address.postal,
                          }
                        : undefined,
                    company: company._id,
                },
            });
        },
    });

    const handleClose = () => {
        setResult(null);
        setState({
            data: {
                label: '',
                address: null,
                company: default_company,
            },
        });
        setMemorized({
            line_1: '',
            city: '',
            state: '',
            postal: '',
        });
        onClose();
    };

    const getHoldup = () => {
        if (addressValidation) return addressValidation;
        if (!state.data.label) return 'Please enter a label';
        if (!state.data.company) return 'Please select a company';
        return null;
    };

    return (
        <Drawer
            PaperProps={{ sx: { padding: 2 } }}
            open={focus !== null}
            anchor="right"
            onClose={handleClose}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingBottom: 2,
                    width: 400,
                }}
            >
                <Typography variant="crisp">
                    {focus !== true && focus !== null ? (
                        data ? (
                            <Typography variant="h4">
                                {data.location.label}
                            </Typography>
                        ) : (
                            <Skeleton height={60} width={100} />
                        )
                    ) : (
                        <Typography variant="h4">New Location</Typography>
                    )}
                </Typography>
                <IconButton onClick={handleClose}>
                    <MdClear />
                </IconButton>
            </Box>
            {result ? (
                <FormResult
                    entity="Location"
                    clear={() => setResult(null)}
                    onComplete={handleClose}
                >
                    {result}
                </FormResult>
            ) : (
                <Box>
                    <FormRow>
                        <TextField
                            fullWidth
                            label="Label"
                            value={state.data.label}
                            onChange={(e) =>
                                setState({
                                    ...state,
                                    data: {
                                        ...state.data,
                                        label: e.target.value,
                                    },
                                })
                            }
                        />
                    </FormRow>
                    <FormRow>
                        <CompanyField
                            fullWidth
                            multiple={false}
                            label="Company"
                            value={state.data.company}
                            onChange={(company) =>
                                setState({
                                    ...state,
                                    data: {
                                        ...state.data,
                                        company,
                                    },
                                })
                            }
                        />
                    </FormRow>
                    <FormRow>
                        <FormControlLabel
                            label="Has Address"
                            control={
                                <Switch
                                    checked={Boolean(state.data.address)}
                                    onChange={(e, checked) => {
                                        setState({
                                            ...state,
                                            data: {
                                                ...state.data,
                                                address: checked
                                                    ? memorized
                                                    : undefined,
                                            },
                                        });
                                    }}
                                />
                            }
                        />
                    </FormRow>
                    <Collapse in={Boolean(state.data.address)}>
                        <Box>
                            <FormRow>
                                <TextField
                                    fullWidth
                                    label={'Line 1'}
                                    value={
                                        state.data.address
                                            ? state.data.address.line_1
                                            : ''
                                    }
                                    onChange={(e) => {
                                        if (state.data.address) {
                                            setState({
                                                ...state,
                                                data: {
                                                    ...state.data,
                                                    address: {
                                                        ...state.data.address,
                                                        line_1: e.target.value,
                                                    },
                                                },
                                            });
                                        }
                                    }}
                                />
                            </FormRow>
                            <FormRow>
                                <TextField
                                    fullWidth
                                    label={'Line 2'}
                                    value={
                                        state.data.address
                                            ? state.data.address.line_2
                                            : ''
                                    }
                                    onChange={(e) => {
                                        if (state.data.address) {
                                            setState({
                                                ...state,
                                                data: {
                                                    ...state.data,
                                                    address: {
                                                        ...state.data.address,
                                                        line_2: e.target.value,
                                                    },
                                                },
                                            });
                                        }
                                    }}
                                />
                            </FormRow>
                            <FormRow>
                                <TextField
                                    fullWidth
                                    label={'City'}
                                    value={
                                        state.data.address
                                            ? state.data.address.city
                                            : ''
                                    }
                                    onChange={(e) => {
                                        if (state.data.address) {
                                            setState({
                                                ...state,
                                                data: {
                                                    ...state.data,
                                                    address: {
                                                        ...state.data.address,
                                                        city: e.target.value,
                                                    },
                                                },
                                            });
                                        }
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    label={'State'}
                                    value={
                                        state.data.address
                                            ? state.data.address.state
                                            : ''
                                    }
                                    onChange={(e) => {
                                        if (state.data.address) {
                                            setState({
                                                ...state,
                                                data: {
                                                    ...state.data,
                                                    address: {
                                                        ...state.data.address,
                                                        state: e.target.value,
                                                    },
                                                },
                                            });
                                        }
                                    }}
                                />
                            </FormRow>
                            <FormRow>
                                <TextField
                                    fullWidth
                                    label={'Postal'}
                                    value={
                                        state.data.address
                                            ? state.data.address.postal
                                            : ''
                                    }
                                    onChange={(e) => {
                                        if (state.data.address) {
                                            setState({
                                                ...state,
                                                data: {
                                                    ...state.data,
                                                    address: {
                                                        ...state.data.address,
                                                        postal: e.target.value,
                                                    },
                                                },
                                            });
                                        }
                                    }}
                                />
                            </FormRow>
                        </Box>
                    </Collapse>
                    <FormRow>
                        {data ? (
                            <CarefullButton
                                startIcon={<MdDelete />}
                                loading={createLoading || updateLoading}
                                onClick={() =>
                                    handleUpdate({
                                        variables: {
                                            id: data.location._id,
                                            data: {
                                                deleted: true,
                                                label: data.location.label,
                                                address: data.location.address
                                                    ? {
                                                          line_1: data.location
                                                              .address.line_1,
                                                          line_2: data.location
                                                              .address.line_2,
                                                          line_3: data.location
                                                              .address.line_3,
                                                          city: data.location
                                                              .address.city,
                                                          state: data.location
                                                              .address.state,
                                                          postal: data.location
                                                              .address.postal,
                                                      }
                                                    : null,
                                                company:
                                                    data.location.company._id,
                                            },
                                        },
                                        onCompleted: (data) =>
                                            setResult({ success: true, data }),
                                        onError: (error) =>
                                            setResult({
                                                success: false,
                                                error,
                                            }),
                                        refetchQueries: [CompanyQuery],
                                    })
                                }
                            >
                                Delete Location
                            </CarefullButton>
                        ) : (
                            <Box sx={{ flex: 1 }} />
                        )}
                        <SuccessButton
                            holdup={getHoldup()}
                            loading={createLoading || updateLoading}
                            success={result ? 'Location saved' : null}
                            onSuccess={handleClose}
                            onClick={() =>
                                'id' in state ? handleUpdate() : handleCreate()
                            }
                        >
                            Save Location
                        </SuccessButton>
                    </FormRow>
                </Box>
            )}
        </Drawer>
    );
};

export default LocationDrawer;
