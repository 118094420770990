import { gql } from '@apollo/client';
import { BaseFragment } from '../../../../Base/Base';
import { TinyItemFragment } from '../../../../Item/Item';
import { getAtomicQueryHook } from '../../../../types';
import {
    QualityCheckForm,
    QualityCheckFormFragment,
} from '../QualityCheckForm';

export const QualityCheckFormQuery = gql`
    ${BaseFragment}
    ${QualityCheckFormFragment}
    ${TinyItemFragment}
    query QualityCheckFormQuery($id: ObjectId!) {
        qualityCheckForm(id: $id) {
            ...QualityCheckFormFragment
        }
    }
`;

export const useQualityCheckForm = getAtomicQueryHook<{
    qualityCheckForm: QualityCheckForm;
}>(QualityCheckFormQuery);
