import {
    Autocomplete,
    Chip,
    MenuItem,
    Stack,
    TextField,
    TextFieldProps,
    useTheme,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { ItemType, TinyItem } from '../../../../graphql/Item/Item';
import { useTinyItems } from '../../../../graphql/Item/operations/useTinyItems';

export type ItemFieldProps<M extends boolean | undefined> = M extends true
    ? Omit<TextFieldProps, 'value' | 'onChange' | 'multiple'> & {
          multiple: true;
          value: string[];
          onChange: (value: string[]) => void;
          types?: ItemType[];
          ids?: string[];
      }
    : Omit<TextFieldProps, 'value' | 'onChange' | 'multiple'> & {
          multiple: false | undefined;
          value: string;
          onChange: (value: string) => void;
          types?: ItemType[];
          ids?: string[];
      };

const ItemField = <M extends boolean | undefined>(
    props: ItemFieldProps<M>
): ReactElement => {
    const { value, types, multiple, onChange, ids, ...rest } = props;

    const { palette } = useTheme();

    const { data, error, loading } = useTinyItems({
        variables: {
            filter: {
                skip: 0,
                take: 500,
                types,
                ids,
            },
        },
    });

    const items = data ? data.items.items : [];

    if (multiple)
        return (
            <Autocomplete
                multiple
                fullWidth={rest.fullWidth}
                options={items}
                isOptionEqualToValue={(item, value) => {
                    return item._id === value._id;
                }}
                freeSolo
                value={items.filter((val) => value.includes(val._id))}
                onChange={(v, newVal) => {
                    onChange((newVal as TinyItem[]).map((v) => v._id));
                }}
                renderOption={(props, op) => (
                    <MenuItem {...props}>{op.name}</MenuItem>
                )}
                renderTags={(val) => (
                    <Stack
                        sx={{
                            paddingTop: 1,
                            paddingBottom: 1,
                            flexWrap: 'wrap',
                        }}
                        direction="row"
                        gap={1}
                    >
                        {val.map((comp) => {
                            return (
                                <Chip
                                    key={'op_' + comp._id}
                                    label={comp.name}
                                    onDelete={() =>
                                        onChange(
                                            value.filter((v) => v !== comp._id)
                                        )
                                    }
                                />
                            );
                        })}
                    </Stack>
                )}
                renderInput={(params) => (
                    <TextField {...rest} {...params} variant="filled" />
                )}
            />
        );

    return (
        <TextField
            {...rest}
            value={value || 'null'}
            onChange={(e) => onChange(e.target.value || '')}
            variant={rest.variant || 'filled'}
            select
            InputProps={{
                ...rest.InputProps,
                sx: !value
                    ? {
                          color: palette.text.disabled,
                          ...rest.InputProps?.sx,
                      }
                    : { ...rest.InputProps?.sx },
            }}
        >
            <MenuItem disabled value={'null'}>
                Item
            </MenuItem>
            {items.map((item) => (
                <MenuItem key={'op_' + item._id} value={item._id}>
                    {item.name}
                </MenuItem>
            ))}
        </TextField>
    );
};

export default ItemField;
