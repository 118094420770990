import {
    Box,
    ButtonBase,
    Chip,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material/';
import { format } from 'date-fns';
import React, { ReactElement } from 'react';
import { MdAdd, MdCheck } from 'react-icons/md';
import {
    getGroupedCrumbs,
    getStateFromBatch,
    isSectionComplete,
    useGate,
} from '../..';
import LotPopover, {
    LotPopoverProps,
} from '../../../../../../components/display/LotPopover';
import Spanish from '../../../../../../components/display/Spanish';
import { Batch } from '../../../../../../graphql/Batch/Batch';
import { useBatchUpdate } from '../../../../../../graphql/Batch/operations/useBatchUpdate';
import { RecipeStep } from '../../../../../../graphql/RecipeStep/RecipeStep';
import { RecipeVersion } from '../../../../../../graphql/RecipeVersion/RecipeVersion';
import { dateFormats } from '../../../../../../utils/dateFormats';
import { fraction } from '../../../../../../utils/fraction';
import { niceList } from '../../../../../../utils/niceList';
import BatchLineForm from './components/BatchLineForm';

export interface BatchDetailFormProps {
    batch: Batch;
    recipe: RecipeVersion;
}

export interface BatchDetailState {
    focus: { type: 'step'; step: RecipeStep } | { type: 'crumb' };
    item: string;
    unit: string;
    qty: number | null;
    lot: string;
}

const BatchDetailForm = (props: BatchDetailFormProps): ReactElement => {
    const { recipe, batch } = props;

    const { palette, shape } = useTheme();

    const [state, setState] = React.useState<BatchDetailState | null>(null);

    const [lotFocus, setLotFocus] =
        React.useState<LotPopoverProps['children']>(null);

    const gate = useGate(batch, recipe);

    const [updateBatch] = useBatchUpdate();

    return (
        <Box
            sx={{
                ...shape,
                flex: 1,
                maxWidth: 725,
                overflow: 'hidden',
            }}
        >
            <Box
                sx={{
                    p: 1.5,
                    background: palette.background.paper,
                    display: 'Flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                }}
            >
                <Box>
                    <Typography variant="h6">{batch.recipe.name}</Typography>
                    <Spanish variant="subtitle1">
                        {[batch.item.spanish_name, batch.item.name]}
                    </Spanish>
                </Box>
                <Box sx={{ paddingRight: 1, textAlign: 'right' }}>
                    <Typography variant="subtitle1">
                        {'Mixer: ' + batch.created_by.name}
                    </Typography>
                    <Typography color="text.secondary" variant="body2">
                        {'Started: ' +
                            format(
                                new Date(batch.date_created),
                                dateFormats.time
                            )}
                    </Typography>
                    {batch.date_completed ? (
                        <Typography color="text.secondary" variant="body2">
                            {'Completed: ' +
                                format(
                                    new Date(batch.date_completed),
                                    dateFormats.time
                                )}
                        </Typography>
                    ) : batch.date_abandoned ? (
                        <Typography color="text.secondary" variant="body2">
                            {'Abandoned: ' +
                                format(
                                    new Date(batch.date_abandoned),
                                    dateFormats.time
                                )}
                        </Typography>
                    ) : (
                        <Typography variant="body2"></Typography>
                    )}
                </Box>
            </Box>
            <Box>
                {recipe.sections.map((section, sectionIndex) => (
                    <Box
                        sx={{ background: palette.tonal }}
                        key={'s_' + sectionIndex}
                    >
                        <Box
                            sx={{
                                p: 1.5,
                                gap: 1,
                                background: palette.background.paper,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Spanish variant="caption" color="text.secondary">
                                {section.label || `Section ${sectionIndex + 1}`}
                            </Spanish>
                            <Box>
                                {isSectionComplete(
                                    sectionIndex,
                                    gate,
                                    batch,
                                    recipe
                                ) && (
                                    <MdCheck
                                        style={{
                                            color: palette.success.main,
                                        }}
                                    />
                                )}
                            </Box>
                        </Box>
                        <Box>
                            {section.steps.map((step, stepIndex) => {
                                const stepLots = batch.lines.filter(
                                    (l) => l.recipe_step == step._id
                                );

                                return (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            p: 1.5,
                                        }}
                                        key={`s_${sectionIndex}_step_${stepIndex}`}
                                    >
                                        <Box>
                                            {step.content ? (
                                                <Box
                                                    sx={{
                                                        display: 'Flex',
                                                        gap: 3,
                                                    }}
                                                >
                                                    <Spanish variant="body2">
                                                        {[
                                                            niceList(
                                                                step.content.items.map(
                                                                    (i) =>
                                                                        i.spanish_name
                                                                ),
                                                                'or'
                                                            ),
                                                            niceList(
                                                                step.content.items.map(
                                                                    (i) =>
                                                                        i.name
                                                                ),
                                                                'or'
                                                            ),
                                                        ]}
                                                    </Spanish>
                                                    <Spanish
                                                        color="text.secondary"
                                                        variant="body2"
                                                    >
                                                        {[
                                                            `${fraction(
                                                                step.content
                                                                    .client_qty
                                                            )} ${
                                                                step.content
                                                                    .client_unit[
                                                                    step.content
                                                                        .client_qty >
                                                                        0 &&
                                                                    step.content
                                                                        .client_qty <=
                                                                        1
                                                                        ? 'spanish_name'
                                                                        : 'spanish_plural'
                                                                ]
                                                            }`,
                                                            `${fraction(
                                                                step.content
                                                                    .client_qty
                                                            )} ${
                                                                step.content
                                                                    .client_unit[
                                                                    step.content
                                                                        .client_qty >
                                                                        0 &&
                                                                    step.content
                                                                        .client_qty <=
                                                                        1
                                                                        ? 'name'
                                                                        : 'plural'
                                                                ]
                                                            }`,
                                                        ]}
                                                    </Spanish>
                                                </Box>
                                            ) : (
                                                <Spanish variant="body2">
                                                    {[
                                                        step.spanish || '',
                                                        step.english || '',
                                                    ]}
                                                </Spanish>
                                            )}
                                        </Box>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: 1,
                                            }}
                                        >
                                            {stepLots.map((line, lineIndex) => (
                                                <Chip
                                                    onClick={(e) =>
                                                        setLotFocus({
                                                            target: e.currentTarget,
                                                            lot: line.content
                                                                .lot,
                                                        })
                                                    }
                                                    color="success"
                                                    key={
                                                        'step_' +
                                                        stepIndex +
                                                        '_line_' +
                                                        lineIndex
                                                    }
                                                    label={
                                                        step.content &&
                                                        step.content.items
                                                            .length == 1 ? (
                                                            line.content.lot
                                                                .code
                                                        ) : (
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    flexFlow:
                                                                        'column',
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        lineHeight: 1,
                                                                    }}
                                                                    variant="caption"
                                                                >
                                                                    {
                                                                        line
                                                                            .content
                                                                            .lot
                                                                            .code
                                                                    }
                                                                </Typography>
                                                                <Typography
                                                                    sx={{
                                                                        lineHeight: 1,
                                                                    }}
                                                                    variant="caption"
                                                                >
                                                                    {
                                                                        line
                                                                            .content
                                                                            .lot
                                                                            .item
                                                                            .name
                                                                    }
                                                                </Typography>
                                                            </Box>
                                                        )
                                                    }
                                                    onDelete={() => {
                                                        const data =
                                                            getStateFromBatch(
                                                                batch
                                                            );

                                                        data.lines =
                                                            data.lines.filter(
                                                                (l) =>
                                                                    !(
                                                                        l.recipe_step ===
                                                                            step._id &&
                                                                        l
                                                                            .content
                                                                            .lot ===
                                                                            line
                                                                                .content
                                                                                .lot
                                                                                ._id
                                                                    )
                                                            );

                                                        updateBatch({
                                                            variables: {
                                                                id: batch._id,
                                                                data,
                                                            },
                                                        });
                                                    }}
                                                />
                                            ))}
                                            {step.content && (
                                                <ButtonBase
                                                    sx={{
                                                        height: 34,
                                                        width: 34,
                                                        borderRadius: 17,
                                                        background:
                                                            palette.background
                                                                .paper,
                                                    }}
                                                    onClick={() => {
                                                        if (step.content) {
                                                            setState({
                                                                focus: {
                                                                    type: 'step',
                                                                    step,
                                                                },
                                                                item:
                                                                    step.content.items.map(
                                                                        (i) =>
                                                                            i._id
                                                                    )[0] || '',
                                                                unit: step
                                                                    .content
                                                                    .client_unit
                                                                    ._id,
                                                                qty: step
                                                                    .content
                                                                    .client_qty,
                                                                lot: '',
                                                            });
                                                        }
                                                    }}
                                                >
                                                    <MdAdd
                                                        style={{
                                                            fontSize: '1rem',
                                                        }}
                                                    />
                                                </ButtonBase>
                                            )}
                                        </Box>
                                    </Box>
                                );
                            })}
                            <Box p={1} />
                        </Box>
                    </Box>
                ))}
                <Box
                    sx={{
                        p: 1.5,
                        background: palette.background.paper,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    <Spanish variant="caption" color="text.secondary">
                        {`Crumbs, toppings, and additional lots`}
                    </Spanish>
                    <MdCheck
                        style={{
                            color: palette.success.main,
                        }}
                    />
                </Box>
                <Box>
                    {Object.values(getGroupedCrumbs(batch)).map(
                        ({ item, lines }, crumbIndex) => (
                            <Box
                                key={'c_' + crumbIndex}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    background: palette.tonal,
                                    p: 1.5,
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Box>
                                    <Spanish variant="body2">
                                        {[item.name, item.name]}
                                    </Spanish>
                                </Box>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    {lines.map((line, lineIndex) => (
                                        <Chip
                                            onClick={(e) =>
                                                setLotFocus({
                                                    target: e.currentTarget,
                                                    lot: line.content.lot,
                                                })
                                            }
                                            color="success"
                                            key={
                                                'crumb_' +
                                                crumbIndex +
                                                '_line_' +
                                                lineIndex
                                            }
                                            label={line.content.lot.code}
                                            onDelete={() => {
                                                const data =
                                                    getStateFromBatch(batch);

                                                data.lines = data.lines.filter(
                                                    (l) =>
                                                        !(
                                                            l.recipe_step ===
                                                                null &&
                                                            l.content.lot ===
                                                                line.content.lot
                                                                    ._id
                                                        )
                                                );

                                                updateBatch({
                                                    variables: {
                                                        id: batch._id,
                                                        data,
                                                    },
                                                });
                                            }}
                                        />
                                    ))}
                                </Box>
                            </Box>
                        )
                    )}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            background: palette.tonal,
                            p: 1.5,
                            justifyContent: 'space-between',
                        }}
                    >
                        <Tooltip arrow title="Add crumb / topping / other">
                            <ButtonBase
                                sx={{
                                    height: 34,
                                    width: 34,
                                    borderRadius: 17,
                                    background: palette.background.paper,
                                }}
                                onClick={() => {
                                    setState({
                                        focus: {
                                            type: 'crumb',
                                        },
                                        item: '',
                                        unit: '',
                                        qty: null,
                                        lot: '',
                                    });
                                }}
                            >
                                <MdAdd
                                    style={{
                                        fontSize: '1rem',
                                    }}
                                />
                            </ButtonBase>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
            <Box p={1} />
            <Box>
                {recipe.parameters.map((param, paramIndex) => (
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        key={'param_' + paramIndex}
                    >
                        {param}
                    </Typography>
                ))}
            </Box>
            <BatchLineForm
                batch={batch}
                state={state}
                setState={(s) => setState(s)}
            />
            <LotPopover onClose={() => setLotFocus(null)}>
                {lotFocus}
            </LotPopover>
        </Box>
    );
};

export default BatchDetailForm;
