import { gql } from '@apollo/client';
import { BaseFragment } from '../../../../Base/Base';
import { getMutationHook } from '../../../../types';
import { Cookie, CookieFragment } from '../Cookie';
import { CreateCookieInput } from '../inputs/CreateCookieInput';

export const CreateCookieMutation = gql`
    ${BaseFragment}
    ${CookieFragment}
    mutation CreateCookieMutation($data: CreateCookieInput!) {
        createCookie(data: $data) {
            ...CookieFragment
        }
    }
`;

export interface CreateCookieArgs {
    data: CreateCookieInput;
}

export interface CreateCookieRes {
    createCookie: Cookie;
}

export const useCookieCreation = getMutationHook<
    CreateCookieRes,
    CreateCookieArgs
>(CreateCookieMutation);
