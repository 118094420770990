export const niceList = (list: string[], divider = '&') => {
    if (list.length == 0) return '';
    if (list.length == 1) return list[0];
    if (list.length == 2) return list[0] + ` ${divider} ` + list[1];
    else
        return (
            [...list].slice(0, -1).join(', ') +
            ` ${divider} ` +
            list[list.length - 1]
        );
};
