import { gql } from '@apollo/client';
import { Item, TinyItem } from '../../Item';

export interface Product extends Item {
    upc: string;
    sku: string;
    boxes_per_case: number[];
    cases_per_pallet: number[];
}

export interface TinyProduct extends TinyItem {
    upc: string;
    sku: string;
    boxes_per_case: number[];
    cases_per_pallet: number[];
}

export const ProductFragment = gql`
    fragment ProductFragment on Product {
        ...BaseFragment
        name
        spanish_name
        unit_class
        conversions {
            to
            multiplier
        }
        upc
        sku
        boxes_per_case
        cases_per_pallet
    }
`;

export const TinyProductFragment = gql`
    fragment TinyProductFragment on Product {
        _id
        name
        spanish_name
        unit_class
        conversions {
            to
            multiplier
        }
        upc
        sku
        boxes_per_case
        cases_per_pallet
    }
`;
