import { gql } from '@apollo/client';
import { getLazyQueryHook } from '../types';
import { ScanResult } from './ScanResult';

export const ScanQuery = gql`
    query ScanQuery($code: String!) {
        scan(code: $code) {
            type
            identifier
        }
    }
`;

export interface ScanRes {
    scan: ScanResult;
}

export interface ScanArgs {
    code: string;
}

export const useScan = getLazyQueryHook<ScanRes, ScanArgs>(ScanQuery);
