import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppThemeProvider from './providers/AppThemeProvider';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/lab';
import AuthProvider from './providers/AuthProvider';
import ApolloProvider from './providers/ApolloProvider';
import { SnackbarProvider } from './providers/SnackbarProvider';
import { Router } from './routing/router';
import { BrowserRouter } from 'react-router-dom';
import ErrorProvider from './providers/ErrorProvider';
import LanguageProvider from './providers/LanguageProvider';
import ProfileProvider from './providers/ProfileProvider';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <BrowserRouter>
        <AuthProvider>
            <ApolloProvider>
                <ProfileProvider>
                    <AppThemeProvider>
                        <ErrorProvider>
                            <LanguageProvider>
                                <LocalizationProvider dateAdapter={DateAdapter}>
                                    <SnackbarProvider>
                                        <Router />
                                    </SnackbarProvider>
                                </LocalizationProvider>
                            </LanguageProvider>
                        </ErrorProvider>
                    </AppThemeProvider>
                </ProfileProvider>
            </ApolloProvider>
        </AuthProvider>
    </BrowserRouter>
);
