import { AccountTree } from '@mui/icons-material';
import { Box, Button, Typography, useTheme } from '@mui/material/';
import { format } from 'date-fns';
import React, { ReactElement } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LotDocument from '../../../../../../components/Documents/LotDocument';
import Message from '../../../../../../components/feedback/Message';
import ResponsiveDrawer from '../../../../../../components/Layout/ResponsiveDrawer';
import { useLot } from '../../../../../../graphql/Lot/useLot';
import { dateFormats } from '../../../../../../utils/dateFormats';

const LotDrawer = (): ReactElement => {
    const { id } = useParams();
    const nav = useNavigate();
    const { palette } = useTheme();

    const { data, error, loading } = useLot({
        variables: { id: id || '' },
        skip: !id,
        fetchPolicy: 'network-only',
    });

    const lot = data ? data.lot : null;

    const getDetails = (): [string, string][] => {
        const res: [string, string][] = [];

        if (lot) {
            res.push(['Item', lot.item.name]);
            res.push(['Produced By', lot.company.name]);
            res.push([
                'Entry Date',
                format(
                    new Date(lot.date_created),
                    dateFormats.condensedDate + ' ' + dateFormats.time
                ),
            ]);
            res.push(['Entered by', lot.created_by.name]);
        }
        return res;
    };

    return (
        <ResponsiveDrawer
            anchor="right"
            open={Boolean(id)}
            onClose={() => nav('/traceability/lots')}
            PaperProps={{
                sx: {
                    p: 0,
                    minWidth: 320,
                    background: palette.tonal,
                },
            }}
        >
            {loading ? (
                <Message type="Loading" />
            ) : error || !lot ? (
                <Message type="Error">
                    {error ? error.message : 'Problem loading lot'}
                </Message>
            ) : (
                <Box sx={{ flex: 1, display: 'flex', flexFlow: 'column' }}>
                    <Box sx={{ p: 2 }}>
                        <Typography>Lot</Typography>
                        <Typography variant="h5">{lot.code}</Typography>
                    </Box>
                    <Box sx={{ flex: 1, padding: 2, paddingTop: 0 }}>
                        <LotDocument overlay={false} id={lot._id} />
                        <Box p={1} />
                        {getDetails().map(([key, value], detailIndex) => (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    paddingTop: 0.5,
                                    paddingBottom: 0.5,
                                }}
                                key={'detail_' + detailIndex}
                            >
                                <Typography color="text.secondary">
                                    {key}
                                </Typography>
                                <Typography>{value}</Typography>
                            </Box>
                        ))}
                        <Box p={2} />
                        <Button
                            endIcon={<AccountTree />}
                            onClick={() =>
                                nav(`/traceability/nodegraph/${lot._id}/lot`)
                            }
                        >
                            Launch in Node Graph
                        </Button>
                    </Box>
                </Box>
            )}
        </ResponsiveDrawer>
    );
};

export default LotDrawer;
