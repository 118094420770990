import { gql } from '@apollo/client';
import { Profile } from '../../auth/User';
import { PaginateArg } from '../types';

export interface Deletion {
    timestamp: Date;
    user: Profile;
}

export interface UpdateBaseInput {
    deleted?: boolean;
}

export interface TinyBase {
    _id: string;
}

export interface Base extends TinyBase {
    deletion: Deletion | null;
    date_created: Date;
    created_by: Profile;
    note?: string | null;
}

export interface BaseFilter extends PaginateArg {
    date_created?: { start: Date; end: Date };
    deleted?: boolean;
    created_by?: string;
    created_by_name?: string;
}

export const BaseUnionFields = [
    'Bol',
    'Company',
    'Contact',
    'Cookie',
    'Ingredient',
    'Item',
    'Itinerary',
    'Location',
    'Lot',
    'MiscItem',
    'Order',
    'Package',
    'PackingSession',
    'Product',
    'QualityCheckForm',
    'Receipt',
    'Shipment',
    'Team',
    'Unit',
];

export const createBaseFragment = () => {
    let res = ``;

    for (const type of BaseUnionFields) {
        res += `
    ... on ${type} {
        _id
        deletion {
            timestamp
            user {
                email
                name
                username
                user_id
                picture
                given_name
                family_name
                roles
            }
        }
        date_created
        created_by {
            email
            name
            username
            user_id
            picture
            given_name
            family_name
            roles
        }
        note
      }
    `;
    }

    return res;
};

export const BaseFragment = gql(String.raw`
        fragment BaseFragment on BaseUnion {
        ${createBaseFragment()}
        }
`);
