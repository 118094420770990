import {
    Box,
    Button,
    Collapse,
    SxProps,
    Theme,
    Tooltip,
    useTheme,
} from '@mui/material/';
import Typography from '@mui/material/Typography';
import React, { ReactElement } from 'react';
import { MdAdd, MdCheck, MdWarning } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import AppNav from '../../../../components/Layout/AppNav/components';
import NavContent from '../../../../components/Layout/AppNav/components/NavContent';
import {
    RecipeQuery,
    useRecipe,
} from '../../../../graphql/Recipe/operations/useRecipe';
import {
    RecipeVersionQuery,
    useRecipeVersion,
} from '../../../../graphql/RecipeVersion/operations/useRecipeVersion';
import {
    CreateRecipeVersionArgs,
    CreateRecipeVersionRes,
    useRecipeVersionCreation,
} from '../../../../graphql/RecipeVersion/operations/useRecipeVersionCreation';
import {
    UpdateRecipeVersionArgs,
    UpdateRecipeVersionRes,
    useRecipeVersionUpdate,
} from '../../../../graphql/RecipeVersion/operations/useRecipeVersionUpdate';
import ParameterForm from './components/ParameterForm';
import SectionForm from './components/SectionForm';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { relocate } from '../../../../utils/relocate';
import { LoadingButton } from '@mui/lab';
import { useTinyItems } from '../../../../graphql/Item/operations/useTinyItems';
import { ItemType, TinyItem } from '../../../../graphql/Item/Item';
import { useTinyUnits } from '../../../../graphql/Unit/operations/useUnits';
import { UnitClass } from '../../../../graphql/Unit/UnitClass';
import { ItemPluralContentInput } from '../../../../graphql/Content/ItemPluralContent/ItemPluralContentInput';
import ConversionForm from './components/ConversionForm';
import { OperationResult } from '../../../../utils/types/OperationResult';
import Message from '../../../../components/feedback/Message';
import OutputContentForm from './components/OutputContentForm';
import { RecipesQuery } from '../../../../graphql/Recipe/operations/useRecipes';
import { RecipeVersionsQuery } from '../../../../graphql/RecipeVersion/operations/useRecipeVersions';

export const getSectionStyles = (theme: Theme): SxProps<Theme> => {
    return {
        ...theme.shape,
        background: theme.palette.tonal,
        ...theme.shape,
        overflow: 'hidden',
    };
};

const RecipeDetailForm = (): ReactElement => {
    const { id: recipe_id, version: version_id } = useParams();
    const { palette } = useTheme();
    const nav = useNavigate();

    const [conversionFocus, setConversionFocus] = React.useState(false);

    const [state, setState] = React.useState<CreateRecipeVersionArgs>({
        data: {
            recipe: recipe_id || '',
            parameters: [],
            sections: [
                {
                    label: '',
                    steps: [],
                },
            ],
            base_unit_produced: null,
            note: null,
        },
    });

    const [result, setResult] =
        React.useState<null | OperationResult<CreateRecipeVersionRes>>(null);

    const [handleCreate, { loading: createLoaidng }] = useRecipeVersionCreation(
        {
            onCompleted: (data) => setResult({ success: true, data }),
            onError: (error) => setResult({ success: false, error }),
            variables: 'id' in state ? undefined : state,
            refetchQueries: [
                RecipeQuery,
                RecipesQuery,
                RecipeVersionsQuery,
                RecipeVersionQuery,
            ],
        }
    );

    const submit = () => {
        handleCreate();
    };

    const {
        data: recipeData,
        error: recipeError,
        loading: recipeLoading,
    } = useRecipe({
        variables: {
            id: recipe_id || '',
        },
        onCompleted: (d) => {
            setState({
                ...state,
                data: {
                    ...state.data,
                    recipe: d.recipe._id,
                },
            });
        },
    });

    const {
        data: versionData,
        error: versionError,
        loading: versionLoading,
    } = useRecipeVersion({
        variables: {
            id: version_id || '',
        },
        skip: !version_id,
        onCompleted: ({ recipeVersion: r }) => {
            setState({
                data: {
                    ...state.data,
                    parameters: r.parameters,
                    sections: r.sections.map((s) => ({
                        label: s.label,
                        steps: s.steps.map((step) => ({
                            english: step.english,
                            spanish: step.spanish,
                            content: !step.content
                                ? null
                                : {
                                      items: step.content.items.map(
                                          (i) => i._id
                                      ),
                                      quantity: step.content.client_qty,
                                      unit: step.content.client_unit._id,
                                  },
                        })),
                    })),
                    base_unit_produced: r.base_unit_produced,
                },
            });
        },
    });

    const recipe = recipeData ? recipeData.recipe : null;
    const version = versionData ? versionData.recipeVersion : null;

    const { data } = useTinyItems({
        variables: {
            filter: { skip: 0, take: 500, types: [ItemType.Ingredient] },
        },
    });

    const { data: unitData } = useTinyUnits({
        variables: {
            filter: { skip: 0, take: 500 },
        },
    });

    const items = data ? data.items.items : [];
    const units = unitData ? unitData.units.items : [];

    const getRequiredConversions = (): [TinyItem, UnitClass][] => {
        const required: [TinyItem, UnitClass][] = [];

        const contents = state.data.sections
            .map((s) => s.steps.map((st) => st.content))
            .flat()
            .filter((c) => c !== null) as ItemPluralContentInput[];

        for (const content of contents) {
            const thisUnit = units.find((ut) => ut._id == content.unit);
            if (thisUnit)
                for (const cItem of content.items) {
                    const thisItem = items.find((it) => it._id == cItem);

                    if (thisItem) {
                        if (
                            thisItem.unit_class !== thisUnit.class &&
                            !thisItem.conversions
                                .map((c) => c.to)
                                .includes(thisUnit.class)
                        ) {
                            required.push([thisItem, thisUnit.class]);
                        }
                    }
                }
        }

        return required;
    };

    const requiredConversions = getRequiredConversions();

    const getHoldup = (): string | null => {
        const { data: d } = state;
        if (requiredConversions.length > 0)
            return 'Unit conversions are required before save.';
        if (d.sections.length == 0) return 'Recipe is empty.';
        if (d.sections.some((s) => s.steps.length == 0))
            return 'Please remove empty sections.';
        if (d.parameters.some((p) => !p)) return 'Remove empty parameters.';
        const steps = d.sections.map((s) => s.steps).flat();
        if (steps.some((s) => !s.content && !s.english))
            return 'Some instructions are empty.';
        if (steps.some((s) => s.content !== null && s.content.items == []))
            return 'Please specify ingredients for each step.';
        if (steps.some((s) => s.content !== null && s.content.unit == ''))
            return 'Please specify a unit for each ingredient.';
        if (steps.some((s) => s.content !== null && !s.content.quantity))
            return 'Please specify a quantity for each ingredient.';
        if (d.base_unit_produced == null || d.base_unit_produced == 0)
            return 'Please specify output content.';
        return null;
    };

    const holdup = getHoldup();

    return (
        <AppNav
            loading={recipeLoading || versionLoading}
            error={recipeError || versionError}
        >
            {result ? (
                result.success ? (
                    <Message
                        type="Success"
                        onComplete={() => nav(`/recipes/recipe/${recipe_id}`)}
                    >
                        Recipe saved!
                    </Message>
                ) : (
                    <Message
                        type="Error"
                        action={
                            <Button onClick={() => setResult(null)}>
                                Try again
                            </Button>
                        }
                        error={result.error}
                    >
                        {result.error.message}
                    </Message>
                )
            ) : (
                recipe && (
                    <NavContent padding={{ header: 3, content: 3, footer: 3 }}>
                        {{
                            header: (
                                <Box>
                                    <Typography variant="crisp">{`Edit ${recipe.name}`}</Typography>
                                    <Typography
                                        variant="h6"
                                        color="text.secondary"
                                    >{`Recipe for ${recipe.item.name}`}</Typography>
                                </Box>
                            ),
                            content: (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        paddingBottom: 12,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '40px',
                                            right: '40px',
                                        }}
                                    >
                                        <Collapse
                                            in={requiredConversions.length > 0}
                                        >
                                            <Box>
                                                <Button
                                                    onClick={() =>
                                                        setConversionFocus(true)
                                                    }
                                                    size="large"
                                                    startIcon={
                                                        <MdWarning
                                                            style={{
                                                                color: palette
                                                                    .warning
                                                                    .main,
                                                            }}
                                                        />
                                                    }
                                                    color="inherit"
                                                >
                                                    {`${
                                                        requiredConversions.length
                                                    } conversion${
                                                        requiredConversions.length ==
                                                        1
                                                            ? ''
                                                            : 's'
                                                    } required`}
                                                </Button>
                                            </Box>
                                        </Collapse>
                                    </Box>
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            bottom: '40px',
                                            right: '40px',
                                        }}
                                    >
                                        <Tooltip arrow title={holdup || ''}>
                                            <Box>
                                                <LoadingButton
                                                    onClick={submit}
                                                    loading={createLoaidng}
                                                    disabled={Boolean(holdup)}
                                                    variant="contained"
                                                    endIcon={<MdCheck />}
                                                    size="large"
                                                    color="success"
                                                >
                                                    Save Recipe
                                                </LoadingButton>
                                            </Box>
                                        </Tooltip>
                                    </Box>
                                    <DragDropContext
                                        onDragEnd={(event) => {
                                            if (event.destination) {
                                                switch (
                                                    event.type as
                                                        | 'section'
                                                        | 'step'
                                                ) {
                                                    case 'section': {
                                                        const fromIndex =
                                                            event.source.index;

                                                        const toIndex =
                                                            event.destination
                                                                .index;

                                                        const copy = {
                                                            ...state,
                                                        };

                                                        relocate(
                                                            copy.data.sections,
                                                            fromIndex,
                                                            toIndex
                                                        );

                                                        setState(copy);

                                                        break;
                                                    }
                                                    case 'step': {
                                                        const prevSection =
                                                            parseInt(
                                                                event.source.droppableId.split(
                                                                    '_'
                                                                )[1]
                                                            );

                                                        const newSection =
                                                            parseInt(
                                                                event.destination.droppableId.split(
                                                                    '_'
                                                                )[1]
                                                            );

                                                        const copy = {
                                                            ...state,
                                                        };
                                                        const movingStep = {
                                                            ...copy.data
                                                                .sections[
                                                                prevSection
                                                            ].steps[
                                                                event.source
                                                                    .index
                                                            ],
                                                        };

                                                        copy.data.sections[
                                                            prevSection
                                                        ].steps.splice(
                                                            event.source.index,
                                                            1
                                                        );

                                                        copy.data.sections[
                                                            newSection
                                                        ].steps.splice(
                                                            event.destination
                                                                .index,
                                                            0,
                                                            movingStep
                                                        );

                                                        setState(copy);

                                                        break;
                                                    }
                                                }
                                            }
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexFlow: 'column',
                                                flexShrink: 1,
                                                minWidth: 650,
                                            }}
                                        >
                                            <Droppable
                                                type="section"
                                                droppableId="body_"
                                            >
                                                {(provided, snapshot) => (
                                                    <Box
                                                        ref={provided.innerRef}
                                                        {...provided.droppableProps}
                                                        sx={{
                                                            display: 'flex',
                                                            flexFlow: 'column',
                                                            flex: 1,
                                                            gap: 4,
                                                        }}
                                                    >
                                                        {state.data.sections.map(
                                                            (
                                                                section,
                                                                sIndex
                                                            ) => (
                                                                <SectionForm
                                                                    convert={() =>
                                                                        setConversionFocus(
                                                                            true
                                                                        )
                                                                    }
                                                                    index={
                                                                        sIndex
                                                                    }
                                                                    key={
                                                                        'section_' +
                                                                        sIndex
                                                                    }
                                                                >
                                                                    {{
                                                                        section,
                                                                        setSection:
                                                                            (
                                                                                d
                                                                            ) => {
                                                                                const copy =
                                                                                    {
                                                                                        ...state,
                                                                                    };
                                                                                if (
                                                                                    d
                                                                                ) {
                                                                                    copy.data.sections[
                                                                                        sIndex
                                                                                    ] =
                                                                                        d;
                                                                                } else {
                                                                                    copy.data.sections.splice(
                                                                                        sIndex,
                                                                                        1
                                                                                    );
                                                                                }
                                                                                setState(
                                                                                    copy
                                                                                );
                                                                            },
                                                                    }}
                                                                </SectionForm>
                                                            )
                                                        )}
                                                        {provided.placeholder}
                                                    </Box>
                                                )}
                                            </Droppable>
                                            <Box p={1} />
                                            <Box>
                                                <Button
                                                    variant="text"
                                                    startIcon={<MdAdd />}
                                                    onClick={() =>
                                                        setState({
                                                            ...state,
                                                            data: {
                                                                ...state.data,
                                                                sections: [
                                                                    ...state
                                                                        .data
                                                                        .sections,
                                                                    {
                                                                        label: null,
                                                                        steps: [],
                                                                    },
                                                                ],
                                                            },
                                                        })
                                                    }
                                                >
                                                    Add Section
                                                </Button>
                                            </Box>
                                            <Box p={1.5} />
                                            <ParameterForm>
                                                {{
                                                    parameters:
                                                        state.data.parameters,
                                                    setParameters: (d) =>
                                                        setState({
                                                            ...state,
                                                            data: {
                                                                ...state.data,
                                                                parameters: d,
                                                            },
                                                        }),
                                                }}
                                            </ParameterForm>
                                            <Box p={2} />
                                            <OutputContentForm
                                                recipe={recipe}
                                                data={state.data}
                                                onChange={(val) => {
                                                    const copy = { ...state };
                                                    copy.data.base_unit_produced =
                                                        val;
                                                    setState(copy);
                                                }}
                                            />
                                        </Box>
                                    </DragDropContext>
                                </Box>
                            ),
                        }}
                    </NavContent>
                )
            )}
            <ConversionForm
                focused={conversionFocus}
                setFocused={(d) => setConversionFocus(d)}
                required={requiredConversions}
            />
        </AppNav>
    );
};

export default RecipeDetailForm;
