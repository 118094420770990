import { Box, Button, Skeleton, Typography, useTheme } from '@mui/material/';
import { format } from 'date-fns';
import React, { ReactElement } from 'react';
import { MdEdit } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import RecipeVersionPreview from '../../../../../../components/display/RecipeVersionPreview';
import Message from '../../../../../../components/feedback/Message';
import { Recipe } from '../../../../../../graphql/Recipe/Recipe';
import { useRecipeVersion } from '../../../../../../graphql/RecipeVersion/operations/useRecipeVersion';
import { dateFormats } from '../../../../../../utils/dateFormats';
import { niceList } from '../../../../../../utils/niceList';
import NoVersionYet from '../NoVersionYet';

export interface RecipePreviewProps {
    children: Recipe;
}

const RecipePreview = (props: RecipePreviewProps): ReactElement => {
    const { children: recipe } = props;
    const nav = useNavigate();
    const { palette, shape } = useTheme();

    const { data, error, loading } = useRecipeVersion({
        variables: recipe.active ? { id: recipe.active._id } : undefined,
        skip: !recipe.active,
    });

    if (!recipe.active) return <NoVersionYet>{recipe}</NoVersionYet>;

    if (loading)
        return (
            <Box sx={{ display: 'Flex', flexFlow: 'column' }}>
                <Skeleton sx={{ height: '72px', width: '60%' }} />
                <Skeleton sx={{ height: '48px', width: '45%' }} />
                <Skeleton sx={{ height: '48px', width: '45%' }} />
                <Skeleton sx={{ height: '48px', width: '45%' }} />
                <Skeleton sx={{ height: '48px', width: '45%' }} />
            </Box>
        );

    if (error || !data)
        return (
            <Message type="Warning">
                {error ? error.message : 'Something went wrong.'}
            </Message>
        );

    const {
        _id,
        sections,
        base_unit_produced,
        parameters,
        created_by,
        date_created,
    } = data.recipeVersion;

    return (
        <Box sx={{ paddingTop: 2, display: 'flex' }}>
            <RecipeVersionPreview recipe={recipe}>
                {data.recipeVersion}
            </RecipeVersionPreview>
            <Box sx={{ position: 'absolute', bottom: '80px', right: '80px' }}>
                <Button
                    onClick={() => nav(_id)}
                    size="large"
                    endIcon={<MdEdit />}
                >
                    Edit Recipe
                </Button>
            </Box>
        </Box>
    );
};

export default RecipePreview;
