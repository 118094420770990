import { gql } from '@apollo/client';
import { AddressFragment } from '../../Address/Address';
import { BaseFragment } from '../../Base/Base';
import { TinyContactFragment } from '../../Contact/Contact';
import { CompanyLocationFragment } from '../../Location/Location';
import { getMutationHook } from '../../types';
import { Company, CompanyFragment } from '../Company';
import { UpdateCompanyInput } from '../inputs/UpdateCompanyInput';

export const UpdateCompanyMutation = gql`
    ${BaseFragment}
    ${CompanyFragment}
    ${TinyContactFragment}
    ${CompanyLocationFragment}
    ${AddressFragment}
    mutation UpdateCompanyMutation($id: ObjectId!, $data: UpdateCompanyInput!) {
        updateCompany(id: $id, data: $data) {
            ...CompanyFragment
        }
    }
`;

export interface UpdateCompanyArgs {
    id: string;
    data: UpdateCompanyInput;
}

export interface UpdateCompanyRes {
    updateCompany: Company;
}

export const useCompanyUpdate = getMutationHook<
    UpdateCompanyRes,
    UpdateCompanyArgs
>(UpdateCompanyMutation);
