import { Box, Button, Tooltip, useTheme } from '@mui/material';
import React, { ReactElement } from 'react';
import { MdAdd, MdCheck, MdDelete } from 'react-icons/md';
import CarefullButton from '../../../../../../../../../../../../../../components/inputs/Buttons/CarefulButton';
import SuccessButton from '../../../../../../../../../../../../../../components/inputs/Buttons/SuccessButton';
import ResponsiveDialog from '../../../../../../../../../../../../../../components/Layout/ResponsiveDialog';
import {
    UpdateBolArgs,
    UpdateBolRes,
    useBolUpdate,
} from '../../../../../../../../../../../../../../graphql/Bol/operations/useBolUpdate';
import { OperationResult } from '../../../../../../../../../../../../../../utils/types/OperationResult';
import AppointmentForm from './components/AppointmentForm';
import ContentForm from './components/ContentForm';

export interface BolFormProps {
    state: null | UpdateBolArgs;
    setState: (value: null | UpdateBolArgs) => void;
}

const BolForm = (props: BolFormProps): ReactElement => {
    const { state, setState } = props;

    const { palette } = useTheme();

    const [result, setResult] =
        React.useState<null | OperationResult<UpdateBolRes>>(null);

    const handleClose = () => {
        setState(null);
        setResult(null);
    };

    const [update, { loading }] = useBolUpdate({
        variables: state ? state : undefined,
        onCompleted: () => handleClose(),
    });

    const handleChange = (data: Partial<UpdateBolArgs['data']>) => {
        if (state) {
            setState({ ...state, data: { ...state.data, ...data } });
        }
    };

    const getHoldup = (): string | null => {
        if (
            state &&
            state.data.contents.some(
                (content) => !content.item || !content.unit || !content.quantity
            )
        )
            return 'Please specify all content fields';
        return null;
    };

    const holdup = getHoldup();

    return (
        <ResponsiveDialog
            onClose={handleClose}
            open={Boolean(state)}
            maxWidth="sm"
            fullWidth
            padding={0}
        >
            <Box sx={{ background: palette.tonal }}>
                <Box
                    sx={{
                        background: palette.background.paper,
                        padding: 2,
                        display: 'flex',
                    }}
                >
                    <Box
                        sx={{
                            flex: 1,
                            display: 'flex',
                            flexFlow: 'column',
                            alignItems: 'flex-start',
                        }}
                    >
                        {state && (
                            <AppointmentForm
                                mode="from"
                                value={state.data.from}
                                onChange={(from) => {
                                    setState({
                                        ...state,
                                        data: { ...state.data, from },
                                    });
                                }}
                            />
                        )}
                    </Box>
                    <Box
                        sx={{
                            flex: 1,
                            display: 'flex',
                            flexFlow: 'column',
                            alignItems: 'flex-start',
                        }}
                    >
                        {state && (
                            <AppointmentForm
                                mode="to"
                                value={state.data.to}
                                onChange={(to) => {
                                    setState({
                                        ...state,
                                        data: { ...state.data, to },
                                    });
                                }}
                            />
                        )}
                    </Box>
                </Box>
                {state && (
                    <Box>
                        {state.data.contents.map((content, contentIndex) => (
                            <ContentForm
                                key={'content_' + contentIndex}
                                value={content}
                                onChange={(val) => {
                                    if (state) {
                                        const copy = { ...state };

                                        if (val) {
                                            copy.data.contents[contentIndex] =
                                                val;
                                        } else {
                                            copy.data.contents.splice(
                                                contentIndex,
                                                1
                                            );
                                        }

                                        setState(copy);
                                    }
                                }}
                            />
                        ))}
                    </Box>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: 2,
                    }}
                >
                    <Button
                        startIcon={<MdAdd />}
                        variant="text"
                        onClick={() => {
                            if (state) {
                                setState({
                                    ...state,
                                    data: {
                                        ...state.data,
                                        contents: [
                                            ...state.data.contents,
                                            {
                                                item: '',
                                                unit: '',
                                                quantity: null,
                                            },
                                        ],
                                    },
                                });
                            }
                        }}
                    >
                        Content
                    </Button>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <CarefullButton
                            loading={loading}
                            startIcon={<MdDelete />}
                            onClick={() => {
                                if (state) {
                                    update({
                                        variables: {
                                            ...state,
                                            data: {
                                                ...state.data,
                                                deleted: true,
                                            },
                                        },
                                        onCompleted: () => handleClose(),
                                    });
                                }
                            }}
                        >
                            Delete BOL
                        </CarefullButton>
                        <Tooltip arrow title={holdup || ''}>
                            <Box>
                                <SuccessButton
                                    disabled={Boolean(holdup)}
                                    onClick={() => {
                                        if (state) {
                                            update();
                                        }
                                    }}
                                    endIcon={<MdCheck />}
                                    loading={loading}
                                    success={
                                        result && result.success
                                            ? 'BOl saved!'
                                            : null
                                    }
                                    onSuccess={() => {
                                        handleClose();
                                    }}
                                >
                                    Save BOL
                                </SuccessButton>
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>
            </Box>
        </ResponsiveDialog>
    );
};

export default BolForm;
