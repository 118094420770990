import { gql } from '@apollo/client';
import { BaseFragment } from '../../../../Base/Base';
import { getMutationHook } from '../../../../types';
import {
    QualityCheckForm,
    QualityCheckFormFragment,
} from '../QualityCheckForm';
import { UpdateQualityCheckFormInput } from '../inputs/UpdateQualityCheckFormInput';
import { TinyItemFragment } from '../../../../Item/Item';

export const UpdateQualityCheckFormMutation = gql`
    ${BaseFragment}
    ${QualityCheckFormFragment}
    ${TinyItemFragment}
    mutation UpdateQualityCheckFormMutation(
        $id: ObjectId!
        $data: UpdateQualityCheckFormInput!
    ) {
        updateQualityCheckForm(id: $id, data: $data) {
            ...QualityCheckFormFragment
        }
    }
`;

export interface UpdateQualityCheckFormArgs {
    id: string;
    data: UpdateQualityCheckFormInput;
}

export interface UpdateQualityCheckFormRes {
    updateQualityCheckForm: QualityCheckForm;
}

export const useQualityCheckFormUpdate = getMutationHook<
    UpdateQualityCheckFormRes,
    UpdateQualityCheckFormArgs
>(UpdateQualityCheckFormMutation);
