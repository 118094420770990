import { gql, useQuery } from '@apollo/client';
import { OrderQueueLine } from '../graphql/OrderQueue/OrderQueueLine';

const PrferenceQuery = gql`
    query PrferenceQuery($id: ObjectId!) {
        item(id: $id) {
            order_preference {
                po
                contents {
                    unit
                    item
                    quantity
                }
                customer
                customer_location
                vendor
                vendor_location
                date
                time
            }
        }
    }
`;

export interface OrderPreferenceRes {
    item: { order_preference: null | OrderQueueLine };
}

export const useItemOrderPreference = (
    item: string,
    skip: boolean,
    onComplete: (data: OrderPreferenceRes) => void
) => {
    useQuery<OrderPreferenceRes, { id: string }>(PrferenceQuery, {
        skip,
        variables: { id: item },
        onCompleted: (res) => onComplete(res),
    });
};
