import { gql } from '@apollo/client';
import { TinyItem } from '../../Item/Item';
import { TinyUnit } from '../../Unit/Unit';

export interface ItemContent {
    item: TinyItem;
    client_unit: TinyUnit;
    client_qty: number;
    qty: number;
}

export const ItemContentFragment = gql`
    fragment ItemContentFragment on ItemContent {
        item {
            ...TinyItemFragment
        }
        client_unit {
            ...TinyUnitFragment
        }
        client_qty
        qty
    }
`;
