import { gql } from '@apollo/client';
import { Permission } from '../../auth/Permission';
import { BaseFragment } from '../Base/Base';
import { getMutationHook } from '../types';
import { Team, TeamFragment } from './useTeams';

export const CreateTeamMutation = gql`
    ${BaseFragment}
    ${TeamFragment}
    mutation CreateTeamMutation($data: CreateTeamInput!) {
        createTeam(data: $data) {
            ...TeamFragment
        }
    }
`;

export interface CreateTeamRes {
    createTeam: Team;
}

export interface CreateTeamInput {
    name: string;
    description?: string | null;
    members: string[];
    permissions: Permission[];
    locations: string[] | null;
}

export interface CreateTeamArgs {
    data: CreateTeamInput;
}

export const useTeamCreation = getMutationHook<CreateTeamRes, CreateTeamArgs>(
    CreateTeamMutation
);
