import { gql } from '@apollo/client';
import { ItemPluralContent } from '../Content/ItemPluralContent/ItemPluralContent';

export interface RecipeStep {
    _id: string;
    content: ItemPluralContent | null;
    english: string | null;
    spanish: string | null;
}

export const RecipeStepFragment = gql`
    fragment RecipeStepFragment on RecipeStep {
        _id
        content {
            ...ItemPluralContentFragment
        }
        english
        spanish
    }
`;
