import { gql } from '@apollo/client';
import { LotNodeType } from './LotNodeType';

export interface LotNode {
    _id: string;
    helper: string;
    type: LotNodeType;
    code: string | null;
    item: { _id: string; name: string } | null;
    company: { _id: string; name: string } | null;
}

export const LotNodeFragment = gql`
    fragment LotNodeFragment on LotNode {
        _id
        helper
        type
        code
        item {
            _id
            name
        }
        company {
            _id
            name
        }
    }
`;
