import { Typography, useTheme } from '@mui/material/';
import Box from '@mui/material/Box';
import { format } from 'date-fns';
import React, { ReactElement } from 'react';
import { Recipe } from '../../../graphql/Recipe/Recipe';
import { RecipeVersion } from '../../../graphql/RecipeVersion/RecipeVersion';
import { dateFormats } from '../../../utils/dateFormats';
import { fraction } from '../../../utils/fraction';
import { niceList } from '../../../utils/niceList';

export interface RecipeVersionPreviewProps {
    recipe: Recipe;
    children: RecipeVersion;
}

const RecipeVersionPreview = (
    props: RecipeVersionPreviewProps
): ReactElement => {
    const {
        children: { sections, parameters, date_created, created_by, _id },
        recipe: { active },
    } = props;

    const { palette, shape } = useTheme();

    return (
        <Box>
            {!active ? (
                <Box />
            ) : active._id == _id ? (
                <Typography
                    sx={{ color: palette.success.main }}
                    variant="body2"
                >
                    <em>Current!</em>
                </Typography>
            ) : (
                <Box>
                    <Typography
                        sx={{ color: palette.warning.main }}
                        variant="body2"
                    >
                        <em>Not Current!</em>
                    </Typography>
                </Box>
            )}
            <Box sx={{ paddingTop: 2, display: 'flex' }}>
                <Box
                    sx={{
                        fleShrink: 1,
                        minWidth: 450,
                        display: 'flex',
                        flexFlow: 'column',
                        gap: 3,
                    }}
                >
                    {sections.map((section, sectionIndex) => (
                        <Box
                            key={'sec_' + sectionIndex}
                            sx={{
                                background: palette.tonal,
                                ...shape,
                                overflow: 'hidden',
                                paddingBottom: 1,
                            }}
                        >
                            <Box
                                sx={{
                                    p: 2,
                                    paddingBottom: 0.5,
                                    // background: palette.background.paper,
                                }}
                            >
                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                >
                                    {section.label ||
                                        `Section ${sectionIndex + 1}`}
                                </Typography>
                            </Box>
                            <Box>
                                {section.steps.map((step, stepIndex) => (
                                    <Box
                                        key={
                                            'sec_' +
                                            sectionIndex +
                                            '_step_' +
                                            stepIndex
                                        }
                                        sx={{
                                            padding: 1,
                                            paddingLeft: 2,
                                            paddingRight: 2,
                                        }}
                                    >
                                        {step.content ? (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Typography>
                                                    {niceList(
                                                        step.content.items.map(
                                                            (i) => i.name
                                                        ),
                                                        'or'
                                                    )}
                                                </Typography>
                                                <Typography>{`${fraction(
                                                    step.content.client_qty
                                                )} ${
                                                    step.content.client_unit[
                                                        step.content
                                                            .client_qty == 1 ||
                                                        (step.content
                                                            .client_qty > 0 &&
                                                            step.content
                                                                .client_qty < 1)
                                                            ? 'name'
                                                            : 'plural'
                                                    ]
                                                }`}</Typography>
                                            </Box>
                                        ) : (
                                            <Box>
                                                <Typography>
                                                    {step.english
                                                        ? step.english
                                                        : ''}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    ))}
                    <Box
                        sx={{
                            background: palette.tonal,
                            ...shape,
                            overflow: 'hidden',
                        }}
                    >
                        <Box
                            sx={{
                                p: 2,
                                paddingBottom: 0.5,
                            }}
                        >
                            <Typography color="textSecondary" variant="caption">
                                Parameters
                            </Typography>
                        </Box>
                        <Box sx={{ paddingBottom: 1 }}>
                            {parameters.map((param, paramIndex) => (
                                <Box
                                    key={'param_' + paramIndex}
                                    sx={{
                                        padding: 1,
                                        paddingLeft: 2,
                                        paddingRight: 2,
                                    }}
                                >
                                    <Typography>{param}</Typography>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ paddingTop: 2, paddingBottom: 8 }}>
                <Typography
                    color="text.secondary"
                    variant="caption"
                >{`Created by ${created_by.name} on ${format(
                    new Date(date_created),
                    dateFormats.condensedDate + ' @ ' + dateFormats.time
                )}`}</Typography>
            </Box>
        </Box>
    );
};

export default RecipeVersionPreview;
