import { Box, Button, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { MdAdd } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import AppNav from '../../../../../../components/Layout/AppNav/components';
import NavContent from '../../../../../../components/Layout/AppNav/components/NavContent';
import NavHeader from '../../../../../../components/Layout/NavHeader';
import TabFade from '../../../../../../components/Layout/TabFade';
import { ItemType } from '../../../../../../graphql/Item/Item';
import UnitTable from './components/UnitTable';

const typeLanguage: Record<ItemType, string> = {
    [ItemType.Cookie]: 'Cookies',
    [ItemType.Ingredient]: 'Ingredients',
    [ItemType.MiscItem]: 'Misc Items',
    [ItemType.Package]: 'Packaging',
    [ItemType.Product]: 'Product (Finished Goods)',
};

const UnitList = (): ReactElement => {
    const nav = useNavigate();
    const { item_type } = useParams();

    React.useEffect(() => {
        if (!item_type) nav(ItemType.Ingredient);
    }, [item_type, nav]);

    return (
        <AppNav>
            <NavContent>
                {{
                    header: (
                        <NavHeader>
                            <Typography variant="crisp">Units</Typography>
                            <Button
                                onClick={() =>
                                    nav(
                                        `/library/units/create/${
                                            item_type ? item_type : ''
                                        }`
                                    )
                                }
                                startIcon={<MdAdd />}
                            >
                                New Unit
                            </Button>
                        </NavHeader>
                    ),
                    content: (
                        <TabFade
                            start_index={
                                !item_type
                                    ? 0
                                    : Object.values(ItemType)
                                          .filter(
                                              (t) =>
                                                  t != ItemType.Cookie &&
                                                  t != ItemType.Product
                                          )
                                          .indexOf(item_type as ItemType)
                            }
                            onChange={(index) => {
                                const newVal = Object.values(ItemType).filter(
                                    (t) =>
                                        t != ItemType.Cookie &&
                                        t != ItemType.Product
                                )[index];

                                nav(`/library/units/${newVal}`);
                            }}
                        >
                            {Object.values(ItemType).reduce((stack, type) => {
                                if (
                                    type != ItemType.Cookie &&
                                    type != ItemType.Product
                                )
                                    return {
                                        ...stack,
                                        [typeLanguage[type as ItemType]]: (
                                            <Box sx={{ height: '100%' }}>
                                                <UnitTable
                                                    item_type={type as ItemType}
                                                />
                                            </Box>
                                        ),
                                    };
                                else return { ...stack };
                            }, {} as Record<string, ReactElement>)}
                        </TabFade>
                    ),
                }}
            </NavContent>
        </AppNav>
    );
};

export default UnitList;
