import { gql } from '@apollo/client';
import { RecipeFragment } from '../../Recipe/Recipe';
import { getMutationHook } from '../../types';
import { Folder, FolderFragment } from '../Folder';
import { UpdateFolderInput } from '../inputs/UpdateFolderInput';

export const UpdateFolder = gql`
    ${FolderFragment}
    ${RecipeFragment}
    mutation UpdateFolder($id: ObjectId!, $data: UpdateFolderInput!) {
        updateFolder(id: $id, data: $data) {
            ...FolderFragment
        }
    }
`;

export interface UpdateFolderRes {
    updateFolder: Folder;
}

export interface UpdateFolderArgs {
    id: string;
    data: UpdateFolderInput;
}

export const useFolderUpdate = getMutationHook<
    UpdateFolderRes,
    UpdateFolderArgs
>(UpdateFolder);
