import { gql } from '@apollo/client';
import { Pagination } from '../../../utils/types/Pagination';
import { getQueryHook } from '../../types';
import { Recipe, RecipeFragment } from '../Recipe';
import { RecipeFilter } from '../RecipeFilter';

export const RecipesQuery = gql`
    ${RecipeFragment}
    query RecipesQuery($filter: RecipeFilter!) {
        recipeList(filter: $filter) {
            count
            items {
                ...RecipeFragment
            }
        }
    }
`;

export interface RecipesRes {
    recipeList: Pagination<Recipe>;
}

export interface RecipesArgs {
    filter: RecipeFilter;
}

export const useRecipes = getQueryHook<RecipesRes, RecipesArgs>(RecipesQuery);
