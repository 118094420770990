import {
    Box,
    ButtonBase,
    FormControlLabel,
    IconButton,
    MenuItem,
    Switch,
    TextField,
    useTheme,
} from '@mui/material/';
import React, { ReactElement } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { MdClear, MdDragHandle } from 'react-icons/md';
import RecipeSelection from '../../../../../../../../components/inputs/RecipeSelection';
import RecipeSelectionButton from '../../../../../../../../components/inputs/RecipeSelectionButton';
import RecipeVersionButton from '../../../../../../../../components/inputs/RecipeVersionButton';
import RecipeVersionSelection from '../../../../../../../../components/inputs/RecipeVersionSelection';
import { MixingCardLineInput } from '../../../../../../../../graphql/MixingCardLine/MixingCardLineInput';

export interface MixingCardLineFormProps {
    index: number;
    line: MixingCardLineInput;
    setLine: (value: MixingCardLineInput | null) => void;
}

const MixingCardLineForm = (props: MixingCardLineFormProps): ReactElement => {
    const { line, setLine, index } = props;

    const { palette } = useTheme();

    return (
        <Draggable index={index} draggableId={'card_' + index}>
            {(provided, snapshot) => (
                <Box
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    sx={{
                        padding: 1.5,
                        paddingLeft: 2,
                        paddingRight: 2,
                        borderBottom: `1px solid ${palette.divider}`,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 5,
                    }}
                >
                    <Box {...provided.dragHandleProps}>
                        <MdDragHandle
                            style={{
                                fontSize: '1.5rem',
                                color: palette.text.secondary,
                            }}
                        />
                    </Box>
                    <RecipeSelection
                        value={line.recipe}
                        onChange={(e) => {
                            setLine({ ...line, recipe: e });
                        }}
                    >
                        {(provided) => (
                            <RecipeSelectionButton
                                sx={{ width: 150 }}
                                value={line.recipe || null}
                                onClick={(e) =>
                                    provided.setTarget(e.currentTarget)
                                }
                            />
                        )}
                    </RecipeSelection>
                    <TextField
                        sx={{ width: 130 }}
                        InputProps={{ disableUnderline: true }}
                        variant="standard"
                        fullWidth
                        select
                        value={line.qty == null ? 'null' : line.qty}
                        onChange={(e) => {
                            if (e.target.value == 'null') {
                                setLine({ ...line, qty: null });
                            } else {
                                setLine({
                                    ...line,
                                    qty: parseInt(e.target.value),
                                });
                            }
                        }}
                    >
                        <MenuItem value="null">Until stopped</MenuItem>
                        {[...Array(20).keys()].map((count) => (
                            <MenuItem
                                value={count + 1}
                                key={'count_' + count}
                            >{`${count + 1} Batch${
                                count == 0 ? '' : 'es'
                            }`}</MenuItem>
                        ))}
                    </TextField>
                    <Box sx={{ flex: 1 }} />
                    <FormControlLabel
                        label="Use latest version"
                        control={
                            <Switch
                                checked={line.recipe_version === null}
                                onChange={(e, checked) => {
                                    setLine({
                                        ...line,
                                        recipe_version: checked ? null : '',
                                    });
                                }}
                            />
                        }
                    />
                    {line.recipe_version !== null && (
                        <RecipeVersionSelection
                            recipe={line.recipe || ''}
                            value={line.recipe_version || ''}
                            onChange={(e) => {
                                setLine({ ...line, recipe_version: e });
                            }}
                        >
                            {(provided) => (
                                <RecipeVersionButton
                                    sx={{ width: 150 }}
                                    value={line.recipe_version || null}
                                    onClick={(e) =>
                                        provided.setTarget(e.currentTarget)
                                    }
                                />
                            )}
                        </RecipeVersionSelection>
                    )}
                    <Box>
                        <IconButton onClick={() => setLine(null)}>
                            <MdClear />
                        </IconButton>
                    </Box>
                </Box>
            )}
        </Draggable>
    );
};

export default MixingCardLineForm;
