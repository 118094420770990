import {
    Badge,
    Box,
    Button,
    IconButton,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    useTheme,
} from '@mui/material';
import React, { ReactElement } from 'react';
import { MdAdd, MdBadge, MdEdit } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import BaseDetails from '../../../../../../components/baseComponents/BaseDetails';
import ContactDrawer from '../../../../../../components/inputs/LightForms/ContactDrawer';
import LocationDrawer from '../../../../../../components/inputs/LightForms/LocationDrawer';
import AppNav from '../../../../../../components/Layout/AppNav/components';
import NavContent from '../../../../../../components/Layout/AppNav/components/NavContent';
import NavHeader from '../../../../../../components/Layout/NavHeader';
import TabFade from '../../../../../../components/Layout/TabFade';
import { useCompany } from '../../../../../../graphql/Company/operations/useCompany';

const CompanyDetail = (): ReactElement => {
    const { id, contact, location } = useParams();
    const nav = useNavigate();

    const { palette } = useTheme();

    const { data, error, loading } = useCompany({
        variables: { id: id || '' },
        fetchPolicy: 'network-only',
    });

    const company = data ? data.company : null;

    return (
        <AppNav error={error} loading={loading}>
            <NavContent>
                {{
                    header: (
                        <NavHeader back={['Companies', '/library/companies']}>
                            {company ? (
                                <Typography variant="crisp">
                                    {company.name}
                                </Typography>
                            ) : (
                                <Skeleton width={400} height={62} />
                            )}
                            <Box />
                        </NavHeader>
                    ),
                    content: (
                        <TabFade>
                            {{
                                Details: company ? (
                                    <Box>
                                        <BaseDetails
                                            extension={{
                                                Name: {
                                                    icon: <MdBadge />,
                                                    primary: company.name,
                                                },
                                            }}
                                        >
                                            {company}
                                        </BaseDetails>
                                        <Button
                                            onClick={() => nav('edit')}
                                            endIcon={<MdEdit />}
                                        >
                                            Edit
                                        </Button>
                                    </Box>
                                ) : (
                                    <Box />
                                ),
                                Contacts: (
                                    <Box
                                        sx={{
                                            paddingTop: 2,
                                            paddingBottom: 2,
                                            display: 'flex',
                                            flexFlow: 'column',
                                            gap: 2,
                                            maxWidth: 450,
                                        }}
                                    >
                                        {company && (
                                            <Table>
                                                <TableBody>
                                                    {company.contacts.map(
                                                        (contact) => (
                                                            <TableRow
                                                                key={
                                                                    'contact_' +
                                                                    contact._id
                                                                }
                                                            >
                                                                <TableCell>
                                                                    {
                                                                        contact.name
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Badge
                                                                        color={
                                                                            contact.send_cc ||
                                                                            contact.send_to
                                                                                ? 'primary'
                                                                                : undefined
                                                                        }
                                                                        badgeContent={
                                                                            contact.send_cc ||
                                                                            contact.send_to
                                                                                ? contact.send_cc &&
                                                                                  contact.send_to
                                                                                    ? 'Email and CC'
                                                                                    : contact.send_cc
                                                                                    ? 'CC on Order'
                                                                                    : 'Email on order'
                                                                                : ''
                                                                        }
                                                                    >
                                                                        <Box
                                                                            sx={
                                                                                contact.send_cc ||
                                                                                contact.send_to
                                                                                    ? {
                                                                                          marginTop: 1,
                                                                                      }
                                                                                    : {}
                                                                            }
                                                                        >
                                                                            {
                                                                                contact.email
                                                                            }
                                                                        </Box>
                                                                    </Badge>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <IconButton
                                                                        onClick={() =>
                                                                            nav(
                                                                                contact._id
                                                                            )
                                                                        }
                                                                        size="small"
                                                                    >
                                                                        <MdEdit />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    )}
                                                </TableBody>
                                            </Table>
                                        )}
                                        <Box sx={{ paddingTop: 4 }}>
                                            <Button
                                                onClick={() => nav('new')}
                                                startIcon={<MdAdd />}
                                            >
                                                New Contact
                                            </Button>
                                        </Box>
                                    </Box>
                                ),
                                Locations: (
                                    <Box
                                        sx={{
                                            maxWidth: 500,
                                        }}
                                    >
                                        {company && (
                                            <Box>
                                                <Stack>
                                                    {company.locations.map(
                                                        (location) => (
                                                            <Box
                                                                key={
                                                                    'location_' +
                                                                    location._id
                                                                }
                                                                sx={{
                                                                    padding: 2,
                                                                    borderBottom: `1px solid ${palette.divider}`,
                                                                }}
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                        justifyContent:
                                                                            'space-between',
                                                                    }}
                                                                >
                                                                    <Typography variant="h6">
                                                                        {
                                                                            location.label
                                                                        }
                                                                    </Typography>
                                                                    <IconButton
                                                                        onClick={() =>
                                                                            nav(
                                                                                `location/${location._id}`
                                                                            )
                                                                        }
                                                                        size="small"
                                                                    >
                                                                        <MdEdit />
                                                                    </IconButton>
                                                                </Box>
                                                                {location.address && (
                                                                    <Box>
                                                                        <Typography>
                                                                            {
                                                                                location
                                                                                    .address
                                                                                    .line_1
                                                                            }
                                                                        </Typography>
                                                                        <Typography
                                                                            color="textSecondary"
                                                                            variant="body2"
                                                                        >
                                                                            {
                                                                                location
                                                                                    .address
                                                                                    .line_2
                                                                            }
                                                                        </Typography>
                                                                        <Typography
                                                                            color="textSecondary"
                                                                            variant="body2"
                                                                        >
                                                                            {
                                                                                location
                                                                                    .address
                                                                                    .line_3
                                                                            }
                                                                        </Typography>
                                                                        <Typography
                                                                            color="textSecondary"
                                                                            variant="body2"
                                                                        >
                                                                            {`${location.address.city}, ${location.address.state} ${location.address.postal}`}
                                                                        </Typography>
                                                                    </Box>
                                                                )}
                                                            </Box>
                                                        )
                                                    )}
                                                </Stack>
                                                <Box sx={{ paddingTop: 4 }}>
                                                    <Button
                                                        onClick={() =>
                                                            nav('location/new')
                                                        }
                                                        startIcon={<MdAdd />}
                                                    >
                                                        New Location
                                                    </Button>
                                                </Box>
                                            </Box>
                                        )}
                                    </Box>
                                ),
                            }}
                        </TabFade>
                    ),
                }}
            </NavContent>
            <ContactDrawer
                default_asc={company ? [company._id] : id ? [id] : []}
                focus={!contact ? null : contact == 'new' ? true : contact}
                onClose={() => nav('/library/companies/' + id)}
            />
            <LocationDrawer
                default_company={company ? company._id : id ? id : undefined}
                focus={!location ? null : location == 'new' ? true : location}
                onClose={() => nav('/library/companies/' + id)}
            />
        </AppNav>
    );
};

export default CompanyDetail;
