import { gql } from '@apollo/client';
import { Pagination } from '../../utils/types/Pagination';
import { TinyItemFragment } from '../Item/Item';
import { getQueryHook } from '../types';
import { VerificationFragment } from '../Verification/Verification';
import { FulfillmentRow, FulfillmentRowFragment } from './FulfillmentRow';
import { FulfillmentRowFilter } from './FulfillmentRowFilter';

export const FulfillmentRowQuery = gql`
    ${FulfillmentRowFragment}
    ${VerificationFragment}
    ${TinyItemFragment}
    query FulfillmentRowQuery($filter: FulfillmentRowFilter!) {
        fulfillment_rows(filter: $filter) {
            count
            items {
                ...FulfillmentRowFragment
            }
        }
    }
`;

export interface FulfillmentRowQueryRes {
    fulfillment_rows: Pagination<FulfillmentRow>;
}

export interface FulfillmentRowQueryArgs {
    filter: FulfillmentRowFilter;
}

export const useFulfillmentRows = getQueryHook<
    FulfillmentRowQueryRes,
    FulfillmentRowQueryArgs
>(FulfillmentRowQuery);
