import React, { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import ProductDetail from './components/ProductDetail';
import ProductForm from './components/ProductForm';
import ProductList from './components/ProductList';

const Products = (): ReactElement => {
    return (
        <Routes>
            <Route index element={<ProductList />} />
            <Route path={':id'} element={<ProductDetail />} />
            <Route path={':id/edit'} element={<ProductForm />} />
            <Route path={'create'} element={<ProductForm />} />
        </Routes>
    );
};

export default Products;
