import { gql } from '@apollo/client';
import { Base, TinyBase } from '../Base/Base';
import { TinyCompany } from '../Company/Company';
import { TinyItem } from '../Item/Item';

export interface Lot extends Base {
    item: TinyItem;
    company: TinyCompany;
    code: string;
    qty: number;
}

export interface TinyLot extends TinyBase {
    item: TinyItem;
    company: TinyCompany;
    code: string;
    qty: number;
}

export const TinyLotFragment = gql`
    fragment TinyLotFragment on Lot {
        _id
        item {
            ...TinyItemFragment
        }
        company {
            ...TinyCompanyFragment
        }
        code
        qty
    }
`;

export const LotFragment = gql`
    fragment LotFragment on Lot {
        ...BaseFragment
        item {
            ...TinyItemFragment
        }
        company {
            ...TinyCompanyFragment
        }
        code
        qty
    }
`;
