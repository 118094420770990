import { gql } from '@apollo/client';
import { Profile } from '../../auth/User';

export enum VerificationStatus {
    Approved = 'Approved',
    Flagged = 'Flagged',
    Warning = 'Warning',
}

export interface Verification {
    _id: string;
    verified_by: Profile;
    status: VerificationStatus;
    note: string | null;
    date: Date;
}

export const VerificationFragment = gql`
    fragment VerificationFragment on Verification {
        _id
        verified_by {
            email
            name
            username
            user_id
            picture
            given_name
            family_name
            roles
        }
        status
        note
        date
    }
`;
