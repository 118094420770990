import { gql } from '@apollo/client';
import { BaseFragment } from '../../../../Base/Base';
import { getMutationHook } from '../../../../types';
import { Product, ProductFragment } from '../Product';
import { CreateProductInput } from '../inputs/CreateProductInput';

export const CreateProductMutation = gql`
    ${BaseFragment}
    ${ProductFragment}
    mutation CreateProductMutation($data: CreateProductInput!) {
        createProduct(data: $data) {
            ...ProductFragment
        }
    }
`;

export interface CreateProductArgs {
    data: CreateProductInput;
}

export interface CreateProductRes {
    createProduct: Product;
}

export const useProductCreation = getMutationHook<
    CreateProductRes,
    CreateProductArgs
>(CreateProductMutation);
