import Box from '@mui/material/Box';
import React, { ReactElement } from 'react';
import { LotNode } from '../../../../../../../../graphql/LotGraph/LotNode/LotNode';
import { Handle, NodeProps, Position } from 'react-flow-renderer';
import { Collapse, IconButton, Typography, useTheme } from '@mui/material/';
import { Order } from '../../../../../../../../graphql/Order/Order';
import Anima from '../../../../../../../../components/Layout/Anima';
import { MdExpandMore } from 'react-icons/md';
import { Lot } from '../../../../../../../../graphql/Lot/Lot';
import { useLot } from '../../../../../../../../graphql/Lot/useLot';
import { format } from 'date-fns';
import { dateFormats } from '../../../../../../../../utils/dateFormats';

export interface PalletNodeRenderProps extends NodeProps {
    data: LotNode;
}

const PalletNodeRender = (props: PalletNodeRenderProps): ReactElement => {
    const {
        data: { item, company, code, _id, helper },
    } = props;

    const { palette, shape } = useTheme();

    const [showDetail, setShowDetail] = React.useState(false);

    const [lot, setLot] = React.useState<Lot | null>(null);

    useLot({
        variables: { id: helper },
        onCompleted: ({ lot }) => setLot(lot),
        skip: !showDetail,
    });

    return (
        <Box sx={{ p: 2, ...shape, background: palette.background.paper }}>
            <Handle type="target" position={Position.Top} />
            <Handle type="source" position={Position.Bottom} />
            <Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>Pallet</Typography>
                    <Anima type="rotate" in={showDetail}>
                        <IconButton
                            size="small"
                            onClick={() => setShowDetail(!showDetail)}
                        >
                            <MdExpandMore />
                        </IconButton>
                    </Anima>
                </Box>
                <Collapse unmountOnExit in={Boolean(lot) && showDetail}>
                    {lot && (
                        <Box>
                            <Typography variant="body2" color="text.secondary">
                                {lot.item.name}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                            >{`Packed by ${lot.created_by.name}`}</Typography>
                            <Typography variant="body2" color="text.secondary">
                                {format(
                                    new Date(lot.date_created),
                                    dateFormats.condensedDate
                                )}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                            >{`Lot # ${lot.code}`}</Typography>
                        </Box>
                    )}
                </Collapse>
            </Box>
        </Box>
    );
};

export default PalletNodeRender;
