import React, { ReactElement } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import OrderQueue from './components/OrderQueue';
import Orders from './components/Orders';
import OrderStats from './components/OrderStats';

const Library = (): ReactElement => {
    return (
        <Routes>
            <Route index element={<Navigate to="orders" />} />
            <Route path="orders/*" element={<Orders />} />
            <Route path="orders/queue" element={<OrderQueue />} />
            <Route path="statistics" element={<OrderStats />} />
        </Routes>
    );
};

export default Library;
